import React, { useState } from "react";
import "./PayablesExpandedCard.scss";
import { Grid } from "@mui/material";

import { SearchIcon } from "../../../../components/icons/SearchIcon";
import { SearchWrapperIcon } from "../../../../components/icons/SearchWrapperIcon";
import { AgingReportModal } from "../AgingReportModal/AgingReportModal";

import { AccountStatementModal } from "../AccountStatementModal/AccountStatementModal";
import { CalendarWrapperIcon } from "../../../../components/icons/CalendarWrapperIcon";
import { CalendarIcon } from "../../../../components/icons/CalendarIcon";
import { SearchPartyModal } from "../../../../components/modals/SearchPartyModal/SearchPartyModal";
import { ReportModal } from "components/modals/ReportModal/ReportModal";
import { getCols } from "cards/payables/utils/get-cols";
import { getFilters } from "cards/payables/utils/get-filters";
const PayablesExpandedCard = (props: any) => {
  const { title, config, cardId } = props;
  const [
    accountsPayableAgingReportModalIsOpen,
    setAccountsPayableAgingReportModalIsOpen,
  ] = useState(false);
  const [
    accountsReceivableAgingReportModalIsOpen,
    setAccountsReceivableAgingReportModalIsOpen,
  ] = useState(false);
  const accountsReceivableAgingReportCols =
    getCols().accountsReceivableAgingReport;
  const accountsPayableAgingReportReportCols =
    getCols().accountsPayableAgingReport;
  //const accountsPayableAgingReportFilters = getFilters().stockReport;
  // const [accountStatementModalIsOpen, setAccountStatementModalIsOpen] =
  //   useState(false);
  // const [searchPartyModalIsOpen, setSearchPartyModalIsOpen] = useState(false);
  return (
    <>
      <div className="accounts-payable-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <a className="card-btn lg" onClick={() => {}}>
            <strong>
              Buscar {config?.partyType == "customer" ? "cliente" : "proveedor"}
            </strong>
            <div>
              <SearchWrapperIcon />
              <SearchIcon />
            </div>
          </a> */}
          {config?.partyType == "customer" ? (
            <a
              className="card-btn lg"
              onClick={() => {
                setAccountsReceivableAgingReportModalIsOpen(true);
              }}
            >
              <strong>Antigüedad de saldos (clientes)</strong>
              <div>
                <CalendarWrapperIcon />
                <CalendarIcon />
              </div>
            </a>
          ) : (
            <a
              className="card-btn lg"
              onClick={() => {
                setAccountsPayableAgingReportModalIsOpen(true);
              }}
            >
              <strong>Antigüedad de saldos (Proveedores)</strong>
              <div>
                <CalendarWrapperIcon />
                <CalendarIcon />
              </div>
            </a>
          )}
        </div>
        <ReportModal
          isOpen={accountsReceivableAgingReportModalIsOpen}
          title={"Antigüedad de saldos (clientes)"}
          listPath={"transactions/report"}
          reportName={"accountsReceivableAging"}
          onClose={() => {
            setAccountsReceivableAgingReportModalIsOpen(false);
          }}
        />
        <ReportModal
          isOpen={accountsPayableAgingReportModalIsOpen}
          title={"Antigüedad de saldos (Proveedores)"}
          listPath={"transactions/report"}
          reportName={"accountsPayableAging"}
          onClose={() => {
            setAccountsPayableAgingReportModalIsOpen(false)
          }}
        />
      </div>
    </>
  );
};

export { PayablesExpandedCard };
