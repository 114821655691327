export const getFields = () => {
  const data: any = {
    bank: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
    inCheck: [],
    outCheck: [],
  };

  return data;
};
