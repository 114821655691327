export const getFields = () => {
  const data: any = {
    userGroup: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "users",
        visibleName: "Usuarios",
        name: "users",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
    // step: [
    //   {
    //     type: "text",
    //     visibleName: "Nombre",
    //     name: "name",
    //     description: null,
    //     size: 12,
    //     editable: true,
    //     editableIf: null,
    //     validations: {},
    //   },
    //   {
    //     type: "longText",
    //     visibleName: "Descipción",
    //     name: "description",
    //     description: null,
    //     size: 12,
    //     editable: true,
    //     editableIf: null,
    //     validations: {},
    //   },
    //   // {
    //   //   type: "select",
    //   //   visibleName: "Formulario",
    //   //   name: "form",
    //   //   searchPath: "forms",
    //   //   primaryKey: "name",

    //   //   description: null,
    //   //   size: 12,
    //   //   editable: true,
    //   //   editableIf: null,
    //   //   validations: {},
    //   // },
    //   {
    //     type: "formBuilder",
    //     visibleName: "Formulario",
    //     name: "form",
    //     description: null,
    //     size: 12,
    //     editable: true,
    //     editableIf: null,
    //     validations: {},
    //   },
    //   // {
    //   //   type: "userGroups",
    //   //   visibleName: "Grupos de usuarios",
    //   //   name: "approver_user_groups",
    //   //   description: null,
    //   //   size: 6,
    //   //   editable: true,
    //   //   editableIf: null,
    //   //   validations: {},
    //   // },
    //   // {
    //   //   type: "userGroups",
    //   //   visibleName: "Grupos de usuarios",
    //   //   name: "user_groups",
    //   //   description: null,
    //   //   size: 6,
    //   //   editable: true,
    //   //   editableIf: null,
    //   //   validations: {},
    //   // },
    // ],
    // stepUsers: [
    //   {
    //     type: "separator",
    //     visibleName: "Usuarios que pueden completar este paso",
    //     size: 12,
    //     name: "completers",
    //     //name: "",
    //   },
    //   {
    //     type: "checkbox",
    //     visibleName: "Lo completa el usuario que lo inicio",
    //     name: "completable_by_initiator",
    //     description: null,
    //     size: 4,
    //     editable: true,
    //     editableIf: null,
    //     validations: {},
    //   },
    //   {
    //     type: "users",
    //     visibleName: "Usuarios",
    //     name: "completer_users",
    //     description: null,
    //     size: 4,
    //     editable: true,
    //     editableIf: null,
    //     showIf: "completable_by_initiator == false",
    //     validations: {},
    //   },

    //   {
    //     type: "multipleSelect",
    //     visibleName: "Grupos",
    //     name: "completer_user_groups",
    //     searchPath: "user_groups",
    //     primaryKey: "name",

    //     description: null,
    //     size: 4,
    //     editable: true,
    //     editableIf: null,
    //     showIf: "completable_by_initiator == false",
    //     validations: {},
    //   },
    //   {
    //     type: "separator",
    //     visibleName: "Usuarios que pueden aprobar este paso",
    //     size: 12,
    //     name: "approvers",
    //   },
    //   {
    //     type: "users",
    //     visibleName: "Usuarios",
    //     name: "approver_users",
    //     description: null,
    //     size: 6,
    //     editable: true,
    //     editableIf: null,
    //     validations: {},
    //   },
    //   {
    //     type: "multipleSelect",
    //     visibleName: "Grupos",
    //     name: "approver_user_groups",
    //     searchPath: "user_groups",
    //     primaryKey: "name",

    //     description: null,
    //     size: 6,
    //     editable: true,
    //     editableIf: null,
    //     validations: {},
    //   },
    // ],
  };

  return data;
};
