import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { addDays, subtractDays } from "../../../utils";
import "dayjs/locale/es";
import "./DateField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const DateField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    id,
    onChange,
    recordId,
    tableName,
    name,
    type, //FullDate, FullDateTime, monthYear, yearOnly, monthOnly
  }: any = props;
  const [value, setValue] = useState(null as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue == "today") {
        const today = new Date();
        const date =
          today.getFullYear() +
          "-" +
          ("0" + (today.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + today.getDate()).slice(-2);

        //onChange(date);
        setValue(date);
      } else if (
        defaultValue.includes("today") &&
        (defaultValue.includes("+") || defaultValue.includes("-"))
      ) {
        let arr;
        if (defaultValue.includes("+")) {
          arr = defaultValue.split("+");
        } else if (defaultValue.includes("-")) {
          arr = defaultValue.split("-");
        }
        let n = arr[1].trim();
        n = parseInt(n);
        const today = new Date();
        let date: any;
        if (defaultValue.includes("+")) {
          date = addDays(today, n);
        } else if (defaultValue.includes("-")) {
          date = subtractDays(today, n);
        }
        const dateStr =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2);
        //onChange(dateStr);
        setValue(dateStr);
      } else {
        setValue(defaultValue);
      }
    }
  }, []);


  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className="date-field-container">
      {label && <label>{label}</label>}
      <div>
        <div>
          {(recordId || editPath) && editable && !isEditEnabled ? (
            <span>{value}</span>
          ) : (
            <div className="datepicker-wrapper">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
                localeText={{
                  cancelButtonLabel: "Cancelar",
                  clearButtonLabel: "Limpiar",
                  okButtonLabel: "Ok",
                  todayButtonLabel: "Hoy",
                }}
              >
                <DatePicker
                  value={value ? dayjs(value) : null}
                  onChange={(newValue: any) => {
                    if (newValue) {
                      const date = new Date(newValue);

                      // Get year, month, and day part from the date
                      const year = date.toLocaleString("default", {
                        year: "numeric",
                      });
                      const month = date.toLocaleString("default", {
                        month: "2-digit",
                      });
                      const day = date.toLocaleString("default", {
                        day: "2-digit",
                      });

                      // Generate yyyy-mm-dd date string
                      const formattedDate = year + "-" + month + "-" + day;
                      setValue(formattedDate);
                    } else {
                      setValue(null);
                    }
                  }}
                  slotProps={{
                    // pass props `actions={['clear']}` to the actionBar slot
                    actionBar: { actions: ["clear", "today"] },
                  }}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>

        {(recordId || editPath) && editable && (
           <FieldEditControls
           value={value}
           fieldName={name}
           editPath={editPath}
           onEditStart={() => {
             setIsEditEnabled(true);
           }}
           onEditCancel={(originalValue) => {
             setIsEditEnabled(false);
             setValue(originalValue);
           }}
           onEditEnd={(success: boolean, originalValue) => {
             setIsEditEnabled(false);
             if (!success) {
               setValue(originalValue);
             }
           }}
         />
        )}
      </div>
      
    </div>
  );
};

export { DateField };
