import React from "react";
import urls from "config/urls";
import { SquareIcon } from "components/icons/SquareIcon";
import { SquareOutlineIcon } from "components/icons/SquareOutlineIcon";
import { CircleIcon } from "components/icons/CircleIcon";
import { CircleOutlineIcon } from "components/icons/CircleOutlineIcon";
import { CheckmarkIcon } from "components/icons/CheckMarkIcon";
const ContentViewerItem = (props: any) => {
  const { data } = props;
  return (
    <div className="content-viewer-item-container">
      {(() => {
        switch (data.type) {
          case "h1":
          case "h2":
          case "h3":
            return (
              <div
                style={{
                  fontSize: data.fontSize,
                  textAlign: data.textAlign,
                  fontWeight: data.fontWeight,
                }}
              >
                {data.content}
              </div>
            );

          case "image":
            return (
              <div className="image-wrapper">
                {data.content.path && (
                  <img src={`${urls.server}${data?.content?.path}`} />
                )}
                {data.content.description && (
                  <span>{data.content.description}</span>
                )}
              </div>
            );
          case "text":
            return (
              <div
                className="text-wrapper"
                style={{
                  fontSize: data.fontSize,
                  textAlign: data.textAlign,
                  fontWeight: data?.fontStyle.indexOf("bold") + 1 ? 600 : 300,
                  fontStyle:
                    data?.fontStyle.indexOf("italic") + 1 ? "italic" : "",
                  color: data.color,
                }}
              >
                {data.content}
              </div>
            );
          case "paragraph":
            return (
              <div
                className="paragraph-wrapper"
                style={{
                  fontSize: data.fontSize,
                  textAlign: data.textAlign,
                  fontWeight: data?.fontStyle.indexOf("bold") + 1 ? 600 : 300,
                  fontStyle:
                    data?.fontStyle.indexOf("italic") + 1 ? "italic" : "",
                  color: data.color,
                }}
              >
                {data.content}
              </div>
            );
          case "list":
            return (
              <div className="list-wrapper">
                {data.content.map((listItem: string, index: number) => {
                  return (
                    <div key={index}>
                      <span className="marker-wrapper">
                        {data?.markerType === "square" && <SquareIcon />}
                        {data?.markerType === "squareOutline" && (
                          <SquareOutlineIcon />
                        )}
                        {data?.markerType === "circle" && <CircleIcon />}
                        {data?.markerType === "circleOutline" && (
                          <CircleOutlineIcon />
                        )}
                        {data?.markerType === "checkMark" && <CheckmarkIcon />}
                      </span>
                      <div>{listItem}</div>
                    </div>
                  );
                })}
              </div>
            );
          case "table":
            return (
              <div className="table-wrapper">
                <table>
                  <tbody>
                    {data.content.map((row: any, r: number) => {
                      return (
                        <tr key={r}>
                          {row.map((col: any, c: number) => {
                            return (
                              <td key={c}>
                                <div
                                  style={{
                                    fontWeight:
                                      r == 0 && data.withHeader ? "bold" : 300,
                                  }}
                                >
                                  {col.text}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          case "code":
            return (
              <div className="code-wrapper">
                <pre>
                  <code>{data.content}</code>
                </pre>
              </div>
            );
        }
      })()}
    </div>
  );
};

export { ContentViewerItem };
