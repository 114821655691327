import { useState, useEffect } from "react";
import urls from "config/urls";
import { Button } from "@mui/material";
import "./UsersField.scss";
import { UserSelectionModal } from "components/modals/UserSelectionModal/UserSelectionModal";
import { UsersIcon } from "components/icons/UsersIcon";
import { UserListModal } from "components/modals/UserListModal/UserListModal";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const UsersField = (props: any) => {
  let {
    defaultValue = [],
    editable,
    label,
    editPath,
    onChange,
    name,
    recordId,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);



  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userListModalIsOpen, setUserListModalIsOpen] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className="users-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <FieldEditControls
          value={value}
          fieldName={name}
          editPath={editPath}
          onEditStart={() => {
            setIsEditEnabled(true);
          }}
          onEditCancel={(originalValue) => {
            setIsEditEnabled(false);
            setValue(originalValue);
          }}
          onEditEnd={(success: boolean, originalValue) => {
            setIsEditEnabled(false);
            if (!success) {
              setValue(originalValue);
            }
          }}
        />
        )}
      </div>
      <div className="field-body">
        <div>
          {recordId && editable && !isEditEnabled ? (
            <div className="readonly-content">
              {value.length == 0 ? (
                <div className="no-content">
                  <UsersIcon />
                  <span>Sin usuarios</span>
                </div>
              ) : (
                <a
                  className="avatars"
                  onClick={() => {
                    setUserListModalIsOpen(true);
                  }}
                >
                  {value?.map((item: any, index: number) => {
                    return (
                      index < 2 && (
                        <img
                          key={index}
                          className="avatar"
                          src={urls.server + item.profile_picture}
                          alt=""
                        />
                      )
                    );
                  })}
                  {value?.length > 2 && (
                    <div className="additional-count">+ {value.length - 2}</div>
                  )}
                </a>
              )}
            </div>
          ) : (
            <div className="editable-content">
              <a
                className="avatars"
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                {value?.map((item: any, index: number) => {
                  return (
                    index < 2 && (
                      <img
                        key={index}
                        className="avatar"
                        src={urls.server + item.profile_picture}
                        alt=""
                      />
                    )
                  );
                })}
                {value?.length > 2 && (
                  <div className="additional-count">+ {value.length - 2}</div>
                )}
              </a>
              {value.length === 0 && (
                <Button
                  onClick={() => {
                    setModalIsOpen(true);
                  }}
                >
                  Seleccionar usuario
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      
      <UserSelectionModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        onClose={(res: any) => {
          console.log(res);
          if (res) {
            setValue(res);
          }
        }}
        data={value}
        //subtitle={label}
        // tableName={tableName}
        // recordId={recordId}
      />
      <UserListModal
        open={userListModalIsOpen}
        setOpen={setUserListModalIsOpen}
        onClose={(res: any) => {}}
        data={value}
        //subtitle={label}
      />
    </div>
  );
};

export { UsersField };
