import React from 'react'
import Modal from 'components/Modal/Modal';
import './BoardModal.scss';
import BoardCard from 'components/BoardCard/BoardCard';
import SortableList, { SortableItem } from "react-easy-sort";
interface BoardModalProps {
  title: string;
  isOpen: boolean;
  data: any;
  onClose: () => void;
}
const handleSortEnd = (oldIndex: number, newIndex: number) => {

}
const BoardModal: React.FC<BoardModalProps> = ({ isOpen, title, data, onClose }) => {
  return (
    <Modal title={title} className={'board-modal-container'} isOpen={isOpen} onClose={onClose}>
      <SortableList
        onSortEnd={handleSortEnd}
        className="cards-wrapper"
        draggedItemClassName="dragged"
      >
        {data?.map((item: any, index: number) => {
          return (
            <SortableItem key={item.id}>
              <div style={{ zIndex: 9999 }}>
                <BoardCard
                  title={item.visible_name}
                  data={item.data}
                  config={item.config}
                  type={item.type}
                  isSortable={true}
                  isExpandable={item.is_expandable}
                  childCards={item.child_cards}
                  id={item.id}
                />
              </div>
            </SortableItem>
          );
        })}
      </SortableList>
    </Modal>
  )
}

export default BoardModal