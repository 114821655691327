export const getFields = () => {
  const customerFields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Email",
      name: "email",
      description: null,
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "integer",
      visibleName: "D.N.I",
      name: "personal_id",
      description: null,
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "integer",
      visibleName: "C.U.I.T",
      name: "tax_id",
      description: null,
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },

    {
      type: "tags",
      visibleName: "Etiquetas",
      name: "tags",
      description: null,
      size: 12,
      tagGroup: {
        id: 3,
      },
      editable: true,
      editableIf: null,
      validations: {},
    },
    // {
    //   type: "list",
    //   visibleName: "Emails",
    //   name: "emails",
    //   description: null,
    //   size: 12,
    //   editable: null,
    //   editableIf: null,
    //   //defaultValue: ["yyy", "oooo"],
    //   itemPlaceholder: "mi@email.com",
    //   validations: {},
    // },
  ];
  const productFields: any = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "checkbox",
      visibleName: "Llevar seguimiento de stock?",
      name: "is_trackable",
      //description: "assss",
      size: 4,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "integer",
      visibleName: "Stock inicial",
      name: "initial_stock",
      description: null,
      defaultValue: 1,
      size: 4,
      editable: null,
      editableIf: null,
      showIf: "is_trackable",
      validations: {},
    },
  ];
  const data: any = {
    subscription: [
      {
        type: "select",
        visibleName: "Cliente",
        name: "customer",
        searchPath: "customers",
        primaryKey: "name",
        secondaryKey: "display_identifiers",
        formFields: customerFields,
        formTitle: "Ficha de cliente",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "periodicity",
        visibleName: "Periodicidad de la facturación",
        name: "billing_periodicity",
        description: null,
        size: 12,
        editableIf: null,
        validations: {},
      },
      {
        type: "date",
        visibleName: "Fecha de inicio",
        name: "start_date",
        description: null,
        size: 4,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "Día de fact.",
        name: "billing_day",
        description: null,
        size: 4,
        editableIf: null,
        validations: {},
      },
      {
        type: "money",
        visibleName: "Total",
        name: "total",
        description: null,
        size: 4,
        editableIf: null,
        validations: {},
      },
      {
        type: "grid",
        visibleName: "Productos",
        name: "products",
        cols: [
          {
            type: "integer",
            name: "quantity",
            size: 1,
            visibleName: "Cantidad",
            defaultValue: 1,
          },
          {
            type: "select",
            size: 11,
            name: "product",
            visibleName: "Producto",
            searchPath: "products",
            primaryKey: "name",
            secondaryKey: "sku",
            placeholder: "Buscar productos...",
            formFields: productFields,
            formTitle: "Ficha de producto",
          },
        ],
        description: null,
        size: 12,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
