import { useState, useEffect } from "react";
import AxiosInterceptor from "AxiosInterceptor";
import urls from "config/urls";

type TagProps = {
  tagId: Number;
  open: boolean;
  groupId: any;
  datasort: any;
};
export const useTagActions = ({ tagId, open, groupId }: TagProps) => {
  const baseUrl = `${urls.server}/api/tags`;
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("#A597CC");
  const editTag = async () => {
    setProcessing(true);
    try {
      const tagEditId = {
        name: tagName,
        color: tagColor,
      };
      const res = await AxiosInterceptor.put(`${baseUrl}/${tagId}`, tagEditId);
      setProcessing(false);
      setFormIsOpen(false);
      setOpenAlert(true);
      loadRecords();
      const { message } = res?.data;
      setAlertMsg(message.toString());
      setSeverity("success");
      sessionStorage.removeItem("tagEdit");
    } catch (error: any) {
      setProcessing(false);
      setOpenAlert(true);
      setAlertMsg(error.toString());
      setSeverity("error");
    }
  };

  const deleteTag = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.delete(`${baseUrl}/${tagId}`);
      setProcessing(false);
      setFormIsOpen(false);
      setOpenAlert(true);
      loadRecords();
      const { message } = res?.data;
      setAlertMsg(message.toString());

      setSeverity("success");
    } catch (error: any) {
      setProcessing(false);
      setOpenAlert(true);
      setAlertMsg(error.toString());
      setSeverity("error");
    }
  };

  const createTag = async () => {
    setProcessing(true);
    try {
      const data = {
        name: tagName,
        color: tagColor,
        tag_group_id: groupId,
      };
      const res = await AxiosInterceptor.post(`${baseUrl}`, data);
      setProcessing(false);
      setFormIsOpen(false);
      loadRecords();
      setOpenAlert(true);
      setSeverity("success");
      const { message } = res?.data;
      setAlertMsg(message.toString());
    } catch (error: any) {
      setProcessing(false);
      setOpenAlert(true);
      setAlertMsg(error.toString());
      setSeverity("error");
    }
  };

  const sortTag = async (datasort: any) => {
    setProcessing(true);
    try {
      const data = {
        tag_group_id: groupId,
        tags: datasort,
      };
      const res = await AxiosInterceptor.post(`${baseUrl}/sort`, data);
      setProcessing(false);
      setFormIsOpen(false);
      loadRecords();
    } catch (error: any) {
      setProcessing(false);
      setOpenAlert(true);
      setAlertMsg(error.toString());
      setSeverity("error");
    }
  };
  const loadRecords = async () => {
    setLoading(true);
    try {
      const res = await AxiosInterceptor.get(`${baseUrl}?group_id=${groupId}`);
      const data = await res.data;
      setRecords(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) loadRecords();
  }, [open]);
  return {
    records,
    setRecords,
    editTag,
    loading,
    processing,
    openAlert,
    setOpenAlert,
    formIsOpen,
    setFormIsOpen,
    alertMsg,
    severity,
    tagName,
    setTagName,
    tagColor,
    setTagColor,
    deleteTag,
    createTag,
    sortTag,
  };
};

export default useTagActions;
