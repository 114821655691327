import { useState, useEffect, Fragment } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from "config/urls";
import CircularProgress from "@mui/material/CircularProgress";
import "./UsersAutocomplete.scss";
import { useAuth } from "../../contexts/AuthContext";
const UsersAutocomplete = ({ onSelect }: any) => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);

    const res = await AxiosInterceptor.get(
      `${urls.server}/api/users?search_term=${event.target.value}`
    );
    const data = await res.data;
    setOptions(data);
    setLoading(false);
  };
  useEffect(() => {
    onSelect(value);
    if (value) {
      setTimeout(() => {
        setValue(null);
      }, 1000);
    }
  }, [value]);
  return (
    <div className="users-autocomplete-container">
      <Autocomplete
        noOptionsText={noOptionsText}
        loadingText="Buscando..."
        //sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option: any, value) =>
          option["name"] === value["name"]
        }
        getOptionLabel={(option) => option["name"]}
        options={options}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <div className="users-autocomplete-item">
              <img src={urls.server + option?.profile_picture} alt="" />
              <div>
                <div>{option["name"]}</div>
                <span>{option["email"]}</span>
              </div>
            </div>
          </Box>
        )}
        loading={loading}
        value={value}
        onChange={(event: any, newValue: any | null) => {
          console.log(newValue);
          setValue(newValue);
        }}
        onSelect={() => {}}
        renderInput={(params) => (
          <TextField
            hiddenLabel
            onChange={handleChange}
            onFocus={handleChange}
            placeholder="Buscar usuario..."
            {...params}
            size="small"
            //label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export { UsersAutocomplete };
