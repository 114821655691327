import React, { useState, useEffect } from "react";
import {
  Dialog,
  IconButton,
  Slide,
  Fab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import "./IndicatorValueFormModal.scss";
import { CheckIcon } from "../../../../components/icons/CheckIcon";
import { CancelIcon } from "../../../../components/icons/CancelIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const IndicatorValueFormModal = ({
  open,
  setOpen,
  onDismiss,
  onClose,
  indicatorValueId,
  value,
  displayPeriod,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [data, setData] = useState([]);
  const [periodValue, setPeriodValue] = useState(value);
  //const [formData, setFormData] = useState([]);
  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/indicator-values/${indicatorValueId}/breakdown-values`
    );
    setData(res.data);
    // // Inicializar formData con la estructura correcta y valores iniciales
    // const initialFormData = res.data.map((group: any) =>
    //   group.data.map((item: any) => item.value)
    // );
    // setFormData(initialFormData);
    // console.log(initialFormData);

    setLoading(false);
  };
  useEffect(() => {
    if (open) loadData();
  }, [open]);
  const onSave = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.put(
        `${urls.server}/api/indicator-values/${indicatorValueId}/breakdown-values`,
        {
          value: periodValue,
          groups: data,
        }
      );
      const resData = await res.data;
      setProcessing(false);
      setRefreshOnClose(true);
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setAlertIsOpen(true);
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onDismiss({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="indicator-value-form-modal-container"
      >
        <div className="modal-body">
          <div>
            <div className="paper">
              <a
                onClick={() => {
                  setOpen(false);
                  onClose({
                    refresh: refreshOnClose,
                  });
                  // onDismiss({
                  //   refresh: refreshOnClose,
                  // });
                }}
              >
                <CancelIcon />
              </a>
              <span></span>
              <span></span>
              <div>
                <div>
                  <div>{displayPeriod}</div>
                  <input
                    value={periodValue || ""}
                    onChange={(e) => {
                      setPeriodValue(e.target.value);
                    }}
                  />
                </div>
                <div>
                  {data.map((item: any, index) => {
                    const sum = item.data.reduce(
                      (accumulator: number, subItem: any) => {
                        return accumulator + (parseFloat(subItem.value) || 0);
                      },
                      0
                    );
                    return (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div>{item.breakdown_group_name}</div>
                          <div>{sum.toFixed(2)}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {item.data.map((subItem: any, subIndex: number) => {
                            return (
                              <div key={subIndex}>
                                <label>{subItem.label}</label>
                                <input
                                  value={subItem.value || ""}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setData((prevData: any) => {
                                      return prevData.map(
                                        (group: any, gIndex: number) => {
                                          if (gIndex === index) {
                                            return {
                                              ...group,
                                              data: group.data.map(
                                                (item: any, iIndex: number) => {
                                                  if (iIndex === subIndex) {
                                                    return {
                                                      ...item,
                                                      value: newValue,
                                                    };
                                                  }
                                                  return item;
                                                }
                                              ),
                                            };
                                          }
                                          return group;
                                        }
                                      );
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Fab
          className="fab"
          variant="extended"
          //color="primary"
          aria-label="add"
          disabled={processing}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          onClick={onSave}
        // onClick={() => {
        //   console.log(data);
        // }}
        >
          <CheckIcon sx={{ mr: 1 }} />
          Guardar
        </Fab>
      </Dialog>
    </>
  );
};

export { IndicatorValueFormModal };
