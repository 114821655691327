import React, { useState } from "react";

const AddressField = (props: any) => {
  let { defaultValue, editable, label, onChange } = props;

  // Asegurándonos de que defaultValue es un objeto con las propiedades correctas
  defaultValue = defaultValue || {
    street: "",
    number: "",
    postalCode: "",
    neighborhood: "",
    city: "",
    province: "",
    country: "",
  };

  const [enabledEdition, setEnabledEdition] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleInputChange = (e: any, field: any) => {
    const newValue = { ...value, [field]: e.target.value };
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div className="address-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <div>
        {editable && !enabledEdition ? (
          <span>{`${value.street} ${value.number}, ${value.postalCode}, ${value.neighborhood}, ${value.city}, ${value.province}, ${value.country}`}</span>
        ) : (
          <div>
            <label>
              Calle:
              <input
                type="text"
                value={value.street}
                onChange={(e) => handleInputChange(e, "street")}
              />
            </label>
            <label>
              Número:
              <input
                type="text"
                value={value.number}
                onChange={(e) => handleInputChange(e, "number")}
              />
            </label>
            <label>
              Código Postal:
              <input
                type="text"
                value={value.postalCode}
                onChange={(e) => handleInputChange(e, "postalCode")}
              />
            </label>
            <label>
              Barrio:
              <input
                type="text"
                value={value.neighborhood}
                onChange={(e) => handleInputChange(e, "neighborhood")}
              />
            </label>
            <label>
              Ciudad:
              <input
                type="text"
                value={value.city}
                onChange={(e) => handleInputChange(e, "city")}
              />
            </label>
            <label>
              Provincia:
              <input
                type="text"
                value={value.province}
                onChange={(e) => handleInputChange(e, "province")}
              />
            </label>
            <label>
              País:
              <input
                type="text"
                value={value.country}
                onChange={(e) => handleInputChange(e, "country")}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export { AddressField };
