import React, { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";

import "./TutorialsExpandedCard.scss";
import { getRowActions } from "../../utils/get-row-actions";
import { BookWrapperIcon } from "../../../../components/icons/BookWrapperIcon";
import { BookIcon } from "../../../../components/icons/BookIcon";
import { TagsModal } from "../../../../components/modals/TagsModal/TagsModal";
import { TagsWrapperIcon } from "../../../../components/icons/TagsWrapperIcon";
import { TagsIcon } from "../../../../components/icons/TagsIcon";

import { TutorialsModal } from "../TutorialsModal/TutorialsModal";
const TutorialsExpandedCard = (props: any) => {
  const { title, data } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);

  const [tutorialsModalIsOpen, setTutorialsModalIsOpen] = useState(false);

  const tutorialFields = getFields().tutorial;

  const tutorialCols = getCols().tutorial;

  const tutorialDividerGroups = getDividerGroups().tutorial;

  const tutorialRowActions = getRowActions().tutorial;
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  return (
    <>
      <div className="tutorials-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Listado</span> */}
            <strong>Registros</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setTutorialsModalIsOpen(true);
            }}
          >
            <strong>Tutoriales</strong>
            <div>
              <BookWrapperIcon />
              <BookIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setTagsModalIsOpen(true);
            }}
          >
            <strong>Etiquetas de tutoriales</strong>
            <div>
              <TagsWrapperIcon />
              <TagsIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>

      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Tutorials"}
        tableName={"tutorials"}
        fields={tutorialFields}
        cols={tutorialCols}
        formTitle={"Tutorial"}
        dividerGroups={tutorialDividerGroups}
        rowActions={tutorialRowActions}
      />
      <TagsModal
        open={tagsModalIsOpen}
        setOpen={setTagsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        groupId={6}
        groupName={"Tutoriales"}
      />
      {/* <BookModal
        open={bookModalIsOpen}
        setOpen={setBookModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      /> */}
      <TutorialsModal
        open={tutorialsModalIsOpen}
        setOpen={setTutorialsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { TutorialsExpandedCard };
