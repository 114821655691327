import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Header } from 'components/Header/Header';
import './Modal.scss';

interface ModalProps {
    title: string;
    subtitle?: string;
    isOpen: boolean;
    children: React.ReactNode;
    className?: string;
    hasSearchBar?: boolean;
    filters?: Array<[]>;
    onClose: () => void;
    onSearchChange?: (text: string) => void;
    onActiveFiltersChange?: (activeFilters: Array<any>) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Modal: React.FC<ModalProps> = ({
    isOpen,
    children,
    title,
    subtitle,
    className,
    hasSearchBar,
    filters,
    onClose,
    onSearchChange,
    onActiveFiltersChange
}) => {
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
            className={`modal-container ${className}`}
            PaperProps={{
                style: {
                    backgroundColor: "#f2f2f4",
                },
            }}
        >

            <Header
                title={title}
                subtitle={subtitle}
                hasCloseBtn={true}
                onCloseBtnPress={onClose}
                hasSearchBar={hasSearchBar}
                onSearchChange={onSearchChange}
                filters={filters}
                onActiveFiltersChange={onActiveFiltersChange}
            />
            <div className='modal-body'>{children}</div>

        </Dialog>
    );
};

export default Modal;
