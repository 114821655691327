import { useState, useEffect, Fragment } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import "./SelectField.scss";
import {
  //TextField as MatTextField,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";

import { AddIcon } from "components/icons/AddIcon";
import { FormModal } from "components/modals/FormModal/FormModal";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const SelectField = (props: any) => {
  let {
    //id,
    name,
    editable,
    label,
    primaryKey,
    onChange,
    defaultValue,
    editPath,
    recordId,
    formFields,
    formTitle,
    formTableName,
    readonly,
    filters = [],
    searchPath,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);



  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    }
  }, [open]);

  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);
    let url = `${urls.server}/api/${searchPath}?search_term=${event.target.value
      }&offset=0&limit=20&filters=${encodeURIComponent(JSON.stringify(filters))}`;

    const res = await AxiosInterceptor.get(`${url}`);
    const data = await res?.data;
    setOptions(data);
    setLoading(false);
  };

  return (
    <div className="select-field-container">
      <div>
        {readonly ||
          ((recordId || editPath) && editable && !isEditEnabled) ||
          ((recordId || editPath) && !editable) ? (
          <div className="readonly-content">
            <div>
              {label && <label>{label}</label>}
              {value && <span>{value[primaryKey]}</span>}
            </div>
          </div>
        ) : (
          <div className="editable-content">
            <div className="autocomplete-wrapper">
              {label && <label>{label}</label>}
              <Autocomplete
                noOptionsText={noOptionsText}
                loadingText="Buscando..."
                // defaultValue={value}
                //sx={{ width: 300 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option: any, value) =>
                  option[primaryKey] === value[primaryKey]
                }
                getOptionLabel={(option) => option[primaryKey]}
                options={options || []}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option[primaryKey]}
                  </Box>
                )}
                loading={loading}
                value={value || null}
                onChange={(event: any, newValue: any | null) => {
                  //handleChangeInput(`${field.name}_id`, newValue.id);
                  onChange(newValue);
                  // setStorageValue(newValue)
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    hiddenLabel
                    onChange={handleChange}
                    onFocus={handleChange}
                    {...params}
                    size="small"
                    //label={label}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            {formFields && formTableName && (
              <IconButton
                color="primary"
                onClick={() => {
                  setFormModalIsOpen(true);
                }}
                aria-label="add customer"
                size="small"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <FieldEditControls
          value={value}
          fieldName={name}
          editPath={editPath}
          onEditStart={() => {
            setIsEditEnabled(true);
          }}
          onEditCancel={(originalValue) => {
            setIsEditEnabled(false);
            setValue(originalValue);
          }}
          onEditEnd={(success: boolean, originalValue) => {
            setIsEditEnabled(false);
            if (!success) {
              setValue(originalValue);
            }
          }}
        />
      )}

      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        //onSave={() => {}}
        title={formTitle}
        fields={formFields}
        tableName={formTableName}
      />
    </div>
  );
};

export { SelectField };
