import { useState, useEffect } from "react";

import "./DataTablesExpandedCard.scss";
import { GridWrapperIcon } from "components/icons/GridWrapperIcon";
import { GridIcon } from "components/icons/GridIcon";
import { TagsWrapperIcon } from "components/icons/TagsWrapperIcon";
import { TagsIcon } from "components/icons/TagsIcon";
import { DeleteWrapperIcon } from "components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import configurationService from "services/configurationService";
import { AddWrapperIcon } from "components/icons/AddWrapperIcon";
import { AddIcon } from "components/icons/AddIcon";
import { FormModal } from "components/modals/FormModal/FormModal";
import { RecordsModal } from "components/modals/RecordsModal/RecordsModal";
import { DeletedRecordsModal } from "components/modals/DeletedRecordsModal/DeletedRecordsModal";
const DataTablesExpandedCard = (props: any) => {
  const { title, config, cardId } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [cols, setCols] = useState([]);
  const [fields, setFields] = useState([]);
  const [dividerGroups, setDividerGroups] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const configurationNames = [
      "dataTableCols",
      "dataTableFields",
      "dataTableRowActions",
      "dataTableDividerGroups",
    ];
    configurationService
      .findByNames(configurationNames)
      .then((configurations) => {
        console.log(configurations);
        setLoading(false);
        configurations.forEach((configuration: any) => {
          switch (configuration.name) {
            case "dataTableCols":
              setCols(configuration.json_schema);
              break;
            case "dataTableFields":
              setFields(configuration.json_schema);
              break;
            case "dataTableDividerGroups":
              setDividerGroups(configuration.json_schema);
              break;
            case "dataTableRowActions":
              setRowActions(configuration.json_schema);
              break;
            default:
            // Manejar cualquier otro caso
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="data-tables-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nuevo registro</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Form. tabla de datos"}
        fields={fields}
        tableName={"data-tables"}
      />
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={title}
        tableName={"data-tables"}
        cols={cols}
        dividerGroups={dividerGroups}
        rowActions={rowActions}
        // tableActions={config?.tableActions}
        // formTitle={config?.form?.title}
        // formFields={config?.form?.fields}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { DataTablesExpandedCard };
