export const getFields = () => {
  const zoneFields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const neighborhoodFields = [
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const addressFields = [
    {
      type: "text",
      visibleName: "Calle",
      name: "street",
      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Num.",
      name: "number",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Código postal",
      name: "postal_code",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Observación",
      name: "observation",
      placeholder: "Ej; piso, manzana, bloque...",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Barrio",
      name: "neighborhood",
      searchPath: "neighborhoods",
      primaryKey: "composite_field",

      formTitle: "Barrio",
      formFields: neighborhoodFields,

      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Zona",
      name: "zone",
      searchPath: "zones",
      primaryKey: "name",

      description: null,
      formTitle: "Zona",
      formFields: zoneFields,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",

      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },

    {
      type: "coordinates",
      visibleName: "Coordenadas",
      name: "coordinates",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];

  // const data: any = {
  //   address: [
  //     {
  //       type: "text",
  //       visibleName: "Calle",
  //       name: "street",
  //       description: null,
  //       size: 6,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "text",
  //       visibleName: "Num.",
  //       name: "number",
  //       description: null,
  //       size: 3,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "text",
  //       visibleName: "Código postal",
  //       name: "postal_code",
  //       description: null,
  //       size: 3,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "longText",
  //       visibleName: "Observación",
  //       name: "observation",
  //       placeholder: "Ej; piso, manzana, bloque...",
  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "select",
  //       visibleName: "Barrio",
  //       name: "neighborhood",
  //       searchPath: "neighborhoods",
  //       primaryKey: "composite_field",
  //
  //
  //       description: null,
  //       size: 6,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "select",
  //       visibleName: "Zona",
  //       name: "zone",
  //       searchPath: "zones",
  //       primaryKey: "name",
  //
  //
  //       description: null,
  //       size: 6,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "select",
  //       visibleName: "Ciudad",
  //       name: "locality",
  //       searchPath: "localities",
  //       primaryKey: "composite_field",
  //

  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },

  //     {
  //       type: "coordinates",
  //       visibleName: "Coordenadas",
  //       name: "coordinates",
  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //   ],
  //   zone: [
  //     {
  //       type: "text",
  //       visibleName: "Nombre",
  //       name: "name",
  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "longText",
  //       visibleName: "Descipción",
  //       name: "description",
  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //   ],
  //   neighborhood: [
  //     {
  //       type: "select",
  //       visibleName: "Ciudad",
  //       name: "locality",
  //       searchPath: "localities",
  //       primaryKey: "composite_field",
  //

  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "text",
  //       visibleName: "Nombre",
  //       name: "name",
  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //     {
  //       type: "longText",
  //       visibleName: "Descipción",
  //       name: "description",
  //       description: null,
  //       size: 12,
  //       editable: true,
  //       editableIf: null,
  //       validations: {},
  //     },
  //   ],
  // };

  // return data;
  return {
    address: addressFields,
    zone: zoneFields,
    neighborhood: neighborhoodFields,
  };
};
