import { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";
import "./UserGroupsExpandedCard.scss";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { InputField } from "../../../../components/fields/InputField/InputField";
import { LongTextField } from "../../../../components/fields/LongTextField/LongTextField";
import { HeaderSearchbar } from "../../../../components/HeaderSearchbar/HeaderSearchbar";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { UsersIcon } from "../../../../components/icons/UsersIcon";
import { UserGroupListItem } from "../UserGroupListItem/UserGroupListItem";
import { LoadingButton } from "@mui/lab";
import { PlusIcon } from "../../../../components/icons/PlusIcon";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { getFields } from "../../utils/get-fileds";
const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 500,
    width: "80%",
  },
}));
const UserGroupsExpandedCard = (props: any) => {
  const { title, config, cardId, setRefreshOnClose } = props;
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const fields = getFields().userGroup;
  const classes = useStyles();
  const loadRecords = async ({ offset }: any) => {
    if (offset) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const res = await AxiosInterceptor.get(
      `${urls.server
      }/api/user-groups?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    const data = await res.data;
    if (offset > 0) {
      setRecords((prev: any) => [...prev, ...data]);
    } else {
      setRecords(data);
    }

    setLoadingMore(false);
    setLoading(false);
    if (data.length < limit) setHasMore(false);
  };
  useEffect(() => {
    setOffset(0);
    setHasMore(true);
    loadRecords({ offset: 0 });
  }, [searchTerm, filters]);
  useEffect(() => {
    if (offset > 0) loadRecords({ offset });
  }, [offset]);
  const createRecord = async () => {
    setProcessing(true);
    try {
      const data = {
        name: name,
        description: description,
      };
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/user-groups`,
        data
      );
      setProcessing(false);
      setFormIsOpen(false);
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
      setName("");
      setDescription("");
    } catch (error) {
      setProcessing(false);
    }
  };
  useEffect(() => {
    if (!formIsOpen) {
      setName("");
      setDescription("");
    }
  }, [formIsOpen]);
  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  return (
    <>
      <div className="user-groups-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
          <HeaderSearchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <div className="expanded-card-body">
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && records.length == 0 && (
            <div className="no-content">
              <UsersIcon />
              <p>No hay grupo usuarios</p>
            </div>
          )}
          {!loading && records.length > 0 && (
            <>
              <div className="card-list">
                {records.map((item: any, index: number) => {
                  return (
                    <UserGroupListItem
                      key={index}
                      id={index}
                      data={item}
                      onDelete={() => {
                        setHasMore(true);
                        setOffset(0);
                        loadRecords({ offset: 0 });
                        setRefreshOnClose(true);
                      }}
                      onChange={() => {
                        setHasMore(true);
                        setOffset(0);
                        loadRecords({ offset: 0 });
                        setRefreshOnClose(true);
                      }}
                    />
                  );
                })}
              </div>
              {hasMore && (
                <div className="loading-button-wrapper">
                  <LoadingButton
                    onClick={getMore}
                    loading={loadingMore}
                    loadingPosition="start"
                    startIcon={<PlusIcon />}
                  >
                    Más registros
                  </LoadingButton>
                </div>
              )}
            </>
          )}
        </div>
        <Fab
          className="fab"
          aria-label="add"
          onClick={() => {
            setFormIsOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
        <FormModal
          open={formIsOpen}
          setOpen={setFormIsOpen}
          fields={fields}
          title={"Grupo de usuario"}
          tableName={"user_groups"}
          onClose={(res: any) => {
            if (res) {
              setHasMore(true);
              setOffset(0);
              loadRecords({ offset: 0 });
              setRefreshOnClose(true);
            }
          }}
        />
      </div>
    </>
  );
};

export { UserGroupsExpandedCard };
