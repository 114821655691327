import React from "react";
import "./PayablesCard.scss";
import { AnimatedNumber } from "../../../../components/AnimatedNumber/AnimatedNumber";
const PayablesCard = ({ title, data, config }: any) => {
  return (
    <div className="accounts-payable-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <div>
          <AnimatedNumber
            value={data?.total}
            duration="1000"
            fontWeight="700"
            fontSize="20px"
            prepend="$"
          />
          <span>
            Total a {config?.partyType == "customer" ? "cobrar" : "pagar"}
          </span>
        </div>
        <div>
          <AnimatedNumber
            value={data?.overdue}
            duration="1000"
            fontWeight="700"
            fontSize="20px"
            prepend="$"
            color={"#ed3b4b"}
          />
          <span>Vencido</span>
        </div>
      </div>
    </div>
  );
};

export { PayablesCard };
