import React, { useState, useEffect } from "react";

import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
  Button,
  IconButton,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import "./SortableListModal.scss";
import { CloseIcon } from "../../icons/CloseIcon";
import { HeaderSearchbar } from "../../HeaderSearchbar/HeaderSearchbar";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Spinner } from "../../Spinner/Spinner";
import { DocumentsIcon } from "../../icons/DocumentsIcon";
import { AddIcon } from "../../icons/AddIcon";
import { InputField } from "../../fields/InputField/InputField";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../../../contexts/AuthContext";
const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 500,
    width: "80%",
  },
}));
const DragHandle = SortableHandle(() => <DragIndicatorIcon />);
const SortableItem = SortableElement<{ value: any; fieldName: string }>(
  ({ value, fieldName }: { value: any; fieldName: string }) => (
    <div className="sortable-item-container" style={{ zIndex: 99999999 }}>
      <div>
        <span>{value[fieldName]}</span>
      </div>
      <div>
        <DragHandle />
      </div>
    </div>
  )
);

const SortableList = SortableContainer<{ items: string[]; fieldName: string }>(
  ({ items, fieldName }: { items: string[]; fieldName: string }) => {
    return (
      <div className="sortable-list-container">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            fieldName={fieldName}
          />
        ))}
      </div>
    );
  }
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SortableListModal = ({
  open,
  setOpen,
  onDismiss,
  tableName,
  filters,
  title,
  subTitle,
  onSortEnd,
  fieldName,
  fieldLabel,
  formTitle,
  formDescription,
  extraData,
}: any) => {
  const [searchTerm, setSearchTerm] = useState("" as any);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [value, setValue] = useState("");
  const classes = useStyles();
  const loadData = async () => {
    setLoading(true);
    try {
      tableName = tableName.replace(/_/g, "-");
      let url = `${urls.server
        }/api/${tableName}?search_term=${searchTerm}&filters=${encodeURIComponent(
          JSON.stringify(filters)
        )}`;

      const res = await AxiosInterceptor.get(`${url}`);

      const data = await res.data;
      setItems(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) loadData();
  }, [open]);
  // useEffect(() => {
  //   loadData();
  // }, []);
  useEffect(() => {
    if (open) loadData();
  }, [searchTerm]);
  const create = async () => {
    setProcessing(true);
    try {
      tableName = tableName.replace(/_/g, "-");
      const data: any = extraData ? extraData : {};
      data[fieldName] = value;
      let url = `${urls.server}/api/${tableName}`;
      //const mergedData = Object.assign({}, formData, this.props.extraData);
      const res = await AxiosInterceptor.post(`${url}`, data);
      setProcessing(false);
      setFormIsOpen(false);
      loadData();
      setValue("");
      console.log(res);
    } catch (error) {
      setProcessing(false);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onDismiss({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="sortable-list-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>{title}</h2>
            {subTitle && <span>{subTitle}</span>}
          </div>
          <HeaderSearchbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onDismiss({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && items.length == 0 && (
            <div className="no-content">
              <DocumentsIcon />
              <p>No hay registros</p>
            </div>
          )}
          {!loading && items.length > 0 && (
            <SortableList
              items={items}
              onSortEnd={onSortEnd}
              fieldName={fieldName}
              useDragHandle
            />
          )}
        </div>

        <Fab
          className="fab"
          color="primary"
          aria-label="add"
          onClick={() => {
            setFormIsOpen(true);
          }}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={formIsOpen}
          onClose={() => { }}
          className="dialog-form"
          classes={{ paper: classes.dialog }}
        //maxWidth="lg"
        >
          <DialogTitle className="dialog-form-title">{formTitle}</DialogTitle>
          <DialogContent className="dialog-form-content">
            <DialogContentText className="dialog-form-description">
              {formDescription}
            </DialogContentText>
            <div className="dialog-form-inputs-wrapper">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <InputField
                    defaultValue={value}
                    label={fieldLabel}
                    //editable={true}

                    onChange={(value: string) => {
                      setValue(value);
                    }}
                  //editable
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFormIsOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={processing || !value}
              onClick={() => {
                create();
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
};

export { SortableListModal };
