import React, { useEffect, useState } from "react";
import "./BoardPage.scss";
import recordService from "services/recordService";
import { HeaderSearchbar } from "components/HeaderSearchbar/HeaderSearchbar";
import { FixUnfixCardsIcon } from "components/icons/FixUnfixCardsIcon";
import BoardCard from "components/BoardCard/BoardCard";
import { Spinner } from "components/Spinner/Spinner";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import FixUnfixBoardCardsModal from "components/modals/FixUnfixBoardCardsModal/FixUnfixBoardCardsModal";
import { useSortItems } from "hooks/useSortItems";
import { InformationMessage } from "components/InformationMessage/InformationMessage";


const BoardPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [fixUnfixCardsModalIsOpen, setFixUnfixCardsModalIsOpen] =
    React.useState(false);
  const { sortItems, openAlert, processing, setOpenAlert, alertMsg, severity } =
    useSortItems({ sortedData: [], sortingPath: `my/cards/sort` });
  const loadData = async () => {
    setLoading(true);
    try {
      const res = await recordService.fetchAll({
        listPath: 'my/cards',
      });
      setCards(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const filteredCards = cards.filter((card: any) => {
    const matchesSearchTerm = searchTerm === "" || card.visible_name.toLowerCase().includes(searchTerm.toLowerCase());
    return searchTerm === "" ? card.is_fixed && matchesSearchTerm : matchesSearchTerm;
  });

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    //Encuentra los índices correspondientes en el array completo 'cards'.
    const oldIndexInFullArray = cards.findIndex((item) => item === filteredCards[oldIndex]);
    const newIndexInFullArray = cards.findIndex((item, idx) => {
      if (newIndex === 0) { // Si se mueve al principio
        return idx === newIndex;
      } else { // Si se mueve al medio o final
        return item === filteredCards[newIndex] || cards[idx - 1] === filteredCards[newIndex - 1];
      }
    });

    if (oldIndexInFullArray < 0 || newIndexInFullArray < 0) {
      // No se encontraron índices correspondientes, posible error o el elemento no existe en el array completo
      console.error('No se pudo encontrar índices válidos para la reordenación.');
      return;
    }

    const newCards = arrayMoveImmutable(cards, oldIndexInFullArray, newIndexInFullArray);
    setCards(newCards); // Actualiza el estado con las nuevas posiciones.
    sortItems(newCards);
  };
  return (
    <>
      <div className="board-page-container page-container">
        <div className="page-header">
          <h1>Tablero</h1>
          <HeaderSearchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        {loading ? <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div> :
          <SortableList
            onSortEnd={handleSortEnd}
            className="board-cards"
            draggedItemClassName="dragged"
          >
            <a
              onClick={() => {
                setFixUnfixCardsModalIsOpen(true);
              }}
            >
              <span>Agregar/Quitar tarjetas al tablero</span>
              <FixUnfixCardsIcon />
            </a>
            {!loading && filteredCards.map((item: any, index: number) => {

              const cardData =
                item.type == "myIndicators" ||
                  item.type == "myTasks"
                  ? item.data
                  : item.card.data;
              return (
                <SortableItem key={item.id}>
                  <div>
                    <BoardCard
                      title={item.visible_name}
                      data={cardData}
                      config={item.config}
                      type={item.type}
                      isSortable={searchTerm == ""}
                      isExpandable={item.is_expandable}
                      childCards={item.child_cards}
                      id={item.id}
                      onChange={() => { loadData() }}
                    />
                  </div>
                </SortableItem>
              )
            })}
          </SortableList>
        }
        <InformationMessage
          open={openAlert}
          message={alertMsg}
          severity={severity}
          onClose={setOpenAlert}
        />
      </div>
      <FixUnfixBoardCardsModal
        isOpen={fixUnfixCardsModalIsOpen}
        onClose={() => {
          setFixUnfixCardsModalIsOpen(false);
        }}
        onChange={() => { loadData() }}
      />

    </>
  );
};

export { BoardPage };