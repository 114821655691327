export const formatFilters = (filters: any) => {
  const laravelOperators: any = {
    equals: "=",
    greater: ">",
    greater_equals: ">=",
    less: "<",
    less_equals: "<=",
    contains: "LIKE",
  };

  // Filtramos solo los activos
  const activeFilters = filters.filter((filter: any) => filter.selected);

  // Transformamos cada filtro en su representación para Laravel
  const transformedFilters = activeFilters.flatMap((filter: any) => {
    const operator = laravelOperators[filter.value.condition];
    if (filter.type === "tags") {
      const tagIds = filter.value.data.map((tag: any) => tag.id);
      return [[filter.fieldName, tagIds]];
    }
    if (filter.type === "multiSelect") {
      const ids = filter.value.data.map((item: any) => item.id);
      return [[filter.fieldName, ids]];
    }
    // if (filter.type === "statuses") {
    //   const items = filter.value.data.map((item: any) => item);
    //   return [[filter.fieldName, items]];
    // }
    // if (filter.type === "statuses") {
    //   // Mapeamos sobre filter.value.data para acceder a cada item
    //   const items = filter.value.data
    //     .map((item: any) => item.filters) // Accedemos al array de filters de cada item
    //     .reduce((acc: any, filters: any) => [...acc, ...filters], []); // Aplanamos el array de arrays

    //   return items;
    // }

    // if (filter.type === "statuses") {
    //   // Crear un array para los grupos "OR"
    //   const orGroups = filter.value.data.map((status: any) => {
    //     // Si solo hay un filtro en el estado, lo retornamos directamente
    //     // Si hay varios filtros, los envolvemos en un objeto "AND"
    //     return status.filters.length === 1
    //       ? status.filters[0]
    //       : { "and": status.filters };
    //   });

    //   // Si solo hay un grupo "OR", lo retornamos sin envolverlo en un "OR"
    //   // Si hay varios, los envolvemos en un objeto "OR"
    //   const filters = orGroups.length === 1 ? orGroups[0] : { "or": orGroups };
    //   return filters;
    // }
    if (filter.type === "statuses") {
      // Crear un array para los grupos "OR"
      const orGroups = filter.value.data.map((status: any) => {
        // Si solo hay un filtro en el estado, lo retornamos directamente dentro de un array
        // Si hay varios filtros, los envolvemos en un objeto "AND"
        return status.filters.length === 1
          ? status.filters[0] // Asegurarse de que incluso un solo filtro sea un array
          : { "and": status.filters };
      });

      // Si solo hay un grupo "OR", lo retornamos sin envolverlo en un "OR"
      // Si hay varios, los envolvemos en un objeto "OR"
      const filters = orGroups.length === 1 ? orGroups : { "or": orGroups };
      return filters;
    }
    if (filter.type == "boolean") {
      return [[filter.fieldName, filter.value.data == "yes" ? true : false]];
    }
    if (!operator) return []; // Si no hay operador para esa condición, ignoramos el filtro

    // Para el caso especial de 'contains'
    if (filter.value.condition === "contains") {
      return [[filter.fieldName, operator, `%${filter.value.data}%`]];
    }

    // Para el caso especial de 'between'
    if (filter.value.condition === "between") {
      return [
        [filter.fieldName, ">=", filter.value.data.from],
        [filter.fieldName, "<=", filter.value.data.to],
      ];
    }

    // Para los demás casos
    return [[filter.fieldName, operator, filter.value.data]];
  });

  return transformedFilters;
};
