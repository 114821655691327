import React from "react";
import { SvgIcon } from "@mui/material";
const RegularItalicIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M7.475 5.443c1.423-0.313 3.37-0.521 5.107-0.521 2.328 0 4.342 0.555 5.592 1.703 1.078 0.936 1.737 2.292 1.737 3.891 0 3.404-2.603 5.939-5.558 6.774l-0.034 0.070c1.631 0.555 2.432 2.294 2.605 4.723 0.242 3.264 0.417 5.488 0.729 6.426h-2.989c-0.138-0.487-0.379-2.953-0.555-5.662-0.174-3.194-1.354-4.55-3.681-4.55h-2.571l-1.91 10.211h-2.881l4.41-23.066zM8.312 16.072h2.569c3.647 0 6.007-2.398 6.007-5.21 0-2.501-1.875-3.613-4.514-3.613-1.148 0-1.911 0.104-2.432 0.244l-1.631 8.58z"></path>
    <path fill={props?.color} d="M28.516 5.096l-4.446 23.409h-2.885l4.446-23.409h2.885z"></path>
  </SvgIcon>
);

export { RegularItalicIcon };
