import { ContentViewer } from "components/ContentViewer/ContentViewer";
import React from "react";

const ReadonlyTextEditorField = (props: any) => {
  const { data = [] } = props;
  return (
    <div className="readonly-text-editor-field-container">
      <ContentViewer data={data} />
    </div>
  );
};

export { ReadonlyTextEditorField };
