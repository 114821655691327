import Slider from "@mui/material/Slider";
import { useState, useEffect } from "react";
import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

import "./SliderField.scss";
const SliderField = (props: any) => {
  const {
    defaultValue = 1,
    editable,
    label,
    editPath,
    id,
    onChange,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);



  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  /*   useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]); */
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };
  return (
    <div className="slider-field-container">
      {label && (
        <label className="label">
          <span>{label}</span>
        </label>
      )}
      <div>
        <div>
          {editable && !isEditEnabled ? (
            <span>{value}</span>
          ) : (
            <div className="editable-content">
              <b>{value}</b>
              <Slider
                aria-label={label}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="off"
                step={1}
                marks
                min={1}
                max={12}
              />
            </div>
          )}
        </div>

        {editable && (
           <FieldEditControls
           value={value}
           fieldName={label}
           editPath={editPath}
           onEditStart={() => {
             setIsEditEnabled(true);
           }}
           onEditCancel={(originalValue) => {
             setIsEditEnabled(false);
             setValue(originalValue);
           }}
           onEditEnd={(success: boolean, originalValue) => {
             setIsEditEnabled(false);
             if (!success) {
               setValue(originalValue);
             }
           }}
         />
        )}
      </div>
    </div>
  );
};

export { SliderField };
