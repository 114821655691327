import React from "react";
import { SvgIcon } from "@mui/material";
const AlignCenterIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M10 0h12v6h-12v-6z"></path>
    <path fill={props?.color} d="M2 8h28v6h-28v-6z"></path>
    <path fill={props?.color} d="M6 16h20v6h-20v-6z"></path>
    <path fill={props?.color} d="M0 24h32v6h-32v-6z"></path>
  </SvgIcon>
);

export { AlignCenterIcon };
