import React from "react";
import { ProductVariantList } from "../ProductVariantList/ProductVariantList";

const ReadonlyProductVariantsField = ({ data, formData }: any) => {
  return (
    <div>
      {data?.length > 0 ? (
        <div className="">
          <ProductVariantList
            data={data}
            readonly={true}
            isTrackable={formData?.is_trackable}
          />
        </div>
      ) : (
        <div className="">-</div>
      )}
    </div>
  );
};

export { ReadonlyProductVariantsField };
