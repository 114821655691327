import React, { useEffect, useState, Fragment, useRef } from "react";
import { Dialog, IconButton, Slide, Alert, Fab, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import { getFields } from "../../../utils/get-fields";
import { Field } from "./components/Field/Field";
import { AddedFields } from "./components/AddedFields/AddedFields";
import BackupIcon from "@mui/icons-material/Backup";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import "./FieldsBuilderModal.scss";
import { CheckIcon } from "../../icons/CheckIcon";
import { AddIcon } from "../../icons/AddIcon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FieldsBuilderModal = ({
  open,
  setOpen,
  onClose,

  data = [],
  savePath,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const fieldsBuilderFields = getFields();
  const [fieldsConfig, setFieldsConfig] = useState(data || []);
  const [activeFieldConfig, setActiveFieldConfig] = useState(null);
  const [activeFieldIndex, setActiveFieldIndex] = useState(null as any);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [fieldsVisible, setFieldsVisible] = useState(true);

  const toggleFieldsVisibility = () => {
    setFieldsVisible(!fieldsVisible);
  };
  const fieldsConfigCopy = JSON.parse(JSON.stringify(data));
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };
  const addField = (item: any) => {
    console.log(item);
    setFieldsConfig((prev: any) => {
      let data: any = [...prev];
      if (!data) data = [];
      data.push(item);
      return data;
    });
  };
  useEffect(() => {}, []);
  useEffect(() => {
    if (JSON.stringify(fieldsConfigCopy) === JSON.stringify(fieldsConfig)) {
      setDisabledSaveBtn(true);
    } else {
      setDisabledSaveBtn(false);
    }
  }, [fieldsConfig]);

  useEffect(() => {
    if (!open) {
      //setFieldsConfig(fieldsConfigCopy);
    }
  }, [open]);
  const saveConfig = async () => {
    if (savePath) {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/${savePath}`,
        {
          data: fieldsConfig,
        }
      );
      const data = await res.data;
      setAlertIsOpen(true);
      setDisabledSaveBtn(true);
    } else {
      setOpen(false);
      onClose(fieldsConfig);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setActiveFieldConfig(null);
          setOpen(false);
          onClose(null);
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
            position: "relative",
          },
        }}
        className="fields-builder-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Generador de campos</h2>
          </div>
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setActiveFieldConfig(null);
                setOpen(false);
                onClose(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={"modal-body"}>
          <div
            className={`fields ${
              fieldsVisible ? "fields-visible" : "fields-hidden"
            }`}
          >
            <a className="toggle-button" onClick={toggleFieldsVisibility}>
              {/* {fieldsVisible ? "Ocultar" : "Mostrar"} campos */}
              {fieldsVisible ? <CloseIcon /> : <AddIcon />}
            </a>
            <div>
              <div>
                {fieldsBuilderFields.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <Field
                        icon={item.icon}
                        visibleType={item.visibleType}
                        onAdd={() => addField(item)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="added-fields">
            <div className="paper">
              <span></span>
              <span></span>
              <div>
                <h2>Campos</h2>
                <div>
                  <AddedFields
                    items={fieldsConfig || []}
                    setActiveFieldIndex={setActiveFieldIndex}
                    activeFieldIndex={activeFieldIndex}
                    onDeleteItem={(data: any, index: number) => {
                      console.log(data);
                      console.log("index", index);
                      setFieldsConfig((prev: any) => {
                        let data: any = [...prev];

                        data?.splice(index, 1);
                        return data;
                      });
                    }}
                    onSelectItem={(data: any, index: number) => {}}
                    onChangeItem={(data: any, index: number) => {
                      console.log("ssss", index, data);
                      setFieldsConfig((prev: any) => {
                        let dataCopy: any = [...prev];

                        dataCopy[index] = data;
                        return dataCopy;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "300px" }}></div>
        </div>
        <Fab
          className="fab-btn"
          color="primary"
          aria-label="add"
          onClick={() => {
            saveConfig();
          }}
          //disabled={disabledSaveBtn}
        >
          {savePath ? <BackupIcon /> : <CheckIcon />}
        </Fab>
        <Snackbar
          open={alertIsOpen}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: "100%" }}
          >
            Formulario guardado con éxito.
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
};

export { FieldsBuilderModal };
