import React, { useState, useEffect } from "react";

import "./MiniGroupedColumnChart.scss";

const MiniGroupedColumnChart = (props: any) => {
  const { data, height = 60, onSelectGroup } = props;
  const [items, setItems] = useState([] as any);
  const addHeightToItems = (items: any, height: number): any => {
    const maxValue = Math.max(
      ...items.flatMap((item: any) => item.data.map((data: any) => data.value))
    );
    const maxHeight = height;

    return items.map((item: any) => ({
      ...item,
      data: item.data.map((data: any) => ({
        ...data,
        height: Math.round((data.value / maxValue) * maxHeight),
      })),
    }));
  };
  const handleClick = (index: number) => {
    onSelectGroup(index);
  };
  useEffect(() => {
    const newData = addHeightToItems(data, height);
    setItems(newData);
  }, []);
  return (
    <>
      <div className="mini-grouped-column-chart-container">
        <div className="main-content" style={{ height: height + "px" }}>
          {items?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="columns-wrapper"
                style={{
                  height: height + "px",
                }}
                onClick={() => handleClick(index)}
              >
                {item.data.map((subItem: any, subIndex: number) => {
                  return (
                    <a
                      key={subIndex}
                      style={{
                        height: subItem.height + "px",
                        background: subItem.color || "#ccc",
                      }}
                    ></a>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { MiniGroupedColumnChart };
