import React, { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";
import Grid from "@mui/material/Grid";
import "./TaxesExpandedCard.scss";
import { getRowActions } from "../../utils/get-row-actions";
const TaxesExpandedCard = (props: any) => {
  const { title, data } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [valueAddedTaxRecordsModalIsOpen, setValueAddedTaxRecordsModalIsOpen] =
    useState(false);
  const [otherTaxRecordsModalIsOpen, setOtherTaxRecordsModalIsOpen] =
    useState(false);
  const [withholdingRecordsModalIsOpen, setWithholdingRecordsModalIsOpen] =
    useState(false);
  const [perceptionRecordsModalIsOpen, setPerceptionRecordsModalIsOpen] =
    useState(false);

  const [valueAddedTaxFormModalIsOpen, setValueAddedTaxFormModalIsOpen] =
    useState(false);
  const [otherTaxFormModalIsOpen, setOtherTaxFormModalIsOpen] = useState(false);
  const [withholdingFormModalIsOpen, setWithholdingFormModalIsOpen] =
    useState(false);
  const [perceptionFormModalIsOpen, setPerceptionFormModalIsOpen] =
    useState(false);

  const valueAddedTaxFields = getFields().valueAddedTax;
  const valueAddedTaxCols = getCols().valueAddedTax;
  const valueAddedTaxDividerGroups = getDividerGroups().valueAddedTax;

  const otherTaxFields = getFields().otherTax;
  const otherTaxCols = getCols().otherTax;
  const otherTaxDividerGroups = getDividerGroups().otherTax;

  const perceptionFields = getFields().perception;
  const perceptionCols = getCols().perception;
  const perceptionDividerGroups = getDividerGroups().perception;

  const withholdingFields = getFields().withholding;
  const withholdingCols = getCols().withholding;
  const withholdingDividerGroups = getDividerGroups().withholding;

  const valueAddedTaxRowActions = getRowActions().valueAddedTax;
  const otherTaxRowActions = getRowActions().otherTax;
  const perceptionRowActions = getRowActions().perception;
  const withholdingRowActions = getRowActions().withholding;

  return (
    <>
      <div className="taxes-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setValueAddedTaxRecordsModalIsOpen(true);
            }}
          >
            <strong>IVAs</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setWithholdingRecordsModalIsOpen(true);
            }}
          >
            <strong>Retenciones</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setPerceptionRecordsModalIsOpen(true);
            }}
          >
            <strong>Percepciones</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setOtherTaxRecordsModalIsOpen(true);
            }}
          >
            <strong>Otros impuestos</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      {/* <FormModal
        open={valueAddedTaxFormModalIsOpen}
        setOpen={setValueAddedTaxFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Ficha de IVA"}
        fields={valueAddedTaxFields}
        tableName={"value-added-taxes"}
      /> */}
      <RecordsModal
        open={valueAddedTaxRecordsModalIsOpen}
        setOpen={setValueAddedTaxRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"IVAs"}
        tableName={"value-added-taxes"}
        cols={valueAddedTaxCols}
        dividerGroups={valueAddedTaxDividerGroups}
        fields={valueAddedTaxFields}
        formTitle={"IVAs"}
        rowActions={valueAddedTaxRowActions}
      />

      {/* <FormModal
        open={otherTaxFormModalIsOpen}
        setOpen={setOtherTaxFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Formulario de otros impuestos"}
        fields={otherTaxFields}
        tableName={"other-taxes"}
      /> */}
      <RecordsModal
        open={otherTaxRecordsModalIsOpen}
        setOpen={setOtherTaxRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Otros impuestos"}
        tableName={"other-taxes"}
        cols={otherTaxCols}
        dividerGroups={otherTaxDividerGroups}
        formTitle={"Otros impuestos"}
        fields={otherTaxFields}
        rowActions={otherTaxRowActions}
      />
      {/* <FormModal
        open={perceptionFormModalIsOpen}
        setOpen={setPerceptionFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Formulario de percepcion"}
        fields={perceptionFields}
        tableName={"perceptions"}
      /> */}
      <RecordsModal
        open={perceptionRecordsModalIsOpen}
        setOpen={setPerceptionRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Percepciones"}
        tableName={"perceptions"}
        cols={perceptionCols}
        dividerGroups={perceptionDividerGroups}
        formTitle={"Percepciones"}
        fields={perceptionFields}
        rowActions={perceptionRowActions}
      />
      {/* <FormModal
        open={withholdingFormModalIsOpen}
        setOpen={setWithholdingFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Formulario de retencion"}
        fields={withholdingFields}
        tableName={"withholdings"}
      /> */}
      <RecordsModal
        open={withholdingRecordsModalIsOpen}
        setOpen={setWithholdingRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Retenciones"}
        tableName={"withholdings"}
        cols={withholdingCols}
        dividerGroups={withholdingDividerGroups}
        formTitle={"Retenciones"}
        fields={withholdingFields}
        rowActions={withholdingRowActions}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { TaxesExpandedCard };
