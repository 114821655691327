import { SvgIcon } from "@mui/material";
const SquareOutlineIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 26.361 26.361">
    <path
      d="M24.362,0H2C0.896,0,0,0.896,0,2v22.36c0,1.104,0.896,2.001,2,2.001h22.361c1.104,0,2-0.896,2-2.001V2
		C26.362,0.896,25.467,0,24.362,0z M22.362,22.362H4V4h18.361L22.362,22.362L22.362,22.362z"
    />
  </SvgIcon>
);

export { SquareOutlineIcon };
