import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../components/fields/InputField/InputField";
import { Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import "./SignUpPage.scss";
import urls from "config/urls";
import { PasswordField } from "../../components/fields/PasswordField/PasswordField";
import LogoAnimation from "../../components/animations/LogoAnimation";
import { Spinner } from "../../components/Spinner/Spinner";
import FooterAnimation from "components/animations/FooterAnimation";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [processing, setProcessing] = useState(false);
  // const [alertIsOpen, setAlertIsOpen] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState(false);
  const [successAlertIsOpen, setSuccessAlertIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const handleChangeEmail = (value: any) => {
    console.log("value", value);
    setEmail(value);
  };
  const handleChangePassword = (value: any) => {
    console.log("value", value);
    setPassword(value);
  };
  const send = async () => {
    setProcessing(true);
    try {
      const res = await axios.post(`${urls.server}/api/register`, {
        email,
        password,
        password_confirmation: passwordConfirmation,
      });
      const data = await res.data;
      setProcessing(false);
      setSuccessMsg("¡Registro exitoso! Ahora puedes iniciar sesión.");
      setSuccessAlertIsOpen(true);
      setTimeout(() => {
        setSuccessAlertIsOpen(false);
        navigate("/sign-in");
      }, 2000);
    } catch (error: any) {
      setProcessing(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = parseErrorMessages(error.response.data.errors);
        setErrorMsg(errorMessages);
      } else {
        setErrorMsg("Ha ocurrido un error en el servidor.");
      }
      setErrorAlertIsOpen(true);
    }
  };
  function parseErrorMessages(errors: any) {
    let errorMessages = [];
    for (let field in errors) {
      errorMessages.push(errors[field].join(" "));
    }
    return errorMessages.join("\n");
  }
  const getSettings = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${urls.server}/api/public/settings`);
      setLoading(false);
      const data = await res.data.data;
      setLogo(data.logo);
    } catch (error: any) {
      error = JSON.parse(JSON.stringify(error));

      setLoading(false);
    }
  };
  useEffect(() => {
    getSettings();
  }, []);
  return (
    <div className="sign-in-page-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && (
        <>
          <div className="logo-wrapper">
            {/* <img src={"assets/logos/logo.svg"} alt="" /> */}
            {logo ? <img src={urls.server + logo} alt="" /> : <LogoAnimation />}
          </div>

          <div className="form-wrapper">
            <h1>Registrarse</h1>
            <form>
              <div>
                <InputField
                  placeholder="Email"
                  defaultValue=""
                  //editable={true}
                  id="email"
                  onChange={(value: any) => handleChangeEmail(value)}
                />
              </div>
              <div>
                <PasswordField
                  onChange={(value: any) => handleChangePassword(value)}
                />
              </div>
              <div>
                <PasswordField
                  onChange={(value: any) => {
                    setPasswordConfirmation(value);
                  }}
                />
              </div>
              <div>
                <Button
                  onClick={send}
                  variant="contained"
                  disableElevation
                  disabled={processing}
                >
                  Registrarse
                </Button>
              </div>
              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  Ir al ingreso
                </Button>
              </div>
            </form>
          </div>

          <div className="city-wrapper">
            <FooterAnimation name="cafayate" />
          </div>
        </>
      )}
      <Snackbar
        open={errorAlertIsOpen}
        autoHideDuration={6000}
        onClose={() => setErrorAlertIsOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setErrorAlertIsOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successAlertIsOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessAlertIsOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setSuccessAlertIsOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      {/* <Snackbar
        open={alertIsOpen}
        autoHideDuration={6000}
        onClose={() => setAlertIsOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertIsOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar> */}
    </div>
  );
};
export { SignUpPage };
