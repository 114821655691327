import colors from "config/colors";

export const getCols = () => {
  const data: any = {
    invoice: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: false,
      },
      {
        type: "text",
        visibleName: "Cliente",
        name: "party_name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "C.U.I.T",
        name: "party_tax_id",
        sortable: true,
      },
      {
        type: "money",
        visibleName: "Saldo",
        name: "balance",
        sortable: true,
      },
      {
        type: "money",
        visibleName: "Total",
        name: "total",
        sortable: true,
      },
      {
        type: "status",
        visibleName: "Estado",
        name: "status",
        readonly: true,
        options: [
          {
            name: "paid",
            visibleName: "Pagada",
            color: colors.success,
            icon: 'check'
          },
          {
            name: "unpaid",
            visibleName: "Impaga",
            color: colors.danger,
            icon: 'alert'
          },
          {
            name: "partialPayment",
            visibleName: "Pago parcial",
            color: colors.dark,
            icon: 'alert'
          }
        ],
      },
    ],
    //order: [],
    quotation: [],
    interestRate: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },

      {
        type: "text",
        visibleName: "Desc.",
        name: "description",
        sortable: true,
      },
    ],
    priceList: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "radio",
        visibleName: "Tipo de ajuste",
        name: "adjustment_type",
        sortable: true,
        options: [
          {
            name: "profit_margin",
            visibleName: "Margen de ganancia",
          },
          {
            name: "discount",
            visibleName: "Descuento",
          },
        ],
      },
      {
        type: "decimal",
        visibleName: "Valor de ajuste",
        append: "%",
        name: "adjustment_value",
        sortable: true,
      },
      {
        type: "radio",
        visibleName: "Aplicar sobre",
        name: "base_on",
        sortable: true,
        options: [
          {
            name: "cost",
            visibleName: "Costo",
          },
          {
            name: "price",
            visibleName: "Precio de venta",
          },
        ],
      },
      // {
      //   type: "text",
      //   visibleName: "Nombre",
      //   name: "name",
      //   sortable: true,
      // },

      // {
      //   type: "text",
      //   visibleName: "Desc.",
      //   name: "description",
      //   sortable: true,
      // },
    ],
  };

  return data;
};
