import { useState, useEffect, forwardRef } from "react";
import "./ImageCropperField.scss";
import { CloseIcon } from "components/icons/CloseIcon";

import DeleteIcon from "@mui/icons-material/Delete";
import urls from "config/urls";
import { TransitionProps } from "@mui/material/transitions";
import AxiosInterceptor from "../../../AxiosInterceptor";
import DoneIcon from "@mui/icons-material/Done";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Slide,
} from "@mui/material";
import { ImageCropper } from "./ImageCropper/ImageCropper";
import { NubelonIcon } from "components/icons/NubelonIcon";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ImageCropperField = (props: any) => {
  const [croppedImg, setCroppedImg] = useState<string>("");
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    onEdit,
    id,
    recordId,
    readonly,
    folder = "images",
    tableName,
    name,
    aspect = 2,
  }: any = props;
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [value, setValue] = useState(defaultValue as any);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState<any>(null);


  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);
  const removeImg = () => {
    setCroppedImg("");
    onChange(null);
    setValue(null);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    /**
     * Reinicio el valor del input
     */
    let input: any = document.getElementById(id);
    input.value = null;
  };
  const handleChange = async (e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    handleOpenModal();
  };
  const saveCroppedImage = async () => {
    const data = {
      base64_image: croppedImg,
      extension: "jpg",
      folder: folder,
    };
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/images`,
        data
      );
      const resData = await res.data;
      //handleChangeInput(field.name, resData.path);
      //onChange(resData.path);
      setOpenModal(false);
      setProcessing(false);
      setValue(resData.path);
    } catch (error: any) {
      setProcessing(false);
      //setAlertMsg(error.toString());
      setOpenAlert(true);
    }
  };
  return (
    <>
      <div className="image-cropper-field-container">
        <div className="field-header">
          {label && (
            <label>
              <span>{label}</span>
            </label>
          )}
          {!readonly && (recordId || editPath) && editable && (
            <FieldEditControls
              value={value}
              fieldName={name}
              editPath={editPath}
              onEditStart={() => {
                setIsEditEnabled(true);
              }}
              onEditCancel={(originalValue) => {
                setIsEditEnabled(false);
                setValue(originalValue);
              }}
              onEditEnd={(success: boolean, originalValue) => {
                setIsEditEnabled(false);
                if (!success) {
                  setValue(originalValue);
                }
              }}
            />
          )}
        </div>

        <div className="field-body">
          {readonly ||
            ((recordId || editPath) && editable && !isEditEnabled) ||
            ((recordId || editPath) && !editable) ? (
            <div className="readonly-img-wrapper">
              {value ? (
                <img src={`${urls.server}${value}`} alt="miniatura" />
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    color: "#9cb0be",
                    borderRadius: "10px",
                    display: "flex",
                    width: "100px",
                    height: "100px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <NubelonIcon style={{ fontSize: "60px" }} />
                </div>
              )}
            </div>
          ) : (
            <>
              {value && (
                <div className="cropped-img-wrapper">
                  <img src={`${urls.server}${value}`} />
                  <IconButton
                    className="remove-btn"
                    aria-label="delete"
                    onClick={removeImg}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
              {!value && (
                <div className="add-image-btn">
                  <input
                    id={id}
                    accept="image/*"
                    type="file"
                    onChange={handleChange}
                  />
                  <div>
                    <div>
                      <InsertPhotoIcon />
                      <span>Seleccionar imagen</span>
                    </div>
                    <label htmlFor={id}></label>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Cortar imagen
            </Typography>
            <IconButton
              color="inherit"
              onClick={saveCroppedImage}
              aria-label="crop image"
              disabled={processing}
            >
              <DoneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="cropper-wrapper">
          <ImageCropper
            image={image}
            setCroppedImg={setCroppedImg}
            aspect={aspect}
          />
        </div>
      </Dialog>

    </>
  );
};

export { ImageCropperField };
