import { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";

import "./UsersExpandedCard.scss";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { getFields } from "../../utils/get-fileds";
import { getCols } from "../../utils/get-cols";
import { getDividerGroups } from "../../utils/get-divider-groups";
import { getRowActions } from "../../utils/get-row-actions";
const UsersExpandedCard = (props: any) => {
  const { title, data, setRefreshOnClose } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const fields = getFields().user;
  const cols = getCols().user;
  const dividerGroups = getDividerGroups().user;
  const rowActions = getRowActions().user;

  return (
    <>
      <div className="users-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nuevo usuario</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            <strong>Listar usuarios</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Usuarios eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Formulario de usuario"}
        fields={fields}
        tableName={"users"}
      />
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={title}
        tableName={"users"}
        cols={cols}
        tableActions={[]}
        dividerGroups={dividerGroups}
        rowActions={rowActions}
        formTitle={"Usuario"}
        fields={fields}
        onSelectRowAction={(res: any) => {
          console.log(res);
        }}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { UsersExpandedCard };
