export const getFields = () => {
  const data: any = {
    service: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "money",
        visibleName: "Costo",
        name: "cost",
        description: null,
        defaultValue: 0,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "money",
        visibleName: "Precio de venta",
        name: "price",
        description: null,
        defaultValue: 0,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "money",
        visibleName: "Precio por hora",
        name: "hourly_rate",
        description: null,
        defaultValue: 0,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "Duracion estimada",
        name: "estimated_duration",
        description: null,
        defaultValue: 0,
        size: 6,
        append: "hrs.",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "select",
        visibleName: "IVA para la venta",
        name: "sale_value_added_tax",
        searchPath: "value_added_taxes",
        primaryKey: "name",
        description: null,
        filters: [],
        size: 12,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
        formFields: [
          {
            type: "text",
            visibleName: "Nombre",
            name: "name",
            description: null,
            size: 6,
            editable: null,
            editableIf: null,
            validations: {},
          },
          {
            type: "decimal",
            visibleName: "Alícuota",
            name: "rate",
            description: null,
            size: 6,
            append: "%",
            editable: null,
            editableIf: null,
            validations: {},
          },
        ],
        formTitle: "Form. de IVA",
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 2,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Es favorito",
        name: "is_favorite",
        //description: "assss",
        size: 12,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
