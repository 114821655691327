import React, { useState, useEffect } from "react";
import { AddIcon } from "../../../icons/AddIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { InputField } from "../../InputField/InputField";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import "./EditableListField.scss";
const EditableListField = ({
  defaultData,
  itemType,
  itemPlaceholder,
  onChange,
}: any) => {
  const [items, setItems] = useState((defaultData as any) || ([] as any));
  const addItem = () => {
    const itemsCopy = [...items];
    itemsCopy.push("");
    setItems(itemsCopy);
  };
  const handleChangeItemInput = (index: number, value: string) => {
    const itemsCopy = [...items];
    itemsCopy[index] = value;
    setItems(itemsCopy);
  };
  const handleDeleteItem = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    setItems(itemsCopy);
  };
  useEffect(() => {
    if (onChange) {
      onChange(items);
    }
  }, [items]);
  return (
    <div className="editable-list-field-container">
      <div className="add-item-btn-wrapper">
        <Button onClick={addItem} startIcon={<AddIcon />}>
          Agregar
        </Button>
      </div>
      <div className="list-field-items">
        {items?.map((item: any, index: number) => {
          switch (itemType) {
            case "text":
              return (
                <div key={index}>
                  <InputField
                    placeholder={itemPlaceholder}
                    defaultValue={item}
                    onChange={(value: string) =>
                      handleChangeItemInput(index, value)
                    }
                  />

                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteItem(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            default:
              return (
                <div key={index}>
                  <InputField
                    placeholder={itemPlaceholder}
                    defaultValue={item}
                    onChange={(value: string) =>
                      handleChangeItemInput(index, value)
                    }
                  />

                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteItem(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
          }
        })}
      </div>
    </div>
  );
};

export { EditableListField };
