import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import { HeaderSearchbar } from "../../HeaderSearchbar/HeaderSearchbar";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Alert, Fab, Snackbar } from "@mui/material";
import "./SelectModal.scss";
import { AddIcon } from "../../icons/AddIcon";
import { FormModal } from "../FormModal/FormModal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Spinner } from "../../Spinner/Spinner";
import { DocumentsIcon } from "../../icons/DocumentsIcon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SelectModal = ({
  open,
  setOpen,
  onClose,
  title,
  tableName,
  filters,
  primaryKey,
  secondaryKey,
  formTitle,
  formFields,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };
  const loadRecords = async ({ offset }: any) => {
    try {
      if (offset) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }
      tableName = tableName.replace(/_/g, "-");
      let url = `${
        urls.server
      }/api/${tableName}?offset=${offset}&limit=${limit}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`;
      const res = await AxiosInterceptor.get(url);
      const data = await res.data;
      if (offset > 0) {
        setRecords((prev: any) => [...prev, ...data]);
      } else {
        setRecords(data);
      }
      setLoadingMore(false);
      setLoading(false);
      if (data.length < limit) setHasMore(false);
    } catch (error: any) {
      setAlertMsg("Error al listar los registros");
      setAlertIsOpen(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) loadRecords({ offset: 0 });
  }, [searchTerm, filters, open]);
  useEffect(() => {
    if (offset > 0) loadRecords({ offset });
  }, [offset]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose(null);
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="select-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>{title}</h2>
        </div>
        <HeaderSearchbar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose(null);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        {loading && (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        )}
        {!loading && records.length == 0 && (
          <div className="empty-container">
            <DocumentsIcon />
            <p>No hay registros</p>
          </div>
        )}
        {!loading && records.length > 0 && (
          <>
            {records.map((item: any, index: number) => {
              return (
                <a
                  key={index}
                  onClick={() => {
                    setOpen(false);
                    onClose(item);
                  }}
                >
                  <div>
                    <strong>{item[primaryKey]}</strong>
                    {secondaryKey && <span>{item[secondaryKey] || "-"}</span>}
                  </div>

                  <ChevronRightIcon />
                </a>
              );
            })}
          </>
        )}
      </div>
      <Snackbar
        open={alertIsOpen}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      {formTitle && formFields && (
        <Fab
          className="fab"
          color="primary"
          aria-label="add"
          onClick={() => {
            setFormModalIsOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
      )}
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={(data: any) => {
          // console.log("onClose", data);
          // if (data.refresh) loadRecords({ offset: 0 });
        }}
        onDismiss={(data: any) => {
          console.log("onDismiss", data);
        }}
        title={formTitle}
        fields={formFields}
        tableName={tableName}
      />
    </Dialog>
  );
};

export { SelectModal };
