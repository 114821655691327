export const getCols = () => {
  const data: any = {
    address: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      // {
      //   type: "text",
      //   visibleName: "Ciudad",
      //   name: "locality_composite_field",
      //   sortable: true,
      // },
      {
        type: "text",
        visibleName: "Nombre",
        name: "composite_field",
        sortable: true,
      },
    ],
    zone: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
    ],
    neighborhood: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Ciudad",
        name: "locality_composite_field",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
    ],
  };

  return data;
};
