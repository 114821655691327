import React from 'react'
import './IconCell.scss';
import Icon from 'components/Icon/Icon';
const IconCell = ({ data, color, size }: any) => {
    return (
        <div className='icon-cell-container'>
            <Icon name={data} color={color} size={size} />
        </div>
    )
}

export default IconCell