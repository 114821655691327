import React from 'react';
import ListItem from './components/ListItem/ListItem';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// Definir las props de ListItem
interface ListItemProps {
    data: any; // Aquí deberías usar un tipo más específico en lugar de any si es posible
    cells: Array<any>;
    canEdit: boolean;
    canDelete: boolean;
    dragHandleEnabled: boolean;
    onEdit: () => void;
    onDelete: () => void;
}

// Definir las props para SortableItem (incluyendo todas las props de ListItem)
interface SortableItemProps extends ListItemProps {
    index: number; // index es requerido por SortableElement
}

// Crear el componente SortableItem
const SortableItem: any = SortableElement((props: SortableItemProps) => <ListItem {...props} />);

// Crear el componente SortableList
const SortableList: any = SortableContainer(({ children }: { children: React.ReactNode }) => {
    return <div className='list-container'>{children}</div>;
});

// Definir las props de List
interface ListProps {
    data: any[]; // Usa un tipo más específico si es posible
    canEdit?: boolean;
    canDelete?: boolean;
    isSortable?: boolean;
    cells: Array<any>;
    itemStyle?: any;
    onEditItem: (item: any) => void;
    onDeleteItem: (item: any, index: number) => void;
}

// Definir el componente List
const List: React.FC<ListProps> = ({
    data,
    canEdit = false,
    canDelete = false,
    isSortable = false,
    cells,
    itemStyle,
    onEditItem,
    onDeleteItem,
}) => {
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        console.log(oldIndex, newIndex);
        // Aquí se debe actualizar el estado con el nuevo orden de los elementos.
    };

    return (
        <SortableList onSortEnd={onSortEnd} useDragHandle>
            {data.map((item, index) => {
                // Definir funciones de envoltura para onEdit y onDelete
                const handleEdit = () => onEditItem(item);
                const handleDelete = () => onDeleteItem(item, index);

                return (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        disabled={!isSortable}
                        data={item}
                        cells={cells}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        dragHandleEnabled={isSortable}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        style={itemStyle}
                    />
                );
            })}
        </SortableList>
    );
};

export default List;
