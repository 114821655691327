import React from "react";
import "./IndicatorGroupsCard.scss";
import { BasicIndicator } from "components/BasicIndicator/BasicIndicator";
const IndicatorGroupsCard = ({ title, config, data }: any) => {
  return (
    <div className="indicator-groups-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.indicatorGroupsCount}
          label={"Total de grupos de indicadores"}
        />
      </div>
    </div>
  );
};

export { IndicatorGroupsCard };
