import * as React from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Drawer,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./AddedFields.scss";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Icon from "../../../../Icon/Icon";

import { useState } from "react";
import { FieldConfig } from "../FieldConfig/FieldConfig";
const DragHandle = SortableHandle(() => <DragIndicatorIcon />);
const SortableItem = SortableElement<{
  config: any;
  onSelect: any;
  onDelete: any;
  onChange: any;
  itemIndex: number;
  setActiveFieldIndex: any;
  activeFieldIndex: number;
}>(
  ({
    config,
    onSelect,
    onDelete,
    onChange,
    itemIndex,
    setActiveFieldIndex,
    activeFieldIndex,
  }: {
    config: any;
    onSelect: any;
    onDelete: any;
    onChange: any;
    itemIndex: number;
    setActiveFieldIndex: any;
    activeFieldIndex: number;
  }) => {
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);
    const deleteField = () => {
      onDelete(config, itemIndex);
      setAlertIsOpen(false);
    };
    const editField = () => {
      onSelect(config, itemIndex);
      setActiveFieldIndex(itemIndex);
      setOpenConfig(true);
    };
    let className =
      activeFieldIndex == itemIndex ? "added-field active" : "added-field";
    return (
      <>
        <div className={className} style={{ zIndex: 99999999 }}>
          <div>
            <Icon name={config.icon} />
            <div>
              <strong>{config.visibleName}</strong>
              <span>{config.visibleType}</span>
            </div>
          </div>
          <div>
            <IconButton
              aria-label="delete"
              onClick={() => setAlertIsOpen(true)}
              size="small"
              style={{
                backgroundColor: "#fff",
              }}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              aria-label="edit"
              onClick={() => editField()}
              size="small"
              style={{
                backgroundColor: "#fff",
              }}
            >
              <EditIcon />
            </IconButton>
            <Drawer
              anchor={"right"}
              open={openConfig}
              onClose={() => {
                setOpenConfig(false);
                setActiveFieldIndex(null);
              }}
              style={{ zIndex: 99999999 }}
            >
              <FieldConfig
                config={config}
                onChange={(value: any) => {
                  onChange(value, itemIndex);
                }}
              />
            </Drawer>

            <DragHandle />
          </div>
        </div>
        <Dialog
          open={alertIsOpen}
          onClose={() => setAlertIsOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmar"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Desea eliminar el campo <b>{config?.visibleName}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertIsOpen(false)} autoFocus>
              Cancelar
            </Button>
            <Button onClick={deleteField}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

const SortableList = SortableContainer<{
  items: string[];
  onSelectItem: any;
  onDeleteItem: any;
  onChangeItem: any;
  setActiveFieldIndex: any;
  activeFieldIndex: number;
}>(
  ({
    items,
    onSelectItem,
    onDeleteItem,
    onChangeItem,
    setActiveFieldIndex,
    activeFieldIndex,
  }: {
    items: string[];
    onSelectItem: any;
    onDeleteItem: any;
    onChangeItem: any;
    setActiveFieldIndex: any;
    activeFieldIndex: number;
  }) => {
    //const [activeIndex, setActiveIndex] = useState(null as any);
    return (
      <div className="fields-builder-added-field-container">
        {items.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            itemIndex={index}
            config={item}
            onSelect={onSelectItem}
            onDelete={onDeleteItem}
            setActiveFieldIndex={setActiveFieldIndex}
            activeFieldIndex={activeFieldIndex}
            onChange={onChangeItem}
          />
        ))}
      </div>
    );
  }
);
const AddedFields = (props: any) => {
  const {
    items,
    onSelectItem,
    onDeleteItem,
    onChangeItem,
    setActiveFieldIndex,
    activeFieldIndex,
  } = props;
  const handleSortEnd = (items: any) => {
    console.log(items);
  };

  return (
    <SortableList
      items={items}
      onSortEnd={handleSortEnd}
      onSelectItem={onSelectItem}
      onDeleteItem={onDeleteItem}
      onChangeItem={onChangeItem}
      useDragHandle
      setActiveFieldIndex={setActiveFieldIndex}
      activeFieldIndex={activeFieldIndex}
    />
  );
};

export { AddedFields };
