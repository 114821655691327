export const getFields = () => {
  const data: any = {
    tutorial: [
      {
        type: "text",
        visibleName: "Título",
        name: "title",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      // {
      //   type: "longText",
      //   visibleName: "Descipción",
      //   name: "description",
      //   description: null,
      //   size: 12,
      //   editable: true,
      //   editableIf: null,
      //   validations: {},
      // },
      {
        type: "imageCropper",
        visibleName: "Imagen principal",
        name: "main_image",
        description: "",
        aspect: "4 / 3",
        size: 12,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 6,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "textEditor",
        visibleName: "Contenido",
        name: "content",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
