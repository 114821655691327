import React from "react";
import { SvgIcon } from "@mui/material";
const AlignRightIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path fill={props?.color} d="M10 0h22v6h-22v-6z"></path>
    <path fill={props?.color} d="M2 8h30v6h-30v-6z"></path>
    <path fill={props?.color} d="M6 16h26v6h-26v-6z"></path>
    <path fill={props?.color} d="M0 24h32v6h-32v-6z"></path>
  </SvgIcon>
);

export { AlignRightIcon };
