import React from "react";
import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
import "./MyIndicatorsCard.scss";
const MyIndicatorsCard = (props: any) => {
  const { title, data } = props;
  return (
    <div className="indicators-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <div>
          <BasicIndicator
            color={"#4d6572"}
            value={data?.totalIndicators | 0}
            label={"Indicadores"}
            size="sm"
          />
        </div>

        <div>
          <BasicIndicator
            color={"#ed3b4b"}
            value={data?.totalIndicatorsWithOverdueValues | 0}
            label={"Indicadores con valores vencidos"}
            size="sm"
          />
        </div>
      </div>
    </div>
  );
};

export { MyIndicatorsCard };
