import { useState } from "react";

import "./FlowChatCard.scss";
const FlowChatCard = (props: any) => {
  const { title, data, config } = props;

  return (
    <div className="flow-chat-card-container">
      <h2 className="card-title">{title}</h2>
    </div>
  );
};

export { FlowChatCard };
