export const getCols = () => {
  const data: any = {
    tutorial: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "título",
        name: "title",
        sortable: true,
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "selected_tags",
        sortable: false,
      },
      // {
      //   type: "text",
      //   visibleName: "Desc.",
      //   name: "description",
      //   sortable: true,
      // },
    ],
  };

  return data;
};
