import React, { useState, useEffect } from "react";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";

import { Spinner } from "../../../../components/Spinner/Spinner";
import { DocumentsIcon } from "../../../../components/icons/DocumentsIcon";
import "./ProductAttributeList.scss";
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { makeStyles } from "@mui/styles";
import { InputField } from "../../../../components/fields/InputField/InputField";
import { ProductAttributeItem } from "../ProductAttributeItem/ProductAttributeItem";
import { useAuth } from "../../../../contexts/AuthContext";
import { useAttributeActions } from "./useAttributeActions";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";

interface Mode {
  create: string;
  update: string;
  delete: string;
}
const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 500,
    width: "80%",
  },
}));
const ProductAttributeList = ({ searchTerm = "", filters = [] }: any) => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);
  const [formIsOpen, setFormIsOpen] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  /*   const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [processing, setProcessing] = useState(false);
  const [attributeId, setAttributeId] = useState(0); */
  const fields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const [loadParams, setLoadParams] = useState({
    offset: 0,
    sortBy: null,
    sortDirection: "",
    limit: 5,
    filters: filters,
    searchTerm: searchTerm,
  });
  const loadRecords = async (
    offset: number,
    limit: number,
    sortBy: any,
    sortDirection: string,
    searchTerm: string,
    filters: any
  ) => {
    if (offset) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server
        }/api/product-attributes?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
          JSON.stringify(filters)
        )}`
      );
      const data = await res.data;
      if (offset > 0) {
        setRecords((prev: any) => [...prev, ...data]);
      } else {
        setRecords(data);
      }

      setLoadingMore(false);
      setLoading(false);
      if (data.length < limit) setHasMore(false);
    } catch (error) {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  /*   useEffect(() => {
    loadRecords(
      loadParams.offset,
      loadParams.limit,
      loadParams.sortBy,
      loadParams.sortDirection,
      loadParams.searchTerm,
      loadParams.filters
    );
  }, []); */
  useEffect(() => {
    if (!formIsOpen) {
      loadRecords(
        loadParams.offset,
        loadParams.limit,
        loadParams.sortBy,
        loadParams.sortDirection,
        loadParams.searchTerm,
        loadParams.filters
      );
    }
  }, [formIsOpen]);
  //setFormIsOpen
  useEffect(() => {
    setLoadParams((prevState) => ({
      ...prevState,
      offset: 0,
      searchTerm: searchTerm,
    }));
  }, [searchTerm]);

  useEffect(() => {
    setLoadParams((prevState) => ({
      ...prevState,
      offset: 0,
      filters: filters,
    }));
  }, [filters]);

  // const createAttribute = async (data?: any) => {
  //   setProcessing(true);
  //   try {
  //     const res = await AxiosInterceptor.post(`${baseUrl}`, data);
  //     setProcessing(false);
  //     setFormIsOpen(false);
  //     loadRecords(
  //       loadParams.offset,
  //       loadParams.limit,
  //       loadParams.sortBy,
  //       loadParams.sortDirection,
  //       loadParams.searchTerm,
  //       loadParams.filters
  //     );
  //     if (res) {
  //       const xdata = await res.data;
  //       const { message } = xdata;
  //       setAlertMsg(message.toString());
  //       setOpenAlert(true);
  //       setSeverity("success");
  //     }
  //   } catch (error: any) {
  //     setProcessing(false);
  //     setAlertMsg(error.toString());
  //     setOpenAlert(true);
  //     setSeverity("error");
  //     setFormIsOpen(false);
  //   }
  // };

  // const editAttribute = async (data?: any) => {
  //   setProcessing(true);
  //   try {
  //     const res = await AxiosInterceptor.put(`${baseUrl}/${attributeId}`, data);
  //     setProcessing(false);
  //     setFormIsOpen(false);
  //     loadRecords(
  //       loadParams.offset,
  //       loadParams.limit,
  //       loadParams.sortBy,
  //       loadParams.sortDirection,
  //       loadParams.searchTerm,
  //       loadParams.filters
  //     );
  //     if (res) {
  //       const xdata = await res.data;
  //       const { message } = xdata;
  //       setAlertMsg(message.toString());
  //       setOpenAlert(true);
  //       setSeverity("success");
  //     }
  //   } catch (error: any) {
  //     setProcessing(false);
  //     setAlertMsg(error.toString());
  //     setOpenAlert(true);
  //     setSeverity("error");
  //     setFormIsOpen(false);
  //   }
  // };

  /*   useEffect(() => {
    if (!formIsOpen) {
      setAttributeName("");
    }
  }, [formIsOpen]); */
  const handleOpen = () => {
    setFormIsOpen(true);
  };
  // const handleSubmit = (data: any) => {
  //   if (editMode) {
  //     editAttribute(data);
  //   } else createAttribute(data);
  // };

  return (
    <div className="product-attribute-list-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && records.length == 0 && (
        <div className="no-content">
          <DocumentsIcon />
          <p>No hay registros</p>
        </div>
      )}
      {!loading && records.length > 0 && (
        <>
          <div className="items-wrapper">
            {records.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {/* <a>{item.name}</a>
                  <div>
                    <IconButton aria-label="delete" onClick={() => {}}>
                      <PencilIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => {}}>
                      <DeleteIcon />
                    </IconButton>
                  </div> */}
                  <ProductAttributeItem
                    name={item.name}
                    id={item.id}
                  // handleOpen={() => {
                  //   console.log("*como asigno este valor al fields*", item);
                  //   handleOpen({ edit: true });
                  //   setAttributeId(item.id);
                  //   setAttributeName(item);
                  // }}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
      <Fab
        className="fab"
        color="primary"
        aria-label="add"
        onClick={() => {
          handleOpen();
        }}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
      <FormDialog
        open={formIsOpen}
        setOpen={setFormIsOpen}
        title={"Nuevo Atributo de Productos"}
        description={"Ej: talles, colores, etc."}
        fields={fields}
        onClose={() => { }}
        //onSubmit={handleSubmit}
        onSubmit={() => { }}
        createPath={`product-attributes`}
      />

      {/*  <Dialog
        open={formIsOpen}
        onClose={() => {}}
        className="dialog-form"
        classes={{ paper: classes.dialog }}
        //maxWidth="lg"
      >
        <DialogTitle className="dialog-form-title">
          Nuevo atributo de productos
        </DialogTitle>
        <DialogContent className="dialog-form-content">
          <DialogContentText className="dialog-form-description">
            Ej: talles, colores, etc.
          </DialogContentText>
          <div className="dialog-form-inputs-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputField
                  id="product-attribute-name"
                  defaultValue={attributeName}
                  label={"Nombre del atributo"}
                  //editable={true}

                  onChange={(value: string) => {
                    setAttributeName(value);
                  }}
                  //editable
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFormIsOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={processing || !attributeName}
            onClick={() => {
              createAttribute();
            }}
          >
            Crear atributo
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export { ProductAttributeList };
