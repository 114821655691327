import React from "react";
import "./ScrapingCard.scss";
const ScrapingCard = ({ title }: any) => {
  return (
    <div className="scraping-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <svg viewBox="0 0 297.003 297.003">
          <path
            className="scraping-icon"
            d="M 189.47936 11.484213 C 187.1173 11.484213 184.7556 12.38469 182.95269 14.186679 C 151.31592 45.822526 125.47598 79.590132 110.19275 109.26897 C 86.383973 155.50619 95.091695 178.91672 106.58826 190.41329 C 114.24463 198.06967 124.46154 201.95044 136.95275 201.95044 C 179.68933 201.95044 240.32404 156.54495 282.81705 114.05104 C 286.42194 110.44614 286.42194 104.60115 282.81705 100.99625 L 196.00748 14.186679 C 194.20457 12.385613 191.84234 11.484213 189.47936 11.484213 z M 70.137229 186.6413 C 70.137229 186.6413 46.868876 194.47814 37.462549 201.98089 C 28.632557 209.02393 22.026828 218.77603 16.603454 228.68361 C 14.918648 231.76147 13.107723 238.61247 13.107723 238.61247 C 9.2112556 249.67907 12.529022 264.28258 20.82574 272.57929 L 24.422261 276.17581 C 30.3763 282.12985 39.577585 285.51879 48.389156 285.51879 C 51.856578 285.51879 55.263772 284.99367 58.389804 283.89383 C 58.389804 283.89383 65.242424 282.08306 68.320841 280.3981 C 78.228216 274.97523 87.69058 268.13123 95.022832 259.54045 C 102.66183 250.59031 112.11356 228.65243 112.11356 228.65243 C 101.2721 226.6539 91.732232 221.73654 84.384817 214.38309 C 77.037077 207.02932 72.132057 197.49107 70.137229 186.6413 z M 53.061732 232.29028 A 12.247545 12.247545 0 0 1 65.30948 244.53803 A 12.247545 12.247545 0 0 1 53.061732 256.78505 A 12.247545 12.247545 0 0 1 40.813984 244.53803 A 12.247545 12.247545 0 0 1 53.061732 232.29028 z "
          />
        </svg>
      </div>
    </div>
  );
};

export { ScrapingCard };
