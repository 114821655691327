import React, { useState, useEffect } from "react";
import { Dialog, Fab, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { CloseIcon } from "../../../../components/icons/CloseIcon";
import "./TasksCalendarModal.scss";
import { TaskItems } from "../TaskItems/TaskItems";
import { TaskCalendarTabs } from "../TaskCalendarTabs/TaskCalendarTabs";

import { AddIcon } from "../../../../components/icons/AddIcon";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { getFields } from "../../utils/get-fileds";
import { TaskUserTabs } from "../TaskUserTabs/TaskUserTabs";
import { HeaderSearchbar } from "components/HeaderSearchbar/HeaderSearchbar";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TasksCalendarModal = ({ open, setOpen, onClose, mode }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);

  const [filters, setFilters] = useState([] as any);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [refreshTabs, setRefreshTabs] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const taskField = getFields().task;
  const currentDate: any = new Date().toJSON().slice(0, 10);
  useEffect(() => {
    if (mode === "all") {
      // Llamar al endpoint que trae todas las tareas
    } else if (mode === "user") {
      // Llamar al endpoint que trae solo las tareas del usuario
    }
  }, [mode]);
  useEffect(() => {
    setFilters([["status", "!=", "done"]]);
  }, []);
  useEffect(() => {
    if (formModalIsOpen) setRefreshData(false);
  }, [formModalIsOpen]);
  useEffect(() => {
    if (refreshData) {
      setRefreshTabs(true);
      setRefreshOnClose(true);
    }
  }, [refreshData]);
  useEffect(() => {
    if (open) {
      setRefreshTabs(true);
    } else {
      setRefreshTabs(false);
      setFilters([["status", "!=", "done"]]);
    }
  }, [open]);

  const updateFilters = () => {
    // Hacer una copia de los filtros actuales
    let updatedFilters = [...filters];

    // Buscar el índice del filtro ["users", [x]]
    const userFilterIndex = updatedFilters.findIndex(
      (filter) => filter[0] === "users"
    );

    // Si selectedUserId es undefined
    if (selectedUserId === undefined) {
      if (userFilterIndex !== -1) {
        // Eliminar el filtro ["users", [x]]
        updatedFilters.splice(userFilterIndex, 1);
      }
    } else {
      // Si el filtro ["users", [x]] existe
      if (userFilterIndex !== -1) {
        // Reemplazar x con selectedUserId
        updatedFilters[userFilterIndex][1] = [selectedUserId];
      } else {
        // Si no existe, agregarlo
        updatedFilters.push(["users", [selectedUserId]]);
      }
    }

    return updatedFilters;
  };

  useEffect(() => {
    //setFilters(updateFilters());
    setFilters([
      ["status", "!=", "done"],
      ["users", selectedUserId ? [selectedUserId] : null],
    ]);
  }, [selectedUserId]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        setRefreshData(false);
        setSelectedUserId(null);
        setFilters([
          ["status", "!=", "done"],
          ["users", null],
        ]);
        onClose({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="tasks-calendar-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>
            {mode == "all"
              ? "Calendario de tareas"
              : "Calendario de mis tareas"}
          </h2>
        </div>
        <span className="header-searchbar-wrapper">
          <HeaderSearchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setRefreshData={setRefreshData}
          />
        </span>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              setRefreshData(false);
              setSelectedUserId(null);
              setFilters([
                ["status", "!=", "done"],
                ["users", null],
              ]);
              onClose({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        {mode == "all" && (
          <TaskUserTabs
            onChange={(val: any) => {
              console.log(val);
              setRefreshData(true);
              setSelectedUserId(val?.id);
            }}
          />
        )}

        <TaskCalendarTabs
          refresh={refreshTabs}
          setRefresh={setRefreshTabs}
          userId={selectedUserId}
          onSelectItem={(val: any) => {
            console.log("tab", val);

            if (val?.label === "Venc.") {
              setFilters([
                ["due_date", "<", currentDate],
                ["status", "!=", "done"],
                ["users", selectedUserId ? [selectedUserId] : null],
              ]);
            } else if (val?.label === "S/F") {
              setFilters([
                ["due_date", null],
                ["status", "!=", "done"],
                ["users", selectedUserId ? [selectedUserId] : null],
              ]);
            } else {
              if (val?.date) {
                setFilters([
                  ["due_date", val?.date],
                  ["status", "!=", "done"],
                  ["users", selectedUserId ? [selectedUserId] : null],
                ]);
              }
            }
          }}
          mode={mode}
        />
        <TaskItems
          filters={filters}
          searchTerm={searchTerm}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          mode={mode}
        />
      </div>
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="add"
        onClick={() => {
          setFormModalIsOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={(data: any) => {
          console.log("onClose", data);
          if (data) {
            setRefreshData(true);
          }
        }}
        title={"Ficha de tarea"}
        fields={taskField}
        //tableName={"tasks"}
        createPath={mode == 'all' ? 'tasks' : 'my/tasks'}
        mode="create"
      />
    </Dialog>
  );
};

export { TasksCalendarModal };
