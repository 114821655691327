export const getCols = () => {
  const data: any = {
    supplier: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Email",
        name: "email",
        sortable: true,
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "selected_tags",
        sortable: false,
      },
    ],
  };

  return data;
};
