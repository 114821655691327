import React, { useEffect, useState } from "react";
import "./SimpleIndicatorCardBody.scss";
import { AnimatedNumber } from "components/AnimatedNumber/AnimatedNumber";
import { MiniLineChart } from "components/miniCharts/MiniLineChart/MiniLineChart";
import { MiniColumnChart } from "components/miniCharts/MiniColumnChart/MiniColumnChart";
const SimpleIndicatorCardBody = (props: any) => {
  const { data, config } = props;
  const [selectedItem, setSelectedItem] = useState({} as any);
  //const [newData, setNewData] = useState([] as any);

  const handleSelectItem = (data: any) => {
    //console.log(data);
    setSelectedItem(data);
  };
  useEffect(() => {
    const newData = data?.length > 10 ? data.slice(-10) : data;
    //setNewData(newData);
    console.log(newData);
  }, []);
  useEffect(() => {
    console.log(data);
    if (data?.length > 0) {
      const newData = data?.length > 10 ? data.slice(-10) : data;
      //setNewData(newData);
      setSelectedItem(newData[newData?.length - 1]);
    }
  }, [data]);
  return (

    <div className="simple-indicator-card-body-container">

      <div className="selected-item">
        <div>

          {data.length > 0 ? (
            <AnimatedNumber
              value={selectedItem?.value}
              duration="500"
              color={config?.color}
              type={"decimal"}
              fontSize={selectedItem?.value > 9999 ? "16px" : "22px"}
              decimalPrecision={config?.decimalPrecision}
            />
          ) : (
            <div
              style={{
                color: config?.color,
              }}
            >
              -
            </div>
          )}
        </div>
        <div style={{ marginTop: 6, fontSize: 19 }}>
          <span
            style={
              {
                //color: config?.color
              }
            }
          >
            {selectedItem?.visible_period}
          </span>
          {config?.measurementUnit && (
            <span
              style={
                {
                  //color: config?.color,
                }
              }
            >
              {config?.measurementUnit}
            </span>
          )}
        </div>
      </div>
      <div className="chart-wrapper">
        {config?.chartType == "lineChart" ? (
          <>
            {/* {JSON.stringify(newData)} */}
            <MiniLineChart
              //data={data?.slice(-10)}
              data={data?.slice(-10)}
              color={config?.color}
              onSelectPoint={handleSelectItem}
              decimalPrecision={config?.decimalPrecision}
            />
          </>
        ) : (
          <MiniColumnChart
            data={data?.slice(-10)}
            color={config?.color}
            onSelectColumn={handleSelectItem}
            decimalPrecision={config?.decimalPrecision}
          />
        )}
      </div>
    </div>
  );
};

export { SimpleIndicatorCardBody };
