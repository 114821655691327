export const getDividerGroups = () => {
  const data: any = {
    user: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "users/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "users/year-months-with-records",
      },
    ],
  };

  return data;
};
