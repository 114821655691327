import React, { Fragment } from "react";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from "config/urls";
import "./NotificationsPage.scss";
import { Spinner } from "../../components/Spinner/Spinner";
import { NotificationsIcon } from "../../components/icons/NotificationsIcon";
const NotificationsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    React.useState(0);
  const loadData = async () => {
    setLoading(true);
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/notifications/unread`
      );

      const data = await res.data;
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const getUnreadNotificationsCount = async () => {
  //   try {
  //     const res = await AxiosInterceptor.get(
  //       `${urls.server}/api/user/notifications/unread/count`
  //     );

  //     const data = await res.data;
  //     setUnreadNotificationsCount(data);
  //   } catch (error) { }
  // };

  React.useEffect(() => {
    loadData();
    //getUnreadNotificationsCount();
  }, []);
  return (
    <div className="notifications-page-container page-container">
      <div className="page-header">
        <h1>Notificaciones</h1>
      </div>
      <div className="page-body">
        {loading && (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        )}
        {!loading && data.length == 0 && (
          <div className="no-content">
            <NotificationsIcon />
            <p>No hay notificaciones</p>
          </div>
        )}
        {!loading && data.length > 0 && (
          <div className="notifications-list">
            {data.map((item: any, index: number) => {
              return <Fragment key={index}></Fragment>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export { NotificationsPage };
