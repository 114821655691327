import { useState, useEffect } from "react";
import { HideIcon } from "../../icons/HideIcon";
import { ShowIcon } from "../../icons/ShowIcon";
import "./PasswordField.scss";
const PasswordField = (props: any) => {
  const { defaultValue = "", label, id, onChange } = props;
  const [value, setValue] = useState(defaultValue);
  const [isVisible, setIsVisible] = useState(false);

  const showPassword = () => {
    setIsVisible(true);
  };
  const hidePassword = () => {
    setIsVisible(false);
  };
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  return (
    <div className="password-field-container">
      {label && <label>{label}</label>}
      <div>
        <input
          placeholder="Contraseña"
          id={id}
          type={isVisible ? "text" : "password"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        {!isVisible ? (
          <button type="button" onClick={showPassword}>
            <ShowIcon />
          </button>
        ) : (
          <button type="button" onClick={hidePassword}>
            <HideIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export { PasswordField };
