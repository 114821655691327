import React from "react";
import "./ConfigurationsCard.scss";
import { BasicIndicator } from "components/BasicIndicator/BasicIndicator";
const ConfigurationsCard = ({ title, data, config }: any) => {
  return (
    <div className="configurations-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.configurationsCount}
          label={"Configuraciones"}
        />
      </div>
    </div>
  );
};

export { ConfigurationsCard };
