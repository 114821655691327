import React, { useState, useEffect } from "react";
import { getFields } from "../utils/get-fields";
import { FormModal } from "../../../modals/FormModal/FormModal";
import "./EditableTransactionField.scss";
import Grid from "@mui/material/Grid";
import { SelectModal } from "../../../modals/SelectModal/SelectModal";
import { DateField } from "../../DateField/DateField";
import { Button, IconButton } from "@mui/material";
import { DeleteIcon } from "../../../icons/DeleteIcon";
import { Autocomplete } from "../../../Autocomplete/Autocomplete";
import { AddIcon } from "../../../icons/AddIcon";
import { CartIcon } from "../../../icons/CartIcon";
import { formatNumber } from "../../../../utils/number";
import { CloseIcon } from "../../../icons/CloseIcon";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { EditableTransactionItem } from "../EditableTransactionItem/EditableTransactionItem";
import { SelectField } from "components/fields/SelectField/SelectField";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import { CheckboxField } from "components/fields/CheckboxField/CheckboxField";
const EditableTransactionField = ({
  transactionType,
  documentType,
  defaultData,
  onChange,
}: any) => {
  const [selectPartyModalIsOpen, setSelectPartyModalIsOpen] = useState(false);
  const [listPriceData, setListPriceData] = useState(null as any);
  const [selectValueAddedTaxModalIsOpen, setSelectValueAddedTaxModalIsOpen] =
    useState(false);
  const [selectOtherTaxModalIsOpen, setSelectOtherTaxModalIsOpen] =
    useState(false);
  const [selectPerceptionModalIsOpen, setSelectPerceptionModalIsOpen] =
    useState(false);
  const [listPriceLoading, setListPriceLoading] = useState(true);
  const [inventoryLocationData, setInventoryLocationData] = useState(
    null as any
  );
  const [inventoryLocationLoading, setInventoryLocationLoading] =
    useState(true);
  let initialValues: any = {
    taxableTotal: 0,
    nontaxableTotal: 0,
    valueAddedTaxes: [],
    perceptions: [],
    otherTaxes: [],
    total: 0,
    totalPaid: 0,
    allItemsDelivered: true,
  };
  const paymentMethodsInitialData = [
    {
      name: "cash",
      visibleName: "Efectivo",
      amount: 0,
    },
    {
      name: "credit_card",
      visibleName: "Tarjeta de crédito",
      amount: 0,
    },
    {
      name: "debit_card",
      visibleName: "Tarjeta de débito",
      amount: 0,
    },
    {
      name: "mercado_pago",
      visibleName: "Mercado Pago",
      amount: 0,
    },
    {
      name: "bank_payment",
      visibleName: "Pago bancario",
      amount: 0,
    },
  ];
  const [paymentMethods, setPaymentMethods] = useState(
    paymentMethodsInitialData
  );
  const [data, setData] = useState(
    (defaultData as any) || (initialValues as any)
  );
  const [productFormModalIsOpen, setProductFormModalIsOpen] = useState(false);
  const [serviceFormModalIsOpen, setServiceFormModalIsOpen] = useState(false);
  const customerFormFields = getFields().customer;
  const supplierFormFields = getFields().supplier;
  const productFormFields = getFields().product;
  const serviceFormFields = getFields().service;
  const valueAddedTaxFormFields = getFields().valueAddedTax;
  const otherTaxFormFields = getFields().valueAddedTax;
  const perceptionFormFields = getFields().perception;
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [valueAddedTaxes, setValueAddedTaxes] = useState([]);
  const [perceptions, setPerceptions] = useState([]);
  const [otherTaxes, setOtherTaxes] = useState([]);
  const [partyType, setPartyType] = useState("customer"); // customer or supplier
  const [visiblePartyType, setVisiblePartyType] = useState("cliente"); // cliente or proveedor
  useEffect(() => {
    setPartyType(transactionType === "purchase" ? "supplier" : "customer");
    setVisiblePartyType(
      transactionType === "purchase" ? "proveedor" : "cliente"
    );
  }, [transactionType]);
  const removeProduct = (uniqueId: number) => {
    setProducts((oldValues: any) => {
      return oldValues.filter((item: any) => item.uniqueId !== uniqueId);
    });
  };
  const editProduct = (uniqueId: number, data: any) => {
    setProducts((oldValues: any) => {
      const products: any = JSON.parse(JSON.stringify(oldValues));
      //console.log(products);
      products.forEach((item: any, index: number) => {
        if (item.uniqueId == uniqueId) products[index] = data;
      });
      return products;
    });
  };

  const removeService = (id: number) => {
    setServices((oldValues: any) => {
      return oldValues.filter((item: any) => item.id !== id);
    });
  };
  const editService = (id: number, data: any) => {
    setServices((oldValues: any) => {
      const services: any = JSON.parse(JSON.stringify(oldValues));
      //console.log(services);
      services.forEach((item: any, index: number) => {
        if (item.id == id) services[index] = data;
      });
      return services;
    });
  };
  useEffect(() => {
    let total = 0;
    let productsQuantity = 0;
    let servicesQuantity = 0;
    let taxableTotal = 0;
    let nontaxableTotal = 0;
    let valueAddedTaxes: any = [];
    products.forEach((item: any, index: number) => {
      item.quantity = item.quantity || 1;
      productsQuantity += Number(item.quantity);
      // total += item.subtotal_with_tax;
      total += item.subtotal;

      if (
        item[
          (transactionType === "sale" ? "sale" : "purchase") +
            "_value_added_tax"
        ]
      ) {
        taxableTotal += item.subtotal;
      } else {
        nontaxableTotal += item.subtotal;
      }
      if (
        item[
          (transactionType === "sale" ? "sale" : "purchase") +
            "_value_added_tax"
        ]
      ) {
        let tax =
          item[
            (transactionType === "sale" ? "sale" : "purchase") +
              "_value_added_tax"
          ];

        const searchIndex = valueAddedTaxes.findIndex(
          (item: any) => item.id == tax.id
        );

        if (searchIndex == -1) {
          tax.amount = item.tax_amount;
          valueAddedTaxes.push(tax);
        } else {
          valueAddedTaxes.forEach((valueAddedTax: any, index: number) => {
            if (valueAddedTax.id === tax.id) {
              valueAddedTax.amount += item.tax_amount;
            }
          });
        }
        total += item.tax_amount;
      }
    });
    services.forEach((item: any, index: number) => {
      item.quantity = item.quantity || 1;
      servicesQuantity += Number(item.quantity);
      // total += item.subtotal_with_tax;
      total += item.subtotal;

      if (
        item[
          (transactionType === "sale" ? "sale" : "purchase") +
            "_value_added_tax"
        ]
      ) {
        taxableTotal += item.subtotal;
      } else {
        nontaxableTotal += item.subtotal;
      }
      if (
        item[
          (transactionType === "sale" ? "sale" : "purchase") +
            "_value_added_tax"
        ]
      ) {
        let tax =
          item[
            (transactionType === "sale" ? "sale" : "purchase") +
              "_value_added_tax"
          ];
        const searchIndex = valueAddedTaxes.findIndex(
          (item: any) => item.id == tax.id
        );

        if (searchIndex == -1) {
          tax.amount = item.tax_amount;
          valueAddedTaxes.push(tax);
        } else {
          valueAddedTaxes.forEach((valueAddedTax: any, index: number) => {
            if (valueAddedTax.id === tax.id) {
              valueAddedTax.amount += item.tax_amount;
            }
          });
        }
        total += item.tax_amount;
      }
    });
    setValueAddedTaxes(valueAddedTaxes);
    const newData: any = { ...data };
    newData.total = total;
    newData.productsQuantity = productsQuantity;
    newData.servicesQuantity = servicesQuantity;
    newData.taxableTotal = taxableTotal;
    newData.nontaxableTotal = nontaxableTotal;
    newData.valueAddedTaxes = valueAddedTaxes;
    newData.products = products;
    newData.services = services;

    //Pago
    paymentMethods.forEach((item: any, index: number) => {
      item.amount = 0;
    });
    newData.totalPaid = 0;
    newData.paymentMethods = paymentMethods;
    setData(newData);
  }, [products, services]);
  useEffect(() => {
    const newData: any = { ...data };
    let total: any = 0;
    total += newData.taxableTotal;
    total += newData.nontaxableTotal;
    valueAddedTaxes.forEach((item: any, index: number) => {
      total += item.amount;
    });
    perceptions.forEach((item: any, index: number) => {
      total += item.amount;
    });
    otherTaxes.forEach((item: any, index: number) => {
      total += item.amount;
    });
    newData.total = total;
    newData.perceptions = perceptions;
    newData.otherTaxes = otherTaxes;

    //Pago
    paymentMethods.forEach((item: any, index: number) => {
      item.amount = 0;
    });
    newData.totalPaid = 0;
    newData.paymentMethods = paymentMethods;

    setData(newData);
  }, [perceptions, otherTaxes]);
  useEffect(() => {
    const newData: any = { ...data };
    let totalPaid: any = 0;
    paymentMethods.forEach((item: any, index: number) => {
      totalPaid += Number(item.amount);
    });
    newData.totalPaid = totalPaid;
    newData.paymentMethods = paymentMethods;
    setData(newData);
  }, [paymentMethods]);
  useEffect(() => {
    console.log(defaultData);
  }, []);
  const getFirstListPrice = async () => {
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/price-lists/first`
      );
      const resData = await res.data;
      console.log(resData);
      setListPriceData(resData);
      setListPriceLoading(false);
      setData((prev: any) => {
        const dataCopy = { ...prev };
        dataCopy["price_list"] = resData;
        dataCopy[`price_list_id`] = resData?.id;
        return dataCopy;
      });
    } catch (error: any) {}
  };
  const getFirstInventoryLocation = async () => {
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/inventory-locations/first`
      );
      const resData = await res.data;
      console.log(resData);
      setInventoryLocationData(resData);
      setInventoryLocationLoading(false);
      setData((prev: any) => {
        const dataCopy = { ...prev };
        dataCopy["inventory_location"] = resData;
        dataCopy[`inventory_location_id`] = resData?.id;
        return dataCopy;
      });
    } catch (error: any) {}
  };
  useEffect(() => {
    getFirstListPrice();
    getFirstInventoryLocation();
  }, []);
  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);
  useEffect(() => {
    if (defaultData?.products?.length > 0) setProducts(defaultData?.products);
    if (defaultData?.services?.length > 0) setServices(defaultData?.services);
    setTimeout(() => {
      if (defaultData?.paymentMethods)
        setPaymentMethods(defaultData?.paymentMethods);
    }, 1000);
  }, []);

  return (
    <div className="editable-transaction-field">
      <div className="grid-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DateField
              label="Fecha"
              onChange={(data: any) => {
                setData((prev: any) => {
                  const dataCopy: any = { ...prev };
                  dataCopy.date = data;
                  return dataCopy;
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {documentType == "Cotización" ? (
              <DateField
                label="Valido hasta."
                onChange={(data: any) => {
                  console.log(data);
                  setData((prev: any) => {
                    const dataCopy: any = { ...prev };
                    dataCopy.valid_until = data;
                    return dataCopy;
                  });
                }}
              />
            ) : (
              <DateField
                label="Fecha de venc."
                onChange={(data: any) => {
                  console.log(data);
                  setData((prev: any) => {
                    const dataCopy: any = { ...prev };
                    dataCopy.due_date = data;
                    return dataCopy;
                  });
                }}
              />
            )}
          </Grid>
          {!inventoryLocationLoading && (
            <Grid item xs={12} sm={6}>
              <SelectField
                label="Localización del inventario"
                searchPath={"inventory-locations"}
                primaryKey={"name"}
                defaultValue={inventoryLocationData}
                filters={[]}
                onChange={(data: any) => {
                  setData((prev: any) => {
                    const dataCopy = { ...prev };
                    dataCopy["inventory_location"] = data;
                    dataCopy[`inventory_location_id`] = data?.id;
                    return dataCopy;
                  });
                  setInventoryLocationData(data);
                }}
              />
            </Grid>
          )}
          {!listPriceLoading && transactionType == "sale" && (
            <Grid item xs={12} sm={6}>
              <SelectField
                label="Lista de precio"
                searchPath={"price-lists"}
                primaryKey={"name"}
                defaultValue={listPriceData}
                filters={[]}
                onChange={(data: any) => {
                  setData((prev: any) => {
                    const dataCopy = { ...prev };
                    dataCopy["price_list"] = data;
                    dataCopy[`price_list_id`] = data?.id;
                    return dataCopy;
                  });
                  setListPriceData(data);
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {!data[`${partyType}_id`] ? (
              <div className="select-party-btn-wrapper">
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectPartyModalIsOpen(true);
                  }}
                >
                  Seleccionar{" "}
                  {partyType == "customer" ? "cliente" : "proveedor"}
                </Button>
              </div>
            ) : (
              <div className="selected-party">
                <div>
                  <strong>{data[partyType]?.name}</strong>
                  <span>CUIT: {data[partyType]?.tin || "-"} </span>
                  <span>Email: {data[partyType]?.email || "-"} </span>
                </div>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setData((prev: any) => {
                      const dataCopy: any = { ...prev };
                      dataCopy[partyType] = null;
                      dataCopy[`${partyType}_id`] = null;
                      return dataCopy;
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="add-item-wrapper">
              <div>
                <Autocomplete
                  onSelect={(data: any) => {
                    if (data) {
                      //const productsCopy: any = [...products];
                      const productsCopy: any = JSON.parse(
                        JSON.stringify(products)
                      );

                      const searchIndex = productsCopy.findIndex(
                        (item: any) => item.uniqueId == data.uniqueId
                      );
                      console.log("searchIndex", searchIndex);
                      if (searchIndex != -1) {
                        productsCopy[searchIndex].quantity += 1;
                      } else {
                        data.quantity = 1;
                        data.discount_percentage = 0;
                        data.discount_amount = 0;
                        data.subtotal = data.cost;
                        data.subtotal_with_tax = data.cost;

                        productsCopy.push(data);
                      }
                      setProducts(productsCopy);
                    }
                  }}
                  //tableName="products"
                  searchPath="products-and-variants"
                  placeholder="Buscar productos..."
                  primaryKey={"name"}
                  filters={[
                    ["is_sellable", 1],
                    ["is_active", 1],
                  ]}
                  //thumbnailKey={""}
                />
              </div>
            </div>
            <div className="add-item-wrapper" style={{ marginTop: "10px" }}>
              <div>
                <Autocomplete
                  onSelect={(data: any) => {
                    if (data) {
                      const servicesCopy: any = JSON.parse(
                        JSON.stringify(services)
                      );

                      const searchIndex = servicesCopy.findIndex(
                        (item: any) => item.id == data.id
                      );

                      if (searchIndex != -1) {
                        servicesCopy[searchIndex].quantity += 1;
                      } else {
                        data.quantity = 1;
                        data.discount_percentage = 0;
                        data.discount_amount = 0;
                        data.subtotal = data.cost;
                        data.subtotal_with_tax = data.cost;

                        servicesCopy.push(data);
                      }
                      setServices(servicesCopy);
                    }
                  }}
                  tableName="services"
                  placeholder="Buscar servicios..."
                  primaryKey={"name"}
                  filters={[]}
                  //thumbnailKey={""}
                />
              </div>
            </div>
            <div className="items-wrapper">
              {(products?.length == 0 || !products) &&
              (services?.length == 0 || !services) ? (
                <div className="no-content">
                  <CartIcon />
                  <span>No hay productos/servicios en el carrito</span>
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th className="ta-c">Cant.</th>
                        <th className="ta-l">Detalle</th>
                        <th className="ta-r">Precio unitario</th>
                        <th className="ta-r" style={{ minWidth: "60px" }}>
                          Desc. (%)
                        </th>
                        <th className="ta-r" style={{ minWidth: "100px" }}>
                          Desc. ($)
                        </th>
                        <th className="ta-r">Subtotal</th>
                        <th className="ta-c">Impuesto</th>
                        <th className="ta-r">Subtotal con imp.</th>
                        <th className="w-40">
                          <span>&nbsp;</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={item.uniqueId}>
                            <EditableTransactionItem
                              value={item}
                              onChange={(data: any) => {
                                editProduct(data.uniqueId, data);
                              }}
                              onDelete={(data: any) => {
                                removeProduct(data.uniqueId);
                              }}
                              transactionType={transactionType}
                              adjustmentType={listPriceData?.adjustment_type}
                              adjustmentValue={listPriceData?.adjustment_value}
                              baseOn={listPriceData?.base_on}
                            />
                          </React.Fragment>
                        );
                      })}

                      {services.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={item.id}>
                            <EditableTransactionItem
                              value={item}
                              onChange={(data: any) => {
                                editService(data.id, data);
                              }}
                              onDelete={(data: any) => {
                                removeService(data.id);
                              }}
                              transactionType={transactionType}
                              adjustmentType={listPriceData?.adjustment_type}
                              adjustmentValue={listPriceData?.adjustment_value}
                              baseOn={listPriceData?.base_on}
                            />
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div className="totals-wrapper">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Importe neto gravado</td>
                      <td style={{ textAlign: "right" }}>
                        <div className="amount-wrapper">
                          <span>$</span>
                          <span>{formatNumber(data.taxableTotal)}</span>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Importe neto no gravado / exento</td>
                      <td style={{ textAlign: "right" }}>
                        <div className="amount-wrapper">
                          <span>$</span>
                          {formatNumber(data.nontaxableTotal)}
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    {valueAddedTaxes.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td style={{ textAlign: "right" }}>
                            <div className="amount-wrapper">
                              <span>$</span> {formatNumber(item.amount)}
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}

                    {perceptions.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div> {item.name}</div>
                            <span className="taxable-base">Base imponible</span>
                            <div className="input-group">
                              <span>$</span>
                              <input
                                type="number"
                                className="ta-r"
                                value={item.taxable_base || 0}
                                onChange={(e) => {
                                  const newPerceptions: any = [...perceptions];
                                  const value: any = e.target.value;
                                  newPerceptions[index].taxable_base = value;
                                  let amount: any =
                                    (value * newPerceptions[index].rate) / 100;
                                  amount = Math.round(amount * 100) / 100;
                                  newPerceptions[index].amount = amount;
                                  setPerceptions(newPerceptions);
                                }}
                                placeholder="Base imponible"
                              />
                            </div>
                          </td>

                          <td style={{ textAlign: "right" }}>
                            <span>$</span> {formatNumber(item.amount)}
                          </td>
                          <td>
                            <IconButton
                              color="inherit"
                              onClick={() => {
                                const newPerceptions: any = [...perceptions];
                                newPerceptions.splice(index, 1);
                                setPerceptions(newPerceptions);
                              }}
                              aria-label="remove perception"
                            >
                              <CloseIcon />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                    {otherTaxes.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div>{item.name}</div>
                            <span>Base imponible</span>
                            <div className="input-group">
                              <span>$</span>
                              <input
                                className="ta-r"
                                value={item.taxable_base || 0}
                                onChange={(e) => {
                                  const newOtherTaxes: any = [...otherTaxes];
                                  const value: any = e.target.value;
                                  newOtherTaxes[index].taxable_base = value;
                                  let amount: any =
                                    (value * newOtherTaxes[index].rate) / 100;
                                  amount = Math.round(amount * 100) / 100;
                                  newOtherTaxes[index].amount = amount;
                                  setOtherTaxes(newOtherTaxes);
                                }}
                                placeholder="Base imponible"
                              />
                            </div>
                          </td>

                          <td style={{ textAlign: "right" }}>
                            <span>$</span>
                            {formatNumber(item.amount)}
                          </td>
                          <td>
                            <IconButton
                              color="inherit"
                              onClick={() => {
                                const newOtherTaxes: any = [...otherTaxes];
                                newOtherTaxes.splice(index, 1);
                                setOtherTaxes(newOtherTaxes);
                              }}
                              aria-label="remove perception"
                            >
                              <CloseIcon />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}

                    <tr className="main-total-wrapper">
                      <td>Total</td>
                      <td style={{ textAlign: "right" }}>
                        <div className="amount-wrapper">
                          <span>$</span>
                          <span>{formatNumber(data.total)}</span>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <Button
                    onClick={() => {
                      setSelectPerceptionModalIsOpen(true);
                    }}
                    startIcon={<AddIcon />}
                  >
                    Percepción
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectOtherTaxModalIsOpen(true);
                    }}
                    startIcon={<AddIcon />}
                  >
                    Otros impuestos
                  </Button>
                </div>
              </div>
            </div>

            {
              //documentType == "order" ||
              (documentType == "invoice" || documentType == "debit_note") && (
                <div className="payment-wrapper">
                  <div>
                    <h3 className="label">
                      {transactionType == "sale" ? "Cobro" : "Pago"}
                    </h3>
                    <div className="payment-methods">
                      {paymentMethods.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            <span>{item.visibleName}</span>
                            <div>
                              <IconButton
                                aria-label="pay"
                                onClick={() => {
                                  const newPaymentMethods: any = [
                                    ...paymentMethods,
                                  ];
                                  newPaymentMethods.forEach((item: any) => {
                                    item.amount = 0;
                                  });
                                  const amount =
                                    Math.round(data.total * 100) / 100;
                                  newPaymentMethods[index].amount = amount;
                                  setPaymentMethods(newPaymentMethods);
                                }}
                              >
                                <ArrowCircleRightIcon />
                              </IconButton>
                              <div className="input-group">
                                <span>$</span>
                                <input
                                  className="ta-r"
                                  value={item.amount}
                                  onChange={(e) => {
                                    const newPaymentMethods: any = [
                                      ...paymentMethods,
                                    ];
                                    const value: any = e.target.value;

                                    newPaymentMethods[index].amount = value;
                                    setPaymentMethods(newPaymentMethods);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="total-paid">
                      <span>
                        Total {transactionType == "sale" ? "cobrado" : "pagado"}
                      </span>{" "}
                      <strong>$ {formatNumber(data.totalPaid)}</strong>
                    </div>
                  </div>
                </div>
              )
            }

            {documentType == "invoice" && (
              <div className="all-items-delivered-wrapper">
                <CheckboxField
                  label={"Todos los items entregados"}
                  onChange={(data: any) => {
                    console.log(data);
                    setData((prev: any) => {
                      const dataCopy: any = { ...prev };
                      dataCopy.allItemsDelivered = data;
                      return dataCopy;
                    });
                  }}
                  defaultValue={true}
                  description={""}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <SelectModal
        open={selectPartyModalIsOpen}
        setOpen={setSelectPartyModalIsOpen}
        title={"Seleccionar " + visiblePartyType}
        tableName={partyType == "customer" ? "customers" : "suppliers"}
        formTitle={"Ficha de " + visiblePartyType}
        formFields={
          partyType == "customer" ? customerFormFields : supplierFormFields
        }
        primaryKey="name"
        secondaryKey="email"
        filters={[]}
        onClose={(data: any) => {
          console.log("data", data);
          if (data) {
            setData((prev: any) => {
              const dataCopy = { ...prev };
              dataCopy[partyType] = data;
              dataCopy[`${partyType}_id`] = data.id;
              return dataCopy;
            });
          }
        }}
      />
      <SelectModal
        open={selectValueAddedTaxModalIsOpen}
        setOpen={setSelectValueAddedTaxModalIsOpen}
        title="Seleccionar impuesto"
        tableName="value-added-taxes"
        formTitle="Ficha de impuesto"
        formFields={valueAddedTaxFormFields}
        primaryKey="name"
        //secondaryKey="tin"
        onClose={(data: any) => {
          console.log("data", data);
          if (data) {
            setData((prev: any) => {
              const dataCopy = { ...prev };
              // dataCopy.customer = data;
              // dataCopy.customer_id = data.id;
              return dataCopy;
            });
          }
        }}
      />
      <SelectModal
        open={selectPerceptionModalIsOpen}
        setOpen={setSelectPerceptionModalIsOpen}
        title="Seleccionar perception"
        tableName="perceptions"
        formTitle="Ficha de percepcion"
        formFields={perceptionFormFields}
        primaryKey="name"
        //secondaryKey="tin"
        onClose={(data: any) => {
          console.log("data", data);
          if (data) {
            const perceptionsCopy: any = JSON.parse(
              JSON.stringify(perceptions)
            );
            const searchIndex = perceptionsCopy.findIndex(
              (item: any) => item.id == data.id
            );
            if (searchIndex == -1) {
              //data.taxable_base = 0;
              data.amount = 0;
              perceptionsCopy.push(data);
            }

            setPerceptions(perceptionsCopy);
          }
        }}
      />
      <SelectModal
        open={selectOtherTaxModalIsOpen}
        setOpen={setSelectOtherTaxModalIsOpen}
        title="Seleccionar otro impuesto"
        tableName="other-taxes"
        formTitle="Ficha de otro impuesto"
        formFields={otherTaxFormFields}
        primaryKey="name"
        //secondaryKey="tin"
        onClose={(data: any) => {
          console.log("data", data);
          if (data) {
            const otherTaxesCopy: any = JSON.parse(JSON.stringify(otherTaxes));
            const searchIndex = otherTaxesCopy.findIndex(
              (item: any) => item.id == data.id
            );
            if (searchIndex == -1) {
              //data.taxable_base = 0;
              data.amount = 0;
              otherTaxesCopy.push(data);
            }

            setOtherTaxes(otherTaxesCopy);
          }
        }}
      />
      <FormModal
        open={productFormModalIsOpen}
        setOpen={setProductFormModalIsOpen}
        onClose={(data: any) => {
          console.log("onClose", data);
        }}
        title={"Ficha de producto"}
        fields={productFormFields}
        tableName={"products"}
      />
      <FormModal
        open={serviceFormModalIsOpen}
        setOpen={setServiceFormModalIsOpen}
        onClose={(data: any) => {
          console.log("onClose", data);
        }}
        title={"Ficha de servicio"}
        fields={serviceFormFields}
        tableName={"services"}
      />
    </div>
  );
};

export { EditableTransactionField };
