import React, { useState, useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import "./ProductAttributeValuesSelector.scss";
const ProductAttributeValuesSelector = ({ data, onChange }: any) => {
  // Crear un estado local para manejar los cambios de selección
  const [selectedData, setSelectedData] = useState(data);

  // Actualizar el estado local si la prop 'data' cambia
  useEffect(() => {
    setSelectedData(data);
  }, [data]);

  // Manejar la selección/deselección de un valor
  const handleValueSelect = (attributeIndex: number, valueIndex: number) => {
    // Crear una copia del estado actual
    let updatedData = [...selectedData];

    // Actualizar el estado 'selected' del valor seleccionado
    updatedData[attributeIndex].values[valueIndex].selected =
      !updatedData[attributeIndex].values[valueIndex].selected;

    // Actualizar el estado local
    setSelectedData(updatedData);

    // Llamar a la función 'onChange' con los datos actualizados
    onChange(updatedData);
  };

  return (
    <div className="product-attribute-values-selector-container">
      {selectedData.map((attribute: any, attributeIndex: number) => (
        <div key={attributeIndex}>
          <h3>{attribute.name}</h3>
          <div>
            {attribute.values.map((value: any, valueIndex: number) => (
              <a
                key={valueIndex}
                onClick={() => handleValueSelect(attributeIndex, valueIndex)}
              >
                {value.selected ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
                {value.value}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export { ProductAttributeValuesSelector };
