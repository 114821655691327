import React, { useState, useEffect, useRef } from "react";
import { Dialog, IconButton, Slide, Fab } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import "./ScrapingControlPanelModal.scss";
import { PauseIcon } from "components/icons/PauseIcon";
import { PlayIcon } from "components/icons/PlayIcon";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ScrapingControlPanelModal = ({ open, setOpen, onClose, source }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [isScraping, setIsScraping] = useState(false);
  const [requests, setRequests] = useState([] as any);
  const [savedRecordCount, setSavedRecordCount] = useState(0);
  const [path, setPath] = useState(null as any);
  const initialPathRef = useRef<string>(source?.path);
  const [workflowCompleted, setWorkflowCompleted] = useState(false);

  const handleToggleScraping = () => {
    if (isScraping) {
      setIsScraping(false); // Pausamos el workflow
    } else {
      // Si estamos reanudando, debemos verificar si el workflow se completó
      if (workflowCompleted) {
        setPath(initialPathRef.current); // Restablecemos el path al valor inicial
        setWorkflowCompleted(false); // Restablecemos el estado a no completado
      }
      setIsScraping(true); // Reactivamos el workflow
    }
  };
  const handleScrapeData = async () => {
    if (!isScraping) return; // Si no está en ejecución, no hacemos nada

    try {
      const res = await AxiosInterceptor.get(`${urls.server}/api/${path}`);
      const { totalSavedRecords, newPath } = res.data;

      setSavedRecordCount((prevCount) => prevCount + totalSavedRecords);
      setRequests((prevRequests: any) => [
        ...prevRequests,
        { newPath, totalSavedRecords },
      ]);

      if (newPath) {
        setPath(newPath); // Establecemos el nuevo path para la próxima petición
      } else {
        setIsScraping(false); // Detenemos el workflow si no hay nuevo path
        setWorkflowCompleted(true); // Indicamos que el workflow se completó
      }
    } catch (error) {
      console.error("Error during scraping workflow:", error);
      setIsScraping(false); // Detenemos el workflow en caso de error
    }
  };
  useEffect(() => {
    if (open) {
      console.log(source);
      setPath(source?.path);
    }
  }, [open]);
  useEffect(() => {
    // Sólo iniciamos el workflow de scraping si isScraping es true
    if (isScraping && path) {
      handleScrapeData();
    }
  }, [isScraping, path]);
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="scraping-control-panel-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>{"Scraping"}</h2>
            <span>{source?.label}</span>
          </div>

          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          <div className="play-pause-button-wrapper">
            <IconButton
              color={"primary"}
              aria-label="play pause btn"
              onClick={handleToggleScraping}
            >
              {isScraping ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
          </div>
          <div className="saved-record-count-wrapper"></div>
          <div className="list-wrapper"></div>
        </div>
      </Dialog>
    </>
  );
};

export { ScrapingControlPanelModal };
