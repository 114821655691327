import { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import "./CheckboxesField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

import { Spinner } from "../../Spinner/Spinner";
const CheckboxesField = (props: any) => {
  const {
    editable,
    label,
    editPath,
    loadPath,
    onChange,
    description,
    name,
    data = [],
  }: any = props;
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dataCopy, setDataCopy] = useState(data);



  const selectItem = (index: number) => {
    const data = [...dataCopy];
    data[index].selected = !data[index].selected;
    setDataCopy(data);
  };
  useEffect(() => {
    if (onChange) onChange(dataCopy);
  }, [dataCopy]);
  const loadData = async () => {
    setLoading(true);
    let url = `${urls.server}/api/${loadPath}`;

    const res = await AxiosInterceptor.get(url);
    const data = await res.data;
    setLoading(false);
    setDataCopy(data);
  };
  useEffect(() => {
    if (dataCopy.length == 0) {
      loadData();
    }
  }, []);
  return (
    <div className="checkboxes-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <FieldEditControls
          value={dataCopy}
          fieldName={name}
          editPath={editPath}
          onEditStart={() => {
            setIsEditEnabled(true);
          }}
          onEditCancel={(originalValue) => {
            setIsEditEnabled(false);
            setDataCopy(originalValue);
          }}
          onEditEnd={(success: boolean, originalValue) => {
            setIsEditEnabled(false);
            if (!success) {
              setDataCopy(originalValue);
            }
          }}
        />
        )}
      </div>
      <div className="field-body">
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} size="sm" />
          </div>
        ) : (
          <>
            {editable && !isEditEnabled ? (
              <div className="readonly-items"></div>
            ) : (
              <div className="editable-items">
                {dataCopy.map((item: any, index: number) => {
                  return (
                    <a key={index} onClick={() => selectItem(index)}>
                      <span style={{ fontWeight: item.selected ? 700 : 400 }}>
                        {item.name}
                      </span>
                      {item.selected ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </a>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { CheckboxesField };
