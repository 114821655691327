import { IconButton } from "@mui/material";
import React, { useState } from "react";

import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import CircularProgress from "@mui/material/CircularProgress";
import "./LockToggleCell.scss";
import { LockedIcon } from "../../icons/LockedIcon";
import { UnlockedIcon } from "../../icons/UnlockedIcon";
const LockToggleCell = (props: any) => {
  let { editPath, value, tableName, recordId } = props;
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState(value);

  const handleClick = async () => {
    tableName = tableName.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/${data ? "unlock" : "lock"
      }`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.put(`${url}`, {
        data: !data,
      });
      const resData = await res.data;
      setProcessing(false);
      setData((prev: boolean) => !prev);
    } catch (error: any) {
      setProcessing(false);
    }
  };

  return (
    <div className="lock-toggle-cell-container">
      {processing ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <IconButton
          color={data ? "primary" : "inherit"}
          aria-label="lock toogle btn"
          onClick={handleClick}
        >
          {data ? <LockedIcon /> : <UnlockedIcon />}
        </IconButton>
      )}
    </div>
  );
};

export { LockToggleCell };
