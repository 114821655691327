import React, { useState } from "react";

import "./PurchasesExpandedCard.scss";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";

import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";

import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getFields } from "../../utils/get-fields";
import { getCols } from "../../utils/get-cols";
import Grid from "@mui/material/Grid";
import { getDividerGroups } from "../../utils/get-divider-groups";

const PurchasesExpandedCard = (props: any) => {
  const { title, data } = props;
  // const [invoiceFormModalIsOpen, setInvoiceFormModalIsOpen] = useState(false);

  // const [debitNoteFormModalIsOpen, setDebitNoteFormModalIsOpen] =
  //   useState(false);
  // const [creditNoteFormModalIsOpen, setCreditNoteFormModalIsOpen] =
  //   useState(false);
  // const [paymentFormModalIsOpen, setPaymentFormModalIsOpen] = useState(false);
  const [quotationRecordsModalIsOpen, setQuotationRecordsModalIsOpen] =
    useState(false);
  const [orderRecordsModalIsOpen, setOrderRecordsModalIsOpen] = useState(false);
  const [invoiceRecordsModalIsOpen, setInvoiceRecordsModalIsOpen] =
    useState(false);
  const [debitNoteRecordsModalIsOpen, setDebitNoteRecordsModalIsOpen] =
    useState(false);
  const [creditNoteRecordsModalIsOpen, setCreditNoteRecordsModalIsOpen] =
    useState(false);
  const [paymentRecordsModalIsOpen, setPaymentRecordsModalIsOpen] =
    useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const invoiceCols = getCols().invoice;
  const invoiceDividerGroups = getDividerGroups().invoice;
  const debitNoteCols = getCols().debitNote;
  const debitNoteDividerGroups = getDividerGroups().debitNote;
  const creditNoteCols = getCols().creditNote;
  const creditNoteDividerGroups = getDividerGroups().creditNote;
  const paymentCols = getCols().payment;
  const paymentDividerGroups = getDividerGroups().payment;
  return (
    <>
      <div className="purchases-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setInvoiceFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Factura de compra</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDebitNoteFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Nota de débito</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setCreditNoteFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Nota de crédito</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setPaymentFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Pago</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setQuotationRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Cotización</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setOrderRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Pedido</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setInvoiceRecordsModalIsOpen(true);
            }}
          >
            <strong>Facturas</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDebitNoteRecordsModalIsOpen(true);
            }}
          >
            <strong>Notas de débito</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setCreditNoteRecordsModalIsOpen(true);
            }}
          >
            <strong>Notas de crédito</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setPaymentRecordsModalIsOpen(true);
            }}
          >
            <strong>Pagos</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <RecordsModal
        open={quotationRecordsModalIsOpen}
        setOpen={setQuotationRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Cotizaciones de compras"}
        tableName={"purchase-quotations"}
        cols={invoiceCols}
        dividerGroups={invoiceDividerGroups}
        customFormName={"transaction"}
        customFormProps={{
          transactionType: "purchase",
          documentType: "quotation",
        }}
      />
      <RecordsModal
        open={orderRecordsModalIsOpen}
        setOpen={setOrderRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Pedidos de compras"}
        tableName={"purchase-orders"}
        cols={invoiceCols}
        dividerGroups={invoiceDividerGroups}
        customFormName={"transaction"}
        customFormProps={{
          transactionType: "purchase",
          documentType: "order",
        }}
      />
      <RecordsModal
        open={invoiceRecordsModalIsOpen}
        setOpen={setInvoiceRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Facturas de compras"}
        tableName={"purchase-invoices"}
        cols={invoiceCols}
        dividerGroups={invoiceDividerGroups}
        customFormName={"transaction"}
        customFormProps={{
          transactionType: "purchase",
          documentType: "invoice",
        }}
      />
      <RecordsModal
        open={debitNoteRecordsModalIsOpen}
        setOpen={setDebitNoteRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Notas de débito"}
        tableName={"purchase-debit-notes"}
        cols={debitNoteCols}
        dividerGroups={debitNoteDividerGroups}
        customFormName={"transaction"}
        customFormProps={{
          transactionType: "purchase",
          documentType: "debitNote",
        }}
      />
      <RecordsModal
        open={creditNoteRecordsModalIsOpen}
        setOpen={setCreditNoteRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Notas de crédito"}
        tableName={"purchase-credit-notes"}
        cols={creditNoteCols}
        dividerGroups={creditNoteDividerGroups}
        customFormName={"transaction"}
        customFormProps={{
          transactionType: "purchase",
          documentType: "creditNote",
        }}
      />
      <RecordsModal
        open={paymentRecordsModalIsOpen}
        setOpen={setPaymentRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Recibos de cobranzas"}
        tableName={"purchase-payments"}
        cols={paymentCols}
        dividerGroups={paymentDividerGroups}
        customFormName={"payment"}
        customFormProps={{
          transactionType: "purchase",
        }}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { PurchasesExpandedCard };
