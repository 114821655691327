import React from "react";
import "./InventoryManagerCard.scss";
import { AnimatedNumber } from "components/AnimatedNumber/AnimatedNumber";
import colors from "config/colors";

const InventoryManagerCard = ({ title, data }: any) => {
  return (
    <div className="Inventory-manager-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <div>
          <AnimatedNumber
            value={data.negativeOrZeroStockCount}
            duration="500"
            fontWeight="800"
            color={colors.danger}
            fontSize="28px"
          />
          <span>Sin Stock</span>
        </div>
        <div>
          <AnimatedNumber
            value={data.reorderThresholdReachedCount}
            duration="500"
            fontWeight="800"
            color={colors.danger}
            fontSize="28px"
          />
          <span>Al limite</span>
        </div>
      </div>
    </div>
  );
};

export { InventoryManagerCard };
