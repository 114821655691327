import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import urls from "config/urls";
import { useAuth } from "../../contexts/AuthContext";
import { BoardIcon } from "../icons/BoardIcon";
import { BoardShadowIcon } from "../icons/BoardShadowIcon";
import "./Tabs.scss";
import { SnapIcon } from "../icons/SnapIcon";
import { SnapWrapperIcon } from "../icons/SnapWrapperIcon";
import { NotificationWrapperIcon } from "../icons/NotificationWrapperIcon";
import { NotificationIcon } from "../icons/NotificationIcon";

const Tabs = () => {
  const location = useLocation();
  const tabPathNames: any[] = ["/notifications", "/snap", "/profile", "/"];
  const { getUser } = useAuth();
  useEffect(() => {}, []);
  return (
    <>
      {tabPathNames.includes(location.pathname) && (
        <div className="tabs">
          <div>
            {/* <NavLink
              className={({ isActive }) => (isActive ? "active-tab" : "")}
              to="/snap"
            >
              <SnapWrapperIcon />
              <SnapIcon />
              <span>Snap</span>
            </NavLink> */}
            <NavLink
              className={({ isActive }) => (isActive ? "active-tab" : "")}
              to="/notifications"
            >
              <NotificationWrapperIcon />
              <NotificationIcon />
              <span>Notif.</span>
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active-tab" : "")}
              to="/"
            >
              <BoardShadowIcon />
              <BoardIcon />
              Tablero
            </NavLink>
            <div>
              <NavLink
                className={({ isActive }) => (isActive ? "active-tab" : "")}
                to="/profile"
              >
                {getUser() && (
                  <img
                    src={`${urls.server}${getUser()?.profile_picture}`}
                    alt="profile"
                  />
                )}
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { Tabs };
