import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const TutorialsPage = () => {
  const navigate = useNavigate();
  let { slug } = useParams();
  return (
    <>
      <div>Tutorial Page</div>
      <>{slug}</>
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        Volver
      </button>
    </>
  );
};

export { TutorialsPage };
