import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import "./FixUnfixBoardCardsModal.scss";
import { CancelIcon } from 'components/icons/CancelIcon';
import { Spinner } from 'components/Spinner/Spinner';
import { HeaderSearchbar } from 'components/HeaderSearchbar/HeaderSearchbar';
import { BoardIcon } from 'components/icons/BoardIcon';
import { PinWrapperIcon } from 'components/icons/PinWrapperIcon';
import { PinIcon } from 'components/icons/PinIcon';
import recordService from 'services/recordService';
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from 'config/urls';
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FixUnfixBoardCardsModal: React.FC<ModalProps> = ({ isOpen, onClose, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([] as any);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const loadData = async () => {
    setLoading(true);
    try {
      const res = await recordService.fetchAll({
        listPath: 'my/cards',
      });
      setCards(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // const fixUnfixCard = async (index: number, id: number) => {
  //   const res = await AxiosInterceptor.put(`${urls.server}/api/my/cards/${id}`);
  //   const data = await res.data;
  //   if (data.success) {
  //     setCards((prev: any) => {
  //       const cardsCopy = [...prev];
  //       cardsCopy[index].is_fixed = !cardsCopy[index].is_fixed;
  //       return cardsCopy;
  //     });
  //     setRefreshOnClose(true);
  //   }
  // };
  const fixUnfixCard = async (cardId: number) => {
    const res = await AxiosInterceptor.put(`${urls.server}/api/my/cards/${cardId}`);
    const data = await res.data;
    if (data.success) {
      setCards((prev: any) => {
        return prev.map((card: any) => {
          if (card.id === cardId) {
            return { ...card, is_fixed: !card.is_fixed };
          }
          return card;
        });
      });
      setRefreshOnClose(true);
    }
  };

  const filteredCards = cards.filter((card: any) => {
    const matchesSearchTerm = searchTerm === "" || card.visible_name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearchTerm;
  });
  useEffect(() => {
    loadData();
  }, []);
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => {
        onClose();
        if (refreshOnClose && onChange) onChange()
      }}
      TransitionComponent={Transition}
      className={`fix-unfix-cards-modal-container`}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: "20px",
          paddingRight: "35px",
        },
      }}
    >
      <div className='modal-window'>

        <a onClick={() => {
          onClose();
          if (refreshOnClose && onChange) onChange()
        }}>
          <CancelIcon />
        </a>

        <div>
          <div>
            {loading && (
              <div className="spinner-wrapper">
                <Spinner visible={loading} />
              </div>
            )}

            {!loading && (
              <>
                <span className="header-searchbar-wrapper">
                  <HeaderSearchbar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </span>
                {cards.length == 0 && (
                  <div className="no-content">
                    <BoardIcon />
                    <p>No hay tarjetas</p>
                  </div>
                )}

                {filteredCards.map((item: any, index: number) => {
                  return (
                    <a
                      key={item.id}
                      className={item.is_fixed ? "fixed" : ""}
                      onClick={() => fixUnfixCard(item.id)}
                    >
                      <span>
                        <PinWrapperIcon />
                        <PinIcon />
                      </span>

                      {item.visible_name}
                    </a>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

    </Dialog>
  );
};

export default FixUnfixBoardCardsModal;