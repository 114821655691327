import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import "./StatusesFilter.scss";
import colors from "config/colors";

const StatusesFilter = ({ defaultValue, label, options, onChange }: any) => {
  const [selectedStatuses, setSelectedStatuses] = useState(defaultValue?.data || []);

  const toggleStatus = (status: { name: string; visibleName: string; color: string }) => {
    const index = selectedStatuses.findIndex((item: any) => item.name === status.name);
    if (index > -1) {
      // Si el estado ya está seleccionado, lo quitamos
      setSelectedStatuses((prev: any) => prev.filter((item: any) => item.name !== status.name));
    } else {
      // Si el estado no está seleccionado, lo agregamos
      setSelectedStatuses((prev: any) => [...prev, status]);
    }
  };

  useEffect(() => {
    if (onChange) onChange({ data: selectedStatuses });
    console.log(selectedStatuses);
  }, [selectedStatuses]);

  return (
    <div className="statuses-filter-container">
      <label className="filter-label">{label}</label>
      <div className="options-wrapper">
        {options.map((option: any, index: number) => (
          <div key={index} className="option" onClick={() => toggleStatus(option)}>
            <div
              style={{
                color: selectedStatuses.some((selectedStatus: any) => selectedStatus.name === option.name)
                  ? option.color
                  : colors.text,
              }}
            >
              {selectedStatuses.some((selectedStatus: any) => selectedStatus.name === option.name) ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </div>
            <span>{option.visibleName}</span>
          </div>
        ))}

      </div>
    </div>
  );
};

export { StatusesFilter };
