export const getCols = () => {
  const data: any = [
    {
      name: "text",
      visibleName: "Texto",
      icon: "text",
      config: {
        type: "text",
        visibleName: "Label",
        name: null,
        sortable: true,
      },
    },
    {
      name: "thumbnail",
      visibleName: "Miniatura",
      icon: "thumbnail",
      config: {
        type: "thumbnail",
        visibleName: "Label",
        name: null,
        sortable: false,
      },
    },
    // {
    //   name: "timeDiff",
    //   visibleName: "Diferencia de tiempo",
    //   icon: "timeDiff",
    //   config: {
    //     type: "timeDiff",
    //     visibleName: "Label",
    //     name: null,
    //     sortable: true,
    //   },
    // },
    {
      name: "date",
      visibleName: "Fecha",
      icon: "date",
      config: {
        type: "date",
        visibleName: "Label",
        name: null,
        sortable: true,
        showDiff: false,
      },
    },
    {
      name: "tags",
      visibleName: "Etiquetas",
      icon: "tags",
      config: {
        type: "tags",
        visibleName: "Label",
        name: null,
        sortable: false,
      },
    },
    {
      name: "address",
      visibleName: "Domicilio",
      icon: "address",
      config: {
        type: "address",
        visibleName: "Label",
        name: null,
        sortable: true,
      },
    },
    {
      name: "color",
      visibleName: "Color",
      icon: "color",
      config: {
        type: "color",
        visibleName: "Label",
        name: null,
      },
    },
  ];
  return data;
};
// time_ago_in_words
// time_ahead_in_words
// time_diff_in_words
