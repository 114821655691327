export const getCols = () => {
  const data: any = {
    user: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Email",
        name: "email",
        sortable: false,
      },
      {
        type: "checkbox",
        visibleName: "Es admin",
        name: "is_admin",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Tarjetas",
        name: "user_cards_count",
        sortable: false,
      },
      // {
      //   type: "text",
      //   visibleName: "Roles",
      //   name: "roles_str",
      //   sortable: false,
      // },
    ],
  };

  return data;
};
