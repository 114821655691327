import React from "react";

const SnapPage = () => {
  return (
    <div className="page-container">
      <div className="page-header">
        <h1>Snap (Chasquido)</h1>
      </div>
      <div className="page-body"></div>
    </div>
  );
};

export { SnapPage };
