export const getFields = () => {
  const data: any = {
    valueAddedTax: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alicuota",
        name: "rate",
        description: null,
        size: 6,
        append: "%",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
    otherTax: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alicuota",
        name: "rate",
        description: null,
        size: 6,
        append: "%",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
    perception: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alicuota",
        name: "rate",
        description: null,
        size: 6,
        append: "%",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
    withholding: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alicuota",
        name: "rate",
        description: null,
        size: 6,
        append: "%",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
