import React, { useState, useEffect } from "react";
import "./DateFilter.scss"; // Asumiendo que tienes un archivo de estilos específico para este componente.

const DateFilter = ({ defaultValue, label, onChange }: any) => {
  const conditions = [
    { key: "equals", label: "Igual a" },
    { key: "greater", label: "Mayor que" },
    { key: "greater_equals", label: "Mayor o igual que" },
    { key: "less", label: "Menor que" },
    { key: "less_equals", label: "Menor o igual que" },
    { key: "between", label: "Entre" },
  ];

  const [value, setValue] = useState(defaultValue as any);

  const handleConditionClick = (condition: string) => {
    setValue((prevValue: any) => {
      if (condition === "between") {
        return { ...prevValue, condition, data: { from: "", to: "" } };
      }
      return { ...prevValue, condition, data: "" };
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string = "data"
  ) => {
    if (value.condition === "between") {
      setValue((prevValue: any) => ({
        ...prevValue,
        data: {
          ...prevValue.data,
          [key]: event.target.value,
        },
      }));
    } else {
      setValue((prevValue: any) => ({
        ...prevValue,
        data: event.target.value,
      }));
    }
  };

  useEffect(() => {
    if (value) onChange(value);
  }, [value]);

  return (
    <div className="date-filter-container">
      <label className="filter-label">{label}</label>
      <div className="radio-wrapper">
        {conditions.map((item: any, index: number) => (
          <a
            key={index}
            className={item.key === value?.condition ? "selected" : ""}
            onClick={() => handleConditionClick(item.key)}
          >
            {item.label}
          </a>
        ))}
      </div>
      <div className="value-wrapper">
        {value.condition === "between" ? (
          <>
            <input
              type="date"
              onChange={(e) => handleInputChange(e, "from")}
              value={value?.data?.from || ""}
            />
            <input
              type="date"
              onChange={(e) => handleInputChange(e, "to")}
              value={value?.data?.to || ""}
            />
          </>
        ) : (
          <input
            type="date"
            onChange={handleInputChange}
            value={value?.data || ""}
          />
        )}
      </div>
    </div>
  );
};

export { DateFilter };
