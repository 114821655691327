import React, { useState, useEffect, useRef } from "react";
import { Dialog, Fab, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import ClearIcon from "@mui/icons-material/Clear";

import { UsersAutocomplete } from "../../UsersAutocomplete/UsersAutocomplete";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import "./UserListModal.scss";
import { CheckIcon } from "../../icons/CheckIcon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UserListModal = ({ open, setOpen, onClose, data, subtitle }: any) => {
  const [users, setUsers] = useState(data);
  const [refreshOnClose, setRefreshOnClose] = useState(false);

  // const [isDisabled, setIsDisabled] = useState(true);
  // const usersRef = useRef(users);

  // useEffect(() => {
  //   if (JSON.stringify(usersRef.current) !== JSON.stringify(users)) {
  //     setIsDisabled(false);
  //   } else {
  //     setIsDisabled(true);
  //   }
  // }, [users]);
  useEffect(() => {
    if (open) setUsers(data);
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
        // onDismiss({
        //   refresh: refreshOnClose,
        // });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="user-list-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Usuarios vinculados</h2>
          <span>
            <b>{subtitle}</b>
          </span>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="list-wrapper">
          <div>
            {users.map((item: any) => {
              return (
                <div className="user-item" key={item?.id}>
                  <div>
                    {item?.user ? (
                      <img
                        src={urls.server + item?.user?.profile_picture}
                        alt=""
                      />
                    ) : (
                      <img src={urls.server + item?.profile_picture} alt="" />
                    )}
                  </div>
                  <div>
                    <strong>{item?.name}</strong>
                    <span>({item?.email})</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { UserListModal };
