import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { IconButton } from "@mui/material";
import "./TagList.scss";
import { TagIcon } from "components/icons/TagIcon";
import { PencilIcon } from "components/icons/PencilIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);
const SortableItem = SortableElement<{
  value: any;
  onEdit: any;
  onDelete: any;
}>(
  ({ value, onEdit, onDelete }: { value: any; onEdit: any; onDelete: any }) => (
    <div className="tag-item" style={{ zIndex: 99999999 }}>
      <div>
        <TagIcon style={{ color: value.color }} />
        <span>{value.name}</span>
      </div>
      <div>
        <IconButton aria-label="edit" onClick={() => onEdit(value)}>
          <PencilIcon />
        </IconButton>
        <IconButton aria-label="edit" onClick={() => onDelete(value)}>
          <DeleteIcon />
        </IconButton>
        <DragHandle />
      </div>
    </div>
  )
);

const SortableList = SortableContainer<{
  items: string[];
  onEdit: any;
  onDelete: any;
}>(
  ({
    items,
    onEdit,
    onDelete,
  }: {
    items: string[];
    onEdit: any;
    onDelete: any;
  }) => {
    return (
      <div className="tag-list-container">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  }
);

const TagList = ({ handleEdit, handleDelete, items, onSortEnd }: any) => {
  return (
    <SortableList
      items={items}
      onSortEnd={onSortEnd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      useDragHandle
    />
  );
};
export { TagList };
