import React, { useState } from "react";
import { IconButton, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./MoreActionsCell.scss";
const MoreActionsCell = ({ options, id, onSelect }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverIsOpen = Boolean(anchorEl);

  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const selectItem = (action: any) => {
    setAnchorEl(null);
    onSelect(action);
  };
  return (
    <div className="more-actions-cell-container">
      <IconButton aria-label="actions" onClick={presentPopover}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={"record_popover_" + id}
        open={popoverIsOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popover-items">
          {options.map((item: any, index: number) => {
            return (
              <a
                key={index}
                onClick={() => {
                  selectItem(item.actionName);
                }}
              >
                {item.actionVisibleName}
              </a>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export { MoreActionsCell };
