export const getDividerGroups = () => {
  const data: any = {
    supplier: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "suppliers/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "suppliers/year-months-with-records",
      },
      {
        type: "tags",
        name: "supplier_tags",
        visibleName: "Etiquetas",
        abbr: "Etiq.",
        path: "suppliers/tags-with-records",
      },
    ],
  };

  return data;
};
