import { useState, useEffect } from "react";
import { CheckIcon } from "components/icons/CheckIcon";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./ColorField.scss";
import { ColorIcon } from "components/icons/ColorIcon";

const ColorField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    recordId,
    name,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const colors = [
    "#0046BF",
    "#00A7A7",
    "#00A700",
    "#A7A700",
    "#fa7121",
    "#A70000",
    "#A700A7",
    "#650086",
    "#222222",
    "#7f7f7f",
  ];
  const amts: any = [-0.4, -0.25, 0, 0.25, 0.4];
  const lightenDarkenColor = (hex: string, amount?: number) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length === 6) {
      hex = hex[0] + hex[1] + hex[2] + hex[3] + hex[4] + hex[5];
    }
    amount = amount || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c: any;
    for (let i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * amount), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  };
  /**
   * Popover
   */
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePresentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? name : undefined;
  const handleSelectColor = (
    event: React.MouseEvent<HTMLButtonElement>,
    color: string,
    amount: number
  ) => {
    event.preventDefault();
    //onChange(lightenDarkenColor(color, amount));
    console.log(lightenDarkenColor(color, amount));
    setValue(lightenDarkenColor(color, amount));
    setAnchorEl(null);
    setCustomColor(null);
  };

  const [customColor, setCustomColor] = useState(null as any);
  const handleChangeCustomColor = (e: any) => {
    setCustomColor(e.target.value);
  };

  const handleSelectCustomColor = () => {
    console.log(customColor);
    setValue(`#${customColor}`);

    setAnchorEl(null);
    setCustomColor(null);
  };
  return (
    <div className="color-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !isEditEnabled ? (
          <div className="readonly-content">
            {value ? (
              <span
                className="readonly-color"
                style={{ backgroundColor: value }}
              ></span>
            ) : (
              <div className="no-value">
                <ColorIcon />
                <span>Sin color</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            <div className="color-picker">
              <a
                style={{ backgroundColor: value }}
                onClick={(e: any) => handlePresentPopover(e)}
              ></a>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="popover-colors-table-wrapper">
                  <table>
                    <tbody>
                      {colors.map((color: string, r: number) => {
                        return (
                          <tr key={r}>
                            {amts.map((amount: number, c: number) => {
                              return (
                                <td key={c}>
                                  <a
                                    onClick={(e: any) =>
                                      handleSelectColor(e, color, amount)
                                    }
                                    style={{
                                      backgroundColor: lightenDarkenColor(
                                        color,
                                        amount
                                      ),
                                    }}
                                  ></a>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div>
                    <span style={{ backgroundColor: "#" + customColor }}></span>
                    <div>
                      <strong>#</strong>
                      <input
                        type="text"
                        value={customColor || ""}
                        onChange={(e) => handleChangeCustomColor(e)}
                      />
                      <IconButton
                        aria-label="send color"
                        onClick={handleSelectCustomColor}
                        disabled={
                          customColor?.length !== 3 && customColor?.length !== 6
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export { ColorField };
