import { useState, useEffect } from "react";
import Icon from "../../Icon/Icon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import "./PickerField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
const PickerField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    options = [],
    defaultValue,
    validations,
    readonly,
    tableName,
    name,
    recordId,
  }: any = props;
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [value, setValue] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const handleSave = async () => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    if (name) name = name.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/${name}`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const data = {
        data: value,
      };
      const res = await AxiosInterceptor.put(`${url}`, data);
      const resData = await res.data;

      setEnabledEdition(false);
      setProcessing(false);
    } catch (error: any) {
      setEnabledEdition(false);
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
    }
  };


  const selectItem = (index: number) => {
    if (validations?.maxItems === 1) {
      setValue(options[index].value);
    } else {
      setValue((prev: any) => {
        let arr = [...prev];
        if (arr.includes(options[index].value)) {
          arr = arr.filter((e) => e !== options[index].value);
        } else {
          arr.push(options[index].value);
        }
        return arr;
      });
    }
  };
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  function valueExists(searchValue: string) {
    return options.some((item: any) => item.value === searchValue);
  }
  useEffect(() => {
    const initialValue =
      validations?.maxItems === 1 && !valueExists(defaultValue)
        ? options[0].value
        : defaultValue;
    setValue(initialValue);
  }, []);
  // useEffect(() => {
  //   if (!value) setValue(options[0]);
  // }, []);
  return (
    <div className="picker-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {!readonly && (recordId || editPath) && editable && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            ((recordId || editPath) && editable && !isEditEnabled) ||
            ((recordId || editPath) && !editable) ? (
            <span>{value}</span>
          ) : (
            <div className="editable-items">
              {options.map((item: any, index: number) => {
                return (
                  <a
                    key={index}
                    style={{ marginTop: item.icon ? "20px" : "10px" }}
                    onClick={() => selectItem(index)}
                  >
                    {item.icon && (
                      <div>
                        <Icon name={item.icon} />
                      </div>
                    )}
                    <span style={{ marginTop: item.icon ? "20px" : "0px" }}>
                      {item.label}
                    </span>
                    {validations?.maxItems === 1 && (
                      <span style={{ borderRadius: "99px" }}>
                        {item.value == value ? (
                          <RadioButtonCheckedIcon color="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                      </span>
                    )}
                    {validations?.maxItems !== 1 && (
                      <span style={{ borderRadius: "10px" }}>
                        {value.includes(item.value) ? (
                          <CheckBoxIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </span>
                    )}
                  </a>
                );
              })}
            </div>
          )}
        </div>

        {/* {editable && (
          <div>
            {enabledEdition ? (
              <>
                <button onClick={handleSave}>
                  <CheckIcon />
                </button>
                <button onClick={() => setEnabledEdition(false)}>
                  <CloseIcon />
                </button>
              </>
            ) : (
              <>
                <button onClick={enableEdition}>
                  <PencilIcon />
                </button>
              </>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
};

export { PickerField };
