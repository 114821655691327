export const getFields = () => {
  const data: any = {
    indicatorGroup: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "visible_name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "grid",
        visibleName: "Indicadores",
        name: "indicators",
        editable: true,
        size: 12,
        //forceMobileView: true,
        cols: [
          {
            type: "select",
            visibleName: "Indicador",
            name: "indicator",
            searchPath: "indicators",
            primaryKey: "visible_name",
            description: null,
            size: 8,
            editable: true,
            editableIf: null,
            filters: [],
            validations: {},
          },
          {
            type: "iconPicker",
            name: "icon",
            size: 2,
            visibleName: "Icono",
          },

          {
            type: "checkbox",
            name: "visible_on_card",
            size: 2,
            visibleName: "Visible en la tarjeta",
          },
        ],
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
