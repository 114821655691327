import React, { useState } from "react";
import "./RolesExpandedCard.scss";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { getFields } from "../../utils/get-fileds";
import { getCols } from "../../utils/get-cols";
import { getDividerGroups } from "../../utils/get-divider-groups";
const RolesExpandedCard = (props: any) => {
  const { title, config, cardId } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const fields = getFields().role;
  const cols = getCols().role;
  const dividerGroups = getDividerGroups().role;
  return (
    <>
      <div className="roles-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nuevo rol</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            <strong>Listar roles</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Roles Eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Formulario de rol"}
        fields={fields}
        tableName={"roles"}
      />
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={title}
        tableName={"roles"}
        cols={cols}
        dividerGroups={dividerGroups}
        rowActions={[]}
        tableActions={[]}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { RolesExpandedCard };
