import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { PencilIcon } from "../../../../components/icons/PencilIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import "./ProductAttributeItem.scss";
import { SortableListModal } from "../../../../components/modals/SortableListModal/SortableListModal";
import { ChildrenIcon } from "components/icons/ChildrenIcon";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";
const ProductAttributeItem = ({ name, id }: any) => {
  const [sortableListModalIsOpen, setSortableListModalIsOpen] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const fields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  return (
    <>
      <div className="product-attribute-item-container">
        <span>{name}</span>
        <div>
          <IconButton
            aria-label="attr values"
            onClick={() => {
              setSortableListModalIsOpen(true);
            }}
          >
            <ChildrenIcon />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setFormIsOpen(true);
            }}
          >
            <PencilIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => {}}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <FormDialog
        open={formIsOpen}
        setOpen={setFormIsOpen}
        title={"Editar atributo"}
        description={""}
        fields={fields}
        onClose={() => {}}
        onSubmit={() => {}}
        editPath={`product-attributes/${id}`}
        defaultFormData={{ name: name }}
      />
      <SortableListModal
        open={sortableListModalIsOpen}
        setOpen={setSortableListModalIsOpen}
        title={"Valores del atributo"}
        subTitle={"Atributo: " + name}
        onDismiss={() => {}}
        tableName={"product-attribute-values"}
        filters={[["attribute_id", id]]}
        fieldName={"value"}
        fieldLabel={"Valor"}
        formTitle={"Valor del atributo"}
        formDescription={"Ej: XL, L, M, S, etc."}
        extraData={{ attribute_id: id }}
      />
    </>
  );
};

export { ProductAttributeItem };
