import { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";
import "./AddressesExpandedCard.scss";
import { getRowActions } from "../../utils/get-row-actions";

const AddressesExpandedCard = (props: any) => {
  const { title, data } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const [addressRecordsModalIsOpen, setAddressRecordsModalIsOpen] =
    useState(false);
  const [zoneRecordsModalIsOpen, setZoneRecordsModalIsOpen] = useState(false);
  const [neighborhoodRecordsModalIsOpen, setNeighborhoodRecordsModalIsOpen] =
    useState(false);

  // const [addressFormModalIsOpen, setAddressFormModalIsOpen] = useState(false);
  // const [zoneFormModalIsOpen, setZoneFormModalIsOpen] = useState(false);
  // const [neighborhoodFormModalIsOpen, setNeighborhoodFormModalIsOpen] =
  //   useState(false);

  const addressFields = getFields().address;
  const zoneFields = getFields().zone;
  const neighborhoodFields = getFields().neighborhood;

  const addressCols = getCols().address;
  const zoneCols = getCols().zone;
  const neighborhoodCols = getCols().neighborhood;

  const addressDividerGroups = getDividerGroups().address;
  const zoneDividerGroups = getDividerGroups().zone;
  const neighborhoodDividerGroups = getDividerGroups().neighborhood;

  const addressRowActions = getRowActions().address;
  const zoneRowActions = getRowActions().zone;
  const neighborhoodRowActions = getRowActions().neighborhood;

  return (
    <>
      <div className="addresses-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setAddressFormModalIsOpen(true);
            }}
          >
            <span>Nuevo</span>
            <strong>Domicilio</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setNeighborhoodFormModalIsOpen(true);
            }}
          >
            <span>Nuevo</span>
            <strong>Barrio</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setZoneFormModalIsOpen(true);
            }}
          >
            <span>Nueva</span>
            <strong>Zona</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a> */}

          <a
            className="card-btn lg"
            onClick={() => {
              setAddressRecordsModalIsOpen(true);
            }}
          >
            <strong>Domicilios</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setNeighborhoodRecordsModalIsOpen(true);
            }}
          >
            <strong>Barrios</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setZoneRecordsModalIsOpen(true);
            }}
          >
            <strong>Zonas</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      {/* <FormModal
        open={addressFormModalIsOpen}
        setOpen={setAddressFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Domicilio"}
        fields={addressFields}
        tableName={"addresses"}
      />
      <FormModal
        open={zoneFormModalIsOpen}
        setOpen={setZoneFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Zona"}
        fields={zoneFields}
        tableName={"zones"}
      />
      <FormModal
        open={neighborhoodFormModalIsOpen}
        setOpen={setNeighborhoodFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Barrio"}
        fields={neighborhoodFields}
        tableName={"neighborhoods"}
      /> */}

      <RecordsModal
        open={addressRecordsModalIsOpen}
        setOpen={setAddressRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Domicilios"}
        tableName={"addresses"}
        cols={addressCols}
        dividerGroups={addressDividerGroups}
        formTitle={"Domicilio"}
        fields={addressFields}
        rowActions={addressRowActions}
      />
      <RecordsModal
        open={zoneRecordsModalIsOpen}
        setOpen={setZoneRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Zonas"}
        tableName={"zones"}
        cols={zoneCols}
        dividerGroups={zoneDividerGroups}
        formTitle={"Zona"}
        fields={zoneFields}
        rowActions={zoneRowActions}
      />

      <RecordsModal
        open={neighborhoodRecordsModalIsOpen}
        setOpen={setNeighborhoodRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Barrios"}
        tableName={"neighborhoods"}
        cols={neighborhoodCols}
        dividerGroups={neighborhoodDividerGroups}
        formTitle={"Barrio"}
        fields={neighborhoodFields}
        rowActions={neighborhoodRowActions}
      />

      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { AddressesExpandedCard };
