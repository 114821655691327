import { useState } from "react";
import AxiosInterceptor from "../AxiosInterceptor";
import urls from "config/urls";
import { formatFilters } from "utils/format-filters";
export const useLoadReportRecords = ({
  listPath,
  reportName,
  sortDirection,
  searchTerm,
  sortBy,
  filters,
}: any) => {
  const [records, setRecords] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const formattedFilters = formatFilters(filters);
  const loadRecords = async () => {
    const url = `${
      urls.server
    }/api/${listPath}?report_name=${reportName}&search_term=${searchTerm}&sort_by=${sortBy}&sort_direction=${sortDirection}&filters=${encodeURIComponent(
      JSON.stringify(formattedFilters)
    )}`;

    try {
      setLoading(true);
      const res = await AxiosInterceptor.get(url);
      const data = await res?.data;
      if (data) {
        setRecords(data);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      //setError(error);
    }
  };
  return { loadRecords, records, loading };
};
