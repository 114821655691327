import React, { useState } from "react";
import "./IndicatorGroupsExpandedCard.scss";
import { RecordsModal } from "components/modals/RecordsModal/RecordsModal";
import { DeletedRecordsModal } from "components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { getFields } from "cards/indicatorGroups/utils/get-fileds";
import { getCols } from "cards/indicatorGroups/utils/get-cols";
import { getDividerGroups } from "cards/indicatorGroups/utils/get-divider-groups";
import { getRowActions } from "cards/indicatorGroups/utils/get-row-actions";
import { GridWrapperIcon } from "components/icons/GridWrapperIcon";
import { GridIcon } from "components/icons/GridIcon";
import { FormModal } from "components/modals/FormModal/FormModal";
import { AddWrapperIcon } from "components/icons/AddWrapperIcon";
import { AddIcon } from "components/icons/AddIcon";
import { DeleteWrapperIcon } from "components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";

import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
const IndicatorGroupsExpandedCard = (props: any) => {
  const { title, data } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [processing, setProcessing] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  const indicatorGroupFields = getFields().indicatorGroup;
  const indicatorGroupCols = getCols().indicatorGroup;
  const indicatorGroupDividerGroups = getDividerGroups().indicatorGroup;
  const indicatorGroupRowActions = getRowActions().indicatorGroup;
  const refreshCard = async (recordId: number) => {
    setProcessing(true);
    const res = await AxiosInterceptor.put(
      `${urls.server}/api/indicator-groups/${recordId}/card-data`
    );
    console.log(res);

    setProcessing(false);
  };
  return (
    <div className="indicator-groups-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <div className="expanded-card-body">
        <a
          className="card-btn lg"
          onClick={() => {
            setFormModalIsOpen(true);
          }}
        >
          <strong>Nuevo grupo</strong>
          <div>
            <AddWrapperIcon />
            <AddIcon />
          </div>
        </a>
        <a
          className="card-btn lg"
          onClick={() => {
            setRecordsModalIsOpen(true);
          }}
        >
          <strong>Listar grupos</strong>
          <div>
            <GridWrapperIcon />
            <GridIcon />
          </div>
        </a>
        <a
          className="card-btn lg"
          onClick={() => {
            setDeletedRecordsModalIsOpen(true);
          }}
        >
          <strong>Grupos eliminados</strong>
          <div>
            <DeleteWrapperIcon />
            <DeleteIcon />
          </div>
        </a>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        title={"Grupo de indicador (Ficha)"}
        fields={indicatorGroupFields}
        tableName={"indicator_groups"}
      />
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => { }}
        title={"Grupos de indicadores"}
        tableName={"indicator_groups"}
        cols={indicatorGroupCols}
        dividerGroups={indicatorGroupDividerGroups}
        formTitle={"Grupo de indicador (Ficha)"}
        fields={indicatorGroupFields}
        rowActions={indicatorGroupRowActions}
        onSelectRowAction={({ actionName, recordId }: any) => {
          console.log("action");
          console.log(actionName);
          console.log(recordId);
          if (actionName == "refreshCard") {
            refreshCard(recordId);
          }
        }}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
      />
    </div>
  );
};

export { IndicatorGroupsExpandedCard };
