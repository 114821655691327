export const getCols = () => {
  const data: any = {
    indicatorGroup: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "visible_name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Desc.",
        name: "description",
        sortable: true,
      },
    ],
  };

  return data;
};
