import { useState, useEffect } from "react";

import { CloseIcon } from "../../icons/CloseIcon";

import IconButton from "@mui/material/IconButton";

import { AddIcon } from "../../icons/AddIcon";

import { Autocomplete } from "../../Autocomplete/Autocomplete";
import { FormModal } from "../../modals/FormModal/FormModal";
import { ListCol } from "./ListCol";
import { ObjectCol } from "./ObjectCol";
import { RadioField } from "../RadioField/RadioField";
import { Parser } from "expr-eval";
import { PickerField } from "../PickerField/PickerField";
import { PastelColorField } from "../PastelColorField/PastelColorField";
import { ImageField } from "../ImageField/ImageField";
import { IconPickerModal } from "components/modals/IconPickerModal/IconPickerModal";
import { SelectIcon } from "components/icons/SelectIcon";
import Icon from "components/Icon/Icon";
import { FieldsBuilderModal } from "components/modals/FieldsBuilderModal/FieldsBuilderModal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { SliderField } from "../SliderField/SliderField";
import colors from "config/colors";
const GridFieldInput = ({
  setData,
  rowIndex,
  col,
  row,
  handleInputChange,
  formData,
  extraData,
}: any) => {
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [iconPickerModalIsOpen, setIconPickerModalIsOpen] = useState(false);
  const [fieldsBuilderModalIsOpen, setFieldsBuilderModalIsOpen] =
    useState(false);
  const evaluateFilter = (filter: any, formData: any, extraData: any) => {
    try {
      const parser = new Parser();
      const expr = parser.parse(filter);
      return expr.evaluate({ formData, extraData });
    } catch (error) {
      // Si hay un error, retornar el filtro sin cambios
      return filter;
    }
  };

  const workflowFilters = (filters: any, formData: any, extraData: any) => {
    console.log("filters", filters);
    return filters?.map((subArray: any) => {
      return subArray.map((item: any) => {
        if (item.includes("formData") || item.includes("extraData")) {
          return evaluateFilter(item, formData, extraData);
        } else {
          return item;
        }
      });
    });
  };
  const renderInput = (
    rowIndex: number,
    col: any,
    value: any,
    onChange: any
  ) => {
    switch (col.type) {
      case "longText":
        return (
          <>
            {col.readonly ? (
              <div className="readonly-long-text-wrapper">{value || "-"}</div>
            ) : (
              <textarea
                className="long-text-input"
                value={value || ""}
                onChange={onChange}
              />
            )}
          </>
        );
      case "integer":
        return (
          <div
            style={{
              //paddingLeft: "5px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <input
              className="number-input"
              type="number"
              value={value || ""}
              placeholder={col.placeholder}
              onChange={onChange}
            />
          </div>
        );
      case "decimal":
        return (
          <>
            {col.readonly ? (
              <div className="readonly-long-text-wrapper">{value || "-"}</div>
            ) : (
              <div
                style={{
                  //paddingLeft: "5px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <input
                  className="number-input"
                  type="number"
                  value={value || ""}
                  placeholder={col.placeholder}
                  onChange={onChange}
                />
              </div>
            )}
          </>
        );
      case "money":
        return (
          <div
            style={{
              paddingLeft: "5px",
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <strong style={{ marginRight: "5px" }}>$</strong>
            <input
              className="number-input"
              type="number"
              value={value || ""}
              placeholder={col.placeholder}
              onChange={onChange}
              style={{ textAlign: "right" }}
            />
          </div>
        );
      case "select":
        return (
          <>
            {value ? (
              <div className="selected-item">
                <span>{value[col.primaryKey]}</span>
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => {
                    setData((prevData: any) => {
                      const newData = [...prevData];
                      newData[rowIndex][col.name] = null;
                      newData[rowIndex][`${col.name}_id`] = null;
                      return newData;
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              <div className="autocomplete-wrapper">
                <Autocomplete
                  onSelect={(data: any) => {
                    console.log(data);
                    if (data) {
                      setData((prevData: any) => {
                        const newData = [...prevData];
                        newData[rowIndex][col.name] = data;
                        newData[rowIndex][`${col.name}_id`] = data.id;
                        return newData;
                      });
                    }
                  }}
                  tableName={col.tableName}
                  placeholder={col.placeholder}
                  primaryKey={col.primaryKey}
                  searchPath={col.searchPath}
                  //filters={[["workflow_id", extraData?.workflow_id]]}
                  //filters={col.filters}
                  filters={workflowFilters(col.filters, formData, extraData)}
                />
                {col.formFields && (
                  <IconButton
                    aria-label="new record"
                    size="small"
                    onClick={() => {
                      setFormModalIsOpen(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>
            )}
          </>
        );
      case "iconPicker":
        return (
          <>
            {value ? (
              <div className="selected-icon">
                <a
                  onClick={() => {
                    setIconPickerModalIsOpen(true);
                  }}
                >
                  <Icon name={value?.name} />
                </a>
              </div>
            ) : (
              <div className="icon-picker-btn-wrapper">
                <a
                  onClick={() => {
                    setIconPickerModalIsOpen(true);
                  }}
                >
                  <SelectIcon />
                  <span>Seleccionar icono</span>
                </a>
              </div>
            )}
            <IconPickerModal
              open={iconPickerModalIsOpen}
              setOpen={setIconPickerModalIsOpen}
              onClose={(res: any) => {
                setData((prevData: any) => {
                  const newData = [...prevData];
                  newData[rowIndex][col.name] = res;
                  return newData;
                });
                console.log(value);
                //onChange(value);
              }}
            />
          </>
        );
      case "fieldsBuilder":
        return (
          <>
            {value ? (
              <div className="fields-count">
                <div>Cant. campos: {value.length}</div>
                <a
                  onClick={() => {
                    setFieldsBuilderModalIsOpen(true);
                  }}
                >
                  <SelectIcon />
                  <span>Seleccionar campos</span>
                </a>
              </div>
            ) : (
              <div className="present-fields-builder-btn-wrapper">
                <a
                  onClick={() => {
                    setFieldsBuilderModalIsOpen(true);
                  }}
                >
                  <SelectIcon />
                  <span>Seleccionar campos</span>
                </a>
              </div>
            )}
            <FieldsBuilderModal
              open={fieldsBuilderModalIsOpen}
              setOpen={setFieldsBuilderModalIsOpen}
              data={value}
              onClose={(res: any) => {
                setData((prevData: any) => {
                  const newData = [...prevData];
                  newData[rowIndex][col.name] = res;
                  return newData;
                });
              }}
            />
          </>
        );
      case "checkbox":
        return (
          // <input
          //   className="checkbox-field"
          //   type="checkbox"
          //   //checked={value || false}
          //   value={value || false}
          //   // onChange={(e: any) => {
          //   //   console.log(e.target.value);
          //   //   setData((prevData: any) => {
          //   //     const newData = [...prevData];
          //   //     newData[rowIndex][col.name] = e.target.value;

          //   //     return newData;
          //   //   });
          //   // }}
          //   onChange={onChange}
          // />
          <>
            <a
              className="checkbox-wrapper"
              onClick={() => {
                setData((prevData: any) => {
                  const newData = [...prevData];
                  newData[rowIndex][col.name] =
                    !newData[rowIndex][col.name] || false;

                  return newData;
                });
              }}
            >
              {value ? (
                <CheckBoxIcon style={{ color: colors.primary }} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ color: colors.textTint }} />
              )}
            </a>
          </>
        );
      case "list":
        return (
          <ListCol
            defaultValue={value}
            onChange={(res: any) => {
              console.log(res);
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;

                return newData;
              });
            }}
          />
        );
      case "object":
        return (
          <ObjectCol
            defaultValue={value}
            onChange={(res: any) => {
              console.log(res);
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;

                return newData;
              });
            }}
          />
        );
      case "slider":
        return (
          <SliderField
            defaultValue={value}
            onChange={(res: any) => {
              console.log(res);
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;

                return newData;
              });
            }}
          />
        );
      case "radio":
        return (
          <RadioField
            defaultValue={value}
            options={col.options}
            onChange={(res: any) => {
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;
                return newData;
              });
            }}
          />
        );
      case "picker":
        return (
          <PickerField
            defaultValue={value || []}
            options={col.options}
            validations={col.validations}
            onChange={(res: any) => {
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;
                return newData;
              });
            }}
          />
        );
      case "pastelColor":
        return (
          <PastelColorField
            defaultValue={value || "#A597CC"}
            validations={col.validations}
            onChange={(res: any) => {
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;
                return newData;
              });
            }}
          />
        );
      case "image":
        return (
          <ImageField
            id={col.name + "_" + rowIndex}
            defaultValue={value}
            validations={col.validations}
            onChange={(res: any) => {
              setData((prevData: any) => {
                const newData = [...prevData];
                newData[rowIndex][col.name] = res;
                return newData;
              });
            }}
          />
        );
      default:
        return (
          <input
            className="text-input"
            type="text"
            value={value || ""}
            onChange={onChange}
          />
        );
    }
  };
  return (
    <>
      {renderInput(rowIndex, col, row[col.name], (event: any) =>
        handleInputChange(event, rowIndex, col.name)
      )}
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={col.formTitle}
        fields={col.formFields}
        tableName={col.tableName}
      />
    </>
  );
};

export { GridFieldInput };
