import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Button } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import "./IndicatorValuesModal.scss";
import { AddToTopIcon } from "../../../../components/icons/AddToTopIcon";
import { AddToEndIcon } from "../../../../components/icons/AddToEndIcon";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { RecordsIcon } from "../../../../components/icons/RecordsIcon";
import { AddIcon } from "../../../../components/icons/AddIcon";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { IndicatorValueItem } from "../IndicatorValueItem/IndicatorValueItem";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const IndicatorValuesModal = ({
  open,
  setOpen,
  onDismiss,
  indicatorId,
  indicatorName,
  indicatorVisibleName,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([] as any);
  const addNextPeriod = async () => {
    const res = await AxiosInterceptor.post(
      `${urls.server}/api/indicators/${indicatorId}/next-period`
    );
    const data = await res.data;
    loadRecords();
  };
  const addPrevPeriod = async () => {
    const res = await AxiosInterceptor.post(
      `${urls.server}/api/indicators/${indicatorId}/prev-period`
    );
    const data = await res.data;
    loadRecords();
  };
  const loadRecords = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/indicators/${indicatorId}/values`
    );
    const data = await res.data;

    setRecords(data);
    setLoading(false);
  };
  useEffect(() => {
    if (open) loadRecords();
  }, [open]);
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onDismiss({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="indicator-values-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Valores del indicador</h2>
            <span>{indicatorVisibleName}</span>
          </div>

          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onDismiss({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {/* {!loading && (
            <div className="add-value-btns">
              {records.length == 0 && (
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => () => {}}
                  style={{
                    borderRadius: 99,
                    padding: 10,
                    margin: 5,
                    //borderWidth: 2,
                  }}
                >
                  Nuevo valor
                </Button>
              )}
              {records.length > 0 && (
                <>
                  <Button
                    size="small"
                    //variant="outlined"
                    //endIcon={<VerticalAlignTopIcon />}
                    startIcon={<AddToTopIcon />}
                    onClick={() => {
                      addNextPeriod();
                    }}
                    style={{
                      borderRadius: 99,
                      padding: 10,
                      margin: 5,
                      //borderWidth: 2,
                    }}
                  >
                    Nuevo valor
                  </Button>
                  <Button
                    size="small"
                    //variant="outlined"
                    //endIcon={<VerticalAlignBottomIcon />}
                    startIcon={<AddToEndIcon />}
                    onClick={() => {
                      addPrevPeriod();
                    }}
                    style={{
                      borderRadius: 99,
                      padding: 10,
                      margin: 5,
                      //borderWidth: 2,
                    }}
                  >
                    Nuevo valor
                  </Button>
                </>
              )}
            </div>
          )} */}
          {!loading && records.length == 0 && (
            <div className="no-content">
              <RecordsIcon />
              <p>No hay registros</p>
            </div>
          )}
          {!loading && records.length > 0 && (
            <div className="records-wrapper">
              <div>
                {records.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <IndicatorValueItem
                        data={item}
                        onChange={() => {
                          loadRecords();
                        }}
                        onDelete={() => {
                          loadRecords();
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export { IndicatorValuesModal };
