import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { formatNumber } from "../../../../utils/number";
import "./SingleBreakdownIndicatorTable.scss";
const SingleBreakdownIndicatorTable = ({ data, decimalPrecision = 2 }: any) => {
  const headers = Array.from(
    new Set(data.flatMap((item: any) => item.data.map((d: any) => d.label)))
  );
  const calculateVariation = (currentValue: number, previousValue: number) => {
    if (previousValue === 0) return 0;
    return ((currentValue - previousValue) / previousValue) * 100;
  };
  const dataCopy = [...data];
  const reversedData = dataCopy.reverse();
  return (
    <div className="single-breakdown-indicator-table-container">
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>Periodo</th>
            {headers.map((header: any) => (
              <th colSpan={2} key={header}>
                {header}
              </th>
            ))}
          </tr>
          <tr>
            {headers.map((header: any) => (
              <React.Fragment key={header}>
                <th style={{ textAlign: "right" }}>Valor</th>
                <th style={{ textAlign: "right", paddingRight: "20px" }}>
                  Variación (%)
                </th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {reversedData.map((period: any, index: number) => (
            <tr key={period.period}>
              <td>{period.visible_period}</td>
              {headers.map((header: any) => {
                const item = period.data.find((d: any) => d.label === header);
                const previousPeriod = reversedData[index + 1]?.data.find(
                  (d: any) => d.label === header
                );
                const variation =
                  item && previousPeriod
                    ? calculateVariation(item.value, previousPeriod.value)
                    : 0;

                return (
                  <React.Fragment key={header}>
                    <td>
                      <span className="amount-wrapper">
                        {item ? formatNumber(item.value, decimalPrecision) : "-"}
                      </span>
                    </td>

                    <td>
                      {index != reversedData.length - 1 && (
                        <span className="variation-wrapper">
                          {formatNumber(variation.toFixed(2))}%
                          {variation > 0 ? (
                            <ArrowDropUpIcon color="success" />
                          ) : variation < 0 ? (
                            <ArrowDropDownIcon color="error" />
                          ) : null}
                        </span>
                      )}
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { SingleBreakdownIndicatorTable };
