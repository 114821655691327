import { IconButton } from "@mui/material";
import "./Group.scss";

import Icon from "../../../../Icon/Icon";
import { AddIcon } from "../../../../icons/AddIcon";
const Group = (props: any) => {
  const { icon, visibleName, onAdd } = props;
  return (
    <div className="dividers-builder-group-container">
      <div className="dividers-builder-group-body">
        <Icon name={icon} />
        <strong>{visibleName}</strong>
      </div>
      <div className="dividers-builder-group-footer">
        <IconButton
          aria-label="add"
          onClick={onAdd}
          size="small"
          className="dividers-builder-group-btn"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export { Group };
