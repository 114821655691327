export const getCols = () => {
  const data: any = {
    product: [
      {
        type: "thumbnail",
        name: "thumbnail",
        visibleName: "Imagen",
        sortable: false,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Marca",
        name: "brand_name",
        sortable: true,
      },
      {
        type: "money",
        visibleName: "Precio de venta",
        name: "price",
        sortable: true,
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "selected_tags",
        sortable: false,
      },
    ],
    brand: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
    ],
    extra: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
    ],
    location: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Domicilio",
        name: "address_composite_field",
        sortable: true,
      },
    ],
    //measurementUnit: [],
    serialNumber: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "text",
        visibleName: "Producto",
        name: "product_name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Num. de serie",
        name: "serial_number",
        sortable: true,
      },
    ],
  };

  return data;
};
