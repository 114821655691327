export const getRowActions = () => {
  const data: any = {
    valueAddedTax: [
      {
        name: "view",
        visibleName: "Ver",
      },
      {
        name: "delete",
        visibleName: "Eliminar",
      },
    ],
    otherTax: [
      {
        name: "view",
        visibleName: "Ver",
      },
      {
        name: "delete",
        visibleName: "Eliminar",
      },
    ],
    perception: [
      {
        name: "view",
        visibleName: "Ver",
      },
      {
        name: "delete",
        visibleName: "Eliminar",
      },
    ],
    withholding: [
      {
        name: "view",
        visibleName: "Ver",
      },
      {
        name: "delete",
        visibleName: "Eliminar",
      },
    ],
  };

  return data;
};
