import React from "react";
import Lottie from "lottie-react";
import logoAnimationData from "./files/logo.json";
//import logoAnimationData from "<ruta-hasta-tu-json>";

const LogoAnimation = () => (
  // <></>
  // <div
  //   style={{
  //     width: "100%",
  //     maxWidth: "300px",
  //     height: "0",
  //     paddingBottom: "27%",
  //     position: "relative",
  //   }}
  // >
  <Lottie
    loop={false}
    animationData={logoAnimationData}
    //style={{ position: "absolute", width: "100%", height: "100%" }}
    style={{ width: 250, height: 67.5 }}
  />
  // </div>
);

export default LogoAnimation;
