import React, { useState, useEffect } from "react";
import "./MiniBreakdownChart.scss";
const MiniBreakdownChart = ({ data = [], height = 55, onSelectItem }: any) => {
  const [sortedData, setSortedData] = useState([] as any);
  const [totalValue, setTotalValue] = useState(0 as any);
  useEffect(() => {
    if (data) {
      const totalValue = data.reduce(
        (total: number, item: any) => total + item.value,
        0
      );
      setTotalValue(totalValue);
      // Ordena los datos por valor
      const sortedData = [...data].sort((a: any, b: any) => b.value - a.value);
      setSortedData(sortedData);
    }
  }, []);
  return (
    <div className="mini-breakdown-chart-container">
      <div className="graph" style={{ height: height + "px" }}>
        {sortedData.map((item: any, index: number) => {
          // Calcula el ancho en porcentaje para cada ítem
          const widthPercentage = (item.value / totalValue) * 100;

          return (
            <a
              key={index}
              style={{
                width: `${widthPercentage}%`,
                backgroundColor: item.color,
              }}
              onClick={() => onSelectItem(index)}
            ></a>
          );
        })}
      </div>
    </div>
  );
};

export { MiniBreakdownChart };
