export const getRowActions = () => {
  const data: any = {
    bank: [
      {
        name: "view",
        visibleName: "Ver banco",
      },
      {
        name: "delete",
        visibleName: "Eliminar banco",
      },
    ],
    inCheck: [],
    outcheck: [],
  };

  return data;
};
