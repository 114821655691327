import React, { useState, useEffect } from "react";
import "./BooleanFilter.scss";

const BooleanFilter = ({ defaultValue, label, onChange }: any) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue.data);

  const handleClick = (value: string) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    onChange({ data: selectedValue });
  }, [selectedValue]);

  return (
    <div className="boolean-filter-container">
      <label className="filter-label">{label}</label>
      <div className="options-wrapper">
        <button
          className={selectedValue === "all" ? "active" : ""}
          onClick={() => handleClick("all")}
        >
          Todos
        </button>
        <button
          className={selectedValue === "yes" ? "active" : ""}
          onClick={() => handleClick("yes")}
        >
          Sí
        </button>
        <button
          className={selectedValue === "no" ? "active" : ""}
          onClick={() => handleClick("no")}
        >
          No
        </button>
      </div>
    </div>
  );
};

export { BooleanFilter };
