import React, { useEffect, useState, Fragment, useRef } from "react";
import { Dialog, IconButton, Slide, Alert, Fab, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import { getFields } from "../../../utils/get-fields";
import { Field } from "./components/Field/Field";
import { InputField } from "../../fields/InputField/InputField";
import { AddedFields } from "./components/AddedFields/AddedFields";
import BackupIcon from "@mui/icons-material/Backup";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import "./FormBuilderModal.scss";
import { CheckIcon } from "../../icons/CheckIcon";
import { AddIcon } from "../../icons/AddIcon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FormBuilderModal = ({
  open,
  setOpen,
  onClose,
  // config = {
  //   title: "",
  //   fields: [],
  // },
  title = "",
  fields = [],
  savePath,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const formBuilderFields = getFields();
  const [formConfig, setFormConfig] = useState({
    title: title,
    fields: fields,
  });
  const [activeFieldConfig, setActiveFieldConfig] = useState(null);
  const [activeFieldIndex, setActiveFieldIndex] = useState(null as any);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [fieldsVisible, setFieldsVisible] = useState(true);

  const toggleFieldsVisibility = () => {
    setFieldsVisible(!fieldsVisible);
  };
  //const [alertMsg, setAlertMsg] = useState("");
  const formConfigCopy = JSON.parse(
    JSON.stringify({
      title: title,
      fields: fields,
    })
  );
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };
  const addField = (item: any) => {
    console.log(item);
    setFormConfig((prev: any) => {
      let data: any = { ...prev };
      if (!data.fields) data.fields = [];
      data?.fields.push(item);
      return data;
    });
  };
  useEffect(() => {}, []);
  useEffect(() => {
    if (JSON.stringify(formConfigCopy) === JSON.stringify(formConfig)) {
      setDisabledSaveBtn(true);
    } else {
      setDisabledSaveBtn(false);
    }
  }, [formConfig]);

  useEffect(() => {
    if (!open) {
      setFormConfig(formConfigCopy);
    }
  }, [open]);
  const saveConfig = async () => {
    if (savePath) {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/${savePath}`,
        {
          data: formConfig,
        }
      );
      const data = await res.data;
      setAlertIsOpen(true);
      setDisabledSaveBtn(true);
    } else {
      setOpen(false);
      onClose({
        formConfig: formConfig,
      });
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setActiveFieldConfig(null);
          setOpen(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
            position: "relative",
          },
        }}
        className="form-builder-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Generador de formulario</h2>
          </div>
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setActiveFieldConfig(null);
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={"modal-body"}>
          <div
            className={`fields ${
              fieldsVisible ? "fields-visible" : "fields-hidden"
            }`}
          >
            <a className="toggle-button" onClick={toggleFieldsVisibility}>
              {/* {fieldsVisible ? "Ocultar" : "Mostrar"} campos */}
              {fieldsVisible ? <CloseIcon /> : <AddIcon />}
            </a>
            <div>
              <div>
                {formBuilderFields.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <Field
                        icon={item.icon}
                        visibleType={item.visibleType}
                        onAdd={() => addField(item)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="added-fields">
            <div className="paper">
              <span></span>
              <span></span>
              <div>
                <div>
                  <InputField
                    id="form-title"
                    defaultValue={formConfig?.title}
                    label={"Título del formulario"}
                    onChange={(value: string) => {
                      setFormConfig((prev: any) => {
                        let data: any = { ...prev };
                        data.title = value;
                        return data;
                      });
                    }}
                    //editable
                  />
                </div>
                <div>
                  <AddedFields
                    items={formConfig?.fields || []}
                    setActiveFieldIndex={setActiveFieldIndex}
                    activeFieldIndex={activeFieldIndex}
                    onDeleteItem={(data: any, index: number) => {
                      console.log(data);
                      console.log("index", index);
                      setFormConfig((prev: any) => {
                        let data: any = { ...prev };

                        data?.fields.splice(index, 1);
                        return data;
                      });
                    }}
                    onSelectItem={(data: any, index: number) => {}}
                    onChangeItem={(data: any, index: number) => {
                      setFormConfig((prev: any) => {
                        let dataCopy: any = { ...prev };

                        dataCopy.fields[index] = data;
                        return dataCopy;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "300px" }}></div>
        </div>
        <Fab
          className="fab-btn"
          color="primary"
          aria-label="add"
          onClick={() => {
            saveConfig();
          }}
          disabled={disabledSaveBtn}
        >
          {savePath ? <BackupIcon /> : <CheckIcon />}
        </Fab>
        <Snackbar
          open={alertIsOpen}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: "100%" }}
          >
            Formulario guardado con éxito.
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
};

export { FormBuilderModal };
