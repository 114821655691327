import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import { HeaderSearchbar } from "../../HeaderSearchbar/HeaderSearchbar";
import "./DeletedRecordsModal.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DeletedRecordsModal = ({ open, setOpen, onClose }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="deleted-records-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Registros eliminados</h2>
        </div>
        <HeaderSearchbar />
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body"></div>
    </Dialog>
  );
};

export { DeletedRecordsModal };
