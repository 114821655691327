import React from "react";
import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
import "./CustomersCard.scss";
const CustomersCard = (props: any) => {
  const { title, data, config } = props;
  return (
    <div className="customers-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.customersCount}
          label={"Clientes"}
        />
      </div>
    </div>
  );
};

export { CustomersCard };
