import React, { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";
import Grid from "@mui/material/Grid";
import "./ServicesExpandedCard.scss";
import { TagsWrapperIcon } from "../../../../components/icons/TagsWrapperIcon";
import { TagsIcon } from "../../../../components/icons/TagsIcon";
import { TagsModal } from "../../../../components/modals/TagsModal/TagsModal";
import { DataImporterModal } from "components/modals/DataImporterModal/DataImporterModal";
import { UploadWrapperIcon } from "components/icons/UploadWrapperIcon";
import { UploadIcon } from "components/icons/UploadIcon";
import { getRowActions } from "cards/services/utils/get-row-actions";
import { ReportModal } from "components/modals/ReportModal/ReportModal";
import { ReportWrapperIcon } from "components/icons/ReportWrapperIcon";
import { ReportIcon } from "components/icons/ReportIcon";
const ServicesExpandedCard = (props: any) => {
  const { title, data, config } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [dataImporterModalIsOpen, setDataImporterModalIsOpen] = useState(false);
  const [allServicesReportModalIsOpen, setAllServicesReportModalIsOpen] =
    useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  const serviceFields = getFields().service;
  const serviceCols = getCols().service;
  const serviceDividerGroups = getDividerGroups().service;
  const serviceRowActions = getRowActions().service;
  return (
    <>
      <div className="services-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nuevo servicio</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            <strong>Listar servicios</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setTagsModalIsOpen(true);
            }}
          >
            <strong>Etiquetas de servicios</strong>
            <div>
              <TagsWrapperIcon />
              <TagsIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDataImporterModalIsOpen(true);
            }}
          >
            <strong>Importar</strong>
            <div>
              <UploadWrapperIcon />
              <UploadIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setAllServicesReportModalIsOpen(true);
            }}
          >
            <strong>Servicios</strong>
            <span>(Reporte)</span>
            <div>
              <ReportWrapperIcon />
              <ReportIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Servicios Eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Servicios"}
        tableName={"services"}
        cols={serviceCols}
        dividerGroups={serviceDividerGroups}
        formTitle={"Ficha de servicio"}
        fields={serviceFields}
        rowActions={serviceRowActions}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
      <TagsModal
        open={tagsModalIsOpen}
        setOpen={setTagsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        groupId={2}
        groupName={"Servicios"}
      />
      <DataImporterModal
        open={dataImporterModalIsOpen}
        setOpen={setDataImporterModalIsOpen}
        onClose={(res: any) => { }}
        //cols={config?.importFileColumns}
        configurationPath={`configurations/name/serviceImportCols`}
        savePath={"services/import"}
        title={"Importar servicios"}
      />
      <ReportModal
        isOpen={allServicesReportModalIsOpen}
        title={"Reporte de servicios"}
        listPath={"services/report"}
        reportName={"allServices"}
        onClose={() => {
          setAllServicesReportModalIsOpen(false)
        }}
      />
    </>
  );
};

export { ServicesExpandedCard };
