import React, { useState } from "react";

import "./SalesExpandedCard.scss";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";

import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
// import { SaleInvoiceFormModal } from "../SaleInvoiceFormModal/SaleInvoiceFormModal";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getDividerGroups } from "../../utils/get-divider-groups";
import { getFields } from "../../utils/get-fields";
import { getRowActions } from "cards/sales/utils/get-row-actions";
import { getFilters } from "cards/sales/utils/get-filters";
import { ReportModal } from "components/modals/ReportModal/ReportModal";
import { ReportWrapperIcon } from "components/icons/ReportWrapperIcon";
import { ReportIcon } from "components/icons/ReportIcon";

const SalesExpandedCard = (props: any) => {
  const { title, data } = props;
  // const [invoiceFormModalIsOpen, setInvoiceFormModalIsOpen] = useState(false);

  // const [debitNoteFormModalIsOpen, setDebitNoteFormModalIsOpen] =
  //   useState(false);
  // const [creditNoteFormModalIsOpen, setCreditNoteFormModalIsOpen] =
  //   useState(false);
  // const [paymentFormModalIsOpen, setPaymentFormModalIsOpen] = useState(false);

  const [quotationRecordsModalIsOpen, setQuotationRecordsModalIsOpen] =
    useState(false);
  //const [orderRecordsModalIsOpen, setOrderRecordsModalIsOpen] = useState(false);
  const [invoiceRecordsModalIsOpen, setInvoiceRecordsModalIsOpen] =
    useState(false);
  const [debitNoteRecordsModalIsOpen, setDebitNoteRecordsModalIsOpen] =
    useState(false);
  const [creditNoteRecordsModalIsOpen, setCreditNoteRecordsModalIsOpen] =
    useState(false);
  const [paymentRecordsModalIsOpen, setPaymentRecordsModalIsOpen] =
    useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [interestRateRecordsModalIsOpen, setInterestRateRecordsModalIsOpen] =
    useState(false);
  const [priceListRecordsModalIsOpen, setPriceListRecordsModalIsOpen] =
    useState(false);

  const [salesReportModalIsOpen, setSalesReportModalIsOpen] =
    useState(false);

  const invoiceCols = getCols().invoice;
  const invoiceDividerGroups = getDividerGroups().invoice;
  const invoiceFields = getFields().invoice;
  const invoiceRowActions = getRowActions().invoice;
  const invoiceFilters = getFilters().invoice;

  const debitNoteCols = getCols().debitNote;
  const debitNoteDividerGroups = getDividerGroups().debitNote;
  const debitNoteFields = getFields().debitNote;

  const creditNoteCols = getCols().creditNote;
  const creditNoteDividerGroups = getDividerGroups().creditNote;
  const creditNoteFields = getFields().creditNote;

  const paymentCols = getCols().payment;
  const paymentDividerGroups = getDividerGroups().payment;
  const paymentFields = getFields().payment;

  const orderCols = getCols().order;
  const orderDividerGroups = getDividerGroups().order;
  const orderFields = getFields().order;

  const quotationCols = getCols().quotation;
  const quotationDividerGroups = getDividerGroups().quotation;
  const quotationFields = getFields().quotation;

  const interestRateCols = getCols().interestRate;
  const interestRateDividerGroups = getDividerGroups().interestRate;
  const interestRateFields = getFields().interestRate;

  const priceListCols = getCols().priceList;
  const priceListDividerGroups = getDividerGroups().priceList;
  const priceListFields = getFields().priceList;
  return (
    <>
      <div className="sales-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setInvoiceFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Factura de venta</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDebitNoteFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Nota de débito</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setCreditNoteFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Nota de crédito</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setPaymentFormModalIsOpen(true);
            }}
          >
            <span>Crear</span>
            <strong>Cobranza</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setQuotationRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Cotización</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          {/* <a
            className="card-btn lg"
            onClick={() => {
              setOrderRecordsModalIsOpen(true);
            }}
          >
            <strong>Pedido</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a> */}

          <a
            className="card-btn lg"
            onClick={() => {
              setInvoiceRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Facturas</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDebitNoteRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Notas de débito</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setCreditNoteRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Notas de crédito</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setPaymentRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Tabla</span> */}
            <strong>Cobranzas</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setInterestRateRecordsModalIsOpen(true);
            }}
          >
            <strong>Tasas de interes</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setPriceListRecordsModalIsOpen(true);
            }}
          >
            <strong>Listas de precios</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setSalesReportModalIsOpen(true);
            }}
          >
            <strong>Ventas</strong>
            <span>(Reporte)</span>
            <div>
              <ReportWrapperIcon />
              <ReportIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>

      <RecordsModal
        open={quotationRecordsModalIsOpen}
        setOpen={setQuotationRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Cotizaciones de ventas"}
        tableName={"quotations"}
        cols={quotationCols}
        dividerGroups={quotationDividerGroups}
        hiddenFilters={[["transaction_type", "sale"]]}
        formTitle="Cotización de venta"
        fields={quotationFields}

      /**
       * hiddenFilters -> filtros ocultos. Se envian directamente al server
       */
      // customFormName={"transaction"}
      // customFormProps={{
      //   transactionType: "sale",
      //   documentType: "quotation",
      // }}
      />
      {/* <RecordsModal
        open={orderRecordsModalIsOpen}
        setOpen={setOrderRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Pedidos de ventas"}
        tableName={"orders"}
        cols={invoiceCols}
        dividerGroups={invoiceDividerGroups}
        formTitle="Pedido de venta"
        fields={orderFields}
        hiddenFilters={[["transaction_type", "sale"]]}
        // customFormName={"transaction"}
        // customFormProps={{
        //   transactionType: "sale",
        //   documentType: "order",
        // }}
      /> */}
      <RecordsModal
        open={invoiceRecordsModalIsOpen}
        setOpen={setInvoiceRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Facturas de ventas"}
        tableName={"invoices"}
        cols={invoiceCols}
        dividerGroups={invoiceDividerGroups}
        formTitle="Factura de venta"
        fields={invoiceFields}
        hiddenFilters={[["transaction_type", "sale"]]}
        rowActions={invoiceRowActions}
        allowCreate={false}
        filters={invoiceFilters}
      //readonlyForm={true}
      // customFormName={"transaction"}
      // customFormProps={{
      //   transactionType: "sale",
      //   documentType: "invoice",
      // }}
      />
      <RecordsModal
        open={debitNoteRecordsModalIsOpen}
        setOpen={setDebitNoteRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Notas de débito"}
        tableName={"debit-notes"}
        cols={debitNoteCols}
        dividerGroups={debitNoteDividerGroups}
        formTitle="Nota de débito"
        fields={debitNoteFields}
        hiddenFilters={[["transaction_type", "sale"]]}
      // customFormName={"transaction"}
      // customFormProps={{
      //   transactionType: "sale",
      //   documentType: "debitNote",
      // }}
      />
      <RecordsModal
        open={creditNoteRecordsModalIsOpen}
        setOpen={setCreditNoteRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Notas de crédito"}
        tableName={"credit-notes"}
        cols={creditNoteCols}
        dividerGroups={creditNoteDividerGroups}
        formTitle="Nota de crédito"
        fields={creditNoteFields}
        hiddenFilters={[["transaction_type", "sale"]]}
      // customFormName={"transaction"}
      // customFormProps={{
      //   transactionType: "sale",
      //   documentType: "creditNote",
      // }}
      />
      <RecordsModal
        open={paymentRecordsModalIsOpen}
        setOpen={setPaymentRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Recibos de cobranzas"}
        tableName={"payments"}
        cols={paymentCols}
        dividerGroups={paymentDividerGroups}
        formTitle="Recibo de cobranza"
        fields={paymentFields}
        hiddenFilters={[["transaction_type", "sale"]]}
      // customFormName={"payment"}
      // customFormProps={{
      //   transactionType: "sale",
      // }}
      />

      <RecordsModal
        open={interestRateRecordsModalIsOpen}
        setOpen={setInterestRateRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Tasas de interes"}
        tableName={"interest-rates"}
        cols={interestRateCols}
        dividerGroups={interestRateDividerGroups}
        formTitle="Tasa de interes"
        fields={interestRateFields}
      />
      <RecordsModal
        open={priceListRecordsModalIsOpen}
        setOpen={setPriceListRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Listas de precios"}
        tableName={"price-lists"}
        cols={priceListCols}
        dividerGroups={priceListDividerGroups}
        formTitle="Lista de precio"
        fields={priceListFields}
      />
      <ReportModal
        isOpen={salesReportModalIsOpen}
        title={"Reporte de ventas"}
        listPath={"transactions/report"}
        reportName={"sales"}
        onClose={() => {
          setSalesReportModalIsOpen(false);
        }}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
    </>
  );
};

export { SalesExpandedCard };
