import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { PauseIcon } from "../../icons/PauseIcon";
import { PlayIcon } from "../../icons/PlayIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import CircularProgress from "@mui/material/CircularProgress";
import "./PlayPauseCell.scss";
const PlayPauseCell = (props: any) => {
  const { editPath, value } = props;
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState(value);
  const handleClick = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.put(`${urls.server}/api/${editPath}`, {
        data: !data,
      });
      const resData = await res.data;
      setProcessing(false);
      setData((prev: boolean) => !prev);
    } catch (error: any) {
      setProcessing(false);
      // setAlertMsg(error.toString());
      // setOpenAlert(true);
    }
  };

  return (
    <div className="play-pause-cell-container">
      {processing ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <IconButton
          color={data ? "primary" : "inherit"}
          aria-label="play pause btn"
          onClick={handleClick}
        >
          {data ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
      )}
    </div>
  );
};

export { PlayPauseCell };
