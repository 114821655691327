import React, { useState, useEffect, useRef } from "react";
import { Dialog, Fab, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import ClearIcon from "@mui/icons-material/Clear";

import { UsersAutocomplete } from "../../UsersAutocomplete/UsersAutocomplete";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import "./UserSelectionModal.scss";
import { CheckIcon } from "../../icons/CheckIcon";
import { UsersIcon } from "components/icons/UsersIcon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UserSelectionModal = ({
  open,
  setOpen,
  onClose,
  data,
  subtitle,
  tableName,
  recordId,
}: any) => {
  const [users, setUsers] = useState(data);
  const [refreshOnClose, setRefreshOnClose] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const usersRef = useRef(users);
  const deleteRecord = (idToDelete: number) => {
    const newUsers = users.filter((record: any) => record.id !== idToDelete);
    setUsers(newUsers);
  };

  const handleOnSelect = (data: any) => {
    if (data) {
      // Search for a record in the array that matches the id of the record to add
      const existingRecord = users.find((record: any) => record.id === data.id);
      // If the record doesn't exist, add it to the array
      if (!existingRecord) {
        const newUsers = [...users, data];
        setUsers(newUsers);
      }
    }
  };
  useEffect(() => {
    if (JSON.stringify(usersRef.current) !== JSON.stringify(users)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [users]);
  useEffect(() => {
    if (open) setUsers(data);
  }, [open]);
  const send = async () => {
    if (tableName && recordId) {
      const data = {
        data: users,
      };
      if (tableName) tableName = tableName.replace(/_/g, "-");
      let url = `${urls.server}/api/${tableName}/${recordId}/users`;

      const res = await AxiosInterceptor.put(url, data);

      const resData = await res.data;

      setOpen(false);
      onClose(resData);
    } else {
      setOpen(false);
      onClose(users);
    }
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
        // onDismiss({
        //   refresh: refreshOnClose,
        // });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="user-selection-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Usuarios vinculados</h2>
          <span>
            <b>{subtitle}</b>
          </span>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose();
              // onDismiss({
              //   refresh: refreshOnClose,
              // });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="autocomplete-wrapper">
          <div>
            <UsersAutocomplete onSelect={handleOnSelect} />
          </div>
        </div>

        {users.length == 0 && (
          <div className="no-content">
            <UsersIcon />
            <p>No hay usuarios vinculados</p>
          </div>
        )}
        <div className="list-wrapper">
          <div>
            {users.map((item: any) => {
              return (
                <a key={item?.id}>
                  <div>
                    {item?.user ? (
                      <img
                        src={urls.server + item?.user?.profile_picture}
                        alt=""
                      />
                    ) : (
                      <img src={urls.server + item?.profile_picture} alt="" />
                    )}
                  </div>
                  <div>
                    <strong>{item?.name}</strong>
                    <span>({item?.email})</span>
                  </div>

                  <div>
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        //setCurrentUser(item);
                        deleteRecord(item?.id);
                      }}
                      aria-label="remove"
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="add"
        onClick={() => {
          send();
        }}
        disabled={tableName && recordId ? isDisabled : false}
      >
        <CheckIcon />
      </Fab>
    </Dialog>
  );
};

export { UserSelectionModal };
