import { IconButton } from "@mui/material";
import React from "react";
import "./Col.scss";

import Icon from "../../../../Icon/Icon";
import { AddIcon } from "../../../../icons/AddIcon";
const Col = (props: any) => {
  const { icon, visibleName, onAdd } = props;
  return (
    <div className="table-builder-col-container">
      <div className="table-builder-col-body">
        <Icon name={icon} />
        <strong>{visibleName}</strong>
      </div>
      <div className="table-builder-col-footer">
        <IconButton
          aria-label="add"
          onClick={onAdd}
          size="small"
          className="table-builder-col-btn"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export { Col };
