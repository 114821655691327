import { useState, useEffect, useRef } from "react";
import AxiosInterceptor from "../AxiosInterceptor";
import { useDebounce } from "./useDebounce";
import { formatFilters } from "utils/format-filters";
import urls from "config/urls";

export const useLoadRecords = ({
  tableName,
  loadPath,
  offset = 0,
  limit = 10,
  sortBy,
  dividerFilters,
  filters,
  hiddenFilters,
  sortDirection,
  searchTerm,
  setOffset,
  refreshData,
}: any) => {
  const [records, setRecords] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [allFilters, setAllFilters] = useState<any>([]);
  //esta otra opcion de pasar al metodo
  const prevAllFiltersRef = useRef();
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const loadRecords = async ({ offset }: any) => {
    let url = "";
    if (loadPath) url = `${urls.server}/api/${loadPath}`;
    else {
      const path = tableName.replace(/_/g, "-");
      url = `${
        urls.server
      }/api/${path}?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(allFilters)
      )}`;
    }
    try {
      if (offset) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }

      const res = await AxiosInterceptor.get(url);
      const data = await res?.data;
      if (data) {
        if (offset > 0) {
          setRecords((prev: any) => [...prev, ...data]);
        } else {
          setRecords(data);
        }
        setLoadingMore(false);
        setLoading(false);
        if (data.length < limit) setHasMore(false);
      }
    } catch (error: any) {
      //se comentan variables propias del componente
      setLoading(false);
      setError(error);
    }
  };
  useEffect(() => {
    console.log("sin dependencias loadRecords 66");
    loadRecords({ offset: 0 });
  }, []);
  useEffect(() => {
    prevAllFiltersRef.current = allFilters;
  });
  const prevAllFilters = prevAllFiltersRef.current;
  useEffect(() => {
    if (
      JSON.stringify(allFilters) !== JSON.stringify(prevAllFilters) &&
      JSON.stringify(prevAllFilters) !== undefined
    ) {
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [allFilters]);

  useEffect(() => {
    if (debouncedValue) {
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [debouncedValue]);
  useEffect(() => {
    if (offset > 0) {
      loadRecords({ offset });
    }
  }, [offset]);
  useEffect(() => {
    if (refreshData) {
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [refreshData]);

  useEffect(() => {
    setAllFilters([
      ...formatFilters(filters),
      ...dividerFilters,
      ...hiddenFilters,
    ]);
  }, [filters, dividerFilters, hiddenFilters]);
  return {
    loadRecords,
    hasMore,
    setHasMore,
    records,
    loading,
    loadingMore,
    error,
  };
};
