import React from "react";
import "./BanksCard.scss";
const BanksCard = ({ title }: any) => {
  return (
    <div className="banks-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <svg viewBox="0 0 12 12">
          <path
            className="bank-icon"
            d="m 6.133234,2.0859475 c -0.075499,0 -0.1507781,0.014982 -0.2212143,0.044917 L 1.967592,3.8213337 C 1.7210652,3.926988 1.5837288,4.1911035 1.6383168,4.4517173 1.6929049,4.7123311 1.9218014,4.902502 2.1894591,4.902502 v 0.1408725 c 0,0.2342002 0.1884168,0.4226173 0.4226171,0.4226173 H 2.724368 v 3.3375345 c -0.010566,0.00529 -0.02111,0.012319 -0.031675,0.019363 L 1.8474584,9.3863788 c -0.206026,0.1373505 -0.299361,0.39445 -0.2271639,0.6321722 0.072197,0.237722 0.2922974,0.399746 0.5405838,0.399746 h 7.8888557 c 0.248287,0 0.466668,-0.162024 0.538865,-0.399746 0.0722,-0.2377222 -0.01938,-0.4948217 -0.227164,-0.6321722 L 9.5162006,8.8228892 c -0.010566,-0.00704 -0.021145,-0.012319 -0.03171,-0.019363 V 5.4659914 h 0.171207 c 0.2342004,0 0.4226174,-0.1884171 0.4226174,-0.4226173 V 4.9025017 c 0.267657,0 0.49835,-0.18841 0.551179,-0.4507848 0.05282,-0.2623747 -0.08451,-0.5264901 -0.329277,-0.6303836 L 6.3557889,2.1308644 C 6.2844723,2.1009289 6.2087322,2.0859471 6.133234,2.0859475 Z m 6.528e-4,1.1260852 c 0.3116802,0 0.5634896,0.2518097 0.5634896,0.5634896 0,0.3116799 -0.2518094,0.5634897 -0.5634896,0.5634897 -0.3116803,0 -0.5634897,-0.2518098 -0.5634897,-0.5634897 0,-0.3116799 0.2518094,-0.5634896 0.5634897,-0.5634896 z M 3.8513474,5.4659914 H 5.5417142 V 8.7278277 H 3.8513474 Z m 2.8173463,0 H 8.3592648 V 8.7278277 H 6.6686937 Z"
          />
        </svg>
      </div>
    </div>
  );
};

export { BanksCard };
