import React, { useState, useEffect } from "react";
import "./StatusCell.scss";
import Icon from "../../Icon/Icon";
import { Button, Popover } from "@mui/material";
import { CheckIcon } from "components/icons/CheckIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
const StatusCell = ({
  options,
  data,
  readonly,
  recordId,
  tableName,
  onChange,
  setRefreshData,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [processing, setProcessing] = useState(false);
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (anchorEl && setRefreshData) setRefreshData(false);
  }, [anchorEl]);
  const [value, setValue] = useState(null as any);
  useEffect(() => {
    if (data) {
      options.forEach((item: any) => {
        if (item.name == data) setValue(item);
      });
    }
  }, [data]);
  const updateStatus = async (value: any) => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    const url = `${urls.server}/api/${tableName}/${recordId}/status`;
    setProcessing(true);
    try {
      const data = {
        data: value?.name,
      };
      const res = await AxiosInterceptor.put(`${url}`, data);
      const resData = await res.data;
      setProcessing(false);
      setValue(value);
      closePopover();
      onChange(true);
    } catch (error: any) {
      setProcessing(false);
      closePopover();
    }
  };
  return (
    <>
      <a
        className="status-cell-container"
        style={{
          backgroundColor: value?.color ? value?.color : null,
          cursor: readonly != true ? "pointer" : "initial",
        }}
        onClick={(ev: any) => {
          if (readonly != true) {
            presentPopover(ev);
          }
        }}
      >
        {value?.icon && <Icon name={value?.icon} />}
        <span>{value?.visibleName}</span>
      </a>
      <Popover
        id={"status-popover-" + recordId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popover-items status-popover-items">
          {options.map((item: any, index: number) => {
            return (
              <Button
                key={index}
                onClick={() => {
                  updateStatus(item);
                }}
                color="inherit"
                disabled={processing}
              >
                {item.name == value?.name && <CheckIcon />}
                {item.visibleName}
              </Button>
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export { StatusCell };
