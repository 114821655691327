import React from "react";
import { CompletedConstructionIcon } from "../icons/CompletedConstructionIcon";
import { ConstructionInPauseIcon } from "../icons/ConstructionInPauseIcon";
import { ConstructionInProgressIcon } from "../icons/ConstructionInProgressIcon";
import { BarChartCardIcon } from "../icons/BarChartCardIcon";
import { BasicIndicatorCardIcon } from "../icons/BasicIndicatorCardIcon";
import { ColumnChartCardIcon } from "../icons/ColumnChartCardIcon";
import { FilledShapeChartCardIcon } from "../icons/FilledShapeChartCardIcon";
import { LineChartCardIcon } from "../icons/LineChartCardIcon";
import { MapCardIcon } from "../icons/MapCardIcon";
import { DataTableCardIcon } from "../icons/DataTableCardIcon";
import { ColorIcon } from "../icons/ColorIcon";
import { TextIcon } from "../icons/TextIcon";
import { TagsIcon } from "../icons/TagsIcon";
import { ThumbnailIcon } from "../icons/ThumbnailIcon";
import { AddressIcon } from "../icons/AddressIcon";
import { DateIcon } from "../icons/DateIcon";
import { DaysIcon } from "../icons/DaysIcon";
import { MonthsIcon } from "../icons/MonthsIcon";
import { StatusesIcon } from "../icons/StatusesIcon";
import { MultiLineChartCardIcon } from "../icons/MultiLineChartCardIcon";
import { BreakdownChartCardIcon } from "../icons/BreakdownChartCardIcon";
import { StackedColumnChartCardIcon } from "../icons/StackedColumnChartCardIcon";
import { IndicatorCardIcon } from "../icons/IndicatorCardIcon";
import { SalesCardIcon } from "../icons/SalesCardIcon";
import { PurchasesCardIcon } from "../icons/PurchasesCardIcon";
import { CustomersCardIcon } from "../icons/CustomersCardIcon";
import { SuppliersCardIcon } from "../icons/SuppliersCardIcon";
import { ServicesCardIcon } from "../icons/ServicesCardIcon";
import { ProductsCardIcon } from "../icons/ProductsCardIcon";
import { TaxesCardIcon } from "../icons/TaxesCardIcon";
import { BanksCardIcon } from "../icons/BanksCardIcon";
import { InventoryManagerCardIcon } from "../icons/InventoryManagerCardIcon";
import { SubscriptionsCardIcon } from "../icons/SubscriptionsCardIcon";
import { PayablesCardIcon } from "../icons/PayablesCardIcon";
import { ReceivablesCardIcon } from "../icons/ReceivablesCardIcon";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { UsersIcon } from "../icons/UsersIcon";
import { TextFieldIcon } from "../icons/TextFieldIcon";
import { LongTextFieldIcon } from "../icons/LongTextFieldIcon";
import { IntegerFieldIcon } from "../icons/IntegerFieldIcon";
import { DecimalFieldIcon } from "../icons/DecimalFieldIcon";
import { RadioFieldIcon } from "../icons/RadioFieldIcon";
import { CheckboxFieldIcon } from "../icons/CheckboxFieldIcon";
import { CheckboxesFieldIcon } from "../icons/CheckboxesFieldIcon";
import { ListFieldIcon } from "../icons/ListFieldIcon";
import { DateFieldIcon } from "../icons/DateFieldIcon";
import { DateTimeFieldIcon } from "../icons/DateTimeFieldIcon";
import { EmailFieldIcon } from "../icons/EmailFieldIcon";
import { TextEditorFieldIcon } from "../icons/TextEditorFieldIcon";
import { ColorFieldIcon } from "../icons/ColorFieldIcon";
import { SelectFieldIcon } from "../icons/SelectFieldIcon";
import { MultipleSelectFieldIcon } from "../icons/MultipleSelectFieldIcon";
import { MoneyFieldIcon } from "../icons/MoneyFieldIcon";
import { CalculatedFieldIcon } from "../icons/CalculatedFieldIcon";
import { SeparatorFieldIcon } from "../icons/SeparatorFieldIcon";
import { ImageCropperFieldIcon } from "../icons/ImageCropperFieldIcon";
import { GoalsFieldIcon } from "../icons/GoalsFieldIcon";
import { PasswordFieldIcon } from "../icons/PasswordFieldIcon";
import { AddressFieldIcon } from "../icons/AddressFieldIcon";
import { TagsFieldIcon } from "../icons/TagsFieldIcon";
import { WhatsAppFieldIcon } from "../icons/WhatsAppFieldIcon";
import { UsersCardIcon } from "../icons/UsersCardIcon";
import { UserGroupsCardIcon } from "../icons/UserGroupsCardIcon";
import { DataTablesCardIcon } from "../icons/DataTablesCardIcon";
import { SettingsCardIcon } from "../icons/SettingsCardIcon";
import { CardManagerCardIcon } from "../icons/CardManagerCardIcon";
import { MyIndicatorsCardIcon } from "../icons/MyIndicatorsCardIcon";
import { IndicatorsCardIcon } from "../icons/IndicatorsCardIcon";
import { WorkflowEntriesCardIcon } from "../icons/WorkflowEntriesCardIcon";
import { TasksCardIcon } from "../icons/TasksCardIcon";
import { MyTasksCardIcon } from "../icons/MyTasksCardIcon";
import { AddressesCardIcon } from "../icons/AddressesCardIcon";
import { TutorialsCardIcon } from "../icons/TutorialsCardIcon";
import { WorkflowsCardIcon } from "../icons/WorkflowsCardIcon";
import { BookshelfCardIcon } from "../icons/BookshelfCardIcon";
import { CitrusIcon } from "../icons/CitrusIcon";
import { LivestockIcon } from "../icons/LivestockIcon";
import { CerealsAndGrainsIcon } from "../icons/CerealsAndGrainsIcon";
import { EnergyIcon } from "../icons/EnergyIcon";
import { TransactionFieldIcon } from "../icons/TransactionFieldIcon";
import { PaymentFieldIcon } from "../icons/PaymentFieldIcon";
import { PieChartCardIcon } from "components/icons/PieChartCardIcon";
import { RankingCardIcon } from "components/icons/RankingCardIcon";
import { ScrapingCardIcon } from "components/icons/ScrapingCardIcon";
import { ExportIcon } from "components/icons/ExportIcon";
import { DownloadIcon } from "components/icons/DownloadIcon";
import { EmailIcon } from "components/icons/EmailIcon";
import { UploadIcon } from "components/icons/UploadIcon";
import { WalletIcon } from "components/icons/WalletIcon";
import { ChecklistIcon } from "components/icons/ChecklistIcon";
import { PaymentAllocationIcon } from "components/icons/PaymentAllocationIcon";
import { GridIcon } from "components/icons/GridIcon";
import { WorkIcon } from "components/icons/WorkIcon";
import { EmploymentIcon } from "components/icons/EmploymentIcon";
import { UnemploymentIcon } from "components/icons/UnemploymentIcon";
import { FilesFieldIcon } from "components/icons/FilesFieldIcon";
import { CameraIcon } from "components/icons/CameraIcon";
import { ImageFieldIcon } from "components/icons/ImageFieldIcon";
import { AddIcon } from "components/icons/AddIcon";
import { AddWrapperIcon } from "components/icons/AddWrapperIcon";
import { AlertIcon } from "components/icons/AlertIcon";
import { CheckIcon } from "components/icons/CheckIcon";

const Icon = (props: any) => {
  const { name, color, size } = props;
  return (
    <>
      {name === "completedConstruction" && (
        <CompletedConstructionIcon color={color} />
      )}
      {name === "constructionInProgress" && (
        <ConstructionInProgressIcon color={color} />
      )}
      {name === "constructionInPause" && (
        <ConstructionInPauseIcon color={color} />
      )}
      {name === "check" && <CheckIcon color={color} size={size} />}
      {name === "color" && <ColorIcon color={color} size={size} />}
      {name === "alert" && <AlertIcon color={color} size={size} />}
      {name === "export" && <ExportIcon color={color} size={size} />}
      {name === "upload" && <UploadIcon color={color} size={size} />}
      {name === "work" && <WorkIcon color={color} size={size} />}
      {name === "employment" && <EmploymentIcon color={color} size={size} />}
      {name === "unemployment" && <UnemploymentIcon color={color} size={size} />}

      {name === "download" && <DownloadIcon color={color} size={size} />}
      {name === "email" && <EmailIcon color={color} size={size} />}
      {name === "text" && <TextIcon color={color} size={size} />}
      {name === "tags" && <TagsIcon color={color} size={size} />}
      {name === "thumbnail" && <ThumbnailIcon color={color} size={size} />}
      {name === "address" && <AddressIcon color={color} size={size} />}
      {name === "date" && <DateIcon color={color} size={size} />}
      {name === "days" && <DaysIcon color={color} size={size} />}
      {name === "months" && <MonthsIcon color={color} size={size} />}
      {name === "statuses" && <StatusesIcon color={color} size={size} />}
      {name === "users" && <UsersIcon color={color} size={size} />}
      {name === "wallet" && <WalletIcon color={color} size={size} />}
      {name === "grid" && <GridIcon color={color} size={size} />}
      {name === "add" && <AddIcon color={color} size={size} />}
      {name === "addWrapper" && <AddWrapperIcon color={color} size={size} />}
      {name === "citrus" && (
        <>
          {color} <CitrusIcon color={color} />
        </>
      )}
      {name === "livestock" && <LivestockIcon color={color} size={size} />}
      {name === "paymentAllocation" && <PaymentAllocationIcon color={color} size={size} />}
      {name === "cerealsAndGrains" && <CerealsAndGrainsIcon color={color} size={size} />}
      {name === "energy" && <EnergyIcon color={color} size={size} />}
      {name === "camera" && <CameraIcon color={color} size={size} />}

      {/** Card icons */}
      {name === "indicatorCard" && <IndicatorCardIcon color={color} size={size} />}
      {name === "rankingCard" && <RankingCardIcon color={color} size={size} />}
      {name === "scrapingCard" && <ScrapingCardIcon color={color} size={size} />}
      {name === "salesCard" && <SalesCardIcon color={color} size={size} />}
      {name === "purchasesCard" && <PurchasesCardIcon color={color} size={size} />}
      {name === "customersCard" && <CustomersCardIcon color={color} size={size} />}
      {name === "suppliersCard" && <SuppliersCardIcon color={color} size={size} />}
      {name === "receivablesCard" && <ReceivablesCardIcon color={color} size={size} />}
      {name === "payablesCard" && <PayablesCardIcon color={color} size={size} />}
      {name === "subscriptionsCard" && <SubscriptionsCardIcon color={color} size={size} />}
      {name === "inventoryManagerCard" && (
        <InventoryManagerCardIcon color={color} />
      )}
      {name === "banksCard" && <BanksCardIcon color={color} size={size} />}
      {name === "taxesCard" && <TaxesCardIcon color={color} size={size} />}
      {name === "servicesCard" && <ServicesCardIcon color={color} size={size} />}
      {name === "productsCard" && <ProductsCardIcon color={color} size={size} />}
      {name === "lineChartCard" && <LineChartCardIcon color={color} size={size} />}
      {name === "multiLineChartCard" && <MultiLineChartCardIcon color={color} size={size} />}
      {name === "breakdownChartCard" && <BreakdownChartCardIcon color={color} size={size} />}
      {name === "barChartCard" && <BarChartCardIcon color={color} size={size} />}
      {name === "columnChartCard" && <ColumnChartCardIcon color={color} size={size} />}
      {name === "stackedColumnChartCard" && (
        <StackedColumnChartCardIcon color={color} />
      )}
      {name === "filledShapeChartCard" && (
        <FilledShapeChartCardIcon color={color} />
      )}
      {name === "pieChartCard" && <PieChartCardIcon color={color} size={size} />}
      {name === "basicIndicatorCard" && <BasicIndicatorCardIcon color={color} size={size} />}
      {name === "mapCard" && <MapCardIcon color={color} size={size} />}
      {name === "dataTableCard" && <DataTableCardIcon color={color} size={size} />}
      {name === "dataTablesCard" && <DataTablesCardIcon color={color} size={size} />}
      {name === "usersCard" && <UsersCardIcon color={color} size={size} />}
      {name === "userGroupsCard" && <UserGroupsCardIcon color={color} size={size} />}
      {name === "settingsCard" && <SettingsCardIcon color={color} size={size} />}
      {name === "cardManagerCard" && <CardManagerCardIcon color={color} size={size} />}
      {name === "indicatorsCard" && <IndicatorsCardIcon color={color} size={size} />}
      {name === "myIndicatorsCard" && <MyIndicatorsCardIcon color={color} size={size} />}
      {name === "workflowEntriesCard" && <WorkflowEntriesCardIcon color={color} size={size} />}
      {name === "tasksCard" && <TasksCardIcon color={color} size={size} />}
      {name === "myTasksCard" && <MyTasksCardIcon color={color} size={size} />}
      {name === "addressesCard" && <AddressesCardIcon color={color} size={size} />}
      {name === "tutorialsCard" && <TutorialsCardIcon color={color} size={size} />}
      {name === "workflowsCard" && <WorkflowsCardIcon color={color} size={size} />}
      {name === "bookshelfCard" && <BookshelfCardIcon color={color} size={size} />}

      {/** Field icons */}
      {name === "textField" && <TextFieldIcon color={color} size={size} />}
      {name === "longTextField" && <LongTextFieldIcon color={color} size={size} />}
      {name === "integerField" && <IntegerFieldIcon color={color} size={size} />}
      {name === "decimalField" && <DecimalFieldIcon color={color} size={size} />}
      {name === "radioField" && <RadioFieldIcon color={color} size={size} />}
      {name === "checkboxField" && <CheckboxFieldIcon color={color} size={size} />}
      {name === "checklist" && <ChecklistIcon color={color} size={size} />}
      {name === "checkboxesField" && <CheckboxesFieldIcon color={color} size={size} />}
      {name === "listField" && <ListFieldIcon color={color} size={size} />}
      {name === "dateField" && <DateFieldIcon color={color} size={size} />}
      {name === "datetimeField" && <DateTimeFieldIcon color={color} size={size} />}
      {name === "emailField" && <EmailFieldIcon color={color} size={size} />}
      {name === "textEditorField" && <TextEditorFieldIcon color={color} size={size} />}
      {name === "colorField" && <ColorFieldIcon color={color} size={size} />}
      {name === "selectField" && <SelectFieldIcon color={color} size={size} />}
      {name === "multipleSelectField" && <MultipleSelectFieldIcon color={color} size={size} />}
      {name === "moneyField" && <MoneyFieldIcon color={color} size={size} />}
      {name === "calculatedField" && <CalculatedFieldIcon color={color} size={size} />}
      {name === "separatorField" && <SeparatorFieldIcon color={color} size={size} />}
      {name === "imageCropperField" && <ImageCropperFieldIcon color={color} size={size} />}
      {name === "goalsField" && <GoalsFieldIcon color={color} size={size} />}
      {name === "passwordField" && <PasswordFieldIcon color={color} size={size} />}
      {name === "addressField" && <AddressFieldIcon color={color} size={size} />}
      {name === "tagsField" && <TagsFieldIcon color={color} size={size} />}
      {name === "whatsappField" && <WhatsAppFieldIcon color={color} size={size} />}
      {name === "transactionField" && <TransactionFieldIcon color={color} size={size} />}
      {name === "paymentField" && <PaymentFieldIcon color={color} size={size} />}
      {name === "filesField" && <FilesFieldIcon color={color} size={size} />}
      {name === "imageField" && <ImageFieldIcon color={color} size={size} />}
    </>
  );
};

export default Icon;
