export const getDividerGroups = () => {
  const data: any = {
    address: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "addresses/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "addresses/year-months-with-records",
      },
    ],
    zone: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "zones/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "zones/year-months-with-records",
      },
    ],
    neighborhood: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "neighborhoods/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "neighborhoods/year-months-with-records",
      },
    ],
  };

  return data;
};
