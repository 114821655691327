export const getRowActions = () => {
  const data: any = {
    user: [
      {
        name: "view",
        visibleName: "Ver usuario",
      },
      {
        name: "delete",
        visibleName: "Eliminar usuario",
      },
      // {
      //   name: "cardsPicker",
      //   visibleName: "Seleccionar tarjetas",
      // },
    ],
  };

  return data;
};
