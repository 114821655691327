import React, { useState, useEffect } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { BoardPage } from "./pages/BoardPage/BoardPage";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import { SignInPage } from "./pages/SignInPage/SignInPage";

import { Tabs } from "./components/Tabs/Tabs";
import { SalesRoutes } from "./cards/sales/pages/SalesRoutes";

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import { SnapPage } from "./pages/SnapPage/SnapPage";
import { project } from "./config/project";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";

import { OnboardingPage } from "./pages/OnboardingPage/OnboardingPage";
import { useAuth } from "./contexts/AuthContext";
import { NotFoundPage } from "./pages/NotFoundPage/NotFoundPage";
import { NotificationsPage } from "./pages/NotificationsPage/NotificationsPage";
import axios from "axios";
import { TutorialsRoutes } from "cards/tutorials/pages/TutorialsRoutes";
import colors from "config/colors";
import urls from "config/urls";

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BoardPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/snap" element={<SnapPage />} />
      <Route path="/notifications" element={<NotificationsPage />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      {SalesRoutes}
      {TutorialsRoutes}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

function App() {
  const [primaryColor, setPrimaryColor] = React.useState(colors.primary);
  const { getToken } = useAuth();
  const isAuthenticated = !!getToken();
  const getSettings = async () => {
    try {
      const res = await axios.get(`${urls.server}/api/public/settings`);
      const data = await res.data.data;
      const root = document.documentElement;
      root?.style.setProperty(
        "--color-primary",
        data.primary_color ? data.primary_color : "#3caea3"
      );
      if (data.primary_color) setPrimaryColor(data.primary_color);
      let link: any = document.querySelector("link[rel~='icon']");
      if (data.favicon) link.href = `${urls.server}${data.favicon}`;
    } catch (error: any) {
      error = JSON.parse(JSON.stringify(error));
    }
  };
  React.useEffect(() => {
    getSettings();
    const root = document.documentElement;
    root?.style.setProperty("--color-primary", colors.primary);
    root?.style.setProperty("--color-primary-tint", colors.primaryTint);
    root?.style.setProperty("--color-primary-shade", colors.primaryShade);
    root?.style.setProperty("--color-dark", colors.dark);
    root?.style.setProperty("--color-medium", colors.medium);
    root?.style.setProperty("--color-light", colors.light);
    root?.style.setProperty("--color-text", colors.text);
    root?.style.setProperty("--color-text-shade", colors.textShade);
    root?.style.setProperty("--color-text-tint", colors.textTint);
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        shadows: shadows.map(() => "none"),
        palette: {
          text: {
            primary: colors.text,
          },
          background: {
            default: colors.light,
          },
          primary: { main: primaryColor },
          warning: {
            main: colors.danger,
          },
          success: {
            main: colors.success,
          },
        },
      } as any),
    [primaryColor]
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Routes>
            <Route
              path="*"
              element={
                isAuthenticated ? (
                  <AuthenticatedRoutes />
                ) : (
                  <Routes>
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route path="/sign-in" element={<SignInPage />} />
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="*" element={<Navigate to="/sign-in" />} />
                  </Routes>
                )
              }
            />
          </Routes>
          <Tabs></Tabs>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
