import React from 'react'
import Paper from 'components/Paper/Paper';
import List from 'components/List/List';
import colors from 'config/colors';
import './SelectedConfigurationItems.scss';

interface SelectedConfigurationItemsProps {
    data: any;
    onEditItem: (item: any) => void;
    onRemoveItem: (item: any, index: number) => void;
}
const SelectedConfigurationItems: React.FC<SelectedConfigurationItemsProps> = ({ data, onEditItem, onRemoveItem }) => {
    const itemCells = [
        {
            type: 'icon',
            color: colors.primary,
            name: 'icon',
            size: 50
        },
        {
            type: "template",
            //template: ""
            template: `<div class='item-description'>
                <b>{{visible_type}}</b>
                <span>{{configurable_attributes.visibleName}}</span>
            </div>`,
        }
    ];
    return (
        <div className='selected-configuration-items-container'>
            <Paper title='Items seleccionados'>
                <List
                    data={data}
                    cells={itemCells}
                    onDeleteItem={onRemoveItem}
                    onEditItem={onEditItem}
                    //itemStyle={{}}
                    canDelete
                    canEdit
                    isSortable
                />
            </Paper>
        </div>
    )
}

export default SelectedConfigurationItems