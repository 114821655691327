import { useState, useEffect, Fragment } from "react";
import Icon from "../../../../components/Icon/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import {
  IconButton,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import "./UserGroupListItem.scss";
import { UsersCell } from "../../../../components/cells/UsersCell/UsersCell";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { getFields } from "../../utils/get-fileds";

const UserGroupListItem = ({ data, id, onDelete, onChange }: any) => {
  const [openAlert, setOpenAlert] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const fields = getFields().userGroup;
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const closeAlert = () => {
    setOpenAlert(false);
  };
  const removeRecord = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.delete(
      `${urls.server}/api/user-groups/${data?.id}`
    );
    console.log(res);
    onDelete(true);
    setProcessing(false);
    closeAlert();
    closePopover();
    //setRefreshOnClose(true);
  };
  return (
    <div className="user-group-list-item-container">
      <div>
        <div>
          <Icon name={"users"} />
        </div>

        <div>
          <span>{data?.visible_id}</span>
          <strong>{data?.name}</strong>
        </div>
      </div>

      <div>
        <div style={{ marginRight: "10px" }}>
          <UsersCell
            data={data.users}
            modalSubtitle={"Grupo de usuarios"}
            tableName={"user-groups"}
            recordId={data.id}
            onChange={(value: any) => {
              console.log(value);
              onChange(true);
            }}
          />
        </div>

        <IconButton aria-label="delete" onClick={presentPopover}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={"user-group-popover-" + id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="user-group-popover-items">
            <a onClick={() => setFormIsOpen(true)}>Editar</a>
            <a onClick={() => setOpenAlert(true)}>Eliminar</a>
            {/* <a
              onClick={() => {
                closePopover();
              }}
            >
              Configuración
            </a> */}
          </div>
        </Popover>

        <Dialog
          open={openAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Quitar"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Desea eliminar el grupo <b>{data?.name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} autoFocus>
              Cancelar
            </Button>
            <Button onClick={removeRecord}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <FormModal
        open={formIsOpen}
        setOpen={setFormIsOpen}
        fields={fields}
        title={"Grupo de usuario"}
        tableName={"user_groups"}
        recordId={data.id}
        onClose={(res: any) => {
          if (res) {
          }
        }}
      />
    </div>
  );
};

export { UserGroupListItem };
