import React, { useState } from "react";

const CardsPickerField = (props: any) => {
  const {
    editable,
    label,
    editPath,
    onChange,
    options = [],
    defaultValue,
    validations,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  return (
    <div className="coordinates-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <div>
        {editable && !enabledEdition ? <span>{value}</span> : <div></div>}
      </div>
    </div>
  );
};

export { CardsPickerField };
