export const getFields = () => {
  const data: any = {
    role: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
