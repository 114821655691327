import React from "react";
import "./DataTablesCard.scss";
import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
const DataTablesCard = (props: any) => {
  const { title, config, data } = props;
  return (
    <div className="data-tables-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.dataTablesCount}
          label={"Tablas de datos"}
        />
      </div>
    </div>
  );
};

export { DataTablesCard };
