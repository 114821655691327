import React, { useState } from "react";

import Bibliorate from "../../../../components/Bibliorate/Bibliorate";
import "./BookshelfExpandedCard.scss";
import { GetBibliorates } from "../../utils/get-bibliorates";
import { HeaderSearchbar } from "../../../../components/HeaderSearchbar/HeaderSearchbar";
const BookshelfExpandedCard = (props: any) => {
  const { title, data } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const bibliorates = GetBibliorates();
  return (
    <div className="bookshelf-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
        <span className="header-searchbar-wrapper">
          <HeaderSearchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </span>
      </div>
      <div className="expanded-card-body">
        <div className="bookshelf">
          {bibliorates.map((item: any, index: number) => {
            return (
              <div key={index}>
                <Bibliorate label={item.label} color={item.color} />
                <span></span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { BookshelfExpandedCard };
