import React, { useState } from "react";
import "./InventoryManagerExpandedCard.scss";
import { ArrowWrapperIcon } from "components/icons/ArrowWrapperIcon";
import { ArrowIcon } from "components/icons/ArrowIcon";
import { ReportModal } from "components/modals/ReportModal/ReportModal";
import { RecordsModal } from "components/modals/RecordsModal/RecordsModal";
import { GridWrapperIcon } from "components/icons/GridWrapperIcon";
import { GridIcon } from "components/icons/GridIcon";

import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import { RefreshWrapperIcon } from "components/icons/RefreshWrapperIcon";
import { RefreshIcon } from "components/icons/RefreshIcon";

import { CountWrapperIcon } from "components/icons/CountWrapperIcon";
import { CountIcon } from "components/icons/CountIcon";
const InventoryManagerExpandedCard = (props: any) => {
  const { title, data } = props;
  const [stockReportModalIsOpen, setStockReportModalIsOpen] = useState(false);
  const [
    inventoryLocationRecordsModalIsOpen,
    setInventoryLocationRecordsModalIsOpen,
  ] = useState(false);
  const [
    inventoryCountRecordsModalIsOpen,
    setInventoryCountRecordsModalIsOpen,
  ] = useState(false);

  const [inventoryLocationCols, setInventoryLocationCols] = useState([]);
  const [inventoryLocationFields, setInventoryLocationFields] = useState([]);
  const [inventoryLocationDividerGroups, setInventoryLocationDividerGroups] =
    useState([]);
  const [inventoryLocationRowActions, setInventoryLocationRowActions] =
    useState([]);

  const [inventoryCountCols, setInventoryCountCols] = useState([]);
  const [inventoryCountFields, setInventoryCountFields] = useState([]);
  const [inventoryCountDividerGroups, setInventoryCountDividerGroups] =
    useState([]);
  const [inventoryCountRowActions, setInventoryCountRowActions] = useState([]);

  const refreshCard = async () => {
    try {
      const res = await AxiosInterceptor.put(
        `${urls.server}/api/inventory-manager/card-data`
      );
    } catch (error) { }
  };
  return (
    <div className="Inventory-manager-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <div className="expanded-card-body">
        <a
          className="card-btn lg"
          onClick={() => {
            setStockReportModalIsOpen(true);
          }}
        >
          <strong>Reporte de existencias</strong>
          <div>
            <ArrowWrapperIcon />
            <ArrowIcon />
          </div>
        </a>
        <a
          className="card-btn lg"
          onClick={() => {
            setInventoryLocationRecordsModalIsOpen(true);
          }}
        >
          <strong>Depósitos</strong>
          <div>
            <GridWrapperIcon />
            <GridIcon />
          </div>
        </a>
        <a className="card-btn lg" onClick={() => { }}>
          <strong>Recuentos</strong>
          <div>
            <CountWrapperIcon />
            <CountIcon />
          </div>
        </a>
        <a
          className="card-btn lg"
          onClick={() => {
            refreshCard();
          }}
        >
          <strong>Actualizar inventario</strong>
          <div>
            <RefreshWrapperIcon />
            <RefreshIcon />
          </div>
        </a>
      </div>
      <ReportModal
        isOpen={stockReportModalIsOpen}
        title={"Reporte de existencias"}
        listPath={"inventory/report"}
        reportName={"stock"}
        onClose={() => {
          setStockReportModalIsOpen(false);
        }}
      />

      <RecordsModal
        open={inventoryLocationRecordsModalIsOpen}
        setOpen={setInventoryLocationRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Depósitos"}
        tableName={"inventory-locations"}
        cols={inventoryLocationCols}
        fields={inventoryLocationFields}
        rowActions={inventoryLocationRowActions}
        dividerGroups={inventoryLocationDividerGroups}
        formTitle={"Form. de depósito"}
      />
      <RecordsModal
        open={inventoryCountRecordsModalIsOpen}
        setOpen={setInventoryCountRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Recuentos"}
        tableName={"inventory-counts"}
        cols={inventoryCountCols}
        fields={inventoryCountFields}
        rowActions={inventoryCountRowActions}
        dividerGroups={inventoryCountDividerGroups}
        formTitle={"Form. de recuento"}
      />
    </div>
  );
};

export { InventoryManagerExpandedCard };
