import { useState } from "react";
import AxiosInterceptor from "../AxiosInterceptor";
import urls from "config/urls";

export const useDeleteRecord = ({
  tableName,
  recordId,
  deletePath,
  onDelete,
}: any) => {
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const deleteRecord = async (value: any) => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}`
      : `${urls.server}/api/${deletePath}`;
    setProcessing(true);
    try {
      const data = {
        data: value,
      };
      const res = await AxiosInterceptor.delete(`${url}`, data);
      const resData = await res.data;
      const { message } = resData;
      console.log(resData);
      setProcessing(false);
      if (onDelete !== undefined) onDelete(resData);
      if (message !== undefined) setAlertMsg(message.toString());
      else setAlertMsg(`deleted successfully`);
      setOpenAlert(true);
      setSeverity("success");
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };
  return {
    processing,
    openAlert,
    alertMsg,
    severity,
    deleteRecord,
    setOpenAlert,
    setProcessing,
  };
};
