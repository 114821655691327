import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./TransactionField.scss";
import { ReadonlyTransactionField } from "./ReadonlyTransactionField/ReadonlyTransactionField";
import { EditableTransactionField } from "./EditableTransactionField/EditableTransactionField";
const TransactionField = (props: any) => {
  let {
    defaultValue,
    editable,
    editPath,
    onChange,
    name,
    recordId,
    readonly,
    transactionType,
    documentType,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="transaction-field-container">
      <div className="field-header">
        {(recordId || editPath) && editable && (
          <FieldEditControls
            value={data}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setData(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setData(originalValue);
              }
            }}
          />
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlyTransactionField
              data={defaultValue}
              transactionType={transactionType}
              documentType={documentType}
            />
          ) : (
            <EditableTransactionField
              defaultData={defaultValue}
              transactionType={transactionType}
              documentType={documentType}
              onChange={onChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { TransactionField };
