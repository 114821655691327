import { useState, useEffect } from "react";
import { useSessionStorage } from "hooks/useSessionStorage";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./RadioField.scss";

const RadioField = (props: any) => {
  const {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    options = [],
    //recordId,
    readonly,
    name,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const sessionStorageKey = `${name}`;
  const [storageValue, setStorageValue] = useSessionStorage(
    sessionStorageKey,
    ""
  );

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleClick = (index: number) => {
    setValue(options[index].name);
  };
  useEffect(() => {
    if (!defaultValue) {
      setValue(options[0].name);
      setStorageValue(options[0].name);
    } else {
      setStorageValue(defaultValue);
    }
  }, []);
  function getVisibleNameByName(name: string) {
    const option = options.find((option: any) => option.name === name);
    return option ? option.visibleName : null;
  }
  return (
    <div className="radio-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {!readonly != true || (mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={() => {
                  setIsEditEnabled(false);
                  setValue(storageValue);
                }}
                onEditEnd={(success: boolean) => {
                  setIsEditEnabled(false);
                  if (success) {
                    setStorageValue(value);
                  } else {
                    setValue(storageValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              <div>
                <span>{getVisibleNameByName(value)}</span>
              </div>
            </div>
          ) : (
            <div className="editable-content">
              {options.map((option: any, index: any) => {
                return (
                  <a
                    key={index}
                    onClick={() => handleClick(index)}
                    className={value == option.name ? "actived" : ""}
                  >
                    <span>{option?.visibleName}</span>
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { RadioField };
