import { useState, useEffect, Fragment } from "react";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import "./TagsFilter.scss";

import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
const TagsFilter = ({
  defaultValue = {
    data: [],
  },
  label,
  onChange,
  groupId,
}: any) => {
  const [value, setValue] = useState(defaultValue as any);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value) onChange(value);
  }, [value]);
  const handleChange = async (event: any) => {
    console.log("buscarrr");
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);

    let url = `${urls.server}/api/tags?group_id=${groupId}`;

    const res = await AxiosInterceptor.get(`${url}`);
    const data = await res?.data;
    setOptions(data);
    setLoading(false);
  };
  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    } else {
      setNoOptionsText("Buscar...");
    }
  }, [open]);
  return (
    <div className="multi-select-filter-container">
      <label className="filter-label">{label}</label>
      <div className="autocomplete-wrapper">
        <Autocomplete
          multiple
          noOptionsText={noOptionsText}
          loadingText="Buscando..."
          //sx={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option: any, value: any) =>
            option?.name === value?.name
          }
          getOptionLabel={(option: any) => option?.name}
          options={options}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              //style={{ backgroundColor: option?.color }}
            >
              {option?.name}
            </Box>
          )}
          loading={loading}
          onChange={(event: any, newValue: any | null) => {
            setValue((prevValue: any) => ({ ...prevValue, data: newValue }));
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: any, index: number) => (
              <Chip
                style={{ backgroundColor: option?.color }}
                label={option?.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              hiddenLabel
              onChange={handleChange}
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          value={defaultValue?.data}
        />
      </div>
    </div>
  );
};

export { TagsFilter };
