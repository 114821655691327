import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./Dividers.scss";
import Box from "@mui/material/Box";
import { DividerGroupsPopover } from "./DividerGroupsPopover";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from "config/urls";
const Dividers = ({
  tableName,
  forceRefresh,
  groups,
  onChange,
}: any) => {
  const [value, setValue] = React.useState(0);
  const [selectedGroup, setSelectedGroup] = React.useState(groups[0]);

  const [loadingDividers, setLoadingDividers] = React.useState(true);
  const [dividers, setDividers] = React.useState([]);
  const loadDividers = async () => {
    setLoadingDividers(true);
    let url = `${urls.server}/api/${selectedGroup.path}`;

    const res = await AxiosInterceptor.get(`${url}`);

    const data = await res.data;
    data.unshift({
      value: "Todos",
      label: "Todos",
    });
    setDividers(data);
    setLoadingDividers(false);
  };
  const makeStatusDividers = () => {

    // Primero, construimos las opciones transformadas de 'selectedGroup.options'
    // const transformedOptions = selectedGroup.options.map((option: any) => ({
    //   value: option.name, // 'name' se transforma en 'value'
    //   label: option.visibleName, // 'visibleName' se transforma en 'label'
    //   color: option.color, // Mantenemos el color, suponiendo que lo usarás luego
    // }));

    // Luego, agregamos el objeto que representa la opción "Todos" al inicio del array
    const dividersWithAllOption: any = [
      {
        value: "Todos",
        label: "Todos",
      },
      ...selectedGroup.options
      //...transformedOptions, // Usamos el spread operator para incluir las opciones transformadas
    ];

    // Finalmente, establecemos el estado de 'dividers' con el nuevo array
    setDividers(dividersWithAllOption);

    // Asumiendo que tienes un estado para manejar el loading similar al de 'loadDividers'
    setLoadingDividers(false); // Opcional, dependiendo de si necesitas manejar un estado de carga aquí
  };

  React.useEffect(() => {
    if (selectedGroup) {
      if (selectedGroup.type !== "statuses") {
        loadDividers();
      } else {
        makeStatusDividers();
      }

    }
  }, [selectedGroup]);
  React.useEffect(() => {
    setValue(0);
    setSelectedTabIndex(0);
  }, [forceRefresh]);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);
    tableName = tableName.replace(/-/g, "_");
    const filterName =
      selectedGroup.type == "tags"
        ? `${selectedGroup.name}`
        : `${tableName}.${selectedGroup.name}`;
    let filters: any = [];
    if (dividers[index]["value"] != "Todos") {
      if (selectedGroup.type == "tags") {
        filters = [[filterName, [dividers[index]["value"]]]];
      } else if (selectedGroup.type == "statuses") {
        filters = dividers[index]["filters"];
      } else {
        filters = [[filterName, dividers[index]["value"]]];
      }
    }
    onChange(filters);
  };
  const handleChangeGroup = (item: any) => {
    setSelectedGroup(item);
    setValue(0);
    setSelectedTabIndex(0);
  };
  return (
    <div className="dividers-container">
      <DividerGroupsPopover options={groups} onChange={handleChangeGroup} />
      <Box sx={{ maxWidth: { xs: 320, sm: 480, lg: 1100 } }}>
        {!loadingDividers && (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="folder tabs"
          >
            {dividers.map((item: any, index) => (
              <Tab
                component="a"
                key={index}
                label={
                  <div>
                    <svg
                      height="20"
                      width="20"
                      style={{
                        fill:
                          selectedTabIndex === index && item.color
                            ? item.color
                            : null,
                      }}
                    >
                      <path d="M 20,20 20,0 c 0,0.6925 -0.03481,1.3766 -0.10286,2.0506 -0.06803,0.6739 -0.169363,1.3379 -0.30214,1.99 -0.132776,0.6522 -0.297106,1.2924 -0.491325,1.9195 -0.194206,0.627 -0.418287,1.2406 -0.670634,1.8392 -0.252348,0.5986 -0.53292,1.1821 -0.840094,1.7489 -0.307174,0.5669 -0.640977,1.1172 -0.99969,1.6491 -0.358712,0.5318 -0.742226,1.0452 -1.149163,1.5387 -0.406951,0.4935 -0.837174,0.9672 -1.289061,1.4191 -0.451873,0.4518 -0.925301,0.882 -1.418795,1.289 -0.493508,0.4069 -1.007138,0.7904 -1.53898,1.1492 -0.531842,0.3587 -1.081882,0.6925 -1.648742,0.9997 -0.566874,0.3071 -1.15061,0.5877 -1.749201,0.84 -0.598592,0.2524 -1.21223,0.4765 -1.839251,0.6707 C 5.333042,19.2979 4.692761,19.4622 4.040612,19.595 3.388462,19.7278 2.724526,19.8291 2.05055,19.8972 1.376574,19.9652 0.692502,20 0,20 l 20,0 z" />
                    </svg>
                    <span>{item.label}</span>
                    <svg
                      height="20"
                      width="20"
                      style={{
                        fill:
                          selectedTabIndex === index && item.color
                            ? item.color
                            : null,
                      }}
                    >
                      <path d="M 0,20 0,0 c 0,0.6925 0.03481,1.3766 0.10286,2.0506 0.06803,0.6739 0.169363,1.3379 0.30214,1.99 0.132776,0.6522 0.297106,1.2924 0.491325,1.9195 0.194206,0.627 0.418287,1.2406 0.670634,1.8392 0.252348,0.5986 0.53292,1.1821 0.840094,1.7489 0.307174,0.5669 0.640977,1.1172 0.99969,1.6491 0.358712,0.5318 0.742226,1.0452 1.149163,1.5387 0.406951,0.4935 0.837174,0.9672 1.289061,1.4191 0.451873,0.4518 0.925301,0.882 1.418795,1.289 0.493508,0.4069 1.007138,0.7904 1.53898,1.1492 0.531842,0.3587 1.081882,0.6925 1.648742,0.9997 0.566874,0.3071 1.15061,0.5877 1.749201,0.84 0.598592,0.2524 1.21223,0.4765 1.839251,0.6707 0.627022,0.1942 1.267303,0.3585 1.919452,0.4913 0.65215,0.1328 1.316086,0.2341 1.990062,0.3022 C 18.623426,19.9652 19.307498,20 20,20 L 0,20 Z" />
                    </svg>
                  </div>
                }
                style={{
                  backgroundColor:
                    selectedTabIndex === index && item.color
                      ? item.color
                      : null,
                  zIndex:
                    selectedTabIndex === index
                      ? dividers.length
                      : dividers.length - index,
                }}
                onClick={() => handleSelectTab(index)}
              />
            ))}
          </Tabs>
        )}
      </Box>
    </div>
  );
};

export { Dividers };
