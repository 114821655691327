export const getFields = () => {
  const data: any = [
    {
      type: "text",
      visibleType: "Texto",
      icon: "textField",
      visibleName: "Label",
      name: null,
      description: null,
      size: 6,
      defaultValue: null,
      showIf: null,
      editable: null,
      editableIf: null,
      append: null,
      prepend: null,
      validations: {
        required: false,
        minLength: null,
        maxLength: null,
        pattern: null,
      },
    },
    {
      type: "longText",
      visibleType: "Texto largo",
      icon: "longTextField",
      visibleName: "Label",
      name: null,
      description: null,
      size: 6,
      defaultValue: null,
      showIf: null,
      editable: null,
      editableIf: null,
      append: null,
      prepend: null,
      validations: {
        required: false,
        minLength: null,
        maxLength: null,
        pattern: null,
      },
    },
    {
      type: "integer",
      visibleType: "Número entero",
      icon: "integerField",
      visibleName: "Label",
      name: null,
      description: null,
      size: 6,
      defaultValue: null,
      showIf: null,
      editable: null,
      editableIf: null,
      append: null,
      prepend: null,
      validations: {
        required: false,
        minLength: null,
        maxLength: null,
        pattern: null,
      },
    },
    {
      type: "decimal",
      visibleType: "Número decimal",
      icon: "decimalField",
      visibleName: "Label",
      name: null,
      description: null,
      size: 6,
      defaultValue: null,
      showIf: null,
      editable: null,
      editableIf: null,
      append: null,
      prepend: null,
      validations: {
        required: false,
        minLength: null,
        maxLength: null,
        pattern: null,
      },
    },
    {
      type: "radio",
      visibleType: "Radio",
      visibleName: "Label",
      name: null,
      icon: "radioField",
      description: null,
      options: [],
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },

    {
      type: "checkbox",
      visibleType: "Checkbox",
      visibleName: "Label",
      name: null,
      icon: "checkboxField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "checkboxes",
      visibleType: "Checkboxes",
      visibleName: "Label",
      searchPath: null,
      name: null,
      icon: "checkboxesField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "list",
      visibleType: "Lista",
      visibleName: "Label",
      name: null,
      icon: "listField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      options: [],
      validations: {},
    },
    {
      type: "date",
      visibleType: "Fecha",
      visibleName: "Label",
      name: null,
      icon: "dateField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "datetime",
      visibleType: "Fecha y hora",
      visibleName: "Label",
      name: null,
      icon: "datetimeField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "email",
      visibleType: "Email",
      visibleName: "Label",
      name: null,
      icon: "emailField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "textEditor",
      visibleType: "Editor de texto",
      visibleName: "Label",
      name: null,
      icon: "textEditorField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "color",
      visibleType: "Color",
      visibleName: "Label",
      name: null,
      icon: "colorField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleType: "Seleccionar item",
      visibleName: "Label",
      name: null,
      icon: "selectField",
      description: null,
      primaryKey: null,
      secondaryKey: null,
      size: 6,
      filters: [],
      placeholder: null,
      searchPath: null,
      formId: null,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "multipleSelect",
      visibleType: "Seleccionar multiple items",
      visibleName: "Label",
      name: null,
      icon: "multipleSelectField",
      description: null,
      primaryKey: null,
      secondaryKey: null,
      size: 6,
      filters: [],
      placeholder: null,
      searchPath: null,
      formId: null,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "files",
      visibleType: "Archivos",
      visibleName: "Label",
      name: null,
      icon: "filesField",
      description: null,
      size: 6,
      placeholder: null,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "money",
      visibleType: "Dinero",
      visibleName: "Label",
      name: null,
      icon: "moneyField",
      description: null,
      size: 6,
      placeholder: null,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "calculated",
      visibleType: "Campo calculado",
      visibleName: "Label",
      name: null,
      icon: "calculatedField",
      description: null,
      size: 6,
      showIf: null,
      validations: {},
    },
    {
      type: "separator",
      visibleType: "Separador",
      visibleName: "Label",
      icon: "separatorField",
      description: null,
      size: 6,
      showIf: null,
    },
    {
      type: "image",
      visibleName: "Label",
      visibleType: "Imagen",
      name: null,
      icon: "imageField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "cameraImage",
      visibleName: "Label",
      visibleType: "Imagen de la cámara",
      name: null,
      icon: "camera",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "imageCropper",
      visibleName: "Label",
      visibleType: "Cortador de imágenes",
      name: null,
      icon: "imageCropperField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },

    {
      type: "goals",
      visibleType: "ODS",
      visibleName: "ODS",
      name: null,
      icon: "goalsField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "password",
      visibleType: "Contraseña",
      visibleName: "Contraseña",
      name: null,
      icon: "passwordField",
      description: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "address",
      visibleType: "Domicilio",
      visibleName: "Domicilio",
      name: null,
      icon: "addressField",
      description: null,
      size: 6,
      hasName: null,
      hasZone: null,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "tags",
      visibleType: "Etiquetas",
      visibleName: "Etiquetas",
      name: null,
      icon: "tagsField",
      description: null,
      tagGroup: null,
      //searchPath: null,
      size: 6,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "transaction",
      visibleType: "Transacción",
      visibleName: "Transacción",
      name: null,
      icon: "transactionField",
      description: null,
      transactionType: "sale",
      documentType: "invoice",
      size: 12,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "payment",
      visibleType: "Pago/Cobranza",
      visibleName: "Pago/Cobranza",
      name: null,
      icon: "paymentField",
      description: null,
      transactionType: "sale",
      size: 12,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "simplePayment",
      visibleType: "Pago/Cobranza simple",
      visibleName: "Pago/Cobranza simple",
      name: null,
      icon: "wallet",
      description: null,
      //transactionType: "sale",
      size: 12,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "grid",
      visibleType: "Grilla",
      visibleName: "Grilla",
      name: null,
      icon: "grid",
      description: null,
      cols: [],
      canAddRows: true,
      canRemoveRows: true,
      size: 12,
      showIf: null,
      editable: null,
      editableIf: null,
      validations: {},
      forceMobileView: false,
    },
    // {
    //   type: "whatsApp",
    //   visibleType: "WhatsApp",
    //   visibleName: "WhatsApp",
    //   name: null,
    //   icon: "whatsAppField",
    //   description: null,
    //   size: 6,
    //   showIf: null,
    //   editable: null,
    //   editableIf: null,
    //   validations: {},
    // },
  ];
  return data;
};
