import { useState, useEffect } from "react";

import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import "./CardManagerExpandedCard.scss";
import AxiosInterceptor from "../../../../AxiosInterceptor";

import { CardListItem } from "../CardListItem/CardListItem";
import { LoadingButton } from "@mui/lab";
import { PlusIcon } from "../../../../components/icons/PlusIcon";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { BoardIcon } from "../../../../components/icons/BoardIcon";
import { HeaderSearchbar } from "../../../../components/HeaderSearchbar/HeaderSearchbar";
import urls from "config/urls";

const CardManagerExpandedCard = (props: any) => {
  const { title } = props;

  const [openFormModal, setOpenFormModal] = useState(false);

  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  //const [filters, setFilters] = useState([["is_configurable", "1"]]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);

  const fields = [
    {
      type: "picker",
      visibleName: "Tipo de tarjeta",
      name: "type",
      options: [
        {
          label: "Gráfico de lineas",
          value: "lineChart",
          icon: "lineChartCard",
        },
        {
          label: "Gráfico de barras",
          value: "barChart",
          icon: "barChartCard",
        },
        {
          label: "Gráfico de columnas",
          value: "columnChart",
          icon: "columnChartCard",
        },
        {
          label: "Gráfico de relleno",
          value: "filledShapeChart",
          icon: "filledShapeChartCard",
        },
        {
          label: "Gráfico de lineas multiples",
          value: "multiLineChart",
          icon: "multiLineChartCard",
        },
        {
          label: "Gráfico de columnas apiladas",
          value: "stackedColumnChart",
          icon: "stackedColumnChartCard",
        },
        {
          label: "Gráfico de torta",
          value: "pieChart",
          icon: "pieChartCard",
        },
        {
          label: "Gráfico de area",
          value: "breakdownChart",
          icon: "breakdownChartCard",
        },
        // {
        //   label: "Indicador básico",
        //   value: "basicIndicator",
        //   icon: "basicIndicatorCard",
        // },
        // {
        //   label: "Mapa",
        //   value: "map",
        //   icon: "mapCard",
        // },
        // {
        //   label: "Indicador",
        //   value: "indicator",
        //   icon: "indicatorCard",
        // },
        {
          label: "Tabla de datos",
          value: "dataTable",
          icon: "dataTableCard",
        },
      ],
      description: null,
      size: 12,
      showIf: null,
      editable: null,
      editableIf: null,
      defaultValue: "lineChart",
      validations: {
        maxItems: 1,
      },
    },
    {
      type: "text",
      visibleName: "Título de la tarjeta",
      name: "visible_name",
      description: null,
      size: 12,
      //showIf: "type == 'lineChart'",
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "multipleSelect",
      visibleName: "Tarjetas relacionadas",
      name: "cards",
      description: null,
      size: 12,
      searchPath: "cards",
      primaryKey: "visible_name",
      editable: null,
      editableIf: null,
      validations: {},
    },
  ];
  const loadRecords = async ({ offset }: any) => {
    if (offset) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const res = await AxiosInterceptor.get(
      `${
        urls.server
      }/api/cards?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    const data = await res?.data;
    if (offset > 0) {
      setRecords((prev: any) => [...prev, ...data]);
    } else {
      setRecords(data);
    }

    setLoadingMore(false);
    setLoading(false);
    if (data.length < limit) setHasMore(false);
  };
  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  useEffect(() => {
    //loadRecords();
  }, []);
  useEffect(() => {
    setOffset(0);
    setHasMore(true);
    loadRecords({ offset: 0 });
  }, [searchTerm, filters]);
  useEffect(() => {
    if (offset > 0) loadRecords({ offset });
  }, [offset]);
  return (
    <div className="card-manager-expanded-card-container">
      <div className="expanded-card-header">
        <h2 className="expanded-card-title">{title}</h2>
        <HeaderSearchbar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && records.length == 0 && (
        <div className="no-content">
          <BoardIcon />
          <p>No hay tarjetas</p>
        </div>
      )}
      {!loading && records.length > 0 && (
        <>
          <div className="card-list">
            {records.map((item: any, index: number) => {
              return (
                <CardListItem
                  key={index}
                  id={index}
                  data={item}
                  onDelete={() => {
                    setHasMore(true);
                    setOffset(0);
                    loadRecords({ offset: 0 });
                  }}
                  onChange={() => {
                    setHasMore(true);
                    setOffset(0);
                    loadRecords({ offset: 0 });
                  }}
                />
              );
            })}
          </div>
          {hasMore && (
            <div className="loading-button-wrapper">
              <LoadingButton
                onClick={getMore}
                loading={loadingMore}
                loadingPosition="start"
                startIcon={<PlusIcon />}
              >
                Más registros
              </LoadingButton>
            </div>
          )}
        </>
      )}
      {/* <Fab
        className="fab"
        aria-label="add"
        onClick={() => {
          setOpenFormModal(true);
        }}
      >
        <AddIcon />
      </Fab> */}
      <FormModal
        open={openFormModal}
        setOpen={setOpenFormModal}
        onClose={(res: any) => {
          if (res) {
            setOffset(0);
            setHasMore(true);
            loadRecords({ offset: 0 });
          }
        }}
        onDismiss={() => {}}
        title="Ficha de tarjeta"
        fields={fields}
        tableName="cards"
      />
    </div>
  );
};

export { CardManagerExpandedCard };
