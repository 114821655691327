import React, { useEffect, useState } from "react";

import "./IndicatorCard.scss";
// import { MiniColumnChart } from "../../../../components/miniCharts/MiniColumnChart/MiniColumnChart";
import { AnimatedNumber } from "../../../../components/AnimatedNumber/AnimatedNumber";
import { MiniColumnChart } from "../../../../components/miniCharts/MiniColumnChart/MiniColumnChart";
import { formatPeriod } from "utils/format-period";
import { SimpleIndicatorCardBody } from "../SimpleIndicatorCardBody/SimpleIndicatorCardBody";
import { SingleBreakdownIndicatorCardBody } from "../SingleBreakdownIndicatorCardBody/SingleBreakdownIndicatorCardBody";
const IndicatorCard = (props: any) => {
  const { title, data, config } = props;
  useEffect(() => {}, []);
  return (
    <div className="indicator-card-container">
      <h2 className="card-title">{title}</h2>
      <div className="card-body">
        {data.length > 0 ? (
          <>
            {config.type == "simple" ? (
              <SimpleIndicatorCardBody data={data} config={config} />
            ) : (
              <SingleBreakdownIndicatorCardBody data={data} config={config} />
            )}
          </>
        ) : (
          <div className="no-content">-</div>
        )}
      </div>
    </div>
  );
};

export { IndicatorCard };
