import React, { useState, useEffect } from "react";

const FlowChatExpandedCard = (props: any) => {
  const { title } = props;

  return (
    <div className="flow-chat-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <div className="expanded-card-body"></div>
    </div>
  );
};

export { FlowChatExpandedCard };
