import { Slider } from "@mui/material";
import { useEffect, useState } from "react";

import { CheckboxField } from "../../../../fields/CheckboxField/CheckboxField";
import { InputField } from "../../../../fields/InputField/InputField";

import "./ColConfig.scss";
const ColConfig = ({ config, onChange, open }: any) => {
  const [colConfig, setColConfig] = useState(config);
  const editColConfig = (value: any, key: string) => {
    const configCopy = { ...colConfig };
    configCopy[key] = value;
    setColConfig(configCopy);
  };
  useEffect(() => {
    onChange(colConfig);
  }, [colConfig]);
  return (
    <div className="table-builder-added-col-config">
      {Object.keys(config).map((keyName, i) => (
        <div className="added-col-config-item" key={i}>
          {keyName == "name" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre"}
              onChange={(value: any) => {
                editColConfig(value, keyName);
              }}
            />
          )}
          {keyName == "visibleName" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre a mostar"}
              onChange={(value: any) => {
                editColConfig(value, keyName);
              }}
            />
          )}

          {keyName == "sortable" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Se ordena"}
                description="Ascendente y descendente"
                onChange={(value: any) => {
                  editColConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "showDiff" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Mostar dif. de tiempo"}
                description="Respecto de hoy"
                onChange={(value: any) => {
                  editColConfig(value, keyName);
                }}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export { ColConfig };
