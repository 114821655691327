import React from "react";
import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
import "./SuppliersCard.scss";
const SuppliersCard = (props: any) => {
  const { title, data, config } = props;
  return (
    <div className="suppliers-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.suppliersCount}
          label={"Proveedores"}
        />
      </div>
    </div>
  );
};

export { SuppliersCard };
