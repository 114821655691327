import React, { useEffect } from "react";
import "./Bibliorate.scss";
const Bibliorate = (props: any) => {
  const { color = "#9cb0be", label = "" } = props;

  const s = 1;
  const path1d = `M ${8.0414808 * s},${11.663163 * s} ${61.274009 * s},${
    4.3760834 * s
  } ${61.506204 * s},${21.873893 * s} Z`;
  const path2d = `m ${33.82912 * s},${11.401913 * s} c 0,0 ${38.48095 * s},${
    -3.8865396 * s
  } ${44.830184 * s},${-6.6780796 * s} ${0.244866 * s},${-0.10769 * s} ${
    -0.272862 * s
  },${8.3777796 * s} ${-0.272862 * s},${8.3777796 * s} l ${-44.921054 * s},${
    8.74653 * s
  } z`;
  const path3d = `m ${41.885427 * s},${11.706923 * s} c 0,0 ${38.481072 * s},${
    -3.8865196 * s
  } ${44.830183 * s},${-6.6780696 * s} ${0.244877 * s},${-0.10769 * s} ${
    -0.272736 * s
  },${8.3777596 * s} ${-0.272736 * s},${8.3777596 * s} l ${-44.921177 * s},${
    8.74654 * s
  } z`;
  const path4d = `m ${48.084297 * s},${12.472613 * s} c 0,0 ${31.028856 * s},${
    -1.6163 * s
  } ${43.944576 * s},${-6.6780596 * s} ${0.155384 * s},${-0.0609 * s} ${
    -0.267342 * s
  },${8.3777596 * s} ${-0.267342 * s},${8.3777596 * s} l ${-44.033685 * s},${
    8.74652 * s
  } z`;
  const path5d = `m ${7.678 * s},${11.402983 * s} ${78.984522 * s},${
    0.0886 * s
  } 0,${232.814847 * s} ${-78.8006485 * s},${0.07 * s} z`;
  const circle1r = `${15.060799 * s}`;
  const circle1cy = `${196.10381 * s}`;
  const circle1cx = `${43.424946 * s}`;

  const circle2r = `${12.0656 * s}`;
  const circle2cy = `${196.10381 * s}`;
  const circle2cx = `${43.424946 * s}`;

  const path6d = `m ${14.142074 * s},${25.672213 * s} ${61.813217 * s},0 ${
    -0.833015 * s
  },${136.004177 * s} ${-60.980202 * s},${0.22186 * s} z`;
  //`m ${14.142074*s},${25.672213*s} ${61.813217*s},0 ${-0.833015*s},${136.004177*s} ${-60.980202*s},${0.22186*s} z`;
  const path7d = `m ${86.535371 * s},${11.557963 * s} ${15.502269 * s},${
    -4.6645296 * s
  } ${0.0539 * s},${221.6038966 * s} ${-15.696293 * s},${15.832 * s} z`;

  const circle3r = `${7.4064035 * s}`;
  const circle3cy = `${161.49254 * s}`;
  const circle3cx = `${42.759243 * s}`;

  const rect1x = `${16.627895 * s}`;
  const rect1y = `${33.651966 * s}`;
  const rect1height = `${113.84346 * s}`;
  const rect1width = `${55.895061 * s}`;
  useEffect(() => {
    // Aquí colocamos la lógica que antes estaba en el constructor
  }, []);

  const colorLuminance: any = (hex: any, lum: any) => {
    // La función colorLuminance se mantiene igual.
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;
    // convert to decimal and change luminosity
    let rgb = "#";
    let c;
    let i;

    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  };

  const d2h = (d: any) => {
    // La función d2h se mantiene igual.
  };

  const h2d = (h: any) => {
    // La función h2d se mantiene igual.
  };

  const mix = (color1: any, color2: any, weight: any) => {
    // La función mix se mantiene igual.
  };

  const handleClick = () => {
    console.log("click en el bibliorato");
  };

  return (
    <a className="bibliorate-container" onClick={handleClick}>
      <svg width={110 * s} height={250 * s}>
        <path
          d={path1d}
          style={{
            fill: colorLuminance(color, -0.4),
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: "1px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
        />
        <path
          d={path2d}
          style={{
            fill: "#b3b3b3",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: "1px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
        />

        <path
          d={path3d}
          style={{
            fill: "#cccccc",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: "1px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
        />
        <path
          d={path4d}
          style={{
            fill: "#e6e6e6",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: "1px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
        />
        <path
          d={path5d}
          style={{
            opacity: 1,
            fill: colorLuminance(color, 0),
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <circle
          r={circle1r}
          cy={circle1cy}
          cx={circle1cx}
          style={{
            opacity: 1,
            fill: colorLuminance(color, -0.2),
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <circle
          r={circle2r}
          cy={circle2cy}
          cx={circle2cx}
          style={{
            opacity: 1,
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />

        <path
          d={path6d}
          style={{
            opacity: 1,
            fill: "#fff",
            fillOpacity: 0.4,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <circle
          r={circle3r}
          cy={circle3cy}
          cx={circle3cx}
          style={{
            opacity: 1,
            fill: color,
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <rect
          y={rect1y}
          x={rect1x}
          height={rect1height}
          width={rect1width}
          style={{
            opacity: 1,
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <path
          style={{
            opacity: 1,
            fill: colorLuminance(color, -0.2),
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8,
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
          d={path7d}
        />
      </svg>
      <div className="label">
        <div
          style={{ color: colorLuminance(color, -0.3) }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </div>
    </a>
  );
};

export default Bibliorate;
