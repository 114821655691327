export const getRowActions = () => {
  const data: any = {
    indicatorGroup: [
      {
        name: "view",
        visibleName: "Ver grupo",
      },
      {
        name: "delete",
        visibleName: "Eliminar grupo",
      },
      {
        name: "refreshCard",
        visibleName: "Refrescar tarjeta",
      },
    ],
  };

  return data;
};
