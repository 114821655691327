// ImageCropper.js

import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { blobToBase64, getCroppedImg } from "./cropImage";

const ImageCropper = ({ setCroppedImg, image, aspect = 1 }: any) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  /* onCropComplete() will occur each time the user modifies the cropped area, 
    which isn't ideal. A better implementation would be getting the blob 
    only when the user hits the submit button, but this works for now  */
  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImg(await blobToBase64(croppedImage));

    //getBlob(croppedImage);
  };

  return (
    /* need to have a parent with `position: relative` 
    to prevent cropper taking up whole page */
    <div className="cropper">
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
  );
};

export { ImageCropper };
