import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Fab } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import "./NewWorkflowEntryModal.scss";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { WorkflowIcon } from "../../../../components/icons/WorkflowIcon";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { WorkflowEntryDetailModal } from "../WorkflowEntryDetailModal/WorkflowEntryDetailModal";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const WorkflowItem = ({ data }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [workflowEntryId, setWorkflowEntryId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const createEntry = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.post(
      `${urls.server}/api/workflow-entries`,
      {
        workflow_id: data.id,
        description_template: data.entry_description_template,
      }
    );
    console.log(res);
    setWorkflowEntryId(res?.data?.record_id);
    setProcessing(false);
  };
  useEffect(() => {
    if (workflowEntryId) {
      setModalIsOpen(true);
    }
  }, [workflowEntryId]);
  return (
    <>
      <button
        onClick={() => {
          createEntry();
        }}
        disabled={processing}
      >
        {data.visible_name}
        <div>
          <span>
            <AddIcon />
          </span>
        </div>
      </button>
      <WorkflowEntryDetailModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        onClose={() => { }}
        workflowEntryId={workflowEntryId}
      />
    </>
  );
};
const NewWorkflowEntryModal = ({ open, setOpen, onClose }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(`${urls.server}/api/my/workflows`);
    setRecords(res?.data);
    setLoading(false);
  };
  useEffect(() => {
    if (open) loadData();
  }, [open]);
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="new-workflow-entry-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Nueva entrada de workflow</h2>
          </div>

          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && records.length == 0 && (
            <div className="no-content">
              <WorkflowIcon />
              <p>No hay workflows registrados</p>
            </div>
          )}
          {!loading && records.length > 0 && (
            <>
              <div className="workflow-list">
                {records.map((item: any, index: number) => {
                  return <WorkflowItem key={index} data={item} />;
                })}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export { NewWorkflowEntryModal };
