import { IconButton } from "@mui/material";
import React from "react";
import Icon from "../../../../Icon/Icon";
import { AddIcon } from "../../../../icons/AddIcon";
import "./Field.scss";
const Field = (props: any) => {
  const { icon, visibleType, onAdd } = props;
  return (
    <a className="form-builder-field-container" onClick={onAdd}>
      <div className="field-body">
        <Icon name={icon} />
        <strong>{visibleType}</strong>
      </div>
      <div className="field-footer">
        {/* <IconButton
          aria-label="add"
          
          size="small"
          className="field-btn"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <AddIcon />
        </IconButton> */}
        <div>
          <AddIcon />
        </div>
      </div>
    </a>
  );
};

export { Field };
