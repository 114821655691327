import { IconButton } from '@mui/material';
import { CloseIcon } from 'components/icons/CloseIcon';
import React, { useEffect, useState } from 'react';
import './SearchBar.scss';
import { SearchIcon } from 'components/icons/SearchIcon';

interface SearchBarProps {
    placeholder?: string,
    onChange: (text: string) => void,
    value?: string
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder, onChange, value }) => {
    const [inputValue, setInputValue] = useState(value || '');
    // Función para limpiar el input
    const clearInput = () => {
        setInputValue('');
    };
    useEffect(() => {
        onChange(inputValue)
    }, [inputValue])
    return (
        <div className='search-bar-container'>
            <SearchIcon />
            <input
                type={"text"}
                placeholder={placeholder}
                value={inputValue || ""}
                onChange={(e) => setInputValue(e.target.value)}
                style={{

                }}
            />
            {inputValue && (
                <IconButton

                    color="inherit"
                    onClick={clearInput}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            )}
        </div>
    );
}

export default SearchBar;
