import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import "./CompletedTasksModal.scss";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { TaskItem } from "../TaskItem/TaskItem";
import { LoadingButton } from "@mui/lab";
import { PlusIcon } from "components/icons/PlusIcon";
import { Spinner } from "components/Spinner/Spinner";
import { TasksIcon } from "components/icons/TasksIcon";
import { HeaderSearchbar } from "components/HeaderSearchbar/HeaderSearchbar";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CompletedTasksModal = ({ open, setOpen, onClose, mode }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const loadRecords = async ({ offset }: any) => {
    try {
      if (offset) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }
      let url = "";
      if (mode == "all") {
        url = `${urls.server
          }/api/completed-tasks?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
            JSON.stringify(filters)
          )}`;
      } else {
        url = `${urls.server
          }/api/my/completed-tasks?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
            JSON.stringify(filters)
          )}`;
      }
      const res = await AxiosInterceptor.get(url);
      const data = await res.data;
      if (offset > 0) {
        setRecords((prev: any) => [...prev, ...data]);
      } else {
        setRecords(data);
      }
      setLoadingMore(false);
      setLoading(false);
      if (data.length < limit) setHasMore(false);
    } catch (error) {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setRecords([]);
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [open]);
  useEffect(() => {
    if (open) {
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [searchTerm, sortBy, sortDirection]);
  useEffect(() => {
    if (offset > 0 && open) loadRecords({ offset });
  }, [offset]);

  useEffect(() => {
    if (open) {
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [filters]);
  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="completed-tasks-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>{"Tareas finalizadas"}</h2>
        </div>
        <span className="header-searchbar-wrapper">
          <HeaderSearchbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </span>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        {loading && (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        )}
        {!loading && records.length == 0 && (
          <div className="no-content">
            <TasksIcon />
            <p>No hay tareas finalizadas</p>
          </div>
        )}
        {!loading && records.length > 0 && (
          <>
            <div className="items-wrapper">
              {records.map((item: any, index: number) => (
                <div key={index}>
                  <TaskItem
                    data={item}
                    mode={"completed"}
                    onChange={(res: any) => {
                      if (res) {
                        setHasMore(true);
                        setOffset(0);
                        loadRecords({ offset: 0 });
                        setRefreshOnClose(true);
                      }
                    }}
                  />
                </div>
              ))}
              {hasMore && (
                <div className="loading-button-wrapper">
                  <LoadingButton
                    onClick={getMore}
                    loading={loadingMore}
                    loadingPosition="start"
                    startIcon={<PlusIcon />}
                  >
                    Más registros
                  </LoadingButton>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export { CompletedTasksModal };
