import colors from "config/colors";

export const getDividerGroups = () => {
  const data: any = {
    payment: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "payments/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "payments/year-months-with-records",
      },

    ],
    invoice: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "invoices/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "invoices/year-months-with-records",
      },
      {
        type: "statuses",
        name: "status",
        visibleName: "Estado",
        abbr: "ES",
        options: [
          {
            value: "paid",
            label: "Pagadas",
            color: colors.success,
            filters: [['balance', 0]]
          },
          {
            value: "unpaid",
            label: "Impagas",
            color: colors.danger,
            filters: [['balance', '=', "{{total}}"]]
          },
          {
            value: "partialPayment",
            label: "Con pago parcial",
            color: colors.dark,
            filters: [["balance", ">", 0], ["balance", "<", "{{total}}"]]
          }
        ],
      },
    ],
    // order: [
    //   {
    //     type: "dates",
    //     name: "created_date",
    //     visibleName: "Fecha de creac.",
    //     abbr: "FC",
    //     path: "oders/dates-with-records",
    //   },
    //   {
    //     type: "months",
    //     name: "created_year_month",
    //     visibleName: "Mes de creac.",
    //     abbr: "MC",
    //     path: "oders/year-months-with-records",
    //   },
    // ],
    quotation: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "quotations/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "quotations/year-months-with-records",
      },
    ],
    debitNote: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "debit-notes/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "debit-notes/year-months-with-records",
      },
    ],
    creditNote: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "credit-notes/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "credit-notes/year-months-with-records",
      },
    ],
    interestRate: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "interest-rates/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "interest-rates/year-months-with-records",
      },
    ],
    priceList: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "price-lists/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "price-lists/year-months-with-records",
      },
    ],
  };

  return data;
};
