import { useState, useEffect } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { IconButton } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ImagesField.scss";
import { Spinner } from "components/Spinner/Spinner";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const ImagesField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    name,
    recordId,
    accept = "image/*",
    id,
    maxItems = 1,
    defaultValue,
    folder = "images",
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const [queuedFiles, setQueuedFiles] = useState([] as any);

  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = async (e: any, value: any) => {
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file.name.split(".").pop();
    console.log(extension);
    reader.readAsDataURL(file);
    reader.onload = await function () {
      //  console.log(reader.result);
      const data = {
        content: reader.result,
        extension,
        folder: folder,
      };
      saveImage(value, data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const removeImg = (index: number) => {
    const valueCopy = [...value];
    valueCopy.splice(index, 1);
    setValue(valueCopy);
  };

  const saveImage = async (value: any, { content, extension, folder }: any) => {
    const queuedFilesCopy = [...queuedFiles];
    queuedFilesCopy.push("");
    setQueuedFiles(queuedFilesCopy);

    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/images`, {
        base64_image: content,
        extension,
        folder,
      });
      const resData = await res.data;
      //setValue(resData?.path);
      const valueCopy = value !== null ? [...value] : [];
      valueCopy.push(resData?.path);
      setValue(valueCopy);
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);
    } catch (error: any) {
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);
    }
  };
  const isDisabled = (current: Array<string>, prev: Array<string>) => {
    return (
      current?.length === prev?.length &&
      current.every((value, index) => value === prev[index])
    );
  };
  return (
    <div className="images-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !isEditEnabled ? (
          <div className="readonly-content">
            {value ? (
              value.map((item: any, index: number) => {
                return (
                  <div key={index} className="img-preview-wrapper">
                    <img src={`${urls.server}${item}`} />
                  </div>
                );
              })
            ) : (
              <div className="no-value">
                <InsertPhotoIcon />
                <span>Sin imagenes</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {queuedFiles.length === 0 && !value && (
              <div className="add-image-btn">
                <input
                  id={id}
                  accept={accept}
                  type="file"
                  onChange={(e) => handleChange(e, value)}
                />
                <div>
                  <div>
                    <InsertPhotoIcon />
                    <span>Seleccionar imagen</span>
                  </div>
                  <label htmlFor={id}></label>
                </div>
              </div>
            )}
            {queuedFiles.length === 0 && value?.length < maxItems && (
              <div className="add-image-btn">
                <input
                  id={id}
                  accept={accept}
                  type="file"
                  onChange={(e) => handleChange(e, value)}
                />
                <div>
                  <div>
                    <InsertPhotoIcon />
                    <span>Seleccionar imagen</span>
                  </div>
                  <label htmlFor={id}></label>
                </div>
              </div>
            )}
            {queuedFiles.length > 0 && (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            )}

            {value?.length > 0 && (
              <>
                {value.map((item: any, index: number) => {
                  return (
                    <div key={index} className="img-preview-wrapper">
                      <img src={`${urls.server}${item}`} />
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeImg(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export { ImagesField };
