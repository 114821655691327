import React, { useEffect, useState } from "react";
import "./ScrapingExpandedCard.scss";
import { ArrowWrapperIcon } from "components/icons/ArrowWrapperIcon";
import { ArrowIcon } from "components/icons/ArrowIcon";
import { ScrapingControlPanelModal } from "../ScrapingControlPanelModal/ScrapingControlPanelModal";
const ScrapingExpandedCard = (props: any) => {
  const { title, data } = props;
  const [controlPanelModalIsOpen, setControlPanelModalIsOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const sources = [
    {
      label: "Precios claros - productos",
      path: "scraping/precios-claros-products?store_code=9-2-977",
    },
    {
      label: "Precios claros - etiquetas",
      path: "scraping/precios-claros-product-tags",
    },
    {
      label: "Precios claros - tiendas",
      path: "scraping/precios-claros-stores?offset=0&limit=30",
    },
  ];
  useEffect(() => {
    if (selectedSource) {
      setControlPanelModalIsOpen(true);
    }
  }, [selectedSource]);

  return (
    <>
      <div className="scraping-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {sources.map((item: any, index: number) => {
            return (
              <a
                key={index}
                className="card-btn lg"
                onClick={() => {
                  //setControlPanelModalIsOpen(true);
                  setSelectedSource(item);
                }}
              >
                <strong>{item.label}</strong>
                <div>
                  <ArrowWrapperIcon />
                  <ArrowIcon />
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <ScrapingControlPanelModal
        open={controlPanelModalIsOpen}
        setOpen={setControlPanelModalIsOpen}
        onClose={() => {
          setSelectedSource(null);
        }}
        source={selectedSource}
      />
    </>
  );
};

export { ScrapingExpandedCard };
