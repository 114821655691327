import React, { Fragment, useEffect, useState } from "react";
import { Drawer, Fab, IconButton } from "@mui/material";
import { CloseIcon } from "components/icons/CloseIcon";
import "./FiltersDrawer.scss";
import { TextFilter } from "components/filters/TextFilter/TextFilter";
import { CheckIcon } from "components/icons/CheckIcon";
import { DateFilter } from "components/filters/DateFilter/DateFilter";
import { MultiSelectFilter } from "components/filters/MultiSelectFilter/MultiSelectFilter";
import { TagsFilter } from "components/filters/TagsFilter/TagsFilter";
import { NumberFilter } from "components/filters/NumberFilter/NumberFilter";
import { StatusesFilter } from "components/filters/StatusesFilter/StatusesFilter";
import { BooleanFilter } from "components/filters/BooleanFilter/BooleanFilter";

const FiltersDrawer = ({ isOpen, onClose, filters = [] }: any) => {
  const [filtersCopy, setFiltersCopy] = useState(
    JSON.parse(JSON.stringify(filters))
  );

  const [refreshOnClose, setRefreshOnClose] = useState(false);

  const handleFilterChange = (index: number, newValue: any) => {
    // Actualizar el filtro en el estado
    setFiltersCopy((prevFilters: any) => {
      const updatedFilters = [...prevFilters];

      // Determina si el filtro debe estar seleccionado
      let isSelected = false;

      // Para el filtro booleano, revisa específicamente el valor de 'data'
      if (newValue?.data === "yes" || newValue?.data === "no") {
        isSelected = true;
      } else if (
        (typeof newValue?.data === "string" && newValue?.data !== "all") ||
        typeof newValue?.data === "number"
      ) {
        isSelected = Boolean(newValue.data);
      } else if (
        typeof newValue?.data === "object" &&
        newValue?.data !== null
      ) {
        if (newValue.data.from && newValue.data.to) {
          isSelected = true;
        } else if (Array.isArray(newValue.data) && newValue.data.length > 0) {
          isSelected = true;
        }
      }

      updatedFilters[index].value = newValue;
      updatedFilters[index].selected = isSelected;

      return updatedFilters;
    });

    setRefreshOnClose(true); // indica que hay cambios
  };

  useEffect(() => {
    if (!isOpen) {
      setFiltersCopy(JSON.parse(JSON.stringify(filters)));
      setRefreshOnClose(false);
    } else {
      setTimeout(() => setRefreshOnClose(false), 100);
    }
  }, [isOpen]);
  useEffect(() => {
    setFiltersCopy(JSON.parse(JSON.stringify(filters)));
  }, [filters]);
  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      style={{ zIndex: 99999999 }}
      className="filters-drawer-container"
      PaperProps={{
        sx: { width: "100%", maxWidth: "300px" },
      }}
    >
      <div className="drawer-header">
        <div>
          <h3>Filtros</h3>
        </div>

        <IconButton
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-body">
        {filtersCopy.map((item: any, index: number) => {
          return (
            <Fragment key={index}>
              {(() => {
                switch (item.type) {
                  case "text":
                    return (
                      <TextFilter
                        defaultValue={item?.value || { condition: "equals" }}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                      />
                    );
                  case "date":
                    return (
                      <DateFilter
                        defaultValue={item?.value || { condition: "equals" }}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                      />
                    );
                  case "number":
                    return (
                      <NumberFilter
                        defaultValue={item?.value || { condition: "equals" }}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                      />
                    );
                  case "multiSelect":
                    return (
                      <MultiSelectFilter
                        defaultValue={item?.value}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                        searchPath={item?.searchPath}
                        primaryKey={item?.primaryKey}
                      />
                    );
                  case "tags":
                    return (
                      <TagsFilter
                        defaultValue={item?.value}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                        groupId={item?.groupId}
                      />
                    );
                  case "statuses":
                    return (
                      <StatusesFilter
                        defaultValue={item?.value || { data: [] }}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                        options={item?.options}
                      />
                    );
                  case "boolean":
                    return (
                      <BooleanFilter
                        defaultValue={item?.value || { data: "all" }}
                        label={item?.label}
                        onChange={(newValue: any) =>
                          handleFilterChange(index, newValue)
                        }
                      />
                    );
                }
              })()}
            </Fragment>
          );
        })}
        <Fab
          className="fab-btn"
          color="primary"
          aria-label="send filters"
          onClick={() => {
            onClose(filtersCopy);
          }}
          disabled={!refreshOnClose}
        >
          <CheckIcon />
        </Fab>
      </div>
    </Drawer>
  );
};

export { FiltersDrawer };
