export const getDividerGroups = () => {
  const data: any = [
    {
      type: "dates",
      visibleType: "Etiquetas",
      name: "created_date",
      visibleName: "Fecha de creac.",
      icon: "dates",
      abbr: "FC",
      path: "",
    },
    {
      type: "months",
      visibleType: "Etiquetas",
      name: "created_month",
      visibleName: "Mes de creac.",
      icon: "months",
      abbr: "MC",
      path: "",
    },
    {
      type: "tags",
      visibleType: "Etiquetas",
      name: "tags",
      visibleName: "Etiquetas",
      icon: "tags",
      abbr: "Etiq.",
      path: "",
    },
    {
      type: "textValues",
      visibleType: "Etiquetas",
      name: null,
      visibleName: null,
      icon: "text",
      abbr: null,
      path: "",
    },
    {
      type: "statuses",
      visibleType: "Etiquetas",
      name: "status",
      visibleName: "Estados",
      icon: "statuses",
      abbr: "Est.",
      path: "",
      statuses: [
        // {
        //   value: "Realizada",
        //   pluralValue: "Realizadas",
        //   color: "#666",
        // },
        // {
        //   value: "Pendiente",
        //   pluralValue: "Pendientes",
        //   color: "#666",
        // },
      ],
    },
  ];
  return data;
};
// time_ago_in_words
// time_ahead_in_words
// time_diff_in_words
