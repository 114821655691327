import React, { useState, useEffect } from "react";

import "./MultiLineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const MultiLineChart = (props: any) => {
  //const { datasets, height = 250, periods } = props;
  const { data, height = 250 } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        //text: "Chart.js Line Chart",
      },
    },
  };
  // const chartData = {
  //   labels: periods,
  //   datasets: datasets,
  // };
  function adaptSingleBreakdownData(indicatorData: any) {
    // Primero, creamos un mapa para rastrear los datos para cada etiqueta
    const datasetsMap = new Map();

    // Extraemos las etiquetas de los períodos
    const labels = indicatorData.map((item: any) => item.visible_period);

    // Llenamos el mapa con los datos para cada etiqueta
    indicatorData.forEach((period: any) => {
      period.data.forEach((item: any) => {
        if (!datasetsMap.has(item.label)) {
          datasetsMap.set(item.label, {
            label: item.label,
            data: new Array(indicatorData.length).fill(0),
            borderColor: item.color,
            backgroundColor: item.color,
          });
        }
        const dataset = datasetsMap.get(item.label);
        const periodIndex = labels.indexOf(period.visible_period);
        dataset.data[periodIndex] = item.value;
      });
    });

    // Convertimos el mapa en un array para los datasets
    const datasets = Array.from(datasetsMap.values());

    return {
      labels: labels,
      datasets: datasets,
    };
  }

  const chartData = adaptSingleBreakdownData(data);
  // Ahora puedes usar 'chartData' para alimentar tu gráfico en Chart.js

  return (
    <div
      className="multi-line-chart-container"
      style={{ height: height + "px" }}
    >
      <Line options={options} data={chartData} />
    </div>
  );
};

export { MultiLineChart };
