import colors from "config/colors";

export const getFilters = () => {
  const data: any = {
    invoice: [
      // {
      //   type: "text",
      //   fieldName: "name",
      //   label: "Nombre",
      // },
      {
        type: "date",
        fieldName: "created_at",
        label: "Fecha de creación",
      },
      {
        type: "number",
        fieldName: "total",
        label: "Total",
      },
      {
        type: "number",
        fieldName: "total",
        label: "Total",
      },
      {
        type: "multiSelect",
        fieldName: "party_id",
        label: "Clientes",
        searchPath: "customers",
        primaryKey: "name",
      },
      {
        type: "statuses",
        fieldName: "status",
        label: "Estados",
        options: [
          {
            name: "paid",
            visibleName: "Pagada",
            color: colors.success,
            icon: 'check',
            filters: [['balance', 0]]
          },
          {
            name: "unpaid",
            visibleName: "Impaga",
            color: colors.danger,
            icon: 'alert',
            filters: [['balance', '=', "{{total}}"]]
          },
          {
            name: "partialPayment",
            visibleName: "Pago parcial",
            color: colors.dark,
            icon: 'alert',
            filters: [["balance", ">", 0], ["balance", "<", "{{total}}"]]
          }
        ]
        //options: ["Pagada", "Impaga"],
      },
      // {
      //   type: "boolean",
      //   fieldName: "is_sellable",
      //   label: "¿Se vende?",
      // },
    ],

  };

  return data;
};
