import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./ChecklistField.scss";

import { ReadonlyChecklistField } from "./ReadonlyChecklistField/ReadonlyChecklistField";
import { EditableChecklistField } from "./EditableChecklistField/EditableChecklistField";
const ChecklistField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    recordId,
    readonly,
    mode
  }: any = props;
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [data, setData] = useState(defaultValue as any);


  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="checklist-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <FieldEditControls
            value={data}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setData(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setData(originalValue);
              }
            }}
          />
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            ((recordId || editPath) && editable && !isEditEnabled) ||
            ((recordId || editPath) && !editable) ? (
            <ReadonlyChecklistField data={defaultValue} />
          ) : (
            <EditableChecklistField
              data={defaultValue}
              onChange={(res: any) => {
                console.log(res);
                setData(res);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ChecklistField };
