import React, { useState, useEffect } from "react";
import "./ProductVariantsField.scss";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { EditableProductVariantsField } from "./EditableProductVariantsField/EditableProductVariantsField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { ReadonlyProductVariantsField } from "./ReadonlyProductVariantsField/ReadonlyProductVariantsField";
const ProductVariantsField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    onChange,
    readonly,
    recordId,
    editPath,
    formData,
    name
  } = props;
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [attributesWithValues, setAttributesWithValues] = useState([]);
  const [loading, setLoading] = useState(false);


  const loadAttributesWithValues = async () => {
    setLoading(true);
    try {
      // Guardar los datos en la base de datos del tenant
      const url = `${urls.server}/api/product-attributes/values`;
      const res = await AxiosInterceptor.get(url);
      const resData = await res.data;
      setAttributesWithValues(resData);
      setLoading(false);
    } catch (error: any) { }
  };
  useEffect(() => {
    console.log(defaultValue);
    loadAttributesWithValues();
  }, []);

  return (
    <div className="product-variants-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div>

      <div className="field-body">
        {readonly ||
          ((recordId || editPath) && editable && !isEditEnabled) ||
          ((recordId || editPath) && !editable) ? (
          <div className="readonly-content">
            <ReadonlyProductVariantsField data={value} formData={formData} />
          </div>
        ) : (
          <div className="editable-content">
            <EditableProductVariantsField
              attributesWithValues={attributesWithValues}
              setAttributesWithValues={setAttributesWithValues}
              data={value}
              setData={setValue}
              onChange={onChange}
              formData={formData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { ProductVariantsField };
