import { useState, useEffect } from "react";
import "./MiniLineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import colors from "config/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const MiniLineChart = (props: any) => {
  const { data, height = 73, onSelectPoint, color = "#666", decimalPrecision = 2 } = props;
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  } as any);
  const [currentIndex, setCurrentIndex] = useState(data.length - 1);
  const options = {
    responsive: true,
    scaleShowLabels: false,
    plugins: {
      legend: {
        display: false,
      },
      // legend: {
      //   position: "top" as const,
      // },
      title: {
        display: false,
        //text: "Chart.js Line Chart",
      },
      // scales: {
      //   xAxis: {
      //     // The axis for this scale is determined from the first letter of the id as `'x'`
      //     // It is recommended to specify `position` and / or `axis` explicitly.
      //     display: false,
      //   },
      // },
    },
    pointRadius: 4,
    pointHoverRadius: 4,
    pointBorderWidth: 0,

    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },

    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const firstElementIndex = elements[0].index;
        console.log(firstElementIndex);
        onSelectPoint(data[firstElementIndex]);
        setCurrentIndex(firstElementIndex);
        // setChartData((prev: any) => {
        //   const dataCopy = { ...prev };
        //   dataCopy.datasets[0].pointBackgroundColor =
        //     makeDotColors(firstElementIndex);
        //   return dataCopy;
        // });
      }
    },
  };
  function adaptSimpleIndicatorData(indicatorData: any) {
    const labels = indicatorData.map((item: any) => item.visible_period);
    const data = indicatorData.map((item: any) => item.value.toFixed(decimalPrecision));

    return {
      labels: labels,
      datasets: [
        {
          //label: "Indicador Simple",
          data: data,
          fill: false,

          tension: 0.3,
          borderColor: colors.medium,

          //color: colors.medium,
          //backgroundColor: colors.medium,
        },
      ],
    };
  }
  const makeDotColors = (currentIndex: number) => {
    const colorArr: any = [];
    data.forEach((item: any, index: number) => {
      colorArr.push(index == currentIndex ? color : colors?.medium);
    });
    return colorArr;
  };
  useEffect(() => {
    // const chartData: any = adaptSimpleIndicatorData(data);
    // chartData.datasets[0].pointBackgroundColor = makeDotColors(data.length - 1);
    // setChartData(chartData);
  }, []);
  useEffect(() => {
    const chartData: any = adaptSimpleIndicatorData(data);
    chartData.datasets[0].pointBackgroundColor = makeDotColors(currentIndex);
    setChartData(chartData);
  }, [currentIndex]);
  return (
    <div
      className="mini-line-chart-container"
      style={{
        height: height + "px",
      }}
    //style={{ height: height + "px" }}
    >
      <Line options={options} data={chartData} />
    </div>
  );
};

export { MiniLineChart };
