import React, { useState, useEffect } from "react";
import { Drawer, IconButton } from "@mui/material";
import { CloseIcon } from "components/icons/CloseIcon";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import "./CommentsDrawer.scss";
import { Spinner } from "components/Spinner/Spinner";
import { CommentIcon } from "components/icons/CommentIcon";
import { CheckIcon } from "components/icons/CheckIcon";
import { CommentItem } from "./CommentItem";
const CommentsDrawer = ({
  open,
  setOpen,
  stepName,
  stepEntryId,
  onClose,
}: any) => {
  const bodyRef: any = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [comments, setComments] = useState([]);
  const [content, setContent] = useState("" as string);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/workflow-step-entries/${stepEntryId}/comments`
    );
    setComments(res.data);
    setLoading(false);
    setTimeout(scrollToBottom, 100);
  };
  const saveData = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.post(
      `${urls.server}/api/workflow-step-entries/${stepEntryId}/comments`,
      {
        content: content,
      }
    );
    loadData();
    setContent("");
    setProcessing(false);
    setRefreshOnClose(true);
  };
  useEffect(() => {
    if (open) loadData();
  }, [open]);
  const scrollToBottom = () => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  };
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => {
        setOpen(false);
        if (refreshOnClose) {
          onClose(true);
        } else {
          onClose();
        }
      }}
      style={{ zIndex: 99999999 }}
      className="comments-drawer-container"
      PaperProps={{
        sx: { width: "100%", maxWidth: "300px" },
      }}
    >
      <div className="drawer-header">
        <div>
          <h3>Comentarios</h3>
          <span>{stepName}</span>
        </div>

        <IconButton
          onClick={() => {
            setOpen(false);
            if (refreshOnClose) {
              onClose(true);
            } else {
              onClose();
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-body" ref={bodyRef}>
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        ) : (
          <>
            {comments.length === 0 ? (
              <div className="no-content">
                <CommentIcon />
                <p>No hay comentarios</p>
              </div>
            ) : (
              <>
                {comments.map((item: any, index: number) => {
                  return <CommentItem key={index} data={item} />;
                })}
              </>
            )}
          </>
        )}
      </div>
      <div className="drawer-footer">
        <textarea
          value={content || ""}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        //placeholder="Escribir comentario"
        ></textarea>
        <IconButton
          onClick={() => {
            saveData();
          }}
          color="primary"
          disabled={processing || content == ""}
        >
          <CheckIcon />
        </IconButton>
      </div>
    </Drawer>
  );
};

export { CommentsDrawer };
