import React, { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";

import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";
import Grid from "@mui/material/Grid";
import "./ProductsExpandedCard.scss";
// import { InventoryWrapperIcon } from "../../../../components/icons/InventoryWrapperIcon";
// import { InventoryIcon } from "../../../../components/icons/InventoryIcon";
import { SerialNumberWrapperIcon } from "../../../../components/icons/SerialNumberWrapperIcon";
import { SerialNumberIcon } from "../../../../components/icons/SerialNumberIcon";
// import { ProductSerialNumbersModal } from "../ProductSerialNumbersModal/ProductSerialNumbersModal";
// import { CurrentInventoriesModal } from "../CurrentInventoriesModal/CurrentInventoriesModal";
import { AttributesWrapperIcon } from "../../../../components/icons/AttributesWrapperIcon";
import { AttributesIcon } from "../../../../components/icons/AttributesIcon";
import { ProductAttributesModal } from "../ProductAttributesModal/ProductAttributesModal";
import { getRowActions } from "../../utils/get-row-actions";
import { TagsWrapperIcon } from "../../../../components/icons/TagsWrapperIcon";
import { TagsIcon } from "../../../../components/icons/TagsIcon";
import { TagsModal } from "../../../../components/modals/TagsModal/TagsModal";
import { getFilters } from "cards/products/utils/get-filters";
import { DataImporterModal } from "components/modals/DataImporterModal/DataImporterModal";
import { UploadWrapperIcon } from "components/icons/UploadWrapperIcon";
import { UploadIcon } from "components/icons/UploadIcon";
// import { ExtrasWrapperIcon } from "components/icons/ExtrasWrapperIcon";
// import { ExtrasIcon } from "components/icons/ExtrasIcon";
import { RefreshWrapperIcon } from "components/icons/RefreshWrapperIcon";
import { RefreshIcon } from "components/icons/RefreshIcon";
import { ReportModal } from "components/modals/ReportModal/ReportModal";
import { ReportWrapperIcon } from "components/icons/ReportWrapperIcon";
import { ReportIcon } from "components/icons/ReportIcon";

const ProductsExpandedCard = (props: any) => {
  const { title, data, config } = props;
  // const [extraRecordsModalIsOpen, setExtraRecordsModalIsOpen] = useState(false);
  const [serialNumberRecordsModalIsOpen, setSerialNumberRecordsModalIsOpen] =
    useState(false);
  const [productAttributesModalIsOpen, setProductAttributesModalIsOpen] =
    useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const [productRecordsModalIsOpen, setProductRecordsModalIsOpen] =
    useState(false);
  const [brandRecordsModalIsOpen, setBrandRecordsModalIsOpen] = useState(false);

  const [dataImporterModalIsOpen, setDataImporterModalIsOpen] = useState(false);
  const [allProductsReportModalIsOpen, setAllProductsReportModalIsOpen] =
    useState(false);
  const productFields = getFields().product;
  const brandFields = getFields().brand;

  const serialNumberFields = getFields().serialNumber;
  //const extraFields = getFields().extra;

  const productCols = getCols().product;
  const brandCols = getCols().brand;

  const serialNumberCols = getCols().serialNumber;
  //const extraCols = getCols().extra;

  const productDividerGroups = getDividerGroups().product;
  const brandDividerGroups = getDividerGroups().brand;

  const serialNumberDividerGroups = getDividerGroups().serialNumber;
  //const extraDividerGroups = getDividerGroups().extra;

  const productRowActions = getRowActions().product;
  const brandRowActions = getRowActions().brand;

  const serialNumberRowActions = getRowActions().serialNumber;
  //const extraRowActions = getRowActions().extra;

  const productFilters = getFilters().product;

  return (
    <>
      <div className="products-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setProductRecordsModalIsOpen(true);
            }}
          >
            <strong>Productos</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setTagsModalIsOpen(true);
            }}
          >
            <strong>Etiquetas de productos</strong>
            <div>
              <TagsWrapperIcon />
              <TagsIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setBrandRecordsModalIsOpen(true);
            }}
          >
            <strong>Marcas</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          {/* <a
                className="card-btn lg"
                onClick={() => {
                  setCurrentInventoriesModalIsOpen(true);
                }}
              >
                <strong>Inventario</strong>
                <div>
                  <InventoryWrapperIcon />
                  <InventoryIcon />
                </div>
              </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setProductAttributesModalIsOpen(true);
            }}
          >
            <strong>Atributos</strong>
            <div>
              <AttributesWrapperIcon />
              <AttributesIcon />
            </div>
          </a>
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setExtraRecordsModalIsOpen(true);
            }}
          >
            <strong>Extras</strong>
            <div>
              <ExtrasWrapperIcon />
              <ExtrasIcon />
            </div>
          </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setSerialNumberRecordsModalIsOpen(true);
            }}
          >
            <strong>Num. de series</strong>
            <div>
              <SerialNumberWrapperIcon />
              <SerialNumberIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDataImporterModalIsOpen(true);
            }}
          >
            <strong>Importar</strong>
            <div>
              <UploadWrapperIcon />
              <UploadIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setAllProductsReportModalIsOpen(true);
            }}
          >
            <strong>Productos</strong>
            <span>(Reporte)</span>
            <div>
              <ReportWrapperIcon />
              <ReportIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros Eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>

      <RecordsModal
        open={productRecordsModalIsOpen}
        setOpen={setProductRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Productos"}
        tableName={"products"}
        cols={productCols}
        fields={productFields}
        filters={productFilters}
        rowActions={productRowActions}
        dividerGroups={productDividerGroups}
        formTitle={"Ficha de producto"}
      />
      <RecordsModal
        open={brandRecordsModalIsOpen}
        setOpen={setBrandRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Marcas"}
        tableName={"brands"}
        cols={brandCols}
        fields={brandFields}
        rowActions={brandRowActions}
        dividerGroups={brandDividerGroups}
        formTitle={"Form. de marca"}
      />

      <RecordsModal
        open={serialNumberRecordsModalIsOpen}
        setOpen={setSerialNumberRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Num. de series"}
        tableName={"product-serial-numbers"}
        cols={serialNumberCols}
        fields={serialNumberFields}
        rowActions={serialNumberRowActions}
        dividerGroups={serialNumberDividerGroups}
        formTitle={"Form. de num. de serie"}
      />

      {/* <RecordsModal
        open={extraRecordsModalIsOpen}
        setOpen={setExtraRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Extras"}
        tableName={"product-extras"}
        cols={extraCols}
        fields={extraFields}
        rowActions={extraRowActions}
        dividerGroups={extraDividerGroups}
        formTitle={"Form. de extra"}
      /> */}
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />

      {/* <ProductSerialNumbersModal
        open={productSerialNumbersModalIsOpen}
        setOpen={setProductSerialNumbersModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      /> */}
      <ProductAttributesModal
        open={productAttributesModalIsOpen}
        setOpen={setProductAttributesModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
      <TagsModal
        open={tagsModalIsOpen}
        setOpen={setTagsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        groupId={1}
        groupName={"Productos"}
      />
      <DataImporterModal
        open={dataImporterModalIsOpen}
        setOpen={setDataImporterModalIsOpen}
        onClose={(res: any) => { }}
        //cols={config?.importCols}
        configurationPath={`configurations/name/productImportCols`}
        savePath={"products/import"}
        title={"Importar productos"}
      />
      <ReportModal
        isOpen={allProductsReportModalIsOpen}
        title={"Reporte de productos"}
        listPath={"products/report"}
        reportName={"allProducts"}
        onClose={() => {
          setAllProductsReportModalIsOpen(false);
        }}
      />
    </>
  );
};

export { ProductsExpandedCard };
