import { transformData } from "cards/indicator/utils/transform-data";
import { BarChart } from "components/charts/BarChart/BarChart";
import { ColumnChart } from "components/charts/ColumnChart/ColumnChart";
import { GroupedColumnChart } from "components/charts/GroupedColumnChart/GroupedColumnChart";
import { LineChart } from "components/charts/LineChart/LineChart";
import { MultiLineChart } from "components/charts/MultiLineChart/MultiLineChart";
import { PieChart } from "components/charts/PieChart/PieChart";
import React from "react";

const ChartWrapper = ({ data, config }: any) => {
  let transformedData: any = {};
  if (config.chartType == "multiLineChart") {
    transformedData = data.length > 10 ? transformData(data?.slice(-10)) : data;
  }

  return (
    <div className="chart-wrapper-container">
      {(() => {
        switch (config.chartType) {
          case "lineChart":
            return <LineChart data={data} color={config?.color} decimalPrecision={config?.decimalPrecision} />;
          case "multiLineChart":
            return (
              <MultiLineChart data={data} height={130} decimalPrecision={config?.decimalPrecision} />
            );
          case "pieChart":
            return (
              <PieChart data={data} decimalPrecision={config?.decimalPrecision} />
            );
          case "columnChart":
            return <ColumnChart data={data} color={config?.color} decimalPrecision={config?.decimalPrecision} />;
          case "groupedColumnChart":
            return <GroupedColumnChart data={data} decimalPrecision={config?.decimalPrecision} />;
        }
      })()}
    </div>
  );
};

export { ChartWrapper };
