import { useState, useEffect } from "react";

import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import { Spinner } from "../../../../components/Spinner/Spinner";
import "./TaskItems.scss";
import { TasksIcon } from "../../../../components/icons/TasksIcon";
import LoadingButton from "@mui/lab/LoadingButton";
import { PlusIcon } from "../../../../components/icons/PlusIcon";
import { TaskItem } from "../TaskItem/TaskItem";

import { useAuth } from "../../../../contexts/AuthContext";
import { useDebounce } from "hooks/useDebounce";
const TaskItems = ({
  filters,
  searchTerm,
  refreshData,
  setRefreshData,
  mode,
}: any) => {
  const [records, setRecords] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [hasMore, setHasMore] = useState(true);
  //const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    console.log("loadRecords mode", { mode });
    loadRecords({ offset: 0 });
  }, [mode]);
  useEffect(() => {
    setRefreshData(false);
  }, [records]);
  useEffect(() => {
    if (debouncedValue) {
      loadRecords({ offset: 0 });
    }
  }, [debouncedValue]);
  useEffect(() => {
    if (offset > 0) {
      loadRecords({ offset });
      console.log("loadRecords offset");
    }
  }, [offset]);
  useEffect(() => {
    setHasMore(true);
    setOffset(0);
    loadRecords({ offset: 0 });
  }, [filters]);
  useEffect(() => {
    console.log("loadRecords refreshData", { refreshData });
    if (refreshData) {
      console.log("loadRecords refreshData");
      setHasMore(true);
      setOffset(0);
      loadRecords({ offset: 0 });
    }
  }, [refreshData]);
  /*   useEffect(() => {
    setHasMore(true);
    setOffset(0);
    loadRecords({ offset: 0 });
    console.log('loadRecords filters',{filters})
  }, [filters]); */
  const loadRecords = async ({ offset }: any) => {
    //setHasMore(true);
    try {
      if (offset) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }
      let url = "";
      if (mode == "all") {
        url = `${urls.server
          }/api/tasks?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
            JSON.stringify(filters)
          )}`;
      } else {
        url = `${urls.server
          }/api/my/tasks?offset=${offset}&limit=${limit}&sort_by=${sortBy}&sort_direction=${sortDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
            JSON.stringify(filters)
          )}`;
      }
      const res = await AxiosInterceptor.get(url);
      const data = await res?.data;
      if (data) {
        if (offset > 0) {
          setRecords((prev: any) => [...prev, ...data]);
        } else {
          setRecords(data);
        }

        if (data.length < limit) setHasMore(false);
      }
      setLoadingMore(false);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  // const handleRecordClick = (record: any) => {
  //   console.log(record);
  // };
  const getMore = () => {
    setOffset((prev: number) => prev + limit);
  };
  return (
    <div className="task-items-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && records.length == 0 && (
        <div className="no-content">
          <TasksIcon />
          <p>No hay tareas registradas</p>
        </div>
      )}
      {!loading && records.length > 0 && (
        <>
          <div className="items-wrapper">
            {records.map((item: any, index: number) => (
              <div key={index}>
                <TaskItem
                  data={item}
                  // refreshData={refreshData}
                  setRefreshData={setRefreshData}
                  mode={mode}
                  onChange={(res: any) => {
                    if (res) {
                      setRefreshData(true);
                    }
                  }}
                />
              </div>
            ))}
            {hasMore && (
              <div className="loading-button-wrapper">
                <LoadingButton
                  onClick={getMore}
                  loading={loadingMore}
                  loadingPosition="start"
                  startIcon={<PlusIcon />}
                >
                  Más registros
                </LoadingButton>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { TaskItems };
