import { useEffect, useState } from "react";
import { InputField } from "../../../../fields/InputField/InputField";

import "./GroupConfig.scss";
const GroupConfig = ({ config, onChange, open }: any) => {
  const [groupConfig, setGroupConfig] = useState(config);
  const editGroupConfig = (value: any, key: string) => {
    const configCopy = { ...groupConfig };
    configCopy[key] = value;
    setGroupConfig(configCopy);
  };
  useEffect(() => {
    onChange(groupConfig);
  }, [groupConfig]);
  return (
    <div className="added-group-config">
      {Object.keys(config).map((keyName, i) => (
        <div className="added-group-config-item" key={i}>
          {keyName == "name" && (
            <InputField
              defaultValue={config[keyName]}
              description="Nombre del campo en la base de datos / attr."
              label={"Nombre"}
              onChange={(value: any) => {
                editGroupConfig(value, keyName);
              }}
            />
          )}
          {keyName == "visibleName" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre a mostar"}
              onChange={(value: any) => {
                editGroupConfig(value, keyName);
              }}
            />
          )}
          {keyName == "abbr" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Abrebiatura"}
              onChange={(value: any) => {
                editGroupConfig(value, keyName);
              }}
            />
          )}
          {keyName == "path" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Path"}
              onChange={(value: any) => {
                editGroupConfig(value, keyName);
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export { GroupConfig };
