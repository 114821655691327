import { useEffect, useState } from "react";
import { CheckIcon } from "components/icons/CheckIcon";
import { CloseIcon } from "components/icons/CloseIcon";
import { PencilIcon } from "components/icons/PencilIcon";
import { IconButton } from "@mui/material";
import { Spinner } from "components/Spinner/Spinner";
import recordService from "services/recordService";
import { InformationMessage } from "components/InformationMessage/InformationMessage";
// import { useSessionStorage } from "hooks/useSessionStorage";
import "./FieldEditControls.scss";

interface FieldEditControlsProps {
  value: string;
  fieldName: string;
  editPath?: string;
  onEditStart?: () => any;
  onEditEnd?: (success: boolean, originalValue?: any) => any;
  onEditCancel?: (originalValue: any) => void;
}
const FieldEditControls = ({
  value,
  fieldName,
  onEditStart,
  editPath,
  onEditEnd,
  onEditCancel,
}: FieldEditControlsProps) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  /* 
    const sessionStorageKey = `${fieldName}_field`;
    const [storageValue, setStorageValue] = useSessionStorage(
      sessionStorageKey,
      ""
    ); */
  const getStorageValue = (key: string) => {
    let strValue = sessionStorage.getItem(key);
    if (strValue === null) return null;
    return JSON.parse(strValue);
  };
  const setStorageValue = ({ fieldName, value }: { fieldName: string, value: any }): void => {
    if (value !== undefined) {
      const stringValue = JSON.stringify(value);
      sessionStorage.setItem(fieldName, stringValue);
    } else {
      console.log(`El valor para ${fieldName} es undefined.`);
    }
  };

  const isValueChanged = () => {
    const current = JSON.stringify(value)
    const storage = JSON.stringify(getStorageValue(`${fieldName}_field`))
    return current !== storage
  }
  const handleUpdateValue = async () => {
    setProcessing(true);
    try {
      const res = await recordService.update({
        editPath: editPath,
        data: {
          data: value,
        },
      });
      // setStorageValue(value);
      setStorageValue({ fieldName: `${fieldName}_field`, value })
      setProcessing(false);
      setIsEditEnabled(false);
      onEditEnd?.(true);
      setAlertMsg(`updated successfully`);
      setOpenAlert(true);
      setSeverity("success");
    } catch (error: any) {
      setProcessing(false);
      setIsEditEnabled(false);
      onEditEnd?.(false, getStorageValue(`${fieldName}_field`));
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };
  useEffect(() => {
    //setStorageValue(value);
    setStorageValue({ fieldName: `${fieldName}_field`, value })
  }, []);
  return (
    <>
      <div className="field-edit-controls-container">
        {isEditEnabled ? (
          <>
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <IconButton
                disabled={!isValueChanged()}
                aria-label="update"
                onClick={handleUpdateValue}
                color="primary"
              >
                <CheckIcon />
              </IconButton>
            )}

            <IconButton
              aria-label="close"
              onClick={() => {
                setIsEditEnabled(false);
                onEditCancel?.(getStorageValue(`${fieldName}_field`));
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setIsEditEnabled(true);
                onEditStart?.();
              }}
            >
              <PencilIcon />
            </IconButton>
          </>
        )}
      </div>
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </>
  );
};

export { FieldEditControls };
