export const getFields = () => {
  const zoneFields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const neighborhoodFields = [
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",

      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const addressFields = [
    {
      type: "text",
      visibleName: "Calle",
      name: "street",
      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Num.",
      name: "number",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Código postal",
      name: "postal_code",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Observación",
      name: "observation",
      placeholder: "Ej; piso, manzana, bloque...",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Barrio",
      name: "neighborhood",
      searchPath: "neighborhoods",
      primaryKey: "composite_field",

      formTitle: "Barrio",
      formFields: neighborhoodFields,
      formTableName: "neighborhoods",
      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Zona",
      name: "zone",
      searchPath: "zones",
      primaryKey: "name",

      description: null,
      formTitle: "Zona",
      formFields: zoneFields,
      formTableName: "zones",
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",

      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },

    {
      type: "coordinates",
      visibleName: "Coordenadas",
      name: "coordinates",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const data: any = {
    customer: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "email",
        visibleName: "Email",
        name: "email",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "text",
        visibleName: "Whatsapp",
        name: "whatsapp_number",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "list",
        visibleName: "Otros teléfonos",
        name: "phones",
        description: null,
        size: 12,
        itemPlaceholder: "387...",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "DNI",
        name: "personal_id",
        size: 6,
        description: "Solo números (sin puntos)",
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "CUIT",
        name: "tax_id",
        description: "Solo números (sin puntos ni guiones)",
        size: 6,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Condición frente al IVA",
        name: "vat_condition",
        description: null,
        options: [
          {
            name: "Consumidor final",
            visibleName: "Consumidor final",
          },
          {
            name: "Responsable monotributo",
            visibleName: "Responsable monotributo",
          },
          {
            name: "Responsable inscripto",
            visibleName: "Responsable inscripto",
          },
          {
            name: "Exento",
            visibleName: "Exento",
          },
        ],
        size: 12,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 3,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "select",
        visibleName: "Domicilio",
        name: "address",
        searchPath: "addresses",
        primaryKey: "composite_field",
        //secondaryKey: "display_identifiers",
        formFields: addressFields,
        formTitle: "Domicilio",
        formTableName: "addresses",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descripción",
        name: "description",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
