import React, { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";

import "./ConfigurationsExpandedCard.scss";
import { getRowActions } from "../../utils/get-row-actions";
import ConfigurationBuilderModal from "components/modals/ConfigurationBuilderModal/ConfigurationBuilderModal";

const ConfigurationsExpandedCard = (props: any) => {
  const { title, data } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [configurationBuilderModalIsOpen, setConfigurationBuilderModalIsOpen] = useState(false);
  const [recordsModalIsOpen, setRecordsModalIsOpen] = useState(false);

  const configurationFields = getFields().configuration;

  const configurationCols = getCols().configuration;

  const configurationDividerGroups = getDividerGroups().configuration;

  const configurationRowActions = getRowActions().configuration;

  return (
    <>
      <div className="configurations-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nueva configuración</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Listado</span> */}
            <strong>Registros</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Formulario de configuración de elemento"}
        fields={configurationFields}
        tableName={"configurations"}
      />
      <RecordsModal
        open={recordsModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        title={"Configuraciones de elementos"}
        tableName={"configurations"}
        fields={configurationFields}
        cols={configurationCols}
        formTitle={"Configuración de elemento"}
        dividerGroups={configurationDividerGroups}
        rowActions={configurationRowActions}
        onSelectRowAction={(val: any) => {
          console.log('val', val)
          if (val?.actionName == "viewConfiguration") setConfigurationBuilderModalIsOpen(true);

        }}
      />


      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
      />
      <ConfigurationBuilderModal isOpen={configurationBuilderModalIsOpen} onClose={() => {
        console.log('cerrar')
        setConfigurationBuilderModalIsOpen(false);

      }} type="fields" />
    </>
  );
};

export { ConfigurationsExpandedCard };
