import React, { useState } from "react";
import "./WorkflowEntriesExpandedCard.scss";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { WorkflowEntryListModal } from "../WorkflowEntryListModal/WorkflowEntryListModal";
import { NewWorkflowEntryModal } from "../NewWorkflowEntryModal/NewWorkflowEntryModal";
const WorkflowEntriesExpandedCard = (props: any) => {
  const { title, config } = props;
  const [newWorkflowEntryModalIsOpen, setNewWorkflowEntryModalIsOpen] =
    useState(false);
  const [workflowEntryListModalIsOpen, setWorkflowEntryListModalIsOpen] =
    useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  return (
    <>
      <div className="workflow-entries-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setNewWorkflowEntryModalIsOpen(true);
            }}
          >
            <strong>Nueva entrada</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setWorkflowEntryListModalIsOpen(true);
            }}
          >
            <strong>Registros</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Entradas eliminadas</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>

      <NewWorkflowEntryModal
        open={newWorkflowEntryModalIsOpen}
        setOpen={setNewWorkflowEntryModalIsOpen}
        onClose={() => { }}
      />

      <WorkflowEntryListModal
        open={workflowEntryListModalIsOpen}
        setOpen={setWorkflowEntryListModalIsOpen}
        onClose={() => { }}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => { }}
      />
    </>
  );
};

export { WorkflowEntriesExpandedCard };
