export const getRowActions = () => {
  const data: any = {
    address: [
      {
        name: "view",
        visibleName: "Ver domicilio",
      },
      {
        name: "delete",
        visibleName: "Eliminar domicilio",
      },
    ],
    zone: [
      {
        name: "view",
        visibleName: "Ver zona",
      },
      {
        name: "delete",
        visibleName: "Eliminar zona",
      },
    ],
    neighborhood: [
      {
        name: "view",
        visibleName: "Ver barrio",
      },
      {
        name: "delete",
        visibleName: "Eliminar barrio",
      },
    ],
  };

  return data;
};
