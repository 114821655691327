import React, { useState, useEffect } from "react";
import SearchPlace from "../../SearchPlace/SearchPlace";
import "./CoordinatesField.scss";

const CoordinatesField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    onChange,
    readonly,
    recordId,
    editPath,
  } = props;

  // Asegurándonos de que defaultValue es un objeto con las propiedades lat y lng
  defaultValue = defaultValue || { lat: 43.45, lng: -80.49 };

  const [enabledEdition, setEnabledEdition] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleLatChange = (e: any) => {
    const newValue = { ...value, lat: Number(e.target.value) };
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleLongChange = (e: any) => {
    const newValue = { ...value, lng: Number(e.target.value) };
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  // useEffect(() => {
  //   onChange(value);
  // }, [value]);

  return (
    <div className="coordinates-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}
      <div>
        {readonly ||
        ((recordId || editPath) && editable && !enabledEdition) ||
        ((recordId || editPath) && !editable) ? (
          <div className="readonly-content">
            <span>{`Latitud: ${value?.lat}, Longitud: ${value?.lng}`}</span>
            <SearchPlace
              onChange={(value: any) => {
                console.log("val", value);
                //onChange(value);
                //setValue(value);
              }}
              coordinates={defaultValue}
            />
          </div>
        ) : (
          <div className="editable-content">
            <div>
              <div>
                <div className="input-wrapper">
                  <label>Latitud</label>
                  <div>
                    <input
                      type="number"
                      value={value?.lat}
                      onChange={handleLatChange}
                    />
                  </div>

                  {/* <InputField
                  label={"Latitud:"}
                  size="sm"
                  defaultValue={value.lat}
                  onChange={(val: any) => {
                    console.log(val);
                    const newValue = { ...value, lat: val };
                    setValue(newValue);
                    if (onChange) onChange(newValue);
                  }}
                /> */}
                </div>
              </div>
              <div>
                <div className="input-wrapper">
                  <label>Longitud</label>
                  <div>
                    <input
                      type="number"
                      value={value?.lng}
                      onChange={handleLongChange}
                    />
                  </div>
                  {/* <InputField
                  label={"Longitud:"}
                  size="sm"
                  defaultValue={value.lng}
                  onChange={(val: any) => {
                    console.log(val);
                    const newValue = { ...value, lng: val };
                    setValue(newValue);
                    if (onChange) onChange(newValue);
                  }}
                /> */}
                </div>
              </div>
            </div>
            <div>
              <SearchPlace
                onChange={(value: any) => {
                  console.log(value);

                  const newValue = {
                    ...value,
                    lat: Number(value.lat),
                    lng: Number(value.lng),
                  };
                  setValue(newValue);
                  if (onChange) onChange(newValue);
                }}
                coordinates={defaultValue}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { CoordinatesField };
