import { Slider } from "@mui/material";
import { useEffect, useState } from "react";

import { CheckboxField } from "../../../../fields/CheckboxField/CheckboxField";
import { InputField } from "../../../../fields/InputField/InputField";
import { LongTextField } from "../../../../fields/LongTextField/LongTextField";
import { SelectField } from "../../../../fields/SelectField/SelectField";
import { SliderField } from "../../../../fields/SliderField/SliderField";
import "./FieldConfig.scss";
import { RadioField } from "../../../../fields/RadioField/RadioField";
const FieldConfig = ({ config, onChange, open }: any) => {
  // const { activeFieldConfig, setActiveFieldConfig } = useFormBuilder();
  const [fieldConfig, setFieldConfig] = useState(config);
  const editFieldConfig = (value: any, key: string) => {
    const configCopy = { ...fieldConfig };
    configCopy[key] = value;
    setFieldConfig(configCopy);
    //setActiveFieldConfig(config);
    //onChange(configCopy);
  };
  useEffect(() => {
    onChange(fieldConfig);
  }, [fieldConfig]);
  return (
    <div className="form-builder-added-field-config">
      {Object.keys(config).map((keyName, i) => (
        <div className="added-field-config-item" key={i}>
          {keyName == "name" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre"}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "visibleName" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre a mostar"}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "defaultValue" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Valor por defecto"}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "size" && (
            <>
              <SliderField
                defaultValue={config[keyName]}
                label={"Tamaño"}
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "editable" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Se edita"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "editableIf" && (
            <>
              <LongTextField
                defaultValue={config[keyName]}
                label={"Se edita si"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "showIf" && (
            <>
              <LongTextField
                defaultValue={config[keyName]}
                label={"Mostrar si"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "prepend" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Anteponer"}
              placeholder="EJ: $"
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "append" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Postponer"}
              placeholder="EJ: %"
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "tagGroup" && (
            <SelectField
              defaultValue={config[keyName]}
              label={"Grupo de etiqueta"}
              tableName={"tag_groups"}
              primaryKey={"name"}
              onChange={(value: any) => {
                console.log(value);
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "transactionType" && (
            <RadioField
              defaultValue={config[keyName]}
              label={"Tipo de transacción"}
              options={["Venta", "Compra"]}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "documentType" && (
            <RadioField
              defaultValue={config[keyName]}
              label={"Tipo de documento"}
              options={[
                "Cotización",
                "Orden",
                "Factura",
                "Nota de crédito",
                "Nota de débito",
              ]}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {/* <span className="input-label">
            key: {i} Name: {activeFieldConfig[keyName]}
          </span> */}
        </div>
      ))}
    </div>
  );
};

export { FieldConfig };
