import React, { useState, useEffect } from "react";
import {
  Dialog,
  IconButton,
  Slide,
  Fab,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Grid from "@mui/material/Grid";
import { InputField } from "components/fields/InputField/InputField";
import { PastelColorField } from "components/fields/PastelColorField/PastelColorField";
import { Spinner } from "components/Spinner/Spinner";
import { TagsIcon } from "components/icons/TagsIcon";
import { CloseIcon } from "components/icons/CloseIcon";
import { AddIcon } from "components/icons/AddIcon";
import { InformationMessage } from "components/InformationMessage/InformationMessage";
import { TagList } from "./components/TagList/TagList";
import { useTagActions } from "./components/useTagActions";
import { arrayMoveImmutable } from "array-move";
import "./TagsModal.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Mode {
  create: string;
  edit: string;
  delete: string;
}

const TagsModal = ({ open, setOpen, onDismiss, groupName, groupId }: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [tagId, setTagId] = useState(0);
  const [mode, setMode] = useState<Mode>({
    create: "crear",
    edit: "editar",
    delete: "eliminar",
  });
  const {
    records,
    setRecords,
    editTag,
    loading,
    processing,
    openAlert,
    setOpenAlert,
    formIsOpen,
    setFormIsOpen,
    alertMsg,
    severity,
    tagName,
    setTagName,
    tagColor,
    setTagColor,
    deleteTag,
    createTag,
    sortTag,
  } = useTagActions({ tagId, open, groupId, datasort: [] });

  const getLabel = (mode: Mode): string => {
    let element: string = "etiqueta";
    let msg: string = "";
    if (mode.create === "crear") msg = `${mode.create} ${element}`;
    if (mode.edit === "editar") msg = `${mode.edit} ${element}`;
    if (mode.delete === "eliminar") msg = `${mode.delete} ${element}`;
    return msg;
  };
  useEffect(() => {
    if (!formIsOpen) {
      setTagName("");
      setTagColor("#A597CC");
    }
  }, [formIsOpen]);

  const handleDelete = (value: any) => {
    const { color, name, id } = value;
    setTagName(name);
    setTagColor(color);
    setTagId(id);
    setFormIsOpen(true);
    setMode({ create: "", edit: "", delete: "eliminar" });
  };
  const confirmMsg = ({ value }: { value: string }): React.ReactNode => {
    return (
      <>
        Desea Eliminar: <b>&nbsp;{value}</b>
      </>
    );
  };
  const handleEdit = (value: any) => {
    const { color, name, id } = value;
    console.log(value);
    setTagName(name);
    setTagColor(color);
    setTagId(id);
    setFormIsOpen(true);
    const tagEditId = {
      name,
      color,
    };
    sessionStorage.setItem("tagEdit", JSON.stringify(tagEditId));
    setMode({ create: "", edit: "editar", delete: "" });
  };
  const getValueSessionStorage = (key: string) => {
    let array = sessionStorage.getItem(key);
    if (array === null) return null;
    return JSON.parse(array);
  };
  const isDisabled = (): boolean => {
    const tagEditId = {
      name: tagName,
      color: tagColor,
    };
    const tagEdit = getValueSessionStorage("tagEdit");
    if (JSON.stringify(tagEdit) === JSON.stringify(tagEditId)) {
      return true;
    } else {
      return false;
    }
  };
  const handleSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    let data = arrayMoveImmutable(records, oldIndex, newIndex);
    data.forEach((item: any, index: number) => {
      item.order = ++index;
    });
    console.log(data);
    setRecords(data);
    sortTag(data);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onDismiss({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="tags-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Etiquetas</h2>
          <span>
            <b>Grupo:</b> {groupName}
          </span>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onDismiss({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        {loading && (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        )}
        {!loading && records.length == 0 && (
          <div className="no-content">
            <TagsIcon />
            <p>No hay etiquetas cargadas</p>
          </div>
        )}
        {!loading && records.length > 0 && (
          <>
            <TagList
              items={records}
              onSortEnd={handleSortEnd}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </>
        )}
        <InformationMessage
          open={openAlert}
          message={alertMsg}
          severity={severity}
          onClose={setOpenAlert}
        />
      </div>
      <Fab
        className="fab"
        color="primary"
        aria-label="add"
        onClick={() => {
          setFormIsOpen(true);
          setMode({ create: "crear", edit: "", delete: "" });
        }}
      >
        <AddIcon />
      </Fab>
      <Dialog
        open={formIsOpen}
        onClose={() => { }}
        className="dialog-form"
        fullWidth
        maxWidth={mode.delete !== "eliminar" ? "sm" : "xs"}
      >
        <DialogTitle className="dialog-form-title">
          {getLabel(mode)}
        </DialogTitle>
        <DialogContent className="dialog-form-content">
          <DialogContentText className="dialog-form-description">
            {/* To subscribe to this website, please enter your email address here.
            We will send updates occasionally. */}
          </DialogContentText>
          <div className="dialog-form-inputs-wrapper">
            <Grid container spacing={2}>
              {mode.delete !== "eliminar" ? (
                <>
                  <Grid item xs={12} md={6}>
                    {/* <div className="dialog-form-input-wrapper">
                  <input
                    type="text"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div> */}
                    <InputField
                      id="tag-group-name"
                      defaultValue={tagName}
                      label={"Nombre de la etiqueta"}
                      onChange={(value: string) => {
                        //  console.log("holass", value);
                        setTagName(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* <ColorField
                  defaultValue={tagColor}
                  label={"Color"}
                  onChange={(value: string) => {
                    console.log("imagen", value);
                    setTagColor(value);
                  }}
                /> */}
                    <PastelColorField
                      defaultValue={tagColor}
                      label={"Color"}
                      onChange={(value: string) => {
                        console.log("imagen", value);
                        setTagColor(value);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                confirmMsg({ value: tagName })
              )}
            </Grid>
          </div>

          {/* <div className="mt-10">
            <input
              className="input"
              type="text"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
          </div> */}

          {/* <ColorField
            defaultValue={data?.primary_color}
            label={"Color principal"}
            editable={true}
            editPath={"settings/update-primary-color"}
            onChange={(value: string) => {
              console.log("imagen", value);
            }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFormIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={processing || isDisabled()}
            onClick={() => {
              if (mode.create === "crear") createTag();
              if (mode.edit === "editar") editTag();
              if (mode.delete === "eliminar") deleteTag();
            }}
          >
            {getLabel(mode)}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export { TagsModal };
