import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./InputField.scss";
import { useSessionStorage } from "hooks/useSessionStorage";

const InputField = (props: any) => {
  let {
    type = "text",
    defaultValue,
    editable,
    label,
    editPath,
    id,
    onChange,
    placeholder,
    recordId,
    name,
    prepend,
    append,
    readonly,
    size = "md", //md/sm
    description,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const sessionStorageKey = `${name}`;
  const [storageValue, setStorageValue] = useSessionStorage(
    sessionStorageKey,
    ""
  );
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    console.log(defaultValue)
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className={"input-field-container " + size}>
        {label && <label>{label}</label>}
        <div>
          <div>
            {readonly ||
              (mode == 'edit' && editable && !isEditEnabled) ||
              (mode == 'edit' && !editable) ? (
              <div className="readonly-content">
                {prepend && <span>{prepend}</span>}
                <div>{value || "-"}</div>
                {append && <span>{append}</span>}
              </div>
            ) : (
              <div className="editable-content">
                {prepend && <span>{prepend}</span>}
                <input
                  id={id}
                  type={
                    type == "integer" || type == "decimal" ? "number" : "text"
                  }
                  placeholder={placeholder}
                  value={value || ""}
                  onChange={(e) => setValue(e.target.value)}
                  style={{
                    textAlign:
                      type == "integer" || type == "decimal" ? "right" : "left",
                  }}
                />
                {append && <span>{append}</span>}
              </div>
            )}
          </div>

          {/* {!readonly && (recordId || editPath) && editable && (
            <FieldEditControls
              value={value}
              fieldName={name}
              editPath={editPath}
              onEditStart={() => {
                setIsEditEnabled(true);
              }}
              onEditCancel={(originalValue) => {
                setIsEditEnabled(false);
                setValue(originalValue);
              }}
              onEditEnd={(success: boolean, originalValue) => {
                setIsEditEnabled(false);
                if (!success) {
                  setValue(originalValue);
                }
              }}
            />
          )} */}
          {editable && (
            <div>
              {!readonly != true || (mode == 'edit' && editable) && (
                <FieldEditControls
                  value={value}
                  fieldName={name}
                  editPath={editPath}
                  onEditStart={() => {
                    setIsEditEnabled(true);
                  }}
                  onEditCancel={() => {
                    setIsEditEnabled(false);
                    setValue(storageValue);
                  }}
                  onEditEnd={(success: boolean) => {
                    setIsEditEnabled(false);
                    if (success) {
                      setStorageValue(value);
                    } else {
                      setValue(storageValue);
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>

      </div>
      {description && <span className="field-description">{description}</span>}
    </>
  );
};

export { InputField };
