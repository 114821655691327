import { useState, useEffect, Fragment } from "react";
import Icon from "../../../../components/Icon/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import {
  IconButton,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import "./IndicatorItem.scss";

import { UsersCell } from "../../../../components/cells/UsersCell/UsersCell";

import { IndicatorValuesModal } from "../IndicatorValuesModal/IndicatorValuesModal";

import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { getFields } from "../../utils/get-fileds";
import { IndicatorDataImporterModal } from "../IndicatorDataImporterModal/IndicatorDataImporterModal";

const IndicatorItem = ({ data, id, onDelete, onChange }: any) => {
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [
    indicatorDataImporterModalIsOpen,
    setIndicatorDataImporterModalIsOpen,
  ] = useState(false);
  const [indicatorValuesModalIsOpen, setIndicatorValueModalIsOpen] =
    useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const fields: any = getFields().indicator;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverIsOpen = Boolean(anchorEl);
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const closeAlert = () => {
    setConfirmDialogIsOpen(false);
  };
  const removeIndicator = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.delete(
      `${urls.server}/api/indicators/${data?.id}`
    );
    console.log(res);
    onDelete(true);
    setProcessing(false);
    closeAlert();
    closePopover();
    //setRefreshOnClose(true);
  };
  const refreshCardData = async () => {
    const res = await AxiosInterceptor.put(
      `${urls.server}/api/indicators/${data?.id}/card-data`
    );

    closePopover();
  };

  const csvColumns = [
    { type: "number" },
    { type: "string" },
    { type: "string" },
    { type: "number" },
    { type: "string" },
    { type: "number" },
    { type: "string" },
    { type: "number" },
  ];
  return (
    <div className="indicators-list-item-container">
      <div>
        <div>
          <span>{data.visible_id}</span>
          <strong>{data.visible_name}</strong>
          <span>
            {data.type == "simple"
              ? "Simple"
              : data.type == "singleBreakdown"
                ? "Único desgolse"
                : "Multiples Desgloses"}
          </span>
          {data.values_last_modified_at_human ?
            <div>
              <span>Útimo cambio en los valores:</span> <strong>{data.values_last_modified_at_human}</strong>
            </div> : null
          }

        </div>
      </div>

      <div>
        <UsersCell
          data={data.users}
          modalSubtitle={"Indicadores"}
          tableName={"indicators"}
          recordId={data.id}
          onChange={onChange}
        />
        <div className="more-actions-wrapper">
          <IconButton aria-label="actions" onClick={presentPopover}>
            <MoreVertIcon />
          </IconButton>
        </div>

        <Popover
          id={"indicator_popover_" + id}
          open={popoverIsOpen}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="popover-items">
            <a
              onClick={() => {
                setFormModalIsOpen(true);
                closePopover();
              }}
            >
              Ficha
            </a>
            <a
              onClick={() => {
                setIndicatorValueModalIsOpen(true);
                closePopover();
              }}
            >
              Valores
            </a>
            {data.is_importable ? (
              <a
                onClick={() => {
                  setIndicatorDataImporterModalIsOpen(true);
                  closePopover();
                }}
              >
                Importar CSV
              </a>
            ) : (
              <></>
            )}

            <a
              onClick={() => {
                refreshCardData();
              }}
            >
              Actualizar tarjeta
            </a>
            <a
              onClick={() => {
                setConfirmDialogIsOpen(true);
                closePopover();
              }}
            >
              Eliminar
            </a>
          </div>
        </Popover>

        <Dialog
          open={confirmDialogIsOpen}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Quitar"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Desea eliminar el indicador <b>{data?.name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} autoFocus>
              Cancelar
            </Button>
            <Button onClick={removeIndicator}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>

      <IndicatorValuesModal
        open={indicatorValuesModalIsOpen}
        setOpen={setIndicatorValueModalIsOpen}
        onClose={() => { }}
        onDismiss={() => { }}
        indicatorId={data.id}
        indicatorName={data.name}
        indicatorVisibleName={data.visible_name}
      />
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={(res: any) => {
          if (res) onChange(true);
        }}
        title="Ficha del indicador"
        fields={fields}
        tableName="indicators"
        recordId={data.id}
        mode='edit'
      />
      <IndicatorDataImporterModal
        open={indicatorDataImporterModalIsOpen}
        setOpen={setIndicatorDataImporterModalIsOpen}
        onClose={(res: any) => {
          if (res?.refresh) onChange(true);
        }}
        onDismiss={(res: any) => { }}
        //cols={csvColumns}
        indicatorId={data.id}
        indicatorName={data.name}
        indicatorVisibleName={data.visible_name}
        indicatorCode={data.code}
      />
    </div>
  );
};

export { IndicatorItem };
