import { Slider } from "@mui/material";
import { useEffect, useState } from "react";

import { CheckboxField } from "../../../../fields/CheckboxField/CheckboxField";
import { InputField } from "../../../../fields/InputField/InputField";
import { LongTextField } from "../../../../fields/LongTextField/LongTextField";
import { SelectField } from "../../../../fields/SelectField/SelectField";
import { SliderField } from "../../../../fields/SliderField/SliderField";
import "./FieldConfig.scss";
import { RadioField } from "../../../../fields/RadioField/RadioField";
import { GridField } from "components/fields/GridField/GridField";
const FieldConfig = ({ config, onChange, open }: any) => {
  // const { activeFieldConfig, setActiveFieldConfig } = useFieldsBuilder();
  const [fieldConfig, setFieldConfig] = useState(config);
  const editFieldConfig = (value: any, key: string) => {
    const configCopy = { ...fieldConfig };
    configCopy[key] = value;
    setFieldConfig(configCopy);
    //setActiveFieldConfig(config);
    //onChange(configCopy);
  };
  useEffect(() => {
    onChange(fieldConfig);
  }, [fieldConfig]);
  return (
    <div className="fields-builder-added-field-config">
      {Object.keys(config).map((keyName, i) => (
        <div className="added-field-config-item" key={i}>
          {keyName == "name" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre"}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "visibleName" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Nombre a mostar"}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "defaultValue" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Valor por defecto"}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "size" && (
            <>
              <SliderField
                defaultValue={config[keyName]}
                label={"Tamaño"}
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "editable" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Se edita"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "canAddItems" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Puede agregar items?"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "canRemoveItems" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Puede eliminar items?"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "canAddRows" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Puede agregar filas?"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "canRemoveRows" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Puede quitar filas?"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "forceMobileView" && (
            <>
              <CheckboxField
                defaultValue={config[keyName]}
                label={"Forzar vista móvil?"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "cols" && (
            <>
              <GridField
                defaultValue={config[keyName]}
                label={"Columnas"}
                description=""
                forceMobileView={true}
                cols={[
                  {
                    type: "radio",
                    name: "type",
                    visibleName: "Tipo de col.",
                    options: [
                      { name: "text", visibleName: "Texto" },
                      {
                        name: "longText",
                        visibleName: "Texto largo",
                      },
                      {
                        name: "money",
                        visibleName: "Dinero",
                      },
                      {
                        name: "decimal",
                        visibleName: "Decimal",
                      },
                    ],
                  },
                  {
                    type: "text",
                    name: "name",
                    visibleName: "Nombre",
                  },
                  {
                    type: "text",
                    name: "visibleName",
                    visibleName: "Nombre a mostrar",
                  },
                  {
                    type: "slider",
                    name: "size",
                    visibleName: "Tamaño",
                  },
                  {
                    type: "checkbox",
                    name: "readonly",
                    visibleName: "Solo lectura?",
                  },
                ]}
                onChange={(value: any) => {
                  console.log("secambio", value);
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "editableIf" && (
            <>
              <LongTextField
                defaultValue={config[keyName]}
                label={"Se edita si"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "showIf" && (
            <>
              <LongTextField
                defaultValue={config[keyName]}
                label={"Mostrar si"}
                description=""
                onChange={(value: any) => {
                  editFieldConfig(value, keyName);
                }}
              />
            </>
          )}
          {keyName == "prepend" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Anteponer"}
              placeholder="EJ: $"
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "append" && (
            <InputField
              defaultValue={config[keyName]}
              label={"Postponer"}
              placeholder="EJ: %"
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "tagGroup" && (
            <SelectField
              defaultValue={config[keyName]}
              label={"Grupo de etiqueta"}
              tableName={"tag_groups"}
              primaryKey={"name"}
              onChange={(value: any) => {
                console.log(value);
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "transactionType" && (
            <RadioField
              defaultValue={config[keyName]}
              label={"Tipo de transacción"}
              //options={["Venta", "Compra"]}
              options={[
                {
                  name: "sale",
                  visibleName: "Venta",
                },
                {
                  name: "purchase",
                  visibleName: "Compra",
                },
              ]}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {keyName == "documentType" && (
            <RadioField
              defaultValue={config[keyName]}
              label={"Tipo de documento"}
              // options={[
              //   "Cotización",
              //   "Orden",
              //   "Factura",
              //   "Nota de crédito",
              //   "Nota de débito",
              // ]}
              options={[
                {
                  name: "quotation",
                  visibleName: "Cotización",
                },
                {
                  name: "invoice",
                  visibleName: "Factura",
                },
                {
                  name: "debitNote",
                  visibleName: "Nota de crédito",
                },
                {
                  name: "creditNo",
                  visibleName: "Nota de débito",
                },
              ]}
              onChange={(value: any) => {
                editFieldConfig(value, keyName);
              }}
            />
          )}
          {/* <span className="input-label">
            key: {i} Name: {activeFieldConfig[keyName]}
          </span> */}
        </div>
      ))}
    </div>
  );
};

export { FieldConfig };
