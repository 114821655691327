export const getRowActions = () => {
  const data: any = {
    service: [
      {
        name: "view",
        visibleName: "Ver servicio",
      },
      {
        name: "delete",
        visibleName: "Eliminar servicio",
      },
    ],
  };

  return data;
};
