import React from "react";
import { Snackbar, Alert, Portal } from "@mui/material";

type InformationMessageProps = {
  open: boolean;
  message: string;
  severity: any;
  onClose: any;
};

const InformationMessage = ({
  open,
  onClose,
  severity,
  message,
}: InformationMessageProps) => {
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(false);
  };
  if (!open) return null;
  return (
    <Portal>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert
          onClose={handleCloseAlert}
          severity={severity}
          sx={{ width: "100%", color: "#ffffff" }}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export { InformationMessage };
