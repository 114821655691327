import { useState } from "react";

export const useSessionStorage = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void, (value: T) => void] => {
  const readValue = (): T => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error al leer sessionStorage key “${key}”:`, error);
      return initialValue;
    }
  };
  const [storedValue, setStoredValue] = useState<T>(readValue);
  const setValue = (value: T | ((val: T) => T)) => {
    if (typeof window === "undefined") {
      return;
    }
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.sessionStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error ${key}:`, error);
    }
  };
  const removeValue = () => {
    if (typeof window === "undefined") {
      return;
    }

    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.warn(`Error al eliminar sessionStorage key “${key}”:`, error);
    }
  };

  return [storedValue, setValue, removeValue];
};
