import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import "./PieChart.scss";
import { formatNumber } from "utils/number";
Chart.register(ArcElement, Tooltip, Legend);
const PieChart = ({ data = [], height = 500 }: any) => {
  const [chartData, setChartData] = useState(null as any);
  const [currentIndex, setCurrentIndex] = useState(data.length - 1);

  const convertToChartData = (originalData: any) => {
    // Extrae los labels, valores y colores en arrays separados.
    const labels = originalData.map((item: any) => item.label);
    const data = originalData.map((item: any) => item.value);
    const backgroundColor = originalData.map((item: any) => item.color);

    // Crea el objeto de datos en el formato que Chart.js espera.
    const chartData = {
      labels: labels,
      datasets: [
        {
          //label: "Dataset 1", // Puedes cambiar esta etiqueta por algo más descriptivo si lo deseas
          data: data,
          backgroundColor: backgroundColor,
        },
      ],
    };

    return chartData;
  };

  const options: any = {
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: function (tooltipItem: any) {
            const total = tooltipItem?.dataset?.data?.reduce(
              (total: number, item: any) => total + item,
              0
            );
            const percent =
              (tooltipItem?.dataset?.data[tooltipItem.dataIndex] / total) * 100;
            return "(" + formatNumber(percent) + "%)";
          },
        },
      },
    },
  };
  const goToPrevious = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  // Función para ir al próximo período
  const goToNext = () => {
    setCurrentIndex((prevIndex: number) =>
      prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  useEffect(() => {
    let sortedData = [...data[currentIndex].data].sort(
      (a: any, b: any) => b.value - a.value
    );
    setChartData(convertToChartData(sortedData));
    console.log(JSON.stringify(data));
  }, [currentIndex]);
  return (
    <div className="pie-chart-container">
      <div className="current-period">
        <IconButton
          aria-label="Previous Period"
          onClick={goToPrevious}
          disabled={currentIndex === 0}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <strong>{data[currentIndex]?.visible_period}</strong>
        <IconButton
          aria-label="Next Period"
          onClick={goToNext}
          disabled={currentIndex === data.length - 1}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <div className="graph" style={{ height: height + "px" }}>
        {chartData && <Pie data={chartData} options={options} />}
      </div>
    </div>
  );
};

export { PieChart };
