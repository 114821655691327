export const getDividerGroups = () => {
  const data: any = {
    tutorial: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "tutorials/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "tutorials/year-months-with-records",
      },
      {
        type: "tags",
        name: "tags",
        visibleName: "Etiquetas",
        abbr: "Etiq.",
        path: "tutorials/tags-with-records",
      },
    ],
  };

  return data;
};
