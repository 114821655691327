import { useState, useEffect } from "react";
import "./PaymentField.scss";
import { ReadonlyPaymentField } from "./ReadonlyPaymentField/ReadonlyPaymentField";
import { EditablePaymentField } from "./EditablePaymentField/EditablePaymentField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const PaymentField = (props: any) => {
  let {
    defaultValue,
    editable,
    editPath,
    onChange,
    name,
    recordId,
    readonly,
    transactionType,
  }: any = props;
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [data, setData] = useState(defaultValue as any);

  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return (
    <div className="payment-field-container">
      <div className="field-header">
        {(recordId || editPath) && editable && (
          <FieldEditControls
            value={data}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setData(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setData(originalValue);
              }
            }}
          />
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            ((recordId || editPath) && editable && !isEditEnabled) ||
            ((recordId || editPath) && !editable) ? (
            <ReadonlyPaymentField />
          ) : (
            <EditablePaymentField
              transactionType={transactionType}
              onChange={(value: any) => {
                setData(value);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { PaymentField };
