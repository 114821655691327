export const getFields = () => {
  const data: any = {
    configuration: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
