import { useState, useEffect, Fragment } from "react";
import { CheckIcon } from "../../icons/CheckIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { IconButton } from "@mui/material";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./MultipleSelectField.scss";
import {
  //TextField as MatTextField,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";

import { AddIcon } from "../../icons/AddIcon";
import { FormModal } from "../../modals/FormModal/FormModal";
const MultipleSelectField = (props: any) => {
  let {
    //id,
    editable,
    label,
    tableName,
    primaryKey,
    onChange,
    defaultValue,
    editPath,
    recordId,
    name,
    formFields,
    formTitle,
    readonly,
    searchPath,
    filters = [],
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  // const handleSave = () => {
  //   setEnabledEdition(false);
  // };




  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    } else {
      setNoOptionsText("Buscar...");
    }
  }, [open]);

  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);

    let url = `${urls.server}/api/${searchPath}?search_term=${
      event.target.value
    }&offset=0&limit=20&filters=${encodeURIComponent(JSON.stringify(filters))}`;

    const res = await AxiosInterceptor.get(`${url}`);
    const data = await res?.data;
    setOptions(data);
    setLoading(false);
  };
  useEffect(() => {
    console.log("defaultValue", defaultValue);
  }, []);
  return (
    <div className="multiple-select-field-container">
      <div>
        {readonly ||
        ((recordId || editPath) && editable && !isEditEnabled) ||
        ((recordId || editPath) && !editable) ? (
          <div className="readonly-content">
            <div>
              {label && <label>{label}</label>}
              {value ? <span>{value[primaryKey]}</span> : <span>-</span>}
            </div>
          </div>
        ) : (
          <div className="editable-content">
            <div className="autocomplete-wrapper">
              {label && <label>{label}</label>}
              <Autocomplete
                multiple
                noOptionsText={noOptionsText}
                loadingText="Buscando..."
                //sx={{ width: 300 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option[primaryKey] === value[primaryKey]
                }
                getOptionLabel={(option: any) => option[primaryKey]}
                options={options}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option[primaryKey]}
                  </Box>
                )}
                loading={loading}
                onChange={(event: any, newValue: any | null) => {
                  //handleChangeInput(`${field.name}_id`, newValue.id);
                  setValue(newValue);
                  console.log(newValue);
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    hiddenLabel
                    onChange={handleChange}
                    {...params}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            {formFields && (
              <IconButton
                color="primary"
                onClick={() => {
                  setFormModalIsOpen(true);
                }}
                aria-label="add customer"
                size="small"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <FieldEditControls
        value={value}
        fieldName={name}
        editPath={editPath}
        onEditStart={() => {
          setIsEditEnabled(true);
        }}
        onEditCancel={(originalValue) => {
          setIsEditEnabled(false);
          setValue(originalValue);
        }}
        onEditEnd={(success: boolean, originalValue) => {
          setIsEditEnabled(false);
          if (!success) {
            setValue(originalValue);
          }
        }}
      />
      )}
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        //onSave={() => {}}
        title={formTitle}
        fields={formFields}
        tableName={tableName}
      />
    </div>
  );
};

export { MultipleSelectField };
