import * as React from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Drawer,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./AddedGroups.scss";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import { useState } from "react";
import { GroupConfig } from "../GroupConfig/GroupConfig";

import Icon from "../../../../Icon/Icon";
const DragHandle = SortableHandle(() => <DragIndicatorIcon />);
const SortableItem = SortableElement<{
  config: any;
  onSelect: any;
  onDelete: any;
  onChange: any;
  itemIndex: number;
  setActiveGroupIndex: any;
  activeGroupIndex: number;
}>(
  ({
    config,
    onSelect,
    onDelete,
    onChange,
    itemIndex,
    setActiveGroupIndex,
    activeGroupIndex,
  }: {
    config: any;
    onSelect: any;
    onDelete: any;
    onChange: any;
    itemIndex: number;
    setActiveGroupIndex: any;
    activeGroupIndex: number;
  }) => {
    const [openAlert, setOpenAlert] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);
    const deleteField = () => {
      onDelete(config, itemIndex);
      setOpenAlert(false);
    };
    const editField = () => {
      onSelect(config, itemIndex);
      setActiveGroupIndex(itemIndex);
      setOpenConfig(true);
    };
    let className =
      activeGroupIndex == itemIndex
        ? "divider-builder-added-group active"
        : "divider-builder-added-group";
    const closeAlert = () => {
      setOpenAlert(false);
    };
    return (
      <>
        <div className={className} style={{ zIndex: 99999999 }}>
          <div>
            <Icon name={config.type} />

            <div>
              <strong>{config.visibleName}</strong>
              <span>{config.visibleType}</span>
            </div>
          </div>
          <div>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setOpenAlert(true);
              }}
              size="small"
              style={{
                backgroundColor: "#fff",
              }}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              aria-label="edit"
              onClick={() => editField()}
              size="small"
              style={{
                backgroundColor: "#fff",
              }}
            >
              <EditIcon />
            </IconButton>
            <Drawer
              //variant={"persistent"}
              //key={itemIndex}
              anchor={"right"}
              open={openConfig}
              onClose={() => {
                setOpenConfig(false);
                setActiveGroupIndex(null);
              }}
              style={{ zIndex: 99999999 }}
            >
              <GroupConfig
                config={config}
                onChange={(config: any) => {
                  onChange(config, itemIndex);
                }}
              />
            </Drawer>

            <DragHandle />
          </div>
        </div>
        <Dialog
          open={openAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmar"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Desea eliminar el grupo <b>{config.visibleType}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} autoFocus>
              Cancelar
            </Button>
            <Button onClick={deleteField}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

const SortableList = SortableContainer<{
  items: string[];
  onSelectItem: any;
  onDeleteItem: any;
  onChangeItem: any;
  setActiveGroupIndex: any;
  activeGroupIndex: number;
}>(
  ({
    items,
    onSelectItem,
    onDeleteItem,
    onChangeItem,
    setActiveGroupIndex,
    activeGroupIndex,
  }: {
    items: string[];
    onSelectItem: any;
    onDeleteItem: any;
    onChangeItem: any;
    setActiveGroupIndex: any;
    activeGroupIndex: number;
  }) => {
    return (
      <div className="dividers-builder-added-group-container">
        {items.map((item, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            itemIndex={index}
            config={item}
            onSelect={onSelectItem}
            onDelete={onDeleteItem}
            setActiveGroupIndex={setActiveGroupIndex}
            activeGroupIndex={activeGroupIndex}
            onChange={onChangeItem}
          />
        ))}
      </div>
    );
  }
);
const AddedGroups = (props: any) => {
  const {
    items,
    onSelectItem,
    onDeleteItem,
    onChangeItem,
    setActiveGroupIndex,
    activeGroupIndex,
  } = props;
  const handleSortEnd = (items: any) => {
    console.log(items);
  };

  return (
    <SortableList
      items={items}
      onSortEnd={handleSortEnd}
      onSelectItem={onSelectItem}
      onDeleteItem={onDeleteItem}
      onChangeItem={onChangeItem}
      useDragHandle
      setActiveGroupIndex={setActiveGroupIndex}
      activeGroupIndex={activeGroupIndex}
    />
  );
};

export { AddedGroups };
