import { SvgIcon } from "@mui/material";
const CheckmarkIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 196.407 196.407">
    <path
      d="M56.084,189.137c-1.735,0.897-3.807,1.012-5.891-1.071L2.651,139.517
		c-4.204-4.291-3.225-9.943,1.648-13.462c6.228-4.498,14.555-11.83,21.582-22.55c3.291-5.026,7.995-5.363,11.248-0.315
		l11.727,18.205c3.258,5.053,7.006,11.389,9.018,10.677c0.816-0.288,1.806-0.995,2.986-2.393L156.169,9.877
		c3.742-4.699,9.023-4.194,11.803,1.131l27.054,51.894c2.779,5.325,1.235,12.695-3.448,16.459L69.1,177.786
		C64.422,181.55,59.272,187.5,56.084,189.137z"
    />
  </SvgIcon>
);

export { CheckmarkIcon };
