import { Dialog, Button, Grid, } from "@mui/material";
import { useState, useEffect } from "react";
import AxiosInterceptor from "AxiosInterceptor";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TagIcon } from "components/icons/TagIcon";
import { makeStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./TagsField.scss";
import { AddIcon } from "components/icons/AddIcon";
import { InputField } from "../InputField/InputField";
import { PastelColorField } from "../PastelColorField/PastelColorField";
import { Spinner } from "components/Spinner/Spinner";
import urls from "config/urls";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 800,
    width: "80%",
  },
}));

const TagsField = (props: any) => {
  const {
    editable,
    label,
    editPath,
    onChange,
    recordId,
    groupId,
    readonly,
    defaultValue,
    tableName,
    onEdit,
    name,
  }: any = props;
  const [processing, setProcessing]= useState(false);
  const [tags, setTags] = useState((defaultValue as any) || ([] as any));
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);


  const [formIsOpen, setFormIsOpen] = useState(false);


  const classes = useStyles();
  useEffect(() => {
    if (!defaultValue) loadData();
  }, []);
  useEffect(() => {
    if (onChange) onChange(tags);
  }, [tags]);

  const loadData = async () => {
    setProcessing(true);
    try {
      let url = `${urls.server}/api/tags?group_id=${groupId}`;
      const res = await AxiosInterceptor.get(url);
      const resData = await res.data;
      console.log(resData);
      setTags(resData);
      setProcessing(false);
    } catch (error: any) { }
  };
  const handleToggleTag = (index: number) => {
    const items = [...tags];
    items[index].selected = !items[index].selected;

    const defaultSelected = defaultValue.filter(
      (tag: { selected: any }) => tag.selected
    );

    setTags(items);
    setSelectedTags([...defaultSelected]);
  };
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("#A597CC");
  const createTag = async () => {
    setProcessing(true);
    try {
      const data = {
        name: tagName,
        color: tagColor,
        tag_group_id: groupId,
      };

      let url = `${urls.server}/api/tags`;

      const res = await AxiosInterceptor.post(`${url}`, data);
      setProcessing(false);
      setFormIsOpen(false);
      loadData();
    } catch (error) {
      setProcessing(false);
    }
  };
  useEffect(() => {
    if (!formIsOpen) {
      setTagName("");
      setTagColor("#A597CC");
    }
  }, [formIsOpen]);

  return (
    <div className="tags-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {!readonly && (recordId || editPath) && editable && (
               <FieldEditControls
               value={tags}
               fieldName={name}
               editPath={editPath}
               onEditStart={() => {
                 setIsEditEnabled(true);
               }}
               onEditCancel={(originalValue) => {
                 setIsEditEnabled(false);
                 setTags(originalValue);
               }}
               onEditEnd={(success: boolean, originalValue) => {
                 setIsEditEnabled(false);
                 if (!success) {
                   setTags(originalValue);
                 }
               }}
             />
            )}
          </div>
        )}
      </div>

      <div className="field-body">
        {readonly ||
          ((recordId || editPath) && editable && !isEditEnabled) ||
          ((recordId || editPath) && !editable) ? (
          <>
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner visible={processing} size="sm" />
              </div>
            ) : (
              <div className="readonly-content">
                <div>
                  {tags.length > 0 ? (
                    <>
                      {tags
                        .filter((item: any) => item.selected === true)
                        .map((item: any, index: number) => {
                          return (
                            <span
                              key={index}
                              style={{
                                color: item.color,
                                borderColor: item.color,
                                borderRadius: 99,
                                borderWidth: 2,
                              }}
                            >
                              <TagIcon />
                              <span>{item.name}</span>
                            </span>
                          );
                        })}
                    </>
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner visible={processing} size="sm" />
              </div>
            ) : (
              <div className="editable-items">
                {tags.map((item: any, index: number) => {
                  return (
                    <Button
                      key={index}
                      size="small"
                      variant="outlined"
                      endIcon={
                        item.selected ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )
                      }
                      startIcon={<TagIcon />}
                      onClick={() => handleToggleTag(index)}
                      style={{
                        color: item.selected ? item.color : null,
                        borderColor: item.selected ? item.color : null,
                        borderRadius: 99,
                        borderWidth: 2,
                      }}
                    >
                      {item.name}
                    </Button>
                  );
                })}
                <div>
                  <Button
                    onClick={() => {
                      setFormIsOpen(true);
                    }}
                    startIcon={<AddIcon />}
                  >
                    Etiqueta
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Dialog
        open={formIsOpen}
        onClose={() => { }}
        className="dialog-form"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle className="dialog-form-title">Nueva etiqueta</DialogTitle>
        <DialogContent className="dialog-form-content">
          <DialogContentText className="dialog-form-description">
            {/* To subscribe to this website, please enter your email address here.
            We will send updates occasionally. */}
          </DialogContentText>
          <div className="dialog-form-inputs-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField
                  id="tag-group-name"
                  defaultValue={tagName}
                  label={"Nombre de la etiqueta"}
                  onChange={(value: string) => {
                    setTagName(value);
                  }}
                //editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PastelColorField
                  defaultValue={tagColor}
                  label={"Color"}
                  onChange={(value: string) => {
                    setTagColor(value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFormIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={processing}
            onClick={() => {
              createTag();
            }}
          >
            Crear etiqueta
          </Button>
        </DialogActions>
      </Dialog>
     
    </div>
  );
};

export { TagsField };
