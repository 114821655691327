import React from 'react';
import './ListItem.scss';
import { DateCell } from 'components/cells/DateCell/DateCell';
import { TagsCell } from 'components/cells/TagsCell/TagsCell';
import { ThumbnailCell } from 'components/cells/ThumbnailCell/ThumbnailCell';
import { MoneyCell } from 'components/cells/MoneyCell/MoneyCell';
import { RadioCell } from 'components/cells/RadioCell/RadioCell';
import { IconButton } from '@mui/material';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { SortableHandle } from 'react-sortable-hoc';
import IconCell from 'components/cells/IconCell/IconCell';
import TemplateCell from 'components/cells/TemplateCell/TemplateCell';
const DragHandle = SortableHandle(() => <DragIndicatorIcon />);
interface ListItemProps {
    data: any;
    canEdit?: boolean;
    canDelete?: boolean;
    dragHandleEnabled?: boolean;
    cells?: Array<any>;
    style?: any;
    onEdit?: () => void;
    onDelete?: () => void;
}
const ListItem: React.FC<ListItemProps> = ({
    data = {},
    cells = [],
    canEdit = false,
    canDelete = false,
    dragHandleEnabled = false,
    style = {},
    onEdit,
    onDelete,
}) => {
    return (
        <div className='list-item-container' style={{ zIndex: 99999999, ...style }}>
            <div className="list-item-cells">
                {cells.map((item: any, subIndex: number) => (
                    <div
                        key={subIndex}
                    >
                        {(() => {
                            switch (item.type) {
                                case "checkbox":
                                    return <span>{data[item.name] ? "Si" : "No"}</span>;
                                case "visibleId":
                                    return <span>{data.visible_id}</span>;
                                case "date":
                                    return (
                                        <span>
                                            <DateCell
                                                data={data[item.name]}
                                                extraData={data}
                                                config={item}
                                            />
                                        </span>
                                    );
                                case "template":
                                    return (
                                        <span>
                                            <TemplateCell
                                                data={data}
                                                template={item.template}
                                            />
                                        </span>
                                    );
                                case "tags":
                                    return (
                                        <span>
                                            <TagsCell data={data[item.name]} />
                                        </span>
                                    );
                                case "thumbnail":
                                    return (
                                        <span>
                                            <ThumbnailCell data={data[item.name]} />
                                        </span>
                                    );
                                case "money":
                                    return (
                                        <span>
                                            <MoneyCell data={data[item.name]} />
                                        </span>
                                    );
                                case "text":
                                case "integer":
                                case "decimal":
                                    return (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <span
                                                style={{
                                                    textAlign: item.type === "text" ? "left" : "right",
                                                    lineHeight: 1
                                                }}
                                            >
                                                {item.prepend && <strong> {item.prepend} </strong>}
                                                {data[item.name] || "-"}
                                                {item.append && <strong> {item.append} </strong>}
                                            </span>
                                        </div>
                                    );
                                case "radio":
                                    return (
                                        <span>
                                            <RadioCell data={data[item.name]} options={item.options} />
                                        </span>
                                    );
                                case "icon":
                                    return (
                                        <span>
                                            <IconCell data={data[item.name]} color={item.color} size={item.size} />
                                        </span>
                                    );
                                default:
                                    return <div><span>{data[item.name] || "-"}</span></div>;
                            }
                        })()}
                    </div>
                ))}
            </div>
            <div className="list-item-actions">
                {canEdit && <IconButton
                    aria-label="edit"
                    onClick={onEdit}
                    size="small"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    <EditIcon />
                </IconButton>}
                {canDelete && <IconButton
                    aria-label="delete"
                    onClick={onDelete}
                    size="small"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    <DeleteIcon />
                </IconButton>}
                {dragHandleEnabled && <DragHandle />}
            </div>
        </div>
    )
}

export default ListItem