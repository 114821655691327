import React, { useEffect, useState, Fragment, useRef } from "react";
import { Dialog, IconButton, Slide, Alert, Fab, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import "./DividerBuilderModal.scss";
import { getDividerGroups } from "../../../utils/get-divider-groups";
import BackupIcon from "@mui/icons-material/Backup";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";

import { Group } from "./components/Group/Group";
import { AddedGroups } from "./components/AddedGroups/AddedGroups";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DividerBuilderModal = ({
  open,
  setOpen,
  onDismiss,
  config,
  savePath,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const dividerGroups: any = getDividerGroups();
  const [dividerConfig, setDividerConfig] = useState(config);
  const [activeGroupIndex, setActiveGroupIndex] = useState(null as any);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const dividerConfigCopy = JSON.parse(JSON.stringify(config));
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };
  const addGroup = (item: any) => {
    setDividerConfig((prev: any) => {
      let data: any = [...prev];
      data.push(item.config);
      return data;
    });
  };
  useEffect(() => {
    console.log(config);
  }, []);
  useEffect(() => {
    if (JSON.stringify(dividerConfigCopy) === JSON.stringify(dividerConfig)) {
      setDisabledSaveBtn(true);
    } else {
      setDisabledSaveBtn(false);
    }
  }, [dividerConfig]);

  useEffect(() => {
    if (!open) {
      setDividerConfig(dividerConfigCopy);
    }
  }, [open]);
  const saveData = async () => {
    const res = await AxiosInterceptor.post(`${urls.server}/api/${savePath}`, {
      data: dividerConfig,
    });
    const data = await res.data;
    setAlertIsOpen(true);
    setDisabledSaveBtn(true);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onDismiss({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
          position: "relative",
        },
      }}
      className="dividers-builder-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Generador de pestañas</h2>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onDismiss({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="groups">
          <div>
            {dividerGroups.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <Group
                    icon={item.icon}
                    name={item.name}
                    visibleName={item.visibleName}
                    onAdd={() => addGroup(item)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="added-groups">
          <div className="paper">
            <span></span>
            <span></span>
            <div>
              <div>
                {/* <InputField
                  id="divider-name"
                  defaultValue={dividerConfig?.name}
                  label={"Nombre de la divider"}
                  onChange={(value: string) => {
                    setDividerConfig((prev: any) => {
                      let data: any = { ...prev };
                      data.name = value;
                      return data;
                    });
                  }}
                /> */}
              </div>
              <div>
                <AddedGroups
                  items={dividerConfig || []}
                  setActiveGroupIndex={setActiveGroupIndex}
                  activeGroupIndex={activeGroupIndex}
                  onDeleteItem={(data: any, index: number) => {
                    setDividerConfig((prev: any) => {
                      let data: any = [...prev];
                      data.splice(index, 1);
                      return data;
                    });
                  }}
                  onSelectItem={(data: any, index: number) => {}}
                  onChangeItem={(data: any, index: number) => {
                    setDividerConfig((prev: any) => {
                      let dataCopy: any = [...prev];

                      dataCopy[index] = data;
                      return dataCopy;
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "300px" }}></div>
      </div>
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="add"
        onClick={() => {
          saveData();
        }}
        disabled={disabledSaveBtn}
      >
        <BackupIcon />
      </Fab>
      <Snackbar
        open={alertIsOpen}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        //style={{ zIndex: 999999 }}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Cambios guardados con éxito.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export { DividerBuilderModal };
