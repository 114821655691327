import React, { useState, Fragment, useEffect } from "react";
import urls from "config/urls";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { UserSelectionModal } from "../../modals/UserSelectionModal/UserSelectionModal";

import "./UsersCell.scss";
import { UserListModal } from "components/modals/UserListModal/UserListModal";
import { CloseIcon } from "components/icons/CloseIcon";
const UsersCell = (props: any) => {
  const {
    data,
    modalSubtitle,
    tableName,
    recordId,
    onChange,
    setRefreshData,
    readonly,
  } = props;
  const [userSelectionModalIsOpen, setUserSelectionModalIsOpen] =
    useState(false);
  const [userListModalIsOpen, setUserListModalIsOpen] = useState(false);
  useEffect(() => {
    if (userSelectionModalIsOpen && setRefreshData) setRefreshData(false);
  }, [userSelectionModalIsOpen]);
  return (
    <>
      <div className="users-cell-container">
        <a
          onClick={() => {
            if (readonly != true) {
              setUserSelectionModalIsOpen(true);
            } else {
              setUserListModalIsOpen(true);
            }
          }}
        >
          {data.map((item: any, index: number) => {
            let marginRight = "0px";
            if (index == 0 && data.length > 2) {
              marginRight = "40px";
            } else if (index == 1 && data.length > 2) {
              marginRight = "20px";
            } else if (index == 0 && data.length == 2) {
              marginRight = "20px";
            } else if (index == 1 && data.length == 2) {
              marginRight = "0px";
            } else {
              marginRight = "0px";
            }
            return (
              <Fragment key={index}>
                {index < 2 && (
                  <img
                    key={index}
                    src={urls.server + item.profile_picture}
                    alt=""
                    style={{
                      //right: index == 0 && data.length >= 2 ? "-20px" : "-30px",
                      //left: index == 0 ? "0px" : "20px",
                      marginRight: marginRight,
                    }}
                  />
                )}
              </Fragment>
            );
          })}
          {data.length > 2 && (
            <div
            // style={{
            //   right: "-20px",
            // }}
            >
              + {data.length - 2}
            </div>
          )}
          {data.length == 0 && !readonly && (
            <span>
              <PersonAddIcon />
            </span>
          )}
          {data.length == 0 && readonly && (
            <span>
              <CloseIcon />
            </span>
          )}
        </a>
      </div>
      <UserSelectionModal
        open={userSelectionModalIsOpen}
        setOpen={setUserSelectionModalIsOpen}
        // onDismiss={(res: any) => {
        //   console.log(res);
        //   if (res.refresh) onChange(true);
        // }}
        onClose={(res: any) => {
          if (res) onChange(res);
        }}
        data={data}
        subtitle={modalSubtitle}
        tableName={tableName}
        recordId={recordId}
        //cardData={data}
      />
      <UserListModal
        open={userListModalIsOpen}
        setOpen={setUserListModalIsOpen}
        onClose={(res: any) => {}}
        data={data}
        subtitle={modalSubtitle}
      />
    </>
  );
};

export { UsersCell };
