export const getFields = () => {
  const localityFields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Provincia",
      name: "province",
      searchPath: "provinces",
      primaryKey: "composite_field",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const zoneFields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const neighborhoodFields = [
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",

      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const addressFields = [
    {
      type: "text",
      visibleName: "Calle",
      name: "street",
      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Num.",
      name: "number",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Código postal",
      name: "postal_code",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Observación",
      name: "observation",
      placeholder: "Ej; piso, manzana, bloque...",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Barrio",
      name: "neighborhood",
      searchPath: "neighborhoods",
      primaryKey: "composite_field",

      formTitle: "Barrio",
      formFields: neighborhoodFields,
      formTableName: "neighborhoods",
      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Zona",
      name: "zone",
      searchPath: "zones",
      primaryKey: "name",

      description: null,
      formTitle: "Zona",
      formFields: zoneFields,
      formTableName: "zones",
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",
      formTitle: "Form. de ciudad",
      formFields: localityFields,
      formTableName: "localities",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },

    {
      type: "coordinates",
      visibleName: "Coordenadas",
      name: "coordinates",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const productFields: any = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: null,
      editableIf: null,
      validations: {},
    },

    // {
    //   type: "integer",
    //   visibleName: "Stock inicial",
    //   name: "initial_stock",
    //   description: null,
    //   defaultValue: 1,
    //   size: 4,
    //   editable: null,
    //   editableIf: null,
    //   showIf: "is_trackable",
    //   validations: {},
    // },
  ];
  const data: any = {
    product: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descipción",
        name: "description",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "select",
        visibleName: "Marca",
        name: "brand",
        searchPath: "brands",
        primaryKey: "name",
        description: null,
        size: 12,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
        formFields: [
          {
            name: "name",
            visibleName: "Nombre",
            type: "text",
            size: 12,
            editable: true,
            validations: {
              required: true,
            },
          },

          {
            name: "description",
            visibleName: "Descripción",
            type: "longText",
            editable: true,
            size: 12,
            validations: {},
          },
        ],
        formTitle: "Form. de marca",
        formTableName: "brands",
      },
      {
        type: "select",
        visibleName: "IVA para la venta",
        name: "sale_value_added_tax",
        searchPath: "value-added-taxes",
        primaryKey: "name",
        description: null,
        filters: [],
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
        formFields: [
          {
            type: "text",
            visibleName: "Nombre",
            name: "name",
            description: null,
            size: 6,
            editable: null,
            editableIf: null,
            validations: {},
          },
          {
            type: "decimal",
            visibleName: "Alícuota",
            name: "rate",
            description: null,
            size: 6,
            append: "%",
            editable: null,
            editableIf: null,
            validations: {},
          },
        ],
        formTitle: "Form. de IVA",
        formTableName: "value_added_taxes",
      },
      {
        type: "select",
        visibleName: "IVA para la compra",
        name: "purchase_value_added_tax",
        searchPath: "value-added-taxes",
        primaryKey: "name",
        description: null,
        filters: [],
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
        formFields: [
          {
            type: "text",
            visibleName: "Nombre",
            name: "name",
            description: null,
            size: 6,
            editable: null,
            editableIf: null,
            validations: {},
          },
          {
            type: "decimal",
            visibleName: "Alícuota",
            name: "rate",
            description: null,
            size: 6,
            append: "%",
            editable: null,
            editableIf: null,
            validations: {},
          },
        ],
        formTitle: "Form. de IVA",
        formTableName: "value_added_taxes",
      },
      {
        type: "imageCropper",
        visibleName: "Miniatura",
        name: "thumbnail",
        description: "",
        size: 12,
        aspect: 1,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "images",
        visibleName: "Imagenes",
        name: "images",
        description: null,
        size: 12,

        editable: true,
        editableIf: null,
        validations: { maxItems: 3 },
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 1,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Se vende",
        name: "is_sellable",
        //description: "",
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Tiene variantes",
        name: "has_variants",
        //description: "",
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Tiene extras",
        name: "has_extras",
        //description: "",
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Llevar seguimiento de stock?",
        name: "is_trackable",
        //description: "assss",
        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Es un producto compuesto",
        name: "is_composite",
        //description: "",
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Es favorite",
        name: "is_favorite",
        //description: "",
        size: 6,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },

      {
        type: "money",
        visibleName: "Costo de adquicisión",
        name: "cost",
        description: null,
        defaultValue: 0,
        size: 6,
        editable: true,
        editableIf: null,
        //showIf: "is_sellable",
        validations: {},
      },
      {
        type: "money",
        visibleName: "Precio de venta",
        name: "price",
        description: null,
        defaultValue: 0,
        size: 6,
        editable: true,
        editableIf: null,
        showIf: "is_sellable",
        validations: {},
      },
      // {
      //   type: "decimal",
      //   visibleName: "Stock inicial",
      //   name: "initial_stock",
      //   description: null,
      //   defaultValue: "0",
      //   size: 6,
      //   editable: true,
      //   editableIf: null,
      //   showIf: "is_trackable",
      //   validations: {},
      // },
      {
        type: "text",
        visibleName: "Código",
        name: "code",
        description: null,
        size: 6,
        editable: true,
        editableIf: null,
        showIf: "has_variants == false",
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Punto de reposición",
        name: "reorder_point",
        description: null,
        defaultValue: "0",
        size: 6,
        editable: true,
        editableIf: null,
        showIf: "is_trackable and has_variants == false",
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Cantidad a reponer",
        name: "reorder_quantity",
        description: null,
        defaultValue: "0",
        size: 6,
        editable: true,
        editableIf: null,
        showIf: "is_trackable and has_variants == false",
        validations: {},
      },
      {
        type: "productVariants",
        visibleName: "Variantes",
        name: "variants",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        showIf: "has_variants",
        validations: {},
      },
      {
        type: "grid",
        visibleName: "Extras",
        name: "extras",
        showIf: "is_sellable and has_extras",
        cols: [
          {
            type: "integer",
            name: "quantity",
            size: 2,
            visibleName: "Cantidad",
            defaultValue: 1,
          },
          {
            type: "select",
            size: 7,
            name: "extra",
            visibleName: "Extra",
            searchPath: "products",
            tableName: "products",
            primaryKey: "name",
            //secondaryKey: "sku",
            placeholder: "Buscar productos...",
            formFields: productFields,
            formTitle: "Ficha de producto",
          },
          {
            type: "money",
            name: "cost",
            size: 3,
            visibleName: "Costo",
            defaultValue: 0,
          },
          // {
          //   type: "money",
          //   name: "cost",
          //   size: 1,
          //   visibleName: "Costo",
          //   //defaultValue: 0,
          // },
        ],
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "grid",
        visibleName: "Componentes",
        name: "components",
        showIf: "is_composite",
        cols: [
          {
            type: "integer",
            name: "quantity",
            size: 2,
            visibleName: "Cantidad",
            defaultValue: 1,
          },
          {
            type: "select",
            size: 10,
            name: "component",
            visibleName: "Componente",
            searchPath: "products",
            tableName: "products",
            primaryKey: "name",
            secondaryKey: "sku",
            placeholder: "Buscar productos...",
            formFields: productFields,
            formTitle: "Ficha de producto",
          },
        ],
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      /*       {
        type: "longText",
        visibleName: "DescipciónCopy",
        name: "description_1",
        showIf:"is_composite",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      }, */
    ],
    brand: [
      {
        name: "name",
        visibleName: "Nombre",
        type: "text",
        size: 12,
        editable: true,
        validations: {
          required: true,
        },
      },

      {
        name: "description",
        visibleName: "Descripción",
        type: "longText",
        editable: true,
        size: 12,
        validations: {},
      },
    ],
    extra: [
      {
        name: "name",
        visibleName: "Nombre",
        type: "text",
        size: 12,
        editable: true,
        validations: {
          required: true,
        },
      },

      {
        name: "description",
        visibleName: "Descripción",
        type: "longText",
        editable: true,
        size: 12,
        validations: {},
      },
    ],
    location: [
      {
        name: "name",
        visibleName: "Nombre",
        type: "text",
        size: 12,
        editable: true,
        validations: {
          required: true,
        },
      },
      {
        type: "select",
        visibleName: "Domicilio",
        name: "address",
        searchPath: "addresses",
        primaryKey: "composite_field",
        //secondaryKey: "display_identifiers",
        formFields: addressFields,
        formTitle: "Domicilio",
        formTableName: "addresses",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        name: "description",
        visibleName: "Descripción",
        type: "longText",
        editable: true,
        size: 12,
        validations: {},
      },
    ],
    // measurementUnit: [
    //   {
    //     name: "name",
    //     visibleName: "Nombre",
    //     type: "text",
    //     size: 9,
    //     editable: true,
    //     validations: {
    //       required: true,
    //     },
    //   },
    //   {
    //     name: "abbr",
    //     visibleName: "Abreviatura",
    //     type: "text",
    //     size: 3,
    //     editable: true,
    //     validations: {
    //       required: true,
    //     },
    //   },
    //   {
    //     name: "description",
    //     visibleName: "Descripción",
    //     type: "longText",
    //     editable: true,
    //     size: 12,
    //     validations: {},
    //   },
    // ],
    serialNumber: [
      {
        type: "select",
        visibleName: "Producto",
        name: "product",
        searchPath: "products",
        primaryKey: "name",
        description: null,
        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        name: "serial_number",
        visibleName: "Num. de serie",
        type: "text",
        size: 6,
        prepend: "#",
        validations: {
          required: true,
        },
      },
    ],
    // productAttribute: [
    //   {
    //     type: "text",
    //     visibleName: "Nombre",
    //     name: "name",
    //     description: null,
    //     size: 6,
    //     editable: null,
    //     editableIf: null,
    //     validations: {},
    //   },
    // ],
  };

  return data;
};
