import React, { useState, useEffect } from "react";
import "./TextFilter.scss";
const TextFilter = ({ defaultValue, label, onChange }: any) => {
  const conditions = [
    { key: "equals", label: "Igual a" },
    { key: "contains", label: "Contiene" },
  ];
  const [value, setValue] = useState(defaultValue as any);
  const handleConditionClick = (condition: string) => {
    setValue((prevValue: any) => ({ ...prevValue, condition }));
  };

  // Actualiza el valor de 'data' en el estado
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prevValue: any) => ({ ...prevValue, data: event.target.value }));
  };
  useEffect(() => {
    if (value) onChange(value);
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
  }, []);
  return (
    <div className="text-filter-container">
      <label className="filter-label">{label}</label>
      <div className="radio-wrapper">
        {conditions.map((item: any, index: number) => (
          <a
            key={index}
            className={item.key === value?.condition ? "selected" : ""}
            onClick={() => handleConditionClick(item.key)}
          >
            {item.label}
          </a>
        ))}
      </div>
      <div className="value-wrapper">
        <input onChange={handleInputChange} value={value?.data || ""} />
      </div>
    </div>
  );
};

export { TextFilter };
