import React from "react";
import { ProductAttributeValuesSelector } from "../ProductAttributeValuesSelector/ProductAttributeValuesSelector";
import { ProductVariantList } from "../ProductVariantList/ProductVariantList";
import "./EditableProductVariantsField.scss";
const EditableProductVariantsField = ({
  attributesWithValues,
  setAttributesWithValues,
  onChange,
  formData,
  data,
}: any) => {
  return (
    <div className="editable-product-variants-field-container">
      <ProductAttributeValuesSelector
        data={attributesWithValues}
        onChange={(val: any) => {
          setAttributesWithValues(val);
        }}
      />
      <ProductVariantList
        attributesWithValues={attributesWithValues}
        defaultCost={formData?.cost}
        defaultPrice={formData?.price}
        isTrackable={formData?.is_trackable}
        isSellable={formData?.is_sellable}
        // defaultInitialStock={formData?.initial_stock}
        // defaultReorderPoint={formData?.reorder_point}
        // defaultReorderQuantity={formData?.reorder_quantity}
        data={data}
        onChange={(val: any) => {
          onChange(val);
          //setData(val);
        }}
      />
    </div>
  );
};

export { EditableProductVariantsField };
