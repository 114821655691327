import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  IconButton,
  Slide,
  Fab,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import "./ConfigImporterModal.scss";
import { UploadIcon } from "components/icons/UploadIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReactJson from "react-json-view";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ConfigImporterModal = ({
  open,
  setOpen,
  onClose,
  subTitle,
  savePath,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [data, setData] = useState(null as any);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const onPreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target!.result as string;
        setData(JSON.parse(data));
        setDisabledSaveBtn(false);
      };
      reader.readAsText(file);
    }
  };
  const onSave = async () => {
    setProcessing(true);
    try {
      let url = `${urls.server}/api/${savePath}`;
      //const dataToSend:any = data;
      const res: any = await AxiosInterceptor.post(url, { data: data });
      const resData = await res.data;

      onClose({
        refresh: true,
      });
      setProcessing(false);
      setOpen(false);
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
    }
  };
  useEffect(() => {
    if (!open) {
      setData(null);
      setProcessing(false);
      setDisabledSaveBtn(true);
    }
  }, [open]);
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="config-importer-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Subir JSON</h2>
            {subTitle && <div>{subTitle}</div>}
          </div>
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          <div className="upload-button-wrapper">
            {!data ? (
              <>
                <input
                  ref={uploadInputRef}
                  type="file"
                  accept=".json"
                  className="upload-input"
                  onChange={onPreviewUpload}
                />
                <Button
                  className="upload-button"
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                  variant="contained"
                >
                  <strong>Seleccionar</strong>
                  <UploadFileIcon />
                </Button>
              </>
            ) : (
              <div className="json-preview">
                <Button
                  onClick={() => {
                    setData(null);
                    setDisabledSaveBtn(true);
                  }}
                >
                  Volver a cargar
                </Button>
                <React.Fragment>
                  <ReactJson src={data} />
                </React.Fragment>
              </div>
            )}
          </div>
        </div>
        <Fab
          className="fab-btn"
          color="primary"
          aria-label="add"
          disabled={disabledSaveBtn}
          onClick={onSave}
        >
          {<UploadIcon />}
        </Fab>
        <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
        //style={{ zIndex: 999999 }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
};

export { ConfigImporterModal };
