import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DynamicForm } from "components/DynamicForm/DynamicForm";
import "./FormDialog.scss";
import { DialogContentText, DialogTitle } from "@mui/material";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { InformationMessage } from "components/InformationMessage/InformationMessage";
const FormDialog = ({
  open,
  setOpen,
  title,
  description,
  fields,
  onClose,
  onSubmit,
  extraData,
  defaultFormData,
  maxWidth = "md",
  createPath,
  editPath,
  mode
}: any) => {
  const [data, setData] = useState({} as any);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const onSave = async () => {
    setProcessing(true);
    const dataToSend = data;
    try {
      if (editPath) {
        const url = `${urls.server}/api/${editPath}`;
        const res = await AxiosInterceptor.put(url, dataToSend);
        const data = await res.data;
        const { message } = data;
        setAlertMsg(message.toString());
        setOpenAlert(true);
        setSeverity("success");
        onClose(data);
      } else if (createPath) {
        const url = `${urls.server}/api/${createPath}`;
        const res = await AxiosInterceptor.post(url, dataToSend);
        const data = await res.data;
        const { message } = data;
        setAlertMsg(message.toString());
        setOpenAlert(true);
        setSeverity("success");
        onClose(data);
      }
      setProcessing(false);
      setOpen(false);
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose(null);
  };

  return (
    <>
      {" "}
      <Dialog
        className="form-dialog-container"
        fullWidth
        open={open}
        maxWidth={maxWidth}
        onClose={handleClose}
      >
        <DialogTitle className="dialog-form-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className="dialog-form-description">
            {description}
          </DialogContentText>
          <DynamicForm
            fields={fields}
            onChange={(res: any) => {
              setData(res);
            }}
            extraData={extraData}
            formData={defaultFormData}
            mode={mode}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={processing}
            onClick={() => {
              if (editPath || createPath) {
                onSave();
              } else {
                onClose(null);
                onSubmit(data);
              }
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </>
  );
};

export { FormDialog };
