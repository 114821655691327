import React, { useState, useEffect, useRef } from "react";
import { Dialog, IconButton, Slide, Fab, Button } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import "./IndicatorDataImporterModal.scss";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import { CheckIcon } from "../../../../components/icons/CheckIcon";

import AxiosInterceptor from "../../../../AxiosInterceptor";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import urls from "config/urls";
import { InformationMessage } from "components/InformationMessage/InformationMessage";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const IndicatorDataImporterModal = ({
  open,
  setOpen,
  onClose,
  indicatorId,
  indicatorName,
  indicatorVisibleName,
  indicatorCode,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [data, setData] = useState([] as any);
  const [errors, setErrors] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [headers, setHeaders] = useState([] as string[]);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const onPreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target!.result as string;
        const lines = text.split("\n");
        const headers = lines[0].split(";");
        const data = lines.slice(1).map((line) => line.split(";"));
        setData(data);
        setHeaders(headers); // Agregar esta línea para almacenar los encabezados
      };
      reader.readAsText(file);
    }
  };

  const onSave = async () => {
    if (data.length === 0) {
      return;
    }

    setProcessing(true);
    try {
      const formData = new FormData(); // Crea un nuevo formulario
      formData.append("indicator_id", indicatorId); // Agrega el indicator_id al formulario

      // Agrega el archivo CSV al formulario con la clave "csv_file"
      //const blob = new Blob([data.join("\n")], { type: "text/csv" });
      //const csvContent = [headers.join(";"), ...data].join("\n");
      const csvContent = [
        headers.join(";"),
        ...data.map((row: any) => row.join(";")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      formData.append("csv_file", blob, "data.csv");

      const res = await AxiosInterceptor.post(
        `${urls.server}/api/indicators/import-data`,
        formData, // Envía el formulario
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const resData = await res.data;
      setProcessing(false);
      setRefreshOnClose(true);
      setOpenAlert(true);
      setAlertMsg("Valores importados correctamente.");
      setSeverity("success");
      setData([]);
    } catch (error: any) {
      setProcessing(false);
      setOpenAlert(true);
      setAlertMsg(error.toString());
      setSeverity("error");
    }
  };
  useEffect(() => {
    console.log(JSON.stringify(data));
  }, [data]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="indicator-data-importer-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Subir CSV</h2>
            <div>
              <b>Indicador: </b>
              <span>
                {indicatorVisibleName} ({indicatorCode})
              </span>
            </div>
          </div>
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          <div className="upload-button-wrapper">
            {data.length == 0 ? (
              // <input type="file" onChange={onPreviewUpload} />
              <>
                <input
                  ref={uploadInputRef}
                  type="file"
                  accept=".csv"
                  className="upload-input"
                  onChange={onPreviewUpload}
                />
                <Button
                  className="upload-button"
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                  variant="contained"
                >
                  <strong>Seleccionar</strong>
                  <UploadFileIcon />
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setData([]);
                  }}
                >
                  Volver a cargar
                </Button>
              </>
            )}
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row: any, rowIndex: number) => (
                  <tr key={rowIndex}>
                    {row.map((cell: any, cellIndex: number) => (
                      <td
                        key={cellIndex}
                        data-column-header={headers[cellIndex]}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Fab
          className="fab"
          variant="extended"
          //color="primary"
          aria-label="add"
          disabled={processing}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          onClick={onSave}
        >
          <CheckIcon sx={{ mr: 1 }} />
          Guardar
        </Fab>
      </Dialog>
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </>
  );
};

export { IndicatorDataImporterModal };
