// export const formatNumber = (value: any) => {
//   return parseFloat(value).toLocaleString("es-AR", {
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 2,
//   });
// };
export const formatNumber = (value: any, decimalPrecision: number = 2) => {
  return parseFloat(value).toLocaleString("es-AR", {
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
  });
};
