import React from "react";
import "./SubscriptionsCard.scss";
import { AnimatedNumber } from "../../../../components/AnimatedNumber/AnimatedNumber";
const SubscriptionsCard = (props: any) => {
  const { title, data, id } = props;
  console.log(id);
  return (
    <div className="subscriptions-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <div>
          <AnimatedNumber
            value={data.subscriptionsCount}
            duration="500"
            fontWeight="700"
            //color={}
          />
          <span>Susc. activas</span>
        </div>

        <div>
          <AnimatedNumber
            value={data.outstandingBalance}
            duration={1000}
            type="decimal"
            prepend="$"
            fontWeight="700"
          />
          <span>
            Sin cobrar (
            <AnimatedNumber
              value={data.subscriptionsWithOutstandingBalanceCount}
              duration={1000}
            />
            )
          </span>
        </div>
        <div>
          <AnimatedNumber
            value={data.pastDueBalance}
            duration={1000}
            type="decimal"
            color={"#ed3b4b"}
            prepend="$"
            fontWeight="700"
          />

          <span>
            Vencidas (
            <AnimatedNumber
              value={data.subscriptionsWithPastDueBalanceCount}
              duration={1000}
            />
            )
          </span>
        </div>
      </div>
    </div>
  );
};

export { SubscriptionsCard };
