export const getFields = () => {
  const zoneFields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const neighborhoodFields = [
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",

      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Descipción",
      name: "description",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const addressFields = [
    {
      type: "text",
      visibleName: "Calle",
      name: "street",
      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Num.",
      name: "number",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "text",
      visibleName: "Código postal",
      name: "postal_code",
      description: null,
      size: 3,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "longText",
      visibleName: "Observación",
      name: "observation",
      placeholder: "Ej; piso, manzana, bloque...",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Barrio",
      name: "neighborhood",
      searchPath: "neighborhoods",
      primaryKey: "composite_field",

      formTitle: "Barrio",
      formFields: neighborhoodFields,

      description: null,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Zona",
      name: "zone",
      searchPath: "zones",
      primaryKey: "name",

      description: null,
      formTitle: "Zona",
      formFields: zoneFields,
      size: 6,
      editable: true,
      editableIf: null,
      validations: {},
    },
    {
      type: "select",
      visibleName: "Ciudad",
      name: "locality",
      searchPath: "localities",
      primaryKey: "composite_field",

      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },

    {
      type: "coordinates",
      visibleName: "Coordenadas",
      name: "coordinates",
      description: null,
      size: 12,
      editable: true,
      editableIf: null,
      validations: {},
    },
  ];
  const data: any = {
    invoice: [
      {
        type: "transaction",
        //visibleName: "",
        name: "snapshot",
        description: null,
        size: 12,
        editable: false,
        editableIf: null,
        validations: {},
        transactionType: "sale",
        documentType: "Factura",
      },
    ],
    // order: [
    //   {
    //     type: "transaction",
    //     //visibleName: "",
    //     name: "data",
    //     description: null,
    //     size: 12,
    //     editable: null,
    //     editableIf: null,
    //     validations: {},
    //     transactionType: "sale",
    //     documentType: "Orden",
    //   },
    // ],
    quotation: [
      {
        type: "transaction",
        //visibleName: "",
        name: "data",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
        transactionType: "sale",
        documentType: "Cotización",
      },
    ],
    debitNote: [
      {
        type: "transaction",
        //visibleName: "",
        name: "data",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
        transactionType: "sale",
        documentType: "Nota de débito",
      },
    ],
    creditNote: [
      {
        type: "transaction",
        //visibleName: "",
        name: "data",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
        transactionType: "sale",
        documentType: "Nota de crédito",
      },
    ],
    payment: [
      {
        type: "transaction",
        //visibleName: "",
        name: "data",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
        transactionType: "sale",
      },
    ],
    customer: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "email",
        visibleName: "Email",
        name: "email",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "text",
        visibleName: "Whatsapp",
        name: "whatsapp_number",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "DNI",
        name: "personal_id",
        size: 6,
        description: "Solo números (sin puntos)",
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "integer",
        visibleName: "CUIT",
        name: "tax_id",
        description: "Solo números (sin puntos ni guiones)",
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Condición frente al IVA",
        name: "vat_condition",
        description: null,
        options: [
          {
            name: "Consumidor final",
            visibleName: "Consumidor final",
          },
          {
            name: "Responsable monotributo",
            visibleName: "Responsable monotributo",
          },
          {
            name: "Responsable inscripto",
            visibleName: "Responsable inscripto",
          },
          {
            name: "Exento",
            visibleName: "Exento",
          },
        ],
        size: 12,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "tags",
        visibleName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 3,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "select",
        visibleName: "Domicilio",
        name: "address",
        searchPath: "addresses",
        primaryKey: "composite_field",
        //secondaryKey: "display_identifiers",
        formFields: addressFields,
        formTitle: "Domicilio",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        visibleName: "Descripción",
        name: "description",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
    product: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "El producto se vende?",
        name: "is_sellable",
        //description: "assss",
        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Precio",
        name: "sale_price",
        description: null,
        size: 6,
        showIf: "is_sellable",
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
    valueAddedTax: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alícuota",
        name: "rate",
        description: null,
        size: 6,
        append: "%",
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
    otherTax: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alícuota",
        name: "rate",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        append: "%",
        validations: {},
      },
    ],
    perception: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alícuota",
        name: "rate",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        append: "%",
        validations: {},
      },
    ],
    interestRate: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "money",
        visibleName: "Importe minimo",
        name: "min_amount",
        description: null,
        size: 6,
        //defaultValue: "0.0",
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Alícuota",
        name: "rate_percentage",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        append: "%",
        validations: {},
      },
      {
        type: "periodicity",
        visibleName: "Periodicidad",
        name: "rate_periodicity",
        description: null,
        size: 6,
        editableIf: null,
        validations: {},
      },
      {
        type: "periodicity",
        visibleName: "Capitalización",
        name: "capitalization",
        description: null,
        size: 6,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Tipo de interes",
        name: "interest_type",
        description: null,
        //options: ["Simple", "Compuesto"],
        options: [
          { name: "simple", visibleName: "Simple" },
          { name: "compound", visibleName: "Compuesto" },
        ],
        defaultValue: "compound",
        size: 12,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
    priceList: [
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        description: null,
        size: 6,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Tipo de ajuste",
        name: "adjustment_type",
        description: null,
        options: [
          {
            name: "profit_margin",
            visibleName: "Margen de ganancia",
          },
          {
            name: "discount",
            visibleName: "Descuento",
          },
        ],
        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "decimal",
        visibleName: "Valor de ajuste",
        name: "adjustment_value",
        description: "Ej: 50%",
        size: 6,
        append: "%",
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "radio",
        visibleName: "Aplicar sobre",
        name: "base_on",
        description: null,
        options: [
          {
            name: "cost",
            visibleName: "Costo",
          },
          {
            name: "price",
            visibleName: "Precio de venta",
          },
        ],
        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
    ],
  };

  return data;
};
