import React from "react";
import "./DataTableCard.scss";
const DataTableCard = (props: any) => {
  const { title } = props;
  return (
    <div className="data-table-card-container">
      <h2 className="card-title">{title}</h2>
      <h3>Tabla de datos</h3>
      <div>
        <svg viewBox="0 0 32 32">
          <path
            className="table"
            d="M 29,25.055936 H 22.09117 V 29 H 24 c 1.326,0 2.609757,-0.136761 3.576609,-0.895472 C 28.376614,27.476745 29,26.73219 29,25.40619 Z M 18.65542,29 h -5.02369 v -3.944064 h 5.02369 z M 3,25.055936 v 0.350254 c 0,1.326 0.6670789,2.087642 1.4843046,2.718642 C 5.4401879,28.862894 6.674,29 8,29 h 2.052405 v -3.944064 z m 7.052405,-6.733851 v 2.659899 H 3 v -2.659899 z m 8.603015,0 v 2.659899 h -5.02369 v -2.659899 z m 10.34458,0 v 2.659899 H 22.09117 V 18.322085 Z M 10.052405,13.984772 H 3 v -2.659899 h 7.052405 z m 8.603015,0 h -5.02369 v -2.659899 h 5.02369 z m 10.34458,0 H 22.09117 V 11.324873 H 29 Z M 3,7.4162437 H 29 V 6.9847716 C 29,5.6587716 28.419768,4.9796585 27.679575,4.320425 26.70646,3.4537447 25.326,3 24,3 H 8 C 6.674,3 5.0974305,3.3349429 4.0970747,4.2679217 3.3828352,4.9340551 3,5.6587716 3,6.9847716 Z"
          />
        </svg>
      </div>
    </div>
  );
};

export { DataTableCard };
