import React, { useEffect, useState, Fragment, useRef } from "react";
import { Dialog, IconButton, Slide, Alert, Fab, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import "./TableBuilderModal.scss";
import { getCols } from "../../../utils/get-cols";
import { InputField } from "../../fields/InputField/InputField";
import BackupIcon from "@mui/icons-material/Backup";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { AddedCols } from "./components/AddedCols/AddedCols";
import { Col } from "./components/Col/Col";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TableBuilderModal = ({
  open,
  setOpen,
  onDismiss,
  config,
  savePath,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const tableBuilderCols: any = getCols();
  const [tableConfig, setTableConfig] = useState(config);
  const [activeColIndex, setActiveColIndex] = useState(null as any);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const tableConfigCopy = JSON.parse(JSON.stringify(config));
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };
  const addCol = (item: any) => {
    setTableConfig((prev: any) => {
      let data: any = { ...prev };
      if (!data.cols) data.cols = [];
      data?.cols.push(item.config);
      return data;
    });
  };
  useEffect(() => {
    console.log(config);
  }, []);
  useEffect(() => {
    if (JSON.stringify(tableConfigCopy) === JSON.stringify(tableConfig)) {
      setDisabledSaveBtn(true);
    } else {
      console.log(tableConfigCopy);
      console.log(tableConfig);
      setDisabledSaveBtn(false);
    }
  }, [tableConfig]);

  useEffect(() => {
    if (!open) {
      setTableConfig(tableConfigCopy);
    }
  }, [open]);
  const saveData = async () => {
    const res = await AxiosInterceptor.post(`${urls.server}/api/${savePath}`, {
      data: tableConfig,
    });
    const data = await res.data;
    setAlertIsOpen(true);
    setDisabledSaveBtn(true);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onDismiss({
          refresh: refreshOnClose,
        });
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
          position: "relative",
        },
      }}
      className="table-builder-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>Generador de tabla</h2>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onDismiss({
                refresh: refreshOnClose,
              });
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="cols">
          <div>
            {tableBuilderCols.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <Col
                    icon={item.icon}
                    name={item.name}
                    visibleName={item.visibleName}
                    onAdd={() => addCol(item)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="added-cols">
          <div className="paper">
            <span></span>
            <span></span>
            <div>
              <div>
                <InputField
                  id="table-name"
                  defaultValue={tableConfig?.name}
                  label={"Nombre de la table"}
                  onChange={(value: string) => {
                    setTableConfig((prev: any) => {
                      let data: any = { ...prev };
                      data.name = value;
                      return data;
                    });
                  }}
                />
              </div>
              <div>
                <AddedCols
                  items={tableConfig?.cols || []}
                  setActiveColIndex={setActiveColIndex}
                  activeColIndex={activeColIndex}
                  onDeleteItem={(data: any, index: number) => {
                    setTableConfig((prev: any) => {
                      let data: any = { ...prev };
                      data?.cols.splice(index, 1);
                      return data;
                    });
                  }}
                  onSelectItem={(data: any, index: number) => {}}
                  onChangeItem={(data: any, index: number) => {
                    setTableConfig((prev: any) => {
                      let dataCopy: any = { ...prev };

                      dataCopy.cols[index] = data;
                      return dataCopy;
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "300px" }}></div>
      </div>
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="add"
        onClick={() => {
          saveData();
        }}
        disabled={disabledSaveBtn}
      >
        <BackupIcon />
      </Fab>
      <Snackbar
        open={alertIsOpen}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        //style={{ zIndex: 999999 }}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Tabla guardada con éxito.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export { TableBuilderModal };
