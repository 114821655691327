import React, { useState } from "react";
import "./TaskItem.scss";
import { UsersCell } from "../../../../components/cells/UsersCell/UsersCell";
import { TagsCell } from "../../../../components/cells/TagsCell/TagsCell";
import { StatusCell } from "../../../../components/cells/StatusCell/StatusCell";
import { MoreActionsCell } from "../../../../components/cells/MoreActionsCell/MoreActionsCell";

import { FormModal } from "components/modals/FormModal/FormModal";
import { getFields } from "../../utils/get-fileds";
import { WorkflowEntryDetailModal } from "cards/workflowEntries/components/WorkflowEntryDetailModal/WorkflowEntryDetailModal";
import colors from "config/colors";
const TaskItem = (props: any) => {
  const { data, setRefreshData, mode, onChange } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [workflowEntryDetailModalIsOpen, setWorkflowEntryDetailModalIsOpen] =
    useState(false);
  const fields = getFields().task;
  const modalSubtitle = `Tarea ${data.visible_id}`;
  const statusOptions = [
    {
      //icon: "RadioButtonUnchecked",
      name: "to-do",
      visibleName: "Por hacer",
      color: colors.medium,
    },
    {
      //icon: "RadioButtonUnchecked",
      name: "doing",
      visibleName: "En proceso",
      color: colors.dark,
    },
    {
      //icon: "checkCircleOutline",
      name: "done",
      visibleName: "Finalizada",
      color: colors.success,
    },
  ];
  const moreActionsOptions = data?.workflow_step_entry_id
    ? [
      {
        //icon: "RadioButtonUnchecked",
        actionName: "view",
        actionVisibleName: "Detalle",
      },
      {
        //icon: "RadioButtonUnchecked",
        actionName: data?.action_type,
        actionVisibleName:
          data?.action_type == "complete_form"
            ? "Completar formulario"
            : "Aprobar paso",
      },
      {
        //icon: "RadioButtonUnchecked",
        actionName: "delete",
        actionVisibleName: "Eliminar",
      },
    ]
    : [
      {
        //icon: "RadioButtonUnchecked",
        actionName: "view",
        actionVisibleName: "Detalle",
      },
      {
        //icon: "RadioButtonUnchecked",
        actionName: "delete",
        actionVisibleName: "Eliminar",
      },
    ];
  return (
    <div className="task-item-container">
      <div>
        <div>
          <span>{data.visible_id}</span>
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />
        </div>
        <TagsCell data={data.selected_tags} />
      </div>
      <div>
        {/* <UsersCell data={data.users} modalSubtitle={modalSubtitle} /> */}

        <UsersCell
          data={data.users}
          modalSubtitle={modalSubtitle}
          tableName={"tasks"}
          recordId={data.id}
          setRefreshData={setRefreshData}
          onChange={(val: any) => {
            if (val) {
              onChange(true);
            }
          }}
          readonly={mode == "all" ? false : true}
        />

        <StatusCell
          readonly={data?.workflow_step_entry_id ? true : false}
          options={statusOptions}
          data={data.status}
          recordId={data.id}
          tableName={"tasks"}
          setRefreshData={setRefreshData}
          onChange={(res: boolean) => {
            if (res) {
              onChange(true);
            }
          }}
        />
        <MoreActionsCell
          options={moreActionsOptions}
          onSelect={(val: any) => {
            console.log(val);
            if (val == "view") {
              setFormModalIsOpen(true);
            }
            if (val == "complete_form") {
              setWorkflowEntryDetailModalIsOpen(true);
            }
          }}
        />
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => { }}
        title={"Form. de tarea"}
        fields={fields}
        tableName={"tasks"}
        recordId={data?.id}
        mode="edit"
      />
      <WorkflowEntryDetailModal
        open={workflowEntryDetailModalIsOpen}
        setOpen={setWorkflowEntryDetailModalIsOpen}
        onClose={(res: boolean) => {
          if (res) onChange(true);
        }}
        workflowEntryId={data?.workflow_step_entry?.workflow_entry_id}
        goToStep={
          data?.workflow_step_entry?.workflow_step?.order
            ? data?.workflow_step_entry?.workflow_step?.order - 1
            : null
        }
      />
    </div>
  );
};

export { TaskItem };
