import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  IconButton,
  Slide,
  Fab,
  Button,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import { Tooltip } from "@mui/material";
import "./DataImporterModal.scss";
import { CheckIcon } from "components/icons/CheckIcon";
import { UploadIcon } from "components/icons/UploadIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import colors from "config/colors";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DataImporterModal = ({
  open,
  setOpen,
  onClose,
  configurationPath,
  savePath,
  title,
  subtitle,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [data, setData] = useState([] as any);
  const [errors, setErrors] = useState([]);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [cols, setCols] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const onPreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      setFileToUpload(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target!.result as string;
        const lines = text.split("\n");
        const headers = lines[0].split(";");
        const data = lines.slice(1).map((line) => line.split(";"));
        setData(data);
        validateData(headers, data);
      };
      reader.readAsText(file);
    }
  };
  const isNumeric = (value: string) => {
    const noCommas = value.replace(",", ".").replace(/\.(?=.*\.)/g, "");
    return !isNaN(Number(noCommas));
  };

  const validateData = (headers: string[], data: string[][]) => {
    const newErrors: any = [];
    data.forEach((row, rowIndex) => {
      if (row.length > cols.length) {
        newErrors.push(
          `La fila ${rowIndex + 1} tiene más columnas de las definidas.`
        );
      }
      if (row.length < cols.length) {
        newErrors.push(
          `La fila ${rowIndex + 1} tiene menos columnas de las definidas.`
        );
      }
      row.forEach((value: any, colIndex) => {
        const type = cols[colIndex]?.type;
        if (type === "number" && !isNumeric(value)) {
          newErrors.push(
            `El valor "${value}" en la fila ${rowIndex + 1} columna ${colIndex + 1
            } no es un número.`
          );
        }
        // if (type === "number" && isNaN(Number(value))) {
        //   newErrors.push(
        //     `El valor "${value}" en la fila ${rowIndex + 1} columna ${
        //       colIndex + 1
        //     } no es un número.`
        //   );
        // }
        // if (type === "number" && !/^\d+(\.\d+)?$/.test(value)) {
        //   newErrors.push(
        //     `El valor "${value}" en la fila ${rowIndex + 1} columna ${
        //       colIndex + 1
        //     } no es un número 11.`
        //   );
        // }
        if (type === "string" && !isNaN(value)) {
          newErrors.push(
            `El valor "${value}" en la fila ${rowIndex + 1} columna ${colIndex + 1
            } debería ser una cadena de caracteres.`
          );
        }

        // Aquí puedes agregar más validaciones para otros tipos si es necesario.
      });
    });
    setErrors(newErrors);
    setDisabledSaveBtn(newErrors.length !== 0);
    console.log(newErrors);
  };
  type DataType = (string | number)[][];
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const filteredData: DataType = data.filter(
    (item: (string | number)[]) => item[0] !== "\r" && item[0] !== ""
  );

  const onSave = async () => {
    if (!fileToUpload) {
      return;
    }

    setProcessing(true);

    // Leer el contenido del archivo como texto
    const originalText = await fileToUpload.text();
    const lines = originalText.split("\n");

    // Construir la nueva cabecera
    const newHeader = cols.map((col: any) => col.name).join(";");

    // Eliminamos la cabecera original y agregamos la nueva
    lines[0] = newHeader;
    const modifiedCsvText = lines.join("\n");

    // Convertimos el texto modificado de nuevo a un Blob para enviarlo como un archivo
    const modifiedFile = new Blob([modifiedCsvText], { type: "text/csv" });

    const formData = new FormData();
    formData.append("csv_file", modifiedFile, fileToUpload.name); // Conservamos el nombre original del archivo

    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/${savePath}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const resData = await res.data;
      setProcessing(false);
      setRefreshOnClose(true);
      setData([]);
      setFileToUpload(null);
    } catch (error: any) {
      setProcessing(false);
      console.error("Error al subir el archivo:", error);
    }
  };
  const getConfiguration = async () => {
    setLoading(true);
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/${configurationPath}`
      );
      const resData = await res.data;
      setCols(resData?.json_schema);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open && configurationPath) {
      getConfiguration();
    }
  }, [open, configurationPath]);
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="data-importer-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>{title || "Subir CSV"}</h2>
            <div>{subtitle}</div>
          </div>
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          <div className="upload-button-wrapper">
            {filteredData.length == 0 ? (
              <>
                <input
                  ref={uploadInputRef}
                  type="file"
                  accept=".csv"
                  className="upload-input"
                  onChange={onPreviewUpload}
                />
                <Button
                  className="upload-button"
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                  variant="contained"
                >
                  <strong>Seleccionar</strong>
                  <UploadFileIcon />
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setData([]);
                }}
              >
                Volver a cargar
              </Button>
            )}
          </div>

          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {cols.map((col: any, i: number) => (
                    <th key={i}>{col.visibleName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row: (string | number)[], i: number) => {
                  const rowErrors = errors.filter((error: any) =>
                    error.includes(`fila ${i + 1} `)
                  );

                  return (
                    <tr
                      key={i}
                      style={{
                        color: rowErrors.length > 0 ? colors.danger : "auto",
                      }}
                    >
                      <td data-column-header={"Estado"}>
                        {rowErrors.length > 0 ? (
                          <Tooltip
                            title={
                              <Typography
                                style={{ fontSize: "13px", fontWeight: 300 }}
                                component="span"
                                dangerouslySetInnerHTML={{
                                  __html: rowErrors.join("<br />"),
                                }}
                              />
                            }
                          >
                            <span>{"X"}</span>
                          </Tooltip>
                        ) : (
                          <span>{"✓"}</span>
                        )}
                      </td>
                      {cols.map((col: any, j: number) => (
                        <td
                          data-column-header={col.visibleName}
                          key={j}
                          style={
                            {
                              //textAlign: isNumeric(row[j]) ? "right" : "left",
                            }
                          }
                        >
                          {row[j]}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Fab
          className="fab-btn"
          color="primary"
          aria-label="save"
          onClick={() => {
            onSave();
          }}
          disabled={disabledSaveBtn}
          size="large"
        >
          {<UploadIcon />}
        </Fab>
      </Dialog>
    </>
  );
};

export { DataImporterModal };
