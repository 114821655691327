
import { useEffect, useState } from "react";
import { useTextEditorField } from "./TextEditorFieldContext";
import { ReadonlyTextEditorField } from "./ReadonlyTextEditorField/ReadonlyTextEditorField";
import { EditableTextEditorField } from "./EditableTextEditorField/EditableTextEditorField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";


const TextEditorField = ({
  defaultValue,
  editable,
  label,
  editPath,
  onChange,
  name,
  recordId,
  readonly,
}: any) => {
  //const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const { handleAddItem, items, setItems, handleSortEnd } =
    useTextEditorField();
  useEffect(() => {
    if (onChange) {
      onChange(items);
    }
  }, [items]);
  useEffect(() => {
    setItems(defaultValue || []);
  }, []);

  return (
    <div className="text-editor-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <FieldEditControls
            value={items}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setItems(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setItems(originalValue);
              }
            }}
          />
        )}
      </div>
      <div className="field-body">
        <div>
          {readonly ||
            ((recordId || editPath) && editable && !isEditEnabled) ||
            ((recordId || editPath) && !editable) ? (
            <ReadonlyTextEditorField data={items} />
          ) : (
            <EditableTextEditorField
              data={items}
              onSortEnd={handleSortEnd}
              onAddItem={handleAddItem}
              onChange={(res: any) => {
                console.log(res);
                setItems(res);
              }}
            />
          )}
        </div>
      </div>

    </div>
  );
};

export { TextEditorField };
