import { useState, useEffect } from "react";
import { DateCell } from "../cells/DateCell/DateCell";
import { TagsCell } from "../cells/TagsCell/TagsCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { AuditLogModal } from "../modals/AuditLogModal/AuditLogModal";

import urls from "config/urls";
import AxiosInterceptor from "../../AxiosInterceptor";
import { FormModal } from "../modals/FormModal/FormModal";
import { ThumbnailCell } from "../cells/ThumbnailCell/ThumbnailCell";
import { MoneyCell } from "../cells/MoneyCell/MoneyCell";
import { formatNumber } from "utils";
import { StatusCell } from "components/cells/StatusCell/StatusCell";
const DynamicReportTableRow = ({ data, cols }: any) => {
  return (
    <tr>
      {cols.map((col: any, subIndex: number) => (
        <td
          key={subIndex}
          data-column-header={col["visibleName"]}
          style={{ width: col.type == "thumbnail" ? "70px" : "auto" }}
        >
          {(() => {
            switch (col.type) {
              case "checkbox":
                return <span>{data[col.name] ? "Si" : "No"}</span>;
              case "visibleId":
                return <span>{data.visible_id}</span>;
              case "date":
                return (
                  <span>
                    <DateCell
                      data={data[col.name]}
                      extraData={data}
                      config={col}
                    />
                  </span>
                );
              case "tags":
                return (
                  <span>
                    <TagsCell data={data[col.name]} />
                  </span>
                );
              case "status":
                return (
                  <span>
                    <StatusCell
                      data={data[col.name]}
                      options={col?.options}
                      readonly={true}
                      icon={col.icon}
                    />
                  </span>
                );
              case "thumbnail":
                return (
                  <span>
                    <ThumbnailCell data={data[col.name]} />
                  </span>
                );
              case "money":
                return (
                  <span>
                    <MoneyCell data={data[col.name]} />
                  </span>
                );
              case "text":
                return (
                  <span
                    style={{
                      textAlign: col.type === "text" ? "left" : "right",
                    }}
                  >
                    {col.prepend && <strong> {col.prepend} </strong>}
                    {data[col.name] == null ? "-" : data[col.name]}
                    {col.append && <strong> {col.append} </strong>}
                  </span>
                );
              case "integer":
              case "decimal":
                return (
                  <span
                    style={{
                      textAlign: col.type === "text" ? "left" : "right",
                    }}
                  >
                    {col.prepend && <strong> {col.prepend} </strong>}
                    {data[col.name] == null
                      ? "-"
                      : formatNumber(
                        data[col.name],
                        col.type == "integer" ? 0 : 2
                      )}
                    {col.append && <strong> {col.append} </strong>}
                  </span>
                );
              default:
                return <span>{data[col.name] || "-"}</span>;
            }
          })()}
        </td>
      ))}
    </tr>
  );
};

export { DynamicReportTableRow };
