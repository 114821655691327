export const getDividerGroups = () => {
  const data: any = {
    valueAddedTax: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "value-added-taxes/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "value-added-taxes/year-months-with-records",
      },
    ],
    otherTax: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "other-taxes/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "other-taxes/year-months-with-records",
      },
    ],
    perception: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "perceptions/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "perceptions/year-months-with-records",
      },
    ],
    withholding: [
      {
        type: "dates",
        name: "created_date",
        visibleName: "Fecha de creac.",
        abbr: "FC",
        path: "withholdings/dates-with-records",
      },
      {
        type: "months",
        name: "created_year_month",
        visibleName: "Mes de creac.",
        abbr: "MC",
        path: "withholdings/year-months-with-records",
      },
    ],
  };

  return data;
};
