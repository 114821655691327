import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Fab } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import "./ProductAttributesModal.scss";
import { HeaderSearchbar } from "../../../../components/HeaderSearchbar/HeaderSearchbar";
import { ProductAttributeList } from "../ProductAttributeList/ProductAttributeList";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { getFields } from "../../utils/get-fileds";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ProductAttributesModal = ({ open, setOpen, onDismiss }: any) => {
  const [searchTerm, setSearchTerm] = useState("" as any);
  const [filters, setFilters] = useState([] as any);
  const [refreshOnClose, setRefreshOnClose] = useState(false);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onDismiss({
            refresh: refreshOnClose,
          });
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="product-attributes-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>{"Atributos de productos"}</h2>
          </div>
          <HeaderSearchbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onDismiss({
                  refresh: refreshOnClose,
                });
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          <ProductAttributeList searchTerm={searchTerm} filters={filters} />
        </div>
      </Dialog>
    </>
  );
};

export { ProductAttributesModal };
