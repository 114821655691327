import React, { useState, useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DynamicForm } from "../../DynamicForm/DynamicForm";
import "./FormModal.scss";
import { CancelIcon } from "../../icons/CancelIcon";
import { CheckIcon } from "../../icons/CheckIcon";
import { Alert, Fab, Snackbar } from "@mui/material";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { InformationMessage } from "components/InformationMessage/InformationMessage";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FormModal = ({
  open,
  setOpen,
  onClose,
  fields = [],
  title = "",
  createPath,
  editPath,
  tableName,
  recordId,
  readonly,
  extraData = {},
  globalEdit = false,
  mode //create or edit
}: any) => {
  const [processing, setProcessing] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [values, setValues] = useState({});
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const onSave = async () => {
    setProcessing(true);

    try {
      if (editPath) {
        let url = `${urls.server}/api/${editPath}`;

        const dataToSend = values;
        const res = await AxiosInterceptor.put(url, dataToSend);
        const data = await res.data;
        const { message } = data;
        setAlertMsg(message.toString());
        onClose(data);
        setOpenAlert(true);
        setSeverity("success");
      } else {
        if (tableName) tableName = tableName.replace(/_/g, "-");
        let url = tableName
          ? `${urls.server}/api/${tableName}`
          : `${urls.server}/api/${createPath}`;
        const dataToSend = {
          ...values,
          //...extraData,
        };
        const res = await AxiosInterceptor.post(url, dataToSend);
        const data = await res.data;
        const { message } = data;
        setAlertMsg(message.toString());
        onClose(data);
        setOpenAlert(true);
        setSeverity("success");
      }
      setProcessing(false);
      setOpen(false);
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  const handleFieldEdit = (res: any) => {
    console.log("res", res);
    if (res) setRefreshOnClose(true);
  };
  useEffect(() => {
    if (open) {
      setRefreshOnClose(false);
      setOpenAlert(false);
    }
  }, [open]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        if (refreshOnClose) {
          onClose(true);
        } else {
          onClose();
        }
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
    >
      <div className="form-modal-body">
        <div>
          <div className="paper">
            <a
              onClick={() => {
                setOpen(false);
                if (refreshOnClose) {
                  onClose(true);
                } else {
                  onClose();
                }
              }}
            >
              <CancelIcon />
            </a>
            <span></span>
            <span></span>
            <div>
              <DynamicForm
                fields={fields}
                onChange={(data: any) => setValues(data)}
                title={title}
                //recordId={editPath ? null : recordId}
                recordId={recordId}
                tableName={tableName}
                onFieldEdit={handleFieldEdit}
                readonly={readonly}
                globalEdit={globalEdit} //La edicion es global
                extraData={extraData}
                mode={mode}
              />
            </div>
          </div>
        </div>
      </div>
      {(!recordId || (recordId && globalEdit)) && (
        <Fab
          color="primary"
          variant="extended"
          aria-label="save"
          disabled={processing}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          onClick={onSave}
        >
          <CheckIcon sx={{ mr: 1 }} />

          {!recordId ? "Guardar" : "Editar"}
        </Fab>
      )}
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </Dialog>
  );
};

export { FormModal };
