import React from "react";
import "./IndicatorGroupExpandedCard.scss";
const IndicatorGroupExpandedCard = (props: any) => {
  const { title, data } = props;
  return (
    <div className="indicator-group-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>
      </div>
      <div className="expanded-card-body"></div>
    </div>
  );
};

export { IndicatorGroupExpandedCard };
