import { formatDate } from "../../../utils";
import "./DateCell.scss";
const DateCell = ({ config, data, extraData }: any) => {
  return (
    <div className="date-cell-container">
      <div>{formatDate(data)}</div>
      {config.showDiff && <span> {extraData[config.name + "_diff"]}</span>}
    </div>
  );
};

export { DateCell };
