import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Fab, Button } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "components/icons/CloseIcon";

import AxiosInterceptor from "AxiosInterceptor";
import "./WorkflowEntryDetailModal.scss";
import { Spinner } from "components/Spinner/Spinner";
import { WorkflowEntryStepper } from "../WorkflowEntryStepper/WorkflowEntryStepper";
import { DynamicForm } from "components/DynamicForm/DynamicForm";
import { LockedDocumentIcon } from "components/icons/LockedDocumentIcon";
import { CheckIcon } from "components/icons/CheckIcon";
import { SaveAsDraftIcon } from "components/icons/SaveAsDraftIcon";
import { AlertIcon } from "components/icons/AlertIcon";
import { InformationMessage } from "components/InformationMessage/InformationMessage";
import { LockedIcon } from "components/icons/LockedIcon";
import { CommentIcon } from "components/icons/CommentIcon";
import { CommentsDrawer } from "../../../../components/drawers/CommentsDrawer/CommentsDrawer";
import Icon from "components/Icon/Icon";
import { InteractiveActions } from "../InteractiveActions/InteractiveActions";
import urls from "config/urls";
import colors from "config/colors";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FormWrapper = ({
  form,
  defaultFormData,
  canApprove,
  canComplete,
  minimumApprovals,
  userApprovalStatus,
  stepEntryId,
  status,
  onChange,
  approvals,
  setSeverity,
  setOpenAlert,
  setAlertMsg,
  isLocked,
  isEditable,
  readonly,
  name,
  commentCount,
}: any) => {
  const [processing, setProcessing] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [data, setData] = useState({} as any);
  const saveData = async () => {
    setProcessing(true);

    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/save-data`,
        { data: data }
      );
      const resData = await res.data;
      const { message } = resData;
      setAlertMsg(message.toString());
      setProcessing(false);
      setOpenAlert(true);
      setSeverity("success");
      onChange(true);
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };

  const saveDraftData = async () => {
    setProcessing(true);

    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/save-draft-data`,
        { data: data }
      );
      const resData = await res.data;
      const { message } = resData;
      setAlertMsg(message.toString());
      setProcessing(false);
      setOpenAlert(true);
      setSeverity("success");
      onChange(true);
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };
  const approve = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/approve`
      );
      setProcessing(false);
      onChange(true);
    } catch (error) {
      setProcessing(false);
    }
  };
  const reject = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/reject`
      );
      setProcessing(false);
      onChange(true);
    } catch (error) {
      setProcessing(false);
    }
  };
  return (
    <div className="form-container">
      {(status == "completed" ||
        status == "approved" ||
        status == "partialApproval" ||
        status == "rejected") &&
        canApprove &&
        !isLocked && (
          <div className="approval-container">
            {approvals.length > 0 && (
              <div className="approval-users">
                {approvals.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      {item.is_approved ? (
                        <span style={{ background: colors.success }}>
                          <CheckIcon />
                        </span>
                      ) : (
                        <span style={{ background: colors.danger }}>
                          <AlertIcon />
                        </span>
                      )}

                      <img
                        key={index}
                        src={urls.server + item.user_profile_picture}
                        alt=""
                        style={{}}
                      />
                      <div>{item.user_name}</div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="approval-btns">
              <Button
                disabled={userApprovalStatus == "approved"}
                onClick={approve}
                color="success"
              >
                <CheckIcon />
                Aprobar
              </Button>
              <Button
                disabled={userApprovalStatus == "rejected"}
                onClick={reject}
                color="warning"
              >
                <AlertIcon />
                Rechazar
              </Button>
            </div>
          </div>
        )}

      <div>
        {form ? (
          <>
            <div className="paper">
              <span></span>
              <span></span>
              <div>
                {/* {JSON.stringify(form?.fields)} */}
                <DynamicForm
                  fields={form?.fields}
                  onChange={(res: any) => {
                    setData(res);
                    //console.log("form res", res);
                    //setFormData(res);
                  }}
                  title={form?.title}
                  //formData={formData}
                  formData={defaultFormData}
                  readonly={
                    (!isEditable && status == "completed") ||
                      !canComplete ||
                      userApprovalStatus == "approved" ||
                      isLocked
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <Fab
              aria-label="save"
              size="small"
              disabled={processing}
              sx={{
                position: "fixed",
                bottom: !readonly ? 121 : 30,
                right: 16,
                overflow: "inherit",
              }}
              className="comment-fab"
              onClick={() => {
                setOpenComments(true);
              }}
            >
              <span className="badge">
                {commentCount > 99 ? "+99" : commentCount}
              </span>
              <CommentIcon />
            </Fab>
            <CommentsDrawer
              open={openComments}
              setOpen={setOpenComments}
              stepName={name}
              stepEntryId={stepEntryId}
              onClose={(res: boolean) => {
                if (res) onChange(true);
              }}
            />
            {!readonly && (
              <Fab
                //color="primary"
                variant="extended"
                aria-label="save"
                size="small"
                disabled={processing}
                sx={{
                  position: "fixed",
                  bottom: 76,
                  right: 16,
                }}
                onClick={() => {
                  saveDraftData();
                }}
              >
                <SaveAsDraftIcon sx={{ mr: 1 }} />
                Borrador
              </Fab>
            )}
            {!readonly && (
              <Fab
                color="primary"
                variant="extended"
                aria-label="save"
                disabled={processing}
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
                onClick={() => {
                  saveData();
                }}
              >
                <CheckIcon sx={{ mr: 1 }} />
                Guardar
              </Fab>
            )}
          </>
        ) : (
          <div className="no-content">
            <LockedDocumentIcon />
            <p>Formulario bloqueado</p>
          </div>
        )}
      </div>
    </div>
  );
};
// const InteractiveActionsWrapper = ({
//   actions,
//   commentCount,
//   stepEntryId,
//   onChange,
//   name,
// }: any) => {
//   const [openComments, setOpenComments] = useState(false);
//   return (
//     <div className="interactive-actions-container">
//       <div>
//         {actions?.map((action: any, index: number) => {
//           return (
//             <a key={index}>
//               {action.visible_name}
//               <Icon name={action.icon?.name} />
//             </a>
//           );
//         })}
//       </div>
//       <Fab
//         aria-label="present comments"
//         size="small"
//         sx={{
//           position: "fixed",
//           bottom: 30,
//           right: 16,
//           overflow: "inherit",
//         }}
//         className="comment-fab"
//         onClick={() => {
//           setOpenComments(true);
//         }}
//       >
//         <span className="badge">
//           {commentCount > 99 ? "+99" : commentCount}
//         </span>
//         <CommentIcon />
//       </Fab>
//       <CommentsDrawer
//         open={openComments}
//         setOpen={setOpenComments}
//         stepName={name}
//         stepEntryId={stepEntryId}
//         onClose={(res: boolean) => {
//           if (res) onChange(true);
//         }}
//       />
//     </div>
//   );
// };
const StepBody = ({
  type,
  interactiveActions,
  form,
  defaultFormData,
  canApprove,
  canComplete,
  minimumApprovals,
  userApprovalStatus,
  stepEntryId,
  status,
  onChange,
  approvals,
  setSeverity,
  setOpenAlert,
  setAlertMsg,
  isLocked,
  isEditable,
  readonly,
  name,
  commentCount,
  canExecute,
}: any) => {
  return (
    <div className="step-body-container">
      {type == "formStep" ? (
        <FormWrapper
          stepEntryId={stepEntryId}
          type={type}
          form={form}
          defaultFormData={defaultFormData}
          canApprove={canApprove}
          canComplete={canComplete}
          minimumApprovals={minimumApprovals}
          status={status}
          userApprovalStatus={userApprovalStatus}
          approvals={approvals}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
          setAlertMsg={setAlertMsg}
          isLocked={isLocked}
          isEditable={isEditable}
          readonly={readonly}
          name={name}
          commentCount={commentCount}
          onChange={onChange}
        />
      ) : (
        <>
          <InteractiveActions
            actions={interactiveActions || []}
            commentCount={commentCount}
            stepEntryId={stepEntryId}
            name={name}
            onChange={onChange}
            canExecute={canExecute}
          />
        </>
      )}
    </div>
  );
};

const WorkflowEntryDetailModal = ({
  open,
  setOpen,
  onClose,
  workflowEntryId,
  goToStep,
}: any) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [steps, setSteps] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [currentStep, setCurrentStep] = useState(null as any);
  const [isLocked, setIsLocked] = useState(null);

  const loadData = async () => {
    setLoading(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/workflow-entries/${workflowEntryId}/steps`
    );
    console.log(res);
    const resData = res?.data;
    setTitle(resData?.workflow_visible_name);
    setSubtitle(resData?.visible_id);
    setSteps(resData?.steps);
    setIsLocked(resData?.is_locked);
    setLoading(false);
  };
  useEffect(() => {
    if (open) {
      loadData();
    } else {
      setSteps([]);
    }
  }, [open]);
  useEffect(() => {
    console.log(currentStep);
  }, [currentStep]);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose(refreshOnClose);
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="workflow-entry-detail-modal-container"
      >
        <div
          className="modal-header"
          style={{ marginTop: isLocked ? "10px" : 0 }}
        >
          {isLocked ? (
            <span>
              <LockedIcon /> Entrada bloqueada
            </span>
          ) : (
            <></>
          )}
          <div className="modal-title">
            <h2>{title}</h2>
            <span>{subtitle}</span>
          </div>

          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose(refreshOnClose);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          {/* {workflowId} - {workflowEntryId} */}
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && (
            <>
              <div className="stepper-wrapper">
                <WorkflowEntryStepper
                  steps={steps}
                  goToStep={goToStep}
                  onStepChange={(res: any) => {
                    //console.log(res);
                    setCurrentStep(steps[res]);
                  }}
                />
              </div>
              <div className="step-body-wrapper">
                <StepBody
                  stepEntryId={currentStep?.id}
                  type={currentStep?.type}
                  interactiveActions={currentStep?.interactive_actions}
                  form={currentStep?.form}
                  defaultFormData={currentStep?.form_data}
                  canApprove={currentStep?.can_approve}
                  canComplete={currentStep?.can_complete}
                  minimumApprovals={currentStep?.minimum_approvals}
                  status={currentStep?.status}
                  comments={currentStep?.comments}
                  userApprovalStatus={currentStep?.user_approval_status}
                  approvals={currentStep?.approvals}
                  setSeverity={setSeverity}
                  setOpenAlert={setOpenAlert}
                  setAlertMsg={setAlertMsg}
                  isLocked={isLocked}
                  isEditable={currentStep?.is_editable}
                  readonly={currentStep?.readonly}
                  name={currentStep?.name}
                  commentCount={currentStep?.comment_count}
                  canExecute={currentStep?.can_execute}
                  onChange={(res: boolean) => {
                    if (res) {
                      setCurrentStep(null);
                      loadData();
                      setRefreshOnClose(true);
                      setOpenAlert(false);
                    }
                  }}
                />
              </div>
              <InformationMessage
                open={openAlert}
                message={alertMsg}
                severity={severity}
                onClose={setOpenAlert}
              />
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export { WorkflowEntryDetailModal };
