export const getCols = () => {
  const data: any = {
    valueAddedTax: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Alícuota",
        name: "rate",
        sortable: true,
      },
    ],
    otherTax: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Alícuota",
        name: "rate",
        sortable: true,
      },
    ],
    perception: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Alícuota",
        name: "rate",
        sortable: true,
      },
    ],
    withholding: [
      {
        type: "date",
        visibleName: "Fecha de creación",
        name: "created_at",
        sortable: true,
        showDiff: true,
      },
      {
        type: "visibleId",
        visibleName: "Id",
        name: "id",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Nombre",
        name: "name",
        sortable: true,
      },
      {
        type: "text",
        visibleName: "Alícuota",
        name: "rate",
        sortable: true,
      },
    ],
  };

  return data;
};
