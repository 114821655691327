export const getRowActions = () => {
  const data: any = {
    product: [
      {
        name: "view",
        visibleName: "Ver producto",
      },
      {
        name: "delete",
        visibleName: "Eliminar producto",
      },
    ],
    brand: [
      {
        name: "view",
        visibleName: "Ver marca",
      },
      {
        name: "delete",
        visibleName: "Eliminar marca",
      },
    ],
    extra: [
      {
        name: "view",
        visibleName: "Ver extra",
      },
      {
        name: "delete",
        visibleName: "Eliminar extra",
      },
    ],
    location: [
      {
        name: "view",
        visibleName: "Ver depósito",
      },
      {
        name: "delete",
        visibleName: "Eliminar depósito",
      },
    ],
    serialNumber: [
      {
        name: "view",
        visibleName: "Ver s/n de producto",
      },
      {
        name: "delete",
        visibleName: "Eliminar s/n de producto",
      },
    ],
  };

  return data;
};
