import React from "react";
import "./TutorialsCard.scss";
const TutorialsCard = ({ title }: any) => {
  return (
    <div className="tutorials-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <svg viewBox="0 0 24 24">
          <path
            className="book-icon"
            d="M 4.6722658,1.9999804 C 3.964699,1.9996664 3.3516601,2.0525423 2.8754525,2.1145203 1.7112251,2.2660352 1.0309473,3.3012544 1.0309473,4.3199365 V 17.01183 c 0,1.372612 1.146,2.454699 2.4765938,2.41082 1.1013687,-0.03626 2.6300022,0.0027 4.0147175,0.337347 1.0559171,0.25508 1.72755,0.75714 2.2489268,1.3269 0.3147756,0.3439 0.9036046,0.155944 0.9036046,-0.310337 V 3.9287942 C 9.7104034,2.9644071 8.6616806,2.5511762 7.5729454,2.3109956 6.5178833,2.0782385 5.5262257,2.0003594 4.6722658,1.9999804 Z m 14.7391772,0 c -0.853949,3.79e-4 -1.845617,0.078258 -2.90068,0.3110152 -1.088793,0.2401806 -2.137457,0.6534115 -3.101844,1.6177986 V 20.77656 c 0,0.466281 0.588829,0.654237 0.903605,0.310337 0.521348,-0.56976 1.19298,-1.07182 2.248983,-1.3269 1.384667,-0.334642 2.913274,-0.373608 4.014605,-0.337347 1.330661,0.04388 2.476649,-1.038208 2.476649,-2.41082 V 4.3199365 c 0,-1.0186821 -0.680257,-2.0539013 -1.844561,-2.2054162 C 20.732014,2.052542 20.119001,1.9996664 19.411443,1.9999804 Z"
          />
        </svg>
      </div>
    </div>
  );
};

export { TutorialsCard };
