import React, { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./TaskUserTabs.scss";
import urls from "config/urls";
import { UsersIcon } from "components/icons/UsersIcon";
import AxiosInterceptor from "../../../../AxiosInterceptor";
const TaskUserTabs = ({ onChange }: any) => {
  const [records, setRecords] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(null as any);

  const loadRecords = async () => {
    setLoading(true);
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/tasks/user-tabs`
      );
      const data = await res?.data;
      setRecords(data?.tabs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadRecords();
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const selectTab = (index: number) => {
    setSelectedTabIndex(index);
    console.log(index);
  };
  useEffect(() => {
    onChange(selectedTabIndex != null ? records[selectedTabIndex] : null);
  }, [selectedTabIndex]);
  return (
    <Box
      className="task-user-tabs-container"
      sx={{ maxWidth: { xs: 320, sm: 480, lg: 1100 } }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Usuarios"
      >
        <Tab
          component="a"
          label={
            <div className={selectedTabIndex == null ? "all selected" : "all"}>
              <div
                className="icon-wrapper"
                style={{
                  background: selectedTabIndex == null ? "transparent" : "#fff",
                }}
              >
                <UsersIcon />
              </div>

              <span>Todos</span>
            </div>
          }
          style={{}}
          //value={value}
          onClick={() => {
            setSelectedTabIndex(null);
          }}
        />
        {records.map((item: any, index: number) => (
          <Tab
            component="a"
            key={index}
            label={
              <div className={selectedTabIndex === index ? "selected" : ""}>
                <div className="badge">{item.pendingCount}</div>
                <img src={`${urls.server}${item?.profile_picture}`} />
                {selectedTabIndex === index && <span>{item.name}</span>}
              </div>
            }
            style={{}}
            //value={value}
            onClick={() => selectTab(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export { TaskUserTabs };
