import { useEffect, useState } from "react";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { AddIcon } from "../../../../components/icons/AddIcon";
import { AddWrapperIcon } from "../../../../components/icons/AddWrapperIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import "./DataTableExpandedCard.scss";

const DataTableExpandedCard = (props: any) => {
  const { title, config } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [recordModalIsOpen, setRecordsModalIsOpen] = useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  return (
    <>
      <div className="data-table-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          <a
            className="card-btn lg"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <strong>Nuevo registro</strong>
            <div>
              <AddWrapperIcon />
              <AddIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setRecordsModalIsOpen(true);
            }}
          >
            <strong>Listar registros</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>
          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros Eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      <FormModal
        open={formModalIsOpen}
        setOpen={setFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={config?.form?.title}
        fields={config?.form?.fields}
        tableName={config?.table?.name}
      />
      <RecordsModal
        open={recordModalIsOpen}
        setOpen={setRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={title}
        tableName={config?.table?.name}
        cols={config?.table?.cols}
        dividerGroups={config?.dividerGroups}
        rowActions={config?.rowActions}
        tableActions={config?.tableActions}
        formTitle={config?.form?.title}
        formFields={config?.form?.fields}
      />
      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { DataTableExpandedCard };
