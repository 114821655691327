import React, { useState, useEffect } from "react";

import "./MiniStackedColumnChart.scss";

const MiniStackedColumnChart = (props: any) => {
  const { data, height = 60, onSelectGroup } = props;
  const [items, setItems] = useState([] as any);
  const addHeightToStackedItems = (items: any, height: number) => {
    // Paso 1: Calcular el mayor valor de la suma de los subítems de todos los periodos.
    const maxTotalValue = Math.max(
      ...items.map((item: any) =>
        item.data.reduce((sum: number, subItem: any) => sum + subItem.value, 0)
      )
    );

    // Paso 2 y 3: Calcular la altura de la columna de cada periodo.
    return items.map((item: any) => {
      const periodTotal = item.data.reduce(
        (sum: number, subItem: any) => sum + subItem.value,
        0
      );
      const periodHeight = (periodTotal / maxTotalValue) * height;

      let accumulatedHeight = 0;

      // Paso 4: Calcular la altura de cada subcolumna.
      const dataWithHeight = item.data.map((subItem: any) => {
        const subItemHeight = (subItem.value / periodTotal) * periodHeight;
        const posY = accumulatedHeight;
        accumulatedHeight += subItemHeight;

        return {
          ...subItem,
          height: Math.round(subItemHeight),
          // y: posY, // No necesitas esta línea, pero aquí estaría el inicio vertical de la subcolumna.
        };
      });

      return {
        ...item,
        data: dataWithHeight,
        // Si necesitas la altura total de la columna para alguna referencia visual o cálculo posterior.
        totalHeight: Math.round(periodHeight),
      };
    });
  };

  // Uso de la función

  const handleClick = (index: number) => {
    onSelectGroup(index);
  };
  useEffect(() => {
    console.log(JSON.stringify(data));
    const newData = addHeightToStackedItems(data, height);
    setItems(newData);
  }, []);
  return (
    <>
      <div className="mini-stacked-column-chart-container">
        <div className="main-content" style={{ height: height + "px" }}>
          {items?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="column-wrapper"
                style={{
                  height: height + "px",
                }}
                onClick={() => handleClick(index)}
              >
                {item.data.map((subItem: any, subIndex: number) => {
                  return (
                    <a
                      key={subIndex}
                      style={{
                        height: subItem.height + "px",
                        background: subItem.color || "#ccc",
                      }}
                    ></a>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { MiniStackedColumnChart };
