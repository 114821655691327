import React, { useState, useEffect } from "react";
import { SvgIcon } from "@mui/material";
import "./MiniMultiLineChart.scss";
import { buildPathD } from "utils/build-path-d";

const MiniMultiLineChart = (props: any) => {
  const { datasets, height, periods, onSelectPoints } = props;
  const [selectedIndex, setSelectedIndex] = useState(periods.length - 1);

  const heightValue = parseInt(height, 10);
  const allData = datasets.map((item: any) => item.data).flat(); // Obtener todos los valores de data en un solo array
  const maxVal = Math.max.apply(null, allData);
  const minVal = Math.min.apply(null, allData);
  const colors: any = [];
  let pathPoints = datasets.map((item: any, index: number) => {
    const { data, color } = item;
    colors.push(color);

    return data
      .map((value: any, subindex: number) => {
        const x = (subindex / (data.length - 1)) * 100;
        const y =
          maxVal === minVal
            ? heightValue
            : ((maxVal - value) / (maxVal - minVal)) * heightValue;

        return `[${x},${y - 1}]${subindex < data.length - 1 ? "," : ""}`;
      })
      .join(" ");
  });
  pathPoints = pathPoints
    .map((str: any) => str.match(/(\d+(\.\d+)?)/g).map(Number))
    .map((arr: any) =>
      arr.reduce(
        (acc: any, cur: any, i: number) => (
          i % 2 === 0 ? acc.push([cur]) : acc[acc.length - 1].push(cur), acc
        ),
        []
      )
    );

  const d: any = [];
  pathPoints.forEach((el: any) => {
    d.push(buildPathD(el));
  });
  useEffect(() => {
    onSelectPoints(selectedIndex);
  }, [selectedIndex]);
  return (
    <div className="mini-multi-line-chart-container">
      <SvgIcon
        viewBox={`0 0 100 ${height}`}
        sx={{
          width: "100%",
          height: `${height}px`,
        }}
        preserveAspectRatio="none"
      >
        {pathPoints.map((points: any, index: number) => (
          <path
            key={index}
            className="path"
            fill="transparent"
            vectorEffect="non-scaling-stroke"
            stroke={colors[index]}
            d={d[index]}
          />
        ))}
      </SvgIcon>
      <div className="dots-wrapper">
        {periods.map((period: any, index: number) => (
          <a
            style={{
              left: `${pathPoints[0][index][0]}%`,
              marginLeft: index == selectedIndex ? `-6px` : "-4px",
              width: index == selectedIndex ? `12px` : "8px",
            }}
            onClick={() => {
              setSelectedIndex(index);
            }}
            key={index}
          >
            {colors.map((color: any, subindex: number) => {
              return (
                <span
                  key={subindex}
                  style={{
                    background: colors[subindex],
                    top: pathPoints[subindex][index][1],
                    marginTop: index == selectedIndex ? `-6px` : `-4px`,
                    width: index == selectedIndex ? `12px` : "8px",
                    height: index == selectedIndex ? `12px` : "8px",
                  }}
                ></span>
              );
            })}
          </a>
        ))}
      </div>
    </div>
  );
};

export { MiniMultiLineChart };
