import React, { useState } from "react";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { DeleteWrapperIcon } from "../../../../components/icons/DeleteWrapperIcon";
import { GridIcon } from "../../../../components/icons/GridIcon";
import { GridWrapperIcon } from "../../../../components/icons/GridWrapperIcon";
import { RecordsModal } from "../../../../components/modals/RecordsModal/RecordsModal";
import { getCols } from "../../utils/get-cols";
import { getFields } from "../../utils/get-fileds";
import { getDividerGroups } from "../../utils/get-divider-groups";
import "./BanksExpandedCard.scss";
import { getRowActions } from "../../utils/get-row-actions";
const BanksExpandedCard = (props: any) => {
  const { title, data } = props;
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  const [bankRecordsModalIsOpen, setBankRecordsModalIsOpen] = useState(false);
  const [inCheckRecordsModalIsOpen, setInCheckRecordsModalIsOpen] =
    useState(false);
  const [outCheckRecordsModalIsOpen, setOutCheckRecordsModalIsOpen] =
    useState(false);

  const [bankFormModalIsOpen, setBankFormModalIsOpen] = useState(false);
  const [inCheckFormModalIsOpen, setInCheckFormModalIsOpen] = useState(false);
  const [outCheckFormModalIsOpen, setOutCheckFormModalIsOpen] = useState(false);

  const bankFields = getFields().bank;
  const inCheckFields = getFields().inCheck;
  const outCheckFields = getFields().outCheck;

  const bankCols = getCols().bank;
  const inCheckCols = getCols().inCheck;
  const outCheckCols = getCols().outCheck;

  const bankDividerGroups = getDividerGroups().bank;
  const inCheckDividerGroups = getDividerGroups().inCheck;
  const outCheckDividerGroups = getDividerGroups().outCheck;

  const bankRowActions = getRowActions().bank;
  const inCheckRowActions = getRowActions().inCheck;
  const outCheckRowActions = getRowActions().outCheck;

  return (
    <>
      <div className="banks-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <Grid item xs={6} md={4}>
              <a
                className="card-btn"
                onClick={() => {
                  setBankFormModalIsOpen(true);
                }}
              >
                <span>Nuevo</span>
                <strong>Banco</strong>
                <div>
                  <AddWrapperIcon />
                  <AddIcon />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4}>
              <a
                className="card-btn"
                onClick={() => {
                  setOutCheckFormModalIsOpen(true);
                }}
              >
                <span>Nuevo</span>
                <strong>Cheque emitido</strong>
                <div>
                  <AddWrapperIcon />
                  <AddIcon />
                </div>
              </a>
            </Grid>
            <Grid item xs={6} md={4}>
              <a
                className="card-btn"
                onClick={() => {
                  setInCheckFormModalIsOpen(true);
                }}
              >
                <span>Nuevo</span>
                <strong>Cheque recibido</strong>
                <div>
                  <AddWrapperIcon />
                  <AddIcon />
                </div>
              </a>
            </Grid> */}

          <a
            className="card-btn lg"
            onClick={() => {
              setBankRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Listado</span> */}
            <strong>Bancos</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setOutCheckRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Listado</span> */}
            <strong>Cheques emitidos</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setInCheckRecordsModalIsOpen(true);
            }}
          >
            {/* <span>Listado</span> */}
            <strong>Cheques recibidos</strong>
            <div>
              <GridWrapperIcon />
              <GridIcon />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Registros eliminados</strong>
            <div>
              <DeleteWrapperIcon />
              <DeleteIcon />
            </div>
          </a>
        </div>
      </div>
      {/* <FormModal
        open={bankFormModalIsOpen}
        setOpen={setBankFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Ficha de banco"}
        fields={bankFields}
        tableName={"banks"}
      />
      <FormModal
        open={inCheckFormModalIsOpen}
        setOpen={setInCheckFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Cheque recibido"}
        fields={inCheckFields}
        tableName={"incoming-checks"}
      />
      <FormModal
        open={outCheckFormModalIsOpen}
        setOpen={setOutCheckFormModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Cheque emitido"}
        fields={outCheckFields}
        tableName={"outgoing-checks"}
      /> */}

      <RecordsModal
        open={bankRecordsModalIsOpen}
        setOpen={setBankRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Bancos"}
        tableName={"banks"}
        fields={bankFields}
        cols={bankCols}
        formTitle={"Banco"}
        dividerGroups={bankDividerGroups}
        rowActions={bankRowActions}
      />
      <RecordsModal
        open={inCheckRecordsModalIsOpen}
        setOpen={setInCheckRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Cheques recibidos"}
        tableName={"incoming-checks"}
        cols={inCheckCols}
        dividerGroups={inCheckDividerGroups}
      />

      <RecordsModal
        open={outCheckRecordsModalIsOpen}
        setOpen={setOutCheckRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
        title={"Cheques emitidos"}
        tableName={"outgoing-checks"}
        cols={outCheckCols}
        dividerGroups={outCheckDividerGroups}
      />

      <DeletedRecordsModal
        open={deletedRecordsModalIsOpen}
        setOpen={setDeletedRecordsModalIsOpen}
        onClose={() => {}}
        onDismiss={() => {}}
      />
    </>
  );
};

export { BanksExpandedCard };
