export const GetBibliorates = () => {
  const data: any = [
    // {
    //   label: "Facturas de ventas",
    //   color: "#A597CC",
    // },
    // {
    //   label: "Facturas de compras",
    //   color: "#94B7DF",
    // },
    // {
    //   label: "Pagos",
    //   color: "#83D7F1",
    // },
    // {
    //   label: "compras",
    //   color: "#68CDD4",
    // },
    // {
    //   label: "Contratos de suscripciones",
    //   color: "#4CC2B6",
    // },
    // {
    //   label: "Facturas de ventas",
    //   color: "#A597CC",
    // },
    // {
    //   label: "Facturas de compras",
    //   color: "#94B7DF",
    // },
    {
      label: "Fichas de indic.",
      color: "#83D7F1",
    },
    {
      label: "PDFs",
      color: "#68CDD4",
    },
    {
      label: "...",
      color: "#4CC2B6",
    },
    {
      label: "...",
      color: "#A597CC",
    },
    {
      label: "...",
      color: "#94B7DF",
    },
  ];

  return data;
};
