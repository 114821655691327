import React, { useState, useEffect } from "react";
// import { AddIcon } from "../../../../icons/AddIcon";
// import { CloseIcon } from "../../../../icons/CloseIcon";
// import { SelectModal } from "../../../../modals/SelectModal/SelectModal";
import { Button } from "@mui/material";
import { CloseIcon } from "../../../icons/CloseIcon";
import { AddIcon } from "../../../icons/AddIcon";
import { SelectModal } from "../../../modals/SelectModal/SelectModal";

const ValueAddedTaxPicker = ({ value, onChange }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState(value);
  const fields = [
    {
      type: "text",
      visibleName: "Nombre",
      name: "name",
      description: null,
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
    {
      type: "decimal",
      visibleName: "Alícuota",
      name: "rate",
      description: null,
      append: "%",
      size: 6,
      editable: null,
      editableIf: null,
      validations: {},
    },
  ];
  useEffect(() => {
    onChange(data);
  }, [data]);
  return (
    <>
      {data ? (
        // <a
        //   onClick={() => {
        //     setData(null);
        //   }}
        // >
        //   {data.name}
        //   <CloseIcon />
        // </a>
        <Button
          onClick={() => {
            setData(null);
          }}
          endIcon={<CloseIcon />}
        >
          {data.name}
        </Button>
      ) : (
        // <a
        //   onClick={() => {
        //     setModalIsOpen(true);
        //   }}
        // >
        //   Agregar
        //   <AddIcon />
        // </a>
        <Button
          onClick={() => {
            setModalIsOpen(true);
          }}
          startIcon={<AddIcon />}
        >
          Agregar
        </Button>
      )}
      <SelectModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        title="Seleccionar impuesto"
        tableName="value-added-taxes"
        formTitle="Ficha de impuesto"
        formFields={fields}
        primaryKey="name"
        //secondaryKey="tin"
        onClose={(data: any) => {
          console.log("data", data);
          if (data) {
            setData(data);
          }
        }}
      />
    </>
  );
};

export { ValueAddedTaxPicker };
