import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
import "./CardManagerCard.scss";
const CardManagerCard = (props: any) => {
  const { title, data, config } = props;
  return (
    <div className="card-manager-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.cardsCount}
          label={"Tarjetas"}
        />
      </div>
    </div>
  );
};

export { CardManagerCard };
