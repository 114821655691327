import React, { useState } from "react";
import "./BoardCard.scss";

import { DragIcon } from "../icons/DragIcon";
import { FullScreenIcon } from "../icons/FullscreenIcon";
import { SortableKnob } from "react-easy-sort";
import { UsersCard } from "../../cards/users/components/UsersCard/UsersCard";
import { DataTableCard } from "../../cards/dataTable/components/DataTableCard/DataTableCard";
import { SettingsCard } from "../../cards/settings/components/SettingsCard/SettingsCard";
import { SalesCard } from "../../cards/sales/components/SalesCard/SalesCard";
import { CustomersCard } from "../../cards/customers/components/CustomersCard/CustomersCard";
import { PurchasesCard } from "../../cards/purchases/components/PurchasesCard/PurchasesCard";
import { SuppliersCard } from "../../cards/suppliers/components/SuppliersCard/SuppliersCard";
import { ProductsCard } from "../../cards/products/components/ProductsCard/ProductsCard";
import { TaxesCard } from "../../cards/taxes/components/TaxesCard/TaxesCard";
import { BanksCard } from "../../cards/banks/components/BanksCard/BanksCard";
import { IndicatorsCard } from "../../cards/indicators/components/IndicatorsCard/IndicatorsCard";
import { RolesCard } from "../../cards/roles/components/RolesCard/RolesCard";
import { IndicatorCard } from "../../cards/indicator/components/IndicatorCard/IndicatorCard";
import { InventoryManagerCard } from "../../cards/inventoryManager/components/InventoryManagerCard/InventoryManagerCard";
import { SubscriptionsCard } from "../../cards/subscriptions/components/SubscriptionsCard/SubscriptionsCard";
import { ServicesCard } from "../../cards/services/components/ServicesCard/ServicesCard";
import { MyTasksCard } from "../../cards/tasks/components/MyTasksCard/MyTasksCard";
import { MyIndicatorsCard } from "../../cards/indicators/components/MyIndicatorsCard/MyIndicatorsCard";
import { CardManagerCard } from "../../cards/cardManager/components/CardManagerCard/CardManagerCard";
import { TasksCard } from "../../cards/tasks/components/TasksCard/TasksCard";
import { PayablesCard } from "../../cards/payables/components/PayablesCard/PayablesCard";
import { AddressesCard } from "../../cards/addresses/components/AddressesCard/AddressesCard";
import { RankingCard } from "../../cards/ranking/components/RankingCard/RankingCard";
import { FlowChatCard } from "../../cards/flowChat/components/FlowChatCard/FlowChatCard";
import { BookshelfCard } from "../../cards/bookshelf/components/BookshelfCard/BookshelfCard";
import { UserGroupsCard } from "../../cards/userGroups/components/UserGroupsCard/UserGroupsCard";
import { DataTablesCard } from "../../cards/dataTables/components/DataTablesCard/DataTablesCard";
import { WorkflowsCard } from "../../cards/workflows/components/WorkflowsCard/WorkflowsCard";
import { TutorialsCard } from "../../cards/tutorials/components/TutorialsCard/TutorialsCard";
import { WorkflowEntriesCard } from "../../cards/workflowEntries/components/WorkflowEntriesCard/WorkflowEntriesCard";
import { CardsIcon } from "../icons/CardsIcon";
import { ScrapingCard } from "cards/scraping/components/ScrapingCard/ScrapingCard";
import { IndicatorGroupCard } from "cards/indicatorGroup/components/IndicatorGroupCard/IndicatorGroupCard";
import { IndicatorGroupsCard } from "cards/indicatorGroups/components/IndicatorGroupsCard/IndicatorGroupsCard";
import { ConfigurationsCard } from "cards/configurations/components/ConfigurationsCard/ConfigurationsCard";
import ExpandedCardModal from "components/modals/ExpandedCardModal/ExpandedCardModal";
import BoardModal from "../modals/BoardModal/BoardModal";

interface BoardCardProps {
  title: string;
  data: any;
  config?: any;
  type: string;
  isSortable: boolean;
  isExpandable: boolean;
  childCards?: any[];
  id: number;
  onChange?: () => void;
}

const BoardCard: React.FC<BoardCardProps> = ({ title, data, config, type, childCards = [], isSortable, id, isExpandable, onChange }) => {
  const [boardModalIsOpen, setBoardModalIsOpen] = useState(false);
  const [expandedCardModalIsOpen, setExpandedCardModalIsOpen] = useState(false);

  const CustomKnob = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
    return (
      <div ref={ref} style={{ cursor: "grab" }}>
        <DragIcon />
      </div>
    );
  });
  const handleExpandCard = () => {
    setExpandedCardModalIsOpen(true);
    // onExpand(index);
  };
  return (
    <div className="board-card-container">
      <div>
        <div>
          {(() => {
            switch (type) {
              case "indicators":
                return (
                  <IndicatorsCard title={title} data={data} config={config} />
                );
              case "indicator":
                return (
                  <IndicatorCard
                    title={title}
                    data={data}
                    config={config}
                    id={id}
                  />
                );
              case "indicatorGroup":
                return (
                  <IndicatorGroupCard
                    title={title}
                    data={data}
                    config={config}
                    id={id}
                  />
                );
              case "indicatorGroups":
                return (
                  <IndicatorGroupsCard
                    title={title}
                    data={data}
                    config={config}
                    id={id}
                  />
                );
              case "roles":
                return <RolesCard title={title} data={data} config={config} />;
              case "scraping":
                return (
                  <ScrapingCard title={title} data={data} config={config} />
                );
              case "myIndicators":
                return <MyIndicatorsCard title={title} data={data} />;
              case "myTasks":
                return <MyTasksCard title={title} data={data} />;
              case "tasks":
                return <TasksCard title={title} data={data} />;
              case "users":
                return <UsersCard title={title} data={data} config={config} />;

              case "cardManager":
                return (
                  <CardManagerCard title={title} data={data} config={config} />
                );

              case "dataTable":
                return (
                  <DataTableCard title={title} data={data} config={config} />
                );
              case "settings":
                return (
                  <SettingsCard title={title} data={data} config={config} />
                );
              case "sales":
                return <SalesCard title={title} data={data} config={config} />;
              case "customers":
                return (
                  <CustomersCard title={title} data={data} config={config} />
                );
              case "purchases":
                return (
                  <PurchasesCard title={title} data={data} config={config} />
                );
              case "suppliers":
                return (
                  <SuppliersCard title={title} data={data} config={config} />
                );
              case "products":
                return (
                  <ProductsCard title={title} data={data} config={config} />
                );
              case "taxes":
                return <TaxesCard title={title} data={data} config={config} />;
              case "banks":
                return <BanksCard title={title} data={data} config={config} />;
              case "services":
                return (
                  <ServicesCard title={title} data={data} config={config} />
                );
              case "inventoryManager":
                return (
                  <InventoryManagerCard
                    title={title}
                    data={data}
                    config={config}
                  />
                );
              case "subscriptions":
                return (
                  <SubscriptionsCard
                    title={title}
                    data={data}
                    config={config}
                    id={id}
                  />
                );
              case "payables":
                return (
                  <PayablesCard title={title} data={data} config={config} />
                );
              case "addresses":
                return (
                  <AddressesCard title={title} data={data} config={config} />
                );
              case "ranking":
                return (
                  <RankingCard title={title} data={data} config={config} />
                );

              case "flowChat":
                return (
                  <FlowChatCard title={title} data={data} config={config} />
                );

              case "bookshelf":
                return (
                  <BookshelfCard title={title} data={data} config={config} />
                );
              case "workflows":
                return (
                  <WorkflowsCard title={title} data={data} config={config} />
                );
              case "userGroups":
                return (
                  <UserGroupsCard title={title} data={data} config={config} />
                );
              case "dataTables":
                return (
                  <DataTablesCard title={title} data={data} config={config} />
                );
              case "tutorials":
                return (
                  <TutorialsCard title={title} data={data} config={config} />
                );
              case "workflowEntries":
                return (
                  <WorkflowEntriesCard
                    title={title}
                    data={data}
                    config={config}
                  />
                );
              case "configurations":
                return (
                  <ConfigurationsCard
                    title={title}
                    data={data}
                    config={config}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
        <div className="card-btns">
          {isExpandable ? (
            <button onClick={handleExpandCard}>
              <FullScreenIcon />
            </button>
          ) : (
            <></>
          )}

          {childCards?.length > 0 ? (
            <button
              onClick={() => {
                setBoardModalIsOpen(true);
              }}
            >
              <CardsIcon />
            </button>
          ) : (
            <></>
          )}

          {isSortable ? (
            <SortableKnob>
              <CustomKnob />
            </SortableKnob>
          ) : (
            <SortableKnob>
              <span></span>
            </SortableKnob>
          )}
        </div>
      </div>
      <BoardModal
        isOpen={boardModalIsOpen}
        title={title}
        data={childCards}
        onClose={() => {
          setBoardModalIsOpen(false)
        }}
      />
      <ExpandedCardModal
        isOpen={expandedCardModalIsOpen}
        onClose={() => {
          setExpandedCardModalIsOpen(false)
        }}
        onChange={onChange}
        data={data}
        config={config}
        type={type}
        title={title}
        id={id}
      />
    </div>
  );
};

export default BoardCard;
