export const getFields = () => {
  const data: any = {
    user: [
      // {
      //   type: "text",
      //   visibleName: "Nombre",
      //   name: "name",
      //   description: null,
      //   size: 6,
      //   showIf: null,
      //   editable: null,
      //   editableIf: null,
      //   validations: {},
      // },
      {
        type: "email",
        visibleName: "Email",
        name: "email",
        description: null,
        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "checkbox",
        visibleName: "Es admin?",
        name: "is_admin",
        description: null,

        size: 6,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "cardsPicker",
        visibleName: "Seleccionar tarjetas",
        name: "cards",
        description: null,
        size: 12,
        showIf: null,
        editable: null,
        editableIf: null,
        validations: {},
      },
      // {
      //   type: "checkboxes",
      //   visibleName: "Roles",
      //   name: "roles",
      //   description: null,
      //   loadPath: "roles",
      //   size: 12,
      //   showIf: "is_admin == false",
      //   editable: null,
      //   editableIf: null,
      //   validations: {},

      // },
    ],
  };

  return data;
};
