import React, { useState, useEffect, useRef } from "react";
import { Dialog, Fab, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "../../icons/CloseIcon";
import ClearIcon from "@mui/icons-material/Clear";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import "./VinculationModal.scss";
import { CheckIcon } from "../../icons/CheckIcon";
import { Autocomplete } from "components/Autocomplete/Autocomplete";
import { DocumentsIcon } from "components/icons/DocumentsIcon";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const VinculationModal = ({
  open,
  setOpen,
  onClose,
  data = [],
  title,
  subtitle,
  tableName,
  primaryKey,
  secondaryKey,
  thumbnailKey,
  vinculationPath,
  searchPath,
}: any) => {
  const [items, setItems] = useState(data);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const itemsRef = useRef(items);
  const deleteRecord = (idToDelete: number) => {
    const newItems = items.filter((record: any) => record.id !== idToDelete);
    setItems(newItems);
  };
  const handleOnSelect = (data: any) => {
    if (data) {
      // Search for a record in the array that matches the id of the record to add
      const existingRecord = items.find((record: any) => record.id === data.id);
      // If the record doesn't exist, add it to the array
      if (!existingRecord) {
        const newItems = [...items, data];
        setItems(newItems);
      }
    }
  };
  useEffect(() => {
    if (JSON.stringify(itemsRef.current) !== JSON.stringify(items)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [items]);
  useEffect(() => {
    if (open) setItems(data);
  }, [open]);
  const send = async () => {
    if (vinculationPath) {
      const data = {
        data: items,
      };
      //if (tableName) tableName = tableName.replace(/_/g, "-");
      let url = `${urls.server}/api/${vinculationPath}`;

      const res = await AxiosInterceptor.put(url, data);

      const resData = await res.data;

      setOpen(false);
      onClose(resData);
    } else {
      setOpen(false);
      onClose(items);
    }
  };
  useEffect(() => {
    if (open) {
      console.log(data);
      setItems(data);
    } else {
      setItems([]);
    }
  }, [open]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#f2f2f4",
        },
      }}
      className="vinculation-modal-container"
    >
      <div className="modal-header">
        <div className="modal-title">
          <h2>{title}</h2>
          <span>
            <b>{subtitle}</b>
          </span>
        </div>
        <div className="modal-end-btns">
          <IconButton
            color="inherit"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="autocomplete-wrapper">
          <div>
            <Autocomplete
              onSelect={handleOnSelect}
              tableName={tableName}
              secondaryKey={secondaryKey}
              thumbnailKey={thumbnailKey}
              //placeholder={placeholder}
              primaryKey={primaryKey}
              searchPath={searchPath}
            />
          </div>
        </div>

        {items.length == 0 && (
          <div className="no-content">
            <DocumentsIcon />
            <p>No hay registros vinculados</p>
          </div>
        )}
        <div className="list-wrapper">
          <div>
            {items.map((item: any, index: number) => {
              return (
                <a key={index}>
                  {thumbnailKey && (
                    <div className="thumbnail-wrapper">
                      {item?.thumbnailKey && (
                        <img src={urls.server + item[thumbnailKey]} alt="" />
                      )}
                    </div>
                  )}

                  <div className="main-content-wrapper">
                    <strong>{item[primaryKey]}</strong>
                    <span>{item[secondaryKey]}</span>
                  </div>

                  <div className="remove-btn-wrapper">
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        //setCurrentUser(item);
                        deleteRecord(item?.id);
                      }}
                      aria-label="remove"
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="send"
        onClick={() => {
          send();
        }}
        disabled={vinculationPath ? isDisabled : false}
      >
        <CheckIcon />
      </Fab>
    </Dialog>
  );
};

export { VinculationModal };
