import React from "react";
import "./TaxesCard.scss";
const TaxesCard = ({ title }: any) => {
  return (
    <div className="taxes-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <svg viewBox="0 0 12 12">
          <path
            className="tax-icon"
            d="m 3.7526416,1.4140475 c -0.7738944,0 -1.4017824,0.6278881 -1.4017824,1.4017824 v 7.4760981 c 0,0.09138 0.026473,0.17679 0.072133,0.248934 0.002,0.0034 0.00412,0.0068 0.00636,0.01026 0.073745,0.111563 0.1871711,0.180513 0.3093047,0.201281 0.025842,0.0044 0.052384,0.0068 0.079466,0.0068 0.00365,0 0.00729,-9.1e-5 0.010912,-2.75e-4 0.085543,-0.0021 0.1716332,-0.02754 0.2482597,-0.07819 l 1.0823628,-0.722804 0.6643868,0.664386 c 0.087611,0.08761 0.2080639,0.136883 0.3303538,0.136883 0.1222899,0 0.2427681,-0.04927 0.3303788,-0.136883 l 0.6041428,-0.604143 0.6041426,0.604143 c 0.087611,0.08761 0.208089,0.136883 0.3303789,0.136883 0.1222899,0 0.2427431,-0.04927 0.3303537,-0.136883 L 8.0181817,9.957887 9.1005478,10.68069 c 0.1441927,0.09674 0.3267064,0.104016 0.4800256,0.0237 0.1533184,-0.08213 0.2464069,-0.239102 0.2464069,-0.412497 V 2.8158299 c 0,-0.7738943 -0.6278875,-1.4017824 -1.4017819,-1.4017824 z M 4.673594,3.5942084 c 0.3869461,0 0.7008786,0.3139569 0.7008786,0.7009036 0,0.3869467 -0.3139325,0.7008786 -0.7008786,0.7008786 -0.3869469,0 -0.7008787,-0.3139319 -0.7008787,-0.7008786 0,-0.3869467 0.3139318,-0.7009036 0.7008787,-0.7009036 z m 2.8171088,0.1561181 c 0.1195523,0 0.2390927,0.045646 0.3303537,0.1369069 0.1825221,0.1825219 0.1825221,0.4782106 0,0.6607326 L 5.0175168,7.3515056 c -0.182522,0.1825221 -0.4782105,0.1825221 -0.6607326,0 -0.182522,-0.182522 -0.182522,-0.4782105 0,-0.6607326 L 7.1603239,3.8872334 C 7.2515849,3.7959725 7.3711511,3.7503265 7.4907028,3.7503265 Z m 0.1000827,2.5184416 c 0.00154,-9.5e-6 0.00299,0 0.00453,0 0.386947,0 0.7009037,0.3139319 0.7009037,0.7008786 0,0.3869469 -0.3139567,0.7008787 -0.7009037,0.7008787 -0.386947,0 -0.7008787,-0.3139318 -0.7008787,-0.7008787 0,-0.3854351 0.3114852,-0.6984352 0.6963487,-0.7008786 z"
          />
        </svg>
      </div>
    </div>
  );
};

export { TaxesCard };
