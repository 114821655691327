export const getRowActions = () => {
  const data: any = {
    configuration: [
      {
        name: "viewConfiguration",
        visibleName: "Ver configuración",
      },
      {
        name: "delete",
        visibleName: "Eliminar configuración",
      },
    ],
  };

  return data;
};
