import React, { useState, useEffect } from "react";
import { Fab, Popover } from "@mui/material";
import { DownloadIcon } from "components/icons/DownloadIcon";
const DownloadFab = ({ onSelectItem, disabled, key = "" }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverIsOpen = Boolean(anchorEl);

  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  //const fileTypes = ["CSV", "JSON", "PDF"];

  // const formats = [
  //   {
  //     name: "Excel",
  //     icon: "",
  //   },
  //   {
  //     name: "JSON",
  //     icon: "",
  //   },
  //   {
  //     name: "PDF",
  //     icon: "",
  //   },
  // ];
  const fileTypes = [
    {
      name: "Excel",
      icon: "",
    },
    {
      name: "PDF",
      icon: "",
    },
    {
      name: "JSON",
      icon: "",
    },
  ];
  return (
    <div>
      <Fab

        color="primary"
        disabled={disabled}
        aria-label="download"
        onClick={presentPopover}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <DownloadIcon />
      </Fab>
      <Popover
        id={"download_popover_" + key}
        open={popoverIsOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popover-items">
          {fileTypes.map((item: any, index: number) => {
            return (
              <a
                key={index}
                onClick={() => {
                  onSelectItem(item.name);
                  setAnchorEl(null);
                }}
              >
                {item.name}
              </a>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export { DownloadFab };
