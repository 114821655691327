import { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./CheckboxField.scss";

const CheckboxField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    description,
    readonly,
    recordId,
    name,
    mode
  }:
    any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);


  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleClick = () => {
    setValue((prev: any) => !prev);
  };


  return (
    <>
      <div className="checkbox-field-container">
        <div className="field-header">
          {!readonly != true || (mode == 'edit' && editable) && (
            <FieldEditControls
              value={value}
              fieldName={name}
              editPath={editPath}
              onEditStart={() => {
                setIsEditEnabled(true);
              }}
              onEditCancel={(originalValue) => {
                setIsEditEnabled(false);
                setValue(originalValue);
              }}
              onEditEnd={(success: boolean, originalValue) => {
                setIsEditEnabled(false);
                if (!success) {
                  setValue(originalValue);
                }
              }}
            />
          )}
        </div>
        <div className="field-body">
          {readonly ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              <div className="checkbox">
                <div>
                  <span>{label}</span>
                  {description && <p>{description}</p>}
                </div>
                <div>
                  {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </div>
              </div>
            </div>
          ) : (
            <a className="checkbox" onClick={handleClick}>
              <div>
                <span>{label}</span>
                {description && <p>{description}</p>}
              </div>
              <div>
                {value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              </div>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export { CheckboxField };
