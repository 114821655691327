import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useDeleteRecord } from "hooks/useDeleteReord";
import { InformationMessage } from "components/InformationMessage/InformationMessage";

function DeleteConfirmationDialog({
  open,
  onClose,
  onConfirm,
  confirmationText,
  deletePath,
  tableName,
  recordId,
  data,
  resetState,
}: any) {
  const {
    deleteRecord,
    processing,
    setOpenAlert,
    openAlert,
    alertMsg,
    severity,
  } = useDeleteRecord({ tableName, recordId, deletePath });
  const handleDelete = async () => {
    await deleteRecord(data);
    onConfirm();
    resetState();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="delete-confirmation-dialog-title"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          {"Eliminar Registro"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            {confirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus disabled={processing}>
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" disabled={processing}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      />
    </>
  );
}

export default DeleteConfirmationDialog;
