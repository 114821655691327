export const getFilters = () => {
  const data: any = {
    product: [
      {
        type: "text",
        fieldName: "name",
        label: "Nombre",
      },
      {
        type: "date",
        fieldName: "created_at",
        label: "Fecha de creación",
      },
      {
        type: "number",
        fieldName: "price",
        label: "Precio de venta",
      },
      {
        type: "multiSelect",
        fieldName: "brand_id",
        label: "Marcas",
        searchPath: "brands",
        primaryKey: "name",
      },
      {
        type: "tags",
        fieldName: "tags",
        label: "Etiquetas",
        groupId: 1,
      },
      {
        type: "boolean",
        fieldName: "is_sellable",
        label: "¿Se vende?",
      },
    ],
    brand: [],
    extra: [],
    location: [],
    //measurementUnit: [],
    serialNumber: [],
  };

  return data;
};
